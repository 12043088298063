import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    project: null,
}

const projectReducer = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProject: (state, action) => {
            state.project = action.payload
        },
    },
})

export const { setProject } = projectReducer.actions

export default projectReducer.reducer
