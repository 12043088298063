import React from 'react'
import cn from 'classnames'
import css from './Style.module.css'
import { StatusColorType } from 'types/index'

interface StatusBadgeProps {
    text: React.ReactNode
    type: StatusColorType
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ text, type }) => {
    return <div className={cn(css[type], css.badge)}>{text}</div>
}

export const Badge: React.FC<StatusBadgeProps> = ({ text, type }) => {
    return <div className={cn(css[type], css.badge2)}>{text}</div>
}
