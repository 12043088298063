import { RefreshCircle, Trash } from 'iconsax-react'
import { Badge } from 'components/landing/photographer/StatusBadge'
import { StatusColorMap } from 'utils/maps'
import { DataNotFound } from 'components/common/DataNotFound'
import { IconButton } from 'components/form/Button/Button'
import { TbDots } from '../../css/icons/icons'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { StatusColorType } from 'types/index'

const service = new UserService()

export const ClientsTable = ({ data, setData, loading }) => {
    const { successMessage } = useNotifications()

    const deleteClient = (clientId, email) => {
        service
            .revokeClient(email)
            .then(resp => {
                if (resp?.data?.status === 200) {
                    const newData = [...data].filter(
                        item => item?._id !== clientId,
                    )
                    setData(newData)
                    successMessage('Invitation revoked Successfully')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleResend = email => {
        service
            .resendInvitation(email)
            .then(() => {
                successMessage('Invitation resend Successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="min-w-full">
            <div className="hidden md:flex items-center text-zinc-500 font-TTHovesM p-4 mt-1">
                <p className="w-1/3" />
                <p className="flex-1">Project</p>
                <p className="flex-1">Status</p>
                <p className="flex-1" />
            </div>

            {/* Custom Table */}
            {!loading && data.length === 0 && (
                <DataNotFound title="You do not have any clients" />
            )}
            {loading ? (
                <div className="h-72 w-full flex items-center justify-center">
                    <RefreshCircle className="h-12 w-12 text-tale-10 animate-spin" />
                </div>
            ) : (
                <div className="flex flex-col gap-4 mt-1 min-w-full">
                    {/* Row 1 */}
                    {data.map(item => (
                        <div
                            key={item._id}
                            className="hidden md:flex items-center border border-zinc-200 rounded-lg p-4">
                            <div className="w-1/3 flex gap-2 items-center">
                                <div className="w-12 h-12 rounded-full  border border-zinc-200 bg-opacity-30 bg-brown-10">
                                    <img
                                        alt="profile"
                                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.profileImage}`}
                                        className="h-full w-full rounded-full"
                                    />
                                </div>
                                <div>
                                    <p className="text-slate-800 font-TTHovesM leading-[21px]">
                                        {item?.firstName + ' ' + item?.lastName}
                                    </p>
                                    <p className="text-zinc-600 text-sm">
                                        {item?.email}
                                    </p>
                                </div>
                            </div>
                            {item?.photoshootTitle ? (
                                <div className="flex-1">
                                    <span className="text-slate-800 font-TTHovesM">
                                        {item?.photoshootTitle}
                                    </span>

                                    <p className="text-zinc-600 text-sm mt-0.5">
                                        {new Date().toDateString()}
                                    </p>
                                </div>
                            ) : (
                                <p className="flex-1 text-gray text-sm">
                                    No Project yet
                                </p>
                            )}
                            <div className="flex-1">
                                <Badge
                                    type={
                                        item?.status === 'Active'
                                            ? StatusColorType.green
                                            : StatusColorType.grey
                                    }
                                    text={item?.status}
                                />
                            </div>

                            <div className="flex flex-1 justify-end gap-2">
                                {/*<IconButton*/}
                                {/*    onClick={() => console.log('Clicked')}*/}
                                {/*    icon={*/}
                                {/*        <BiLinkExternal className="h-5 w-5 text-tale-10" />*/}
                                {/*    }*/}
                                {/*    text="View project"*/}
                                {/*/>*/}
                                {/*<IconButton*/}
                                {/*    onClick={() => deleteClient(item?._id)}*/}
                                {/*    icon={*/}
                                {/*        <Trash className="h-5 w-5 text-zinc-700" />*/}
                                {/*    }*/}
                                {/*/>*/}

                                {item?.status !== 'Active' && (
                                    <Menu as="div" className="relative">
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="inline-flex px-4 py-2.5 bg-white rounded-lg  border border-zinc-300">
                                                    <TbDots />
                                                </Menu.Button>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items
                                                        static
                                                        className="flex flex-col absolute z-10 -left-[75px] mt-2 w-28 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleResend(
                                                                        item?.email,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                Resend
                                                            </button>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    deleteClient(
                                                                        item?._id,
                                                                        item?.email,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                Revoke
                                                            </button>
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                )}
                            </div>
                        </div>
                    ))}

                    {data.map(item => (
                        <div
                            key={item._id}
                            className="flex-col md:hidden items-center border border-zinc-200 rounded-lg p-4">
                            <div className="flex justify-between">
                                <div className=" flex gap-2 items-center">
                                    <div className="w-10 flex-shrink-0 h-10 rounded-full  border border-zinc-200 bg-opacity-30 bg-brown-10">
                                        <img
                                            alt="profile"
                                            src={
                                                item.profileImage
                                                    ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item.profileImage}`
                                                    : '/assets/images/blank.webp'
                                            }
                                            className="h-full w-full rounded-full"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-slate-800 text-sm md:text-body-normal font-TTHovesM leading-[21px]">
                                            {item?.firstName +
                                                ' ' +
                                                item?.lastName}
                                        </p>
                                        <p className="text-zinc-600 text-xs md:text-sm">
                                            {item?.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-end gap-2">
                                    {/*<IconButton*/}
                                    {/*    onClick={() => console.log('Clicked')}*/}
                                    {/*    icon={*/}
                                    {/*        <BiLinkExternal className="h-5 w-5 text-tale-10" />*/}
                                    {/*    }*/}
                                    {/*    text="View project"*/}
                                    {/*/>*/}
                                    {/*<IconButton*/}
                                    {/*    onClick={() => deleteClient(item._id)}*/}
                                    {/*    icon={*/}
                                    {/*        <Trash className="h-5 w-5 text-zinc-700" />*/}
                                    {/*    }*/}
                                    {/*/>*/}

                                    <Menu as="div" className="relative">
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="inline-flex px-4 py-2.5 bg-white rounded-lg  border border-zinc-300">
                                                    <TbDots />
                                                </Menu.Button>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items
                                                        static
                                                        className="flex flex-col absolute z-10 -left-[75px] mt-2 w-28 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleResend(
                                                                        item?.email,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                Resend
                                                            </button>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    deleteClient(
                                                                        item?._id,
                                                                        item?.email,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                Revoke
                                                            </button>
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>
                            </div>
                            {item?.projects?.length > 0 ? (
                                <div className="flex-1 mt-2">
                                    <span className="text-slate-800 font-TTHovesM">
                                        Wedding Shoot
                                    </span>

                                    <p className="text-zinc-600 text-sm mt-0.5">
                                        {new Date().toDateString()}
                                    </p>
                                </div>
                            ) : (
                                <p className="flex-1 text-gray text-sm mt-4">
                                    No Project yet
                                </p>
                            )}
                            <div className="flex-1 mt-4">
                                <Badge
                                    type={StatusColorMap['Dead']}
                                    text={item?.status}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* Custom Table End */}
        </div>
    )
}
