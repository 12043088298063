import { useEffect, useState } from 'react'
import styles from 'pages/auth/Auth.module.css'
import * as _ from 'lodash'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import { Add, RefreshCircle, Trash } from 'iconsax-react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import UserService from 'services/user.service'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setUser } from '../../redux/reducers/authReducer'
import { useNavigate } from 'react-router-dom'

const service = new UserService()

export const CreatePortfolio = ({ currentPage, stepIncrement, pageChange }) => {
    const [sessions, setSessions] = useState<any>([])
    const [imageURLS, setImageURLs] = useState<any>([])

    const user = useSelector(userSelector)
    const navigate = useNavigate()
    const dispatch = useThunkDispatch()

    useEffect(() => {
        if (user) {
            const copy = [...user.sessions]
            setSessions(copy)
        }
    }, [user])

    // useEffect(() => {
    //     if (images.length < 1) return
    //

    // files.forEach(image => {
    //     const index = newImageUrls.findIndex(i => i.session === session)
    //     if (index > -1) {
    //         newImageUrls[index].urls.push(URL.createObjectURL(image))
    //         newImageUrls[index].images.push(image)
    //     } else {
    //         newImageUrls.push({
    //             session,
    //             urls: [URL.createObjectURL(image)],
    //             images: [files],
    //         })
    //     }
    // })
    //
    // setImageURLs([...imageURLS, ...newImageUrls])
    // }, [images])

    function onImageChange(e, id, idx) {
        const copy = [...sessions]

        setSessions(
            copy.map(item => {
                if (item.session._id === id) {
                    return {
                        ...item,
                        uploading: true,
                    }
                }
                return item
            }),
        )
        const files = e.target.files

        const newImageUrls = [...imageURLS]
        const index = newImageUrls.findIndex(i => i.session === id)
        service
            .getImagesURL({ images: files })
            .then(resp => {
                if (index > -1) {
                    newImageUrls[index].images.push(...resp.data.urls)
                } else {
                    newImageUrls.push({
                        session: id,
                        images: resp.data.urls,
                    })
                }

                setImageURLs(newImageUrls)
            })
            .catch(error => {
                console.log('Error: ', error)
            })
            .finally(() => {
                copy.map(item => {
                    if (item._id === id) {
                        return {
                            ...item,
                            uploading: false,
                        }
                    }
                    return item
                })
                setSessions(copy)
            })
    }

    // const handleChange = useCallback((e, idx) => {
    //     const files = Array.from(e.target.files)
    //     files.forEach((file, index) => {
    //         console.log(`file${index + 1}`, file)
    //     })
    //
    //     const images: any = []
    //     files.forEach(file => {
    //         const reader = new FileReader()
    //         reader.onload = event => {
    //             images.push(event.target?.result as string)
    //         }
    //         reader.readAsDataURL(file as Blob)
    //     })
    //
    //     const values = [...sessions]
    //     values[idx].images = images
    //     setSessions(values)
    // }, [])

    const handleDeleteImage = (idx, index) => {
        const shallowCopy = [...imageURLS]
        shallowCopy[idx].images.splice(index, 1)
        setImageURLs(shallowCopy)
    }
    const handleSubmit = e => {
        e.preventDefault()
        user.sessions.forEach(item => {
            delete item.uploading
        })
        service
            .createPortfolio(imageURLS)
            .then(resp => {
                const updated = {
                    ...user,
                    portfolio: resp.data.data.portfolio,
                }
                dispatch(setUser(updated))
                if (user?.userType === 'Client') {
                    navigate('/dashboard')
                } else {
                    pageChange(pages.NEXT)
                    stepIncrement(4)
                }
            })
            .catch(error => {
                console.log({ error })
            })
    }

    return (
        <div
            id="page-4"
            style={{
                display:
                    currentPage === 3 && user?.userType === 'Photographer'
                        ? 'block'
                        : 'none',
            }}>
            <div className="grid h-full w-full">
                <p className="leading-10 text-body-caption-bold text-dark-8 font-OTabolas">
                    Create portfolios to showcase your work
                </p>
                <p className={styles.messageText}>
                    Showcase your photos in public portfolios for each of your
                    different session types.
                </p>
                <hr className="text-zinc-200 mt-8" />
                <div className="mt-6">
                    {sessions.map((item, idx) => (
                        <div key={item._id} className="w-full">
                            <div className="text-zinc-700 font-TTHoves font-semibold">
                                {item.session.name}
                            </div>

                            <div className="text-body-thin text-zinc-600 font-TTHovesEL">
                                Max 100mb per image. JPEG, PNG, and GIF files
                                accepted.
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-4 mt-4">
                                {item.uploading ? (
                                    <div className="flex items-center justify-center">
                                        <RefreshCircle className="animate-spin h-10 w-10 my-4 text-tale-10" />
                                    </div>
                                ) : (
                                    imageURLS?.map((image: any, i) => {
                                        return (
                                            image.session ===
                                                item.session._id &&
                                            image.images.map((url, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="h-[240px] rounded-xl mb-6 mr-3 relative">
                                                        <img
                                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${url}`}
                                                            className="h-full w-full object-cover rounded-xl"
                                                            alt="session-image"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleDeleteImage(
                                                                    i,
                                                                    index,
                                                                )
                                                            }
                                                            className="absolute top-3 right-3">
                                                            <Trash className="text-red" />
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        )
                                    })
                                )}
                                <label
                                    id="dropzone"
                                    className="flex flex-row justify-center items-center border cursor-pointer border-dashed border-gray-outline rounded-xl h-[240px] p-8 mb-6 mr-3">
                                    <Add className="text-zinc-600" />
                                    <input
                                        type="file"
                                        className="hidden"
                                        multiple
                                        name="images"
                                        id="dropzone"
                                        accept="image/*"
                                        onChange={e =>
                                            onImageChange(
                                                e,
                                                item.session._id,
                                                idx,
                                            )
                                        }
                                    />
                                </label>
                            </div>
                            <hr className="text-zinc-200 pt-3 pb-2" />
                        </div>
                    ))}

                    <div className="py-6">
                        <Button
                            type="submit"
                            className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-full"
                            onClick={handleSubmit}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
