import { Modal } from 'components/common/Modal'
import { Profile2User } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { TextArea } from 'components/form/Input/TextArea'
import UserService from 'services/user.service'

const service = new UserService()

export const CopyLinkModal = ({ showModal, setShowModal }) => {
    const [projects, setProjects] = useState<any>([])

    useEffect(() => {
        service
            .getBookings()
            .then(resp => {
                setProjects(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <Modal
            width="w-full md:w-2/3 2xl:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            title="Link Gallery"
            footerButton="Save"
            description=""
            handleClickPrimary={() => console.log('')}
            headerIcon={<Profile2User />}>
            {/* Form */}
            <form className="p-5">
                <div className="flex flex-col gap-3">
                    <div>
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Select Project *
                        </label>
                        <ReactSelect
                            value={''}
                            onChange={() => console.log()}
                            options={projects.map(item => ({
                                label: item.photoshootTitle,
                                value: item._id,
                            }))}
                            placeholder={'Choose a project'}
                        />
                    </div>

                    {/*<div>*/}
                    {/*    <label className="text-sm text-zinc-700 font-TTHovesM">*/}
                    {/*        Select Project **/}
                    {/*    </label>*/}
                    {/*    <ReactSelect*/}
                    {/*        value={''}*/}
                    {/*        onChange={() => console.log()}*/}
                    {/*        options={[]}*/}
                    {/*        placeholder={''}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Email Message
                        </label>
                        <TextArea value="" />
                    </div>
                </div>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
