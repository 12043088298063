import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowRight2, Home2, RefreshCircle } from 'iconsax-react'
import { Input } from 'components/form/Input/Input'
import UserService from 'services/user.service'
import { useCollaborations } from 'hooks/useCollaborations'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { RichEditor } from 'components/common/RichEditor'
import { useNotifications } from 'hooks/useNotification'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LocationSelect } from 'components/form/LocationSelect'
import { formatTime } from 'utils/funcs'
import { Times } from 'utils/constants'

const service = new UserService()

export const AddPost = () => {
    const navigate = useNavigate()
    const { collaborations } = useCollaborations()

    const user = useSelector(userSelector)
    const [params] = useSearchParams()
    const { successMessage } = useNotifications()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({ title: '', category: '' })
    const [state, setState] = useState({
        title: '',
        collaborationId: '',
        description: '',
        category: '',
        location: '',
        date: '',
        time: '',
        userId: user?._id,
    })

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target

        if (name === 'title') {
            setError({
                ...error,
                title: '',
            })
        }

        setState({
            ...state,
            [name]: value,
        })
    }

    useEffect(() => {
        if (params.has('id')) {
            setLoading(true)
            service
                .getPost(params.get('id') ?? '')
                .then(resp => {
                    setState({
                        ...state,
                        title: resp.data.data.title,
                        description: resp.data.data.description,
                        collaborationId: resp.data.data.collaborationId,
                        location: resp.data.data.location,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [params])

    const handleSubmit = e => {
        e.preventDefault()

        if (!state.collaborationId && !state.title) {
            setError({
                ...error,
                category: 'Please select category',
                title: 'Please enter title',
            })
            return
        }
        if (!state.title) {
            setError({
                ...error,
                title: 'Please enter title',
            })
            return
        }

        if (params.has('id')) {
            service
                .updatePost(params.get('id') ?? '', state)
                .then(() => {
                    navigate('/dashboard/board?tab=posts')
                    successMessage('Post updated successfully')
                })
                .catch(error => {
                    console.log('Error Adding: ', error)
                })
        } else {
            service
                .addPost({ ...state })
                .then(() => {
                    navigate('/dashboard/board?tab=posts')
                    successMessage('Post added successfully')
                })
                .catch(error => {
                    console.log('Error Adding: ', error)
                })
        }
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <p className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Directory
                    </p>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <p className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        Collab Board
                    </p>
                </div>
                <div className="flex items-center gap-3">
                    <button
                        type="button"
                        onClick={() => navigate('/dashboard/board?tab=posts')}
                        className={styles.default}>
                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                            Cancel
                        </span>
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className={styles.primary}>
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            {params.has('id') ? 'Update' : 'Publish'} Post
                        </span>
                    </button>
                </div>
            </div>
            <div className="py-5 border-b border-zinc-200">
                <div className="text-slate-800 text-lg font-TTHovesSB leading-7">
                    What do you need help with?
                </div>
                <div className="text-slate-600 text-sm">
                    Tell us about your project needs.
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-10 mt-9">
                {/* Form Start */}
                {loading ? (
                    <RefreshCircle className="text-tale-10 mx-auto animate-spin size-10 my-8" />
                ) : (
                    <div className="w-full md:w-2/3">
                        <form className="flex flex-col gap-8">
                            <Input
                                type="text"
                                placeholder="Enter Title"
                                label="Post Title"
                                name="title"
                                error={error.title}
                                maxLength={150}
                                value={state.title}
                                onChange={handleChange}
                            />
                            <ReactSelect
                                error={error.category}
                                options={collaborations.map(item => ({
                                    value: item._id,
                                    label: item.name,
                                }))}
                                onChange={value => {
                                    setState({
                                        ...state,
                                        collaborationId: value,
                                        category: value,
                                    })
                                    setError({
                                        ...error,
                                        category: '',
                                    })
                                }}
                                label="Category"
                                placeholder="Select Category"
                                value={state.collaborationId}
                            />
                            <div className="flex items-center gap-4">
                                <div className="flex-1">
                                    <Input
                                        type="date"
                                        value={state.date}
                                        min={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        label="Shoot Date"
                                        onChange={e =>
                                            setState({
                                                ...state,
                                                date: e.target.value,
                                            })
                                        }
                                        placeholder=""
                                    />
                                </div>
                                <div className="flex-1">
                                    <div className="relative">
                                        <ReactSelect
                                            label="Shoot Time"
                                            value={state?.time}
                                            onChange={value =>
                                                setState({
                                                    ...state,
                                                    time: value,
                                                })
                                            }
                                            options={Times}
                                            placeholder="Select shoot time"
                                        />

                                        {/*<DatePicker*/}
                                        {/*    placeholder="Select shoot time"*/}
                                        {/*    selected={state.time}*/}
                                        {/*    onChange={time =>*/}
                                        {/*        setState({*/}
                                        {/*            ...state,*/}
                                        {/*            time,*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*    showTimeSelect*/}
                                        {/*    customInput={*/}
                                        {/*        <input*/}
                                        {/*            placeholder="Select date"*/}
                                        {/*            className="w-full h-14 bg-transparent rounded-xl border border-gray-outline outline-none focus-visible:shadow-none shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*    showTimeSelectOnly*/}
                                        {/*    timeIntervals={15}*/}
                                        {/*    timeCaption="Time"*/}
                                        {/*    dateFormat="h:mm aa"*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            </div>
                            <LocationSelect
                                value={state.location}
                                onChange={value =>
                                    setState({
                                        ...state,
                                        location: value,
                                    })
                                }
                                label="Select location"
                            />
                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="w-2/3">
                                    <p className="text-slate-800 font-TTHovesSB leading-tight">
                                        Description
                                    </p>
                                    <p className="text-slate-600 text-sm leading-tight mt-1">
                                        Write a short description.
                                    </p>
                                </div>
                                <RichEditor
                                    content={state.description}
                                    setContent={value =>
                                        setState({
                                            ...state,
                                            description: value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>
                )}
                {/* Form End */}
            </div>
        </Fragment>
    )
}

const styles = {
    primary:
        'px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex',
    default:
        'px-4 py-2.5 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex',
}
