import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

const PaymentMethod: React.FC<{ amount: number }> = ({ amount }) => {
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null)

    const options = {
        clientSecret:
            'pi_3Q3JaLCWK1FvgFDX1Mg2HjO7_secret_SiMOrEpB5D3BDSFxQMdbgZuKn',
    }

    useEffect(() => {
        const preFetch = async () => {
            const stripeC = await loadStripe(
                'pk_test_51O2RbECWK1FvgFDXT5EPKaLBLCcmhJjK7ZolW7YjRZUBoxU35fjwlXM6aex2zPctEf3Ml97Toq9gOpxTwlOQSdyP003BZsab2h',
            )

            setStripePromise(stripeC)
        }
        preFetch()
    }, [])

    return (
        <div className="bg-white p-9 rounded-xl">
            <Elements stripe={stripePromise} options={options}>
                <form>
                    <PaymentElement />
                    <button>Submit</button>
                </form>
            </Elements>
        </div>
    )
}

export default PaymentMethod
