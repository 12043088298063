import React, { Fragment, useEffect, useState } from 'react'
import { Add, ArrowRight, ArrowRight2, Home2 } from 'iconsax-react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CheckCircle } from '../../../css/icons/icons'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { IconButton } from 'components/form/Button/Button'
import UserService from 'services/user.service'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setProject } from '../../../redux/reducers/projectReducer'
import { TabButton } from 'pages/dashboard/business/Service'

const service = new UserService()

export const CreateContract = () => {
    const navigate = useNavigate()
    const dispatch = useThunkDispatch()
    const { project } = useSelector((state: any) => state.project)
    const user = useSelector(userSelector)

    const { id } = useParams()

    const basic = false

    const [params] = useSearchParams()

    useEffect(() => {
        service
            .getSpecificProject(id ?? '')
            .then(resp => {
                dispatch(setProject(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
    }, [id])

    return (
        <Fragment>
            <div className="flex items-center justify-between ">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Business
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <p>...</p>
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {project?.photoshootTitle}
                    </div>
                </div>
            </div>

            <div className="w-full p-1 overflow-auto bg-white rounded-lg border border-zinc-200 justify-start items-center gap-2 inline-flex mt-4">
                <TabButton
                    label="Overview"
                    isActive={!params.has('tab')}
                    onClick={() =>
                        console.log(`/dashboard/business/contract/${id}`)
                    }
                />
                <TabButton
                    label="Questionnaires"
                    isActive={params.get('tab') === 'questionnaires'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=questionnaires`,
                        )
                    }
                />
                <TabButton
                    label="Contract"
                    isActive={params.get('tab') === 'contract'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=contract`,
                        )
                    }
                />
                <TabButton
                    label="Invoices"
                    isActive={params.get('tab') === 'invoices'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=invoices`,
                        )
                    }
                />
                {/*<TabButton*/}
                {/*    label="Messages"*/}
                {/*    isActive={params.get('tab') === 'email'}*/}
                {/*    onClick={() =>*/}
                {/*        navigate(*/}
                {/*            '/dashboard/business/deliverables?tab=questionnaires',*/}
                {/*        )*/}
                {/*    }*/}
                {/*/>*/}
                <TabButton
                    label="Notes"
                    isActive={params.get('tab') === 'invoice'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=notes`,
                        )
                    }
                />
            </div>

            <div className="flex flex-col md:flex-row gap-2 mt-4 items-start">
                <div className="w-full bg-white rounded-xl border border-zinc-200 p-5 relative">
                    <p className="font-OTabolas text-2xl">
                        {project?.photoshootTitle}
                    </p>
                    <p className="text-zinc-600 mt-1">Project Activity</p>
                    <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                        <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                            Next Step
                            <ArrowRight className="h-3 w-3" />
                        </button>
                        <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                            Generate an Invoice
                        </p>
                        <p className="text-zinc-600 text-sm">
                            Create a contract invoice and sent it to the client.
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                        <IconButton
                            container="mt-4"
                            icon={<Add className="text-white h-5 w-5" />}
                            text="Create Invoice"
                            variant="primary"
                            onClick={() =>
                                navigate(`/dashboard/photoshoot/invoice`)
                            }
                        />
                    </div>
                    <div className="relative flex flex-col gap-8 mt-6 ml-5 z-10">
                        <div className="absolute h-full w-px bg-zinc-200 left-3 -top-6 -z-10" />

                        <div className="flex items-center gap-3 z-10">
                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />

                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                Contract Added
                            </p>
                        </div>

                        <div className="flex items-center gap-3">
                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                Project Created
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className="hidden md:block w-[350px] bg-[#DEEDEE] rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-800 font-TTHovesM">
                            Collaborate
                        </p>

                        <p className="mt-1 text-zinc-600 text-sm font-TTHovesM  leading-tight">
                            Collaborate on job with other photographers
                        </p>
                    </div>
                    <div className=" bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                            CLIENT
                        </p>
                        <div className="flex gap-3 items-center mt-4">
                            <div className="h-12 w-12 rounded-full relative">
                                <img
                                    alt="profile"
                                    className="h-full w-full rounded-full object-cover object-top"
                                    src={user.profileImage}
                                />
                                <div className="absolute bottom-0.5 right-1 h-2 w-2 rounded-full bg-green-600" />
                            </div>
                            <div>
                                <div className="text-zinc-700 text-base font-TTHovesSB ">
                                    {user?.firstName + ' ' + user?.lastName}
                                </div>
                                <div className="text-zinc-600 text-sm leading-[18px]">
                                    {user?.location}
                                </div>
                            </div>
                        </div>
                        <div className="h-px w-full my-4 bg-zinc-200" />
                        <div className="text-zinc-700 text-base font-TTHovesSB">
                            Pre-launch checklist
                        </div>
                        <div className="text-zinc-600 text-[13px] leading-[18px]">
                            Complete the checklist in oder to submit the job
                            quotation
                        </div>
                        <div className="flex flex-col gap-6 mt-4 relative z-10">
                            <div className="absolute h-full w-px bg-zinc-200 left-3 -z-10" />

                            <div className="flex items-center gap-3">
                                <CheckCircle className="text-[#E8C0A6] w-6 h-6" />

                                <p className="text-zinc-600 text-sm">
                                    Proposal Created
                                </p>
                            </div>
                            <div className="flex items-center gap-3">
                                <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                <p className="text-zinc-600 text-sm">
                                    Create Contract
                                </p>
                            </div>
                            <div className="flex items-center gap-3">
                                {project?.invoiceCreated ? (
                                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                    </div>
                                ) : (
                                    <CheckCircle className="text-zinc-200 w-6 h-6" />
                                )}
                                <p className="text-zinc-900 text-sm font-TTHovesM">
                                    Create Invoice
                                </p>
                            </div>
                            <div className="flex items-center gap-3">
                                {project?.clientSignature ? (
                                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                    </div>
                                ) : (
                                    <CheckCircle className="text-zinc-200 w-6 h-6" />
                                )}
                                <p className="text-zinc-600 text-sm">
                                    Client Signature
                                </p>
                            </div>
                            <div className="flex items-center gap-3">
                                {basic ? (
                                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                    </div>
                                ) : (
                                    <CheckCircle className="text-zinc-200 w-6 h-6" />
                                )}
                                <p className="text-zinc-600 text-sm">
                                    Project Active
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
