import React, { useState } from 'react'
import { Modal } from 'components/common/Modal'
import { Profile2User, RefreshCircle } from 'iconsax-react'
import { IconGallery } from '../../../css/icons/icons'
import UserService from 'services/user.service'

const service = new UserService()

export const UploadImageModal = ({
    showModal,
    setShowModal,
    setData,
    data,
    id,
}) => {
    const [image, setImage] = useState('')
    const [loading, setLoading] = useState(false)

    const uploadImage = e => {
        setLoading(true)
        const file = e.target.files
        service
            .getImagesURL(file)
            .then(rep => {
                setData({
                    ...data,
                    images: [...data.images, ...rep.data.urls],
                })
                setImage(rep.data.urls[0])
                service
                    .updateGallery(id, rep.data.urls.join(','))
                    .then(resp => {
                        setImage('')
                        setLoading(false)
                        setData(resp.data.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
                setImage('')
            })
    }

    return (
        <Modal
            width="w-full md:w-2/3 2xl:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            title="Upload and attach files"
            footerButton="Attach Files"
            handleClickPrimary={() => console.log('')}
            headerIcon={<Profile2User />}>
            {/* Form */}
            <form className="p-5">
                {loading ? (
                    <div className="mx-auto">
                        <RefreshCircle className="animate-spin mx-auto text-tale-10 h-10 w-10" />
                    </div>
                ) : image ? (
                    <div className="h-44 w-full">
                        <img
                            alt="photo"
                            src={image}
                            className="h-full w-full object-contain"
                        />
                    </div>
                ) : (
                    <label id="cover-dropzone">
                        <div className="cursor-pointer flex flex-row justify-center items-center border border-dashed border-gray-outline rounded-xl h-44">
                            <div className="flex flex-col justify-center items-center">
                                <div className="mb-1">
                                    <IconGallery />
                                </div>
                                <div className="text-base font-TTHovesM text-tale-10 text-center">
                                    Click to upload
                                    <span className="text-base font-TTHoves text-normal text-dark-5 ">
                                        &nbsp; or drag and drop
                                    </span>
                                </div>
                                <div className="text-xs font-TTHoves text-normal text-dark-5">
                                    PNG, JPG or GIF (Optimal size 1440 x 500px)
                                </div>
                                <input
                                    id="cover-dropzone"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={uploadImage}
                                    className="hidden"
                                />
                            </div>
                        </div>
                    </label>
                )}
            </form>
            <hr className="text-zinc-200 w-full " />
        </Modal>
    )
}
