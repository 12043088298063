import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Add,
    ArrowRight2,
    GalleryAdd,
    Home2,
    Lock,
    Profile2User,
    RefreshCircle,
    Trash,
} from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'components/common/Modal'
import { Input } from 'components/form/Input/Input'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { ConfirmModal } from 'components/common/ConfirmModal'

const service = new UserService()

export const ClientGallery = () => {
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [data, setData] = useState<any>([])
    const [submitting, setSubmitting] = useState(false)
    const [id, setId] = useState('')
    const [passwordModal, setPasswordModal] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const navigate = useNavigate()
    const { successMessage } = useNotifications()
    const user = useSelector(userSelector)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getGalleries()
            .then(resp => {
                setData(resp.data.data)
            })
            .catch(error => {
                console.log('Gallery', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    const handleSubmit = e => {
        setSubmitting(true)
        e.preventDefault()
        service
            .createGallery({ title, images: [], userId: user._id })
            .then(resp => {
                setData([...data, { ...resp.data.data }])
                navigate(`/dashboard/gallery/${resp.data.data._id}`)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setTitle('')
                setShowModal(false)
                setSubmitting(false)
            })
    }

    const deleteGallery = id => {
        setConfirmModal(true)
        setId(id)
    }

    const handleDeleteGallery = () => {
        service
            .deleteClientGallery(id)
            .then(() => {
                const index = data.findIndex(item => item._id === id)
                const updated = [...data]
                updated.splice(index, 1)
                setData(updated)
                successMessage('Gallery Deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setConfirmModal(false)
            })
    }

    const openGallery = id => {
        // if (index === 0) {
        // setPasswordModal(true)
        // } else {
        navigate(`/dashboard/gallery/${id}`)
        // }
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onChange={() => navigate(-1)}
                        className="cursor-pointer px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        Client Galleries
                    </div>
                </div>

                <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    <Add className="text-white h-5 w-5" />
                    <span className="text-white text-sm font-TTHovesM ">
                        New Client Gallery
                    </span>
                </button>
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-6 p-5 w-full">
                <div className="border-b border-zinc-200 pb-5">
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        Client Galleries
                    </p>
                    {/*<p className="text-slate-600 text-sm leading-tight">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                    {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                    {/*    magna aliqua.*/}
                    {/*</p>*/}
                </div>

                <div className="flex flex-col md:flex-row gap-4 mt-4">
                    <div className="px-6 shadow-sm py-4 bg-violet-200 rounded-xl">
                        <p className="text-zinc-700 text-lg font-TTHovesSB">
                            Galleries
                        </p>
                        <p className=" text-gray-600 text-[15px] font-TTHovesM">
                            Bonus for Suura subscribers. Try the beta version of
                            Surra galleries for free
                        </p>
                    </div>
                    <div className="px-6 shadow-sm py-4 bg-white border border-zinc-200 rounded-xl">
                        <p className="flex gap-2 items-center">
                            <span className="text-zinc-700 text-lg font-TTHovesSB">
                                Free Gallery
                            </span>
                            <span className="text-zinc-400 text-sm font-TTHovesM">
                                1 of 1
                            </span>
                        </p>
                        <p className="text-gray-600 text-[15px] font-TTHovesM">
                            <span className="underline text-zinc-800 underline-offset-2">
                                Upgrade
                            </span>{' '}
                            to pro version of Suura to get access of unlimited
                            galleries
                        </p>
                    </div>
                </div>
                <div className="flex flex-wrap gap-4 mt-4">
                    <div
                        onClick={() => setShowModal(true)}
                        className="w-full cursor-pointer md:w-[245px] h-[270px] overflow-hidden flex flex-col items-center justify-center bg-gradient-to-b from-white to-tale-10/20 rounded-xl border-2 border-dashed border-zinc-200">
                        <GalleryAdd className="h-8 w-8" />
                        <div className="text-slate-800 text-sm mt-2">
                            Create new client gallery
                        </div>
                        {/*<div className="w-[181px]  text-center text-slate-600 text-xs mt-1">*/}
                        {/*    Lorem ipsum dolor set amet is a dummy text here*/}
                        {/*</div>*/}
                    </div>
                    {loading ? (
                        <RefreshCircle className="text-tale-10 mx-auto my-10 size-10 animate-spin" />
                    ) : (
                        data.map((item, index) => {
                            return (
                                <div
                                    key={item._id}
                                    className="w-full cursor-pointer md:w-[245px] h-[270px] relative overflow-hidden rounded-xl">
                                    {item.images.length > 0 ? (
                                        <img
                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.images[0]}`}
                                            alt="gallery1"
                                            onClick={() =>
                                                openGallery(item._id)
                                            }
                                            className="h-full w-full object-cover cursor-pointer"
                                        />
                                    ) : (
                                        <div
                                            onClick={() =>
                                                openGallery(item._id)
                                            }
                                            className="w-full cursor-pointer md:w-[245px] h-[270px] overflow-hidden flex flex-col items-center justify-center bg-zinc-200 rounded-xl border-2 border-dashed border-zinc-200">
                                            <div className="text-slate-800 text-sm mt-2">
                                                Upload Photos to Gallery
                                            </div>
                                        </div>
                                    )}
                                    <div className="absolute bottom-1.5 px-3 py-2 flex items-center justify-between w-full">
                                        <p className="text-white font-TTHovesM">
                                            {item.title}
                                        </p>
                                        <div className="font-TTHovesM text-center text-white text-xs px-1.5 py-0.5 bg-white bg-opacity-40 rounded-md shadow border border-zinc-400">
                                            {item.images.length}
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            deleteGallery(item._id)
                                        }}
                                        className="absolute right-4 top-4 z-10 text-red">
                                        <Trash />
                                    </button>
                                    {/*{index === 0 && (*/}
                                    {/*    <button*/}
                                    {/*        type="button"*/}
                                    {/*        className="absolute left-4 top-4 z-10 text-zinc-100">*/}
                                    {/*        <Lock />*/}
                                    {/*    </button>*/}
                                    {/*)}*/}
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
            <Modal
                width="w-full md:w-2/3 2xl:w-1/3"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Create Client Gallery"
                footerButton="Save"
                handleClickPrimary={handleSubmit}
                headerIcon={<Profile2User />}>
                {/* Form */}
                <form onSubmit={handleSubmit} className="p-5">
                    {!submitting ? (
                        <Input
                            type="text"
                            label="Title"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            placeholder="Gallery Title"
                        />
                    ) : (
                        <div className="py-4 mx-auto">
                            <RefreshCircle className="animate-spin text-tale-10 mx-auto h-12 w-12" />
                        </div>
                    )}
                </form>
                <hr className="text-zinc-200 w-full " />
                {/* Form End */}
            </Modal>

            {/*Password Modal*/}
            <Modal
                width="w-full md:w-2/3 2xl:w-1/3"
                showModal={passwordModal}
                setShowModal={setPasswordModal}
                title="Password Protected"
                description="Enter password to view the gallery"
                footerButton="View"
                handleClickPrimary={handleSubmit}
                headerIcon={<Lock />}>
                {/* Form */}
                <form onSubmit={handleSubmit} className="p-5">
                    <Input
                        type="text"
                        label="Password"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Enter password"
                    />
                </form>
                <hr className="text-zinc-200 w-full " />
                {/* Form End */}
            </Modal>

            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                onConfirm={handleDeleteGallery}
                title="Are you sure to delete the gallery?"
                description="The gallery and its images will be deleted permanently"
            />
        </Fragment>
    )
}
