import { StatusColorType, StatusType } from 'types/index'

export const StatusColorMap = {
    [StatusType.AVAILABLE]: StatusColorType.green,
    [StatusType.RESPONSE]: StatusColorType.orange,
    [StatusType.COLD]: StatusColorType.blue,
    [StatusType.DEAD]: StatusColorType.grey,
    [StatusType.OPEN]: StatusColorType.blueLight,
    [StatusType.ACTIVE]: StatusColorType.green,
    [StatusType.REVIEW]: StatusColorType.pink,
    [StatusType.COMPLETED]: StatusColorType.violet,
    [StatusType.PROGRESS]: StatusColorType.progress,
}
