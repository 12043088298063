import React from 'react'
import classNames from 'classnames'
import { Add, DollarCircle } from 'iconsax-react'
import { InvoicesTable } from 'components/tables/InvoicesTable'
import { useNavigate } from 'react-router-dom'

export const Invoices = ({ tab }) => {
    const navigate = useNavigate()

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 md:items-center md:justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-2xl font-OTabolas">Invoices</p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}

                <div className="flex gap-2">
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    */}
                    {/*    className="w-full md:w-auto border border-zinc-200 px-4 py-2.5 bg-white rounded-lg shadow justify-center items-center gap-2 inline-flex">*/}
                    {/*    <Add className=" h-5 w-5" />*/}
                    {/*    <span className="text- text-sm font-TTHovesM ">*/}
                    {/*        Create Invoice*/}
                    {/*    </span>*/}
                    {/*</button>*/}
                    <button
                        type="button"
                        onClick={() =>
                            navigate('/dashboard/photoshoot/invoice')
                        }
                        className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <DollarCircle className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Generate Invoice
                        </span>
                    </button>
                </div>
            </div>
            <div className="mt-6">
                <InvoicesTable />
            </div>
        </div>
    )
}
