import styles from 'pages/auth/Auth.module.css'
import { IconInstagramBlank } from '../../css/icons/icons'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()

export const LinkPlatform = ({ currentPage, stepIncrement, pageChange }) => {
    const user = useSelector(userSelector)

    const handleConnect = () => {
        service.connectInstagram().then(resp => {
            console.log(resp)
        })
    }

    console.log(user?.userType)

    return (
        <div
            id="page-2"
            style={{
                display:
                    currentPage === 2 && user.userType === 'Photographer'
                        ? 'block'
                        : 'none',
            }}>
            <div className="w-full">
                <p className="font-normal text-body-caption-bold text-dark-8 font-OTabolas">
                    Quickly populate your portfolio
                </p>
                <p className={`mt-1 ${styles.messageText}`}>
                    Link your Insta account to easily upload your gorgeous feed!
                    Or, manually select your faves later.
                </p>
                <div>
                    <button
                        onClick={handleConnect}
                        className="w-full flex flex-row items-center border border-dashed border-gray-outline rounded-xl p-8 my-6">
                        <div className="mr-10">
                            <IconInstagramBlank />
                        </div>
                        {/* <IconLineVertical /> */}
                        <p className="text-base text-dark-8 font-TTHovesSB font-extrabold">
                            Connect to Instagram
                        </p>
                    </button>
                    <div className="py-6">
                        <Button
                            onClick={() => {
                                pageChange(pages.NEXT)
                                stepIncrement(2)
                            }}
                            className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-full md:w-1/3">
                            Next Step
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
