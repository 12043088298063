import styles from 'pages/auth/Auth.module.css'

export const AuthRightSide = () => {
    return (
        <div className="hidden flex-1 md:block relative h-screen p-5 bg-white">
            <div className="h-full w-full">
                <img
                    src="/assets/image-signup-page.jpg"
                    alt="signup-display-picture"
                    className="h-full w-full rounded-xl object-cover"
                />
            </div>
            <div className="absolute bottom-0 p-10 text-white">
                <div className={styles.captionMessage}>
                    I was glad the day I discovered Suura. As the name
                    indicates, it makes the process of connecting with client
                    for re-building shoots incredibly easy.
                </div>
                <div className={styles.authorContainer}>
                    <img
                        src="/assets/author-image.jpg"
                        alt="signup-display-picture"
                        className="h-10 w-10 rounded-full"
                    />
                    <div className="ml-2">
                        <div className="text-sm ">Danyal Saif</div>
                        <div className="text-xs opacity-50">@danyalsaif</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
