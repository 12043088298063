export let booking = [
    {
        day: 'Monday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Tuesday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Wednesday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Thursday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Friday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Saturday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
    {
        day: 'Sunday',
        openToWork: false,
        workingHours: [
            {
                from: '',
                to: '',
            },
        ],
    },
]

export const initWeekDays = {
    title: '',
    timezone: '',
    weekDays: booking,
}

export const initViewAvailability = {
    timezone: '',
    weekDays: [],
    title: '',
}
