import classNames from 'classnames'
import { ArrowLeft, DocumentDownload } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()

export const ViewInvoice = () => {
    const [invoice, setInvoice] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    // const [invoiceTerms, setInvoiceTerms] = useState('')

    const { project } = useSelector((state: any) => state.project)
    const user = useSelector(userSelector)
    const { id } = useParams()
    const [params] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        service
            .getSpecificInvoice(id ?? '', params.get('invoice'))
            .then(resp => {
                setInvoice(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [params])

    const handleClickPaid = () => {
        service
            .updateInvoiceStatus(project._id, {
                invoiceId: id,
                paymentStatus: 'Mark as Paid',
            })
            .then(resp => {
                console.log(resp)
            })
    }

    // useEffect(() => {
    //     const getId =
    //         project?.proposalCreatedAs === 'Photographer'
    //             ? project?.proposalCreatedBy?._id
    //             : project?.photographers[0]?._id
    //     service
    //         .getInvoiceTermsByUser(getId ?? '')
    //         .then(resp => {
    //             setInvoiceTerms(resp.data.data.invoiceTerms)
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }, [])

    return (
        <div
            className={classNames(
                'bg-white rounded-xl print:border-0 border border-zinc-200 print:p-0 p-5 w-full',
            )}>
            <div className="print:hidden border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 md:items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-2xl font-OTabolas">Invoices</p>

                <div className="flex gap-2">
                    <button
                        onClick={() => navigate(-1)}
                        type="button"
                        className="w-full md:w-auto border border-zinc-200 px-2 md:px-4 py-2.5 bg-white rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <ArrowLeft className=" h-5 w-5" />
                        <span className="text- text-sm font-TTHovesM ">
                            Back
                        </span>
                    </button>
                    {user?.userType === 'Client' && (
                        <button
                            type="button"
                            onClick={() => handleClickPaid()}
                            className="w-full md:w-auto px-2 md:px-4 py-2.5 bg-tale-10 rounded-lg shadow md:justify-center items-center gap-2 inline-flex">
                            {/*<DocumentDownload className="text-white h-5 w-5" />*/}
                            <span className="text-white text-sm font-TTHovesM ">
                                Mark as Paid
                            </span>
                        </button>
                    )}
                    <button
                        type="button"
                        onClick={() => window.print()}
                        className="w-full md:w-auto px-2 md:px-4 py-2.5 bg-tale-10 rounded-lg shadow md:justify-center items-center gap-2 inline-flex">
                        <DocumentDownload className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Download PDF
                        </span>
                    </button>
                </div>
            </div>
            <article className="overflow-hidden">
                <div className="bg-[white] rounded-b-md">
                    <div className="hidden print:block mb-4 text-body-bold text-dark-8 font-OTabolas">
                        Suura
                    </div>
                    <div className="print:p-0 p-9">
                        <div className="flex w-full">
                            <div className="grid grid-cols md:grid-cols-3 gap-6 md:gap-12 w-full">
                                <div className="text-sm font-light text-slate-500">
                                    <p className="text-sm text-zinc-700">
                                        INVOICE #
                                    </p>
                                    <p className="text-zinc-800 font-TTHovesM">
                                        {invoice?.invoiceNumber}
                                    </p>
                                    <p className="text-sm text-zinc-700 mt-3">
                                        Issued
                                    </p>
                                    <p className="text-zinc-800 font-TTHovesM">
                                        {new Date(
                                            invoice?.issuedOn,
                                        ).toDateString()}
                                    </p>
                                </div>
                                <div className="text-sm font-light text-slate-500">
                                    <p className="text-sm text-zinc-700">
                                        Billed To
                                    </p>
                                    <p className="text-zinc-800 font-TTHovesM">
                                        {project?.proposalCreatedAs ===
                                        'Photographer'
                                            ? project?.clients[0]?.email
                                            : project?.proposalCreatedBy?.email}
                                    </p>
                                </div>
                                <div className="text-sm font-light text-slate-500">
                                    <p className="text-sm text-slate-700">
                                        From
                                    </p>
                                    <p className="text-zinc-800 font-TTHovesM">
                                        {project?.proposalCreatedAs === 'Client'
                                            ? project?.photographers[0]?.email
                                            : project?.proposalCreatedBy?.email}
                                    </p>
                                    <p>
                                        {
                                            project?.proposalCreatedBy
                                                ?.contactNumber
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="print:p-0 p-9">
                        <div className="flex flex-col mx-0 ">
                            <table className="min-w-full divide-y divide-zinc-200">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-slate-700 sm:pl-6 md:pl-0">
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden py-3.5 px-3 text-right text-sm font-normal text-slate-700 sm:table-cell">
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden py-3.5 px-3 text-right text-sm font-normal text-slate-700 sm:table-cell">
                                            Rate
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-3 pr-4 text-right text-sm font-normal text-slate-700 sm:pr-6 md:pr-0">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice?.customPackage.map(item => (
                                        <tr
                                            key={item._id}
                                            className="border-b border-slate-200">
                                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                <div className="font-medium text-slate-700">
                                                    {item.serviceName}
                                                </div>
                                                <div className="mt-0.5 text-slate-500 sm:hidden">
                                                    1 unit at $0.00
                                                </div>
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                                                1
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                                                {user?.currencySymbol}{' '}
                                                {item.rate}
                                            </td>
                                            <td className="py-4 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                                {user?.currencySymbol}{' '}
                                                {item.rate}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-6 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0">
                                            Subtotal
                                        </th>
                                        <th
                                            scope="row"
                                            className="pt-6 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden">
                                            Subtotal
                                        </th>
                                        <td className="pt-6 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                            {user?.currencySymbol}{' '}
                                            {invoice?.customPackage.reduce(
                                                (a, b) => a + b.rate,
                                                0,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-6 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0">
                                            Discount
                                        </th>
                                        <th
                                            scope="row"
                                            className="pt-6 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden">
                                            Discount
                                        </th>
                                        <td className="pt-6 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                            {user?.currencySymbol} 0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-4 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0">
                                            Tax
                                        </th>
                                        <th
                                            scope="row"
                                            className="pt-4 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden">
                                            Tax
                                        </th>
                                        <td className="pt-4 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                            {user?.currencySymbol} 0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-4 pl-6 pr-3 text-sm font-normal text-right text-slate-700 sm:table-cell md:pl-0">
                                            Total
                                        </th>
                                        <th
                                            scope="row"
                                            className="pt-4 pl-4 pr-3 text-sm font-normal text-left text-slate-700 sm:hidden">
                                            Total
                                        </th>
                                        <td className="pt-4 pl-3 pr-4 text-sm font-normal text-right text-slate-700 sm:pr-6 md:pr-0">
                                            {user?.currencySymbol}{' '}
                                            {invoice?.customPackage.reduce(
                                                (a, b) => a + b.rate,
                                                0,
                                            )}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="print:mt-8 mt-24 print:p-0 p-9">
                        <div className="border-t pt-9 border-slate-200">
                            <div className="flex flex-col md:flex-row justify-between">
                                <div className="w-full md:w-1/2">
                                    <p className="text-2xl font-TTHovesM text-zinc-900">
                                        Invoice Terms
                                    </p>
                                    <div className="text-slate-700">
                                        {project?.invoiceTerms
                                            ?.split('\n')
                                            .map((line, index) => (
                                                <div key={index} className="">
                                                    {line}
                                                    <br className="leading-none" />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/3">
                                    <div className="flex flex-col">
                                        <p className="text-2xl font-TTHovesM text-zinc-900">
                                            Payment Method
                                        </p>
                                        <p>{project?.billing?.method}</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-2xl font-TTHovesM text-zinc-900">
                                            Account Details
                                        </p>
                                        <p>
                                            {
                                                project?.billing?.bankTransfer
                                                    ?.bankName
                                            }
                                        </p>
                                        <p>
                                            {
                                                project?.billing?.bankTransfer
                                                    ?.iban
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}
