// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/OTAbalos-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/OTAbalos-Hairline.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/OTAbalos-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/TT Hoves ExtraLight.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("fonts/TT Hoves Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("fonts/TT Hoves Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("fonts/TT Hoves Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("fonts/TT Hoves Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("fonts/TT Hoves DemiBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "OTAbalos-Regular";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}

@font-face {
    font-family: "OTAbalos-Hairline";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}

@font-face {
    font-family: "OTAbalos-Medium";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-ExtraLight";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-Light";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-Regular";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-Medium";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-Bold";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
}

@font-face {
    font-family: "TT-Hoves-SemiBold";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff");
}
`, "",{"version":3,"sources":["webpack://./src/css/fonts.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,2DAAqD;AACzD;;AAEA;IACI,gCAAgC;IAChC,2DAAsD;AAC1D;;AAEA;IACI,8BAA8B;IAC9B,2DAAsD;AAC1D;;AAEA;IACI,kCAAkC;IAClC,2DAAwD;AAC5D;;AAEA;IACI,6BAA6B;IAC7B,2DAAmD;AACvD;;AAEA;IACI,+BAA+B;IAC/B,2DAAqD;AACzD;;AAEA;IACI,8BAA8B;IAC9B,2DAAoD;AACxD;;AAEA;IACI,4BAA4B;IAC5B,2DAAkD;AACtD;;AAEA;IACI,gCAAgC;IAChC,2DAAsD;AAC1D","sourcesContent":["@font-face {\n    font-family: \"OTAbalos-Regular\";\n    src: url(\"fonts/OTAbalos-Regular.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"OTAbalos-Hairline\";\n    src: url(\"fonts/OTAbalos-Hairline.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"OTAbalos-Medium\";\n    src: url(\"fonts/OTAbalos-Semibold.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-ExtraLight\";\n    src: url(\"fonts/TT Hoves ExtraLight.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-Light\";\n    src: url(\"fonts/TT Hoves Light.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-Regular\";\n    src: url(\"fonts/TT Hoves Regular.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-Medium\";\n    src: url(\"fonts/TT Hoves Medium.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-Bold\";\n    src: url(\"fonts/TT Hoves Bold.otf\") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"TT-Hoves-SemiBold\";\n    src: url(\"fonts/TT Hoves DemiBold.otf\") format(\"woff\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
