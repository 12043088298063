import * as Yup from 'yup'

const LoginValidation = Yup.object().shape({
    email: Yup.string()
        .email('Enter a valid email address')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Enter a valid email address')
        .required('Please enter email address'),
    password: Yup.string().required('Please enter password'),
})

const AccountValidation = Yup.object().shape({
    registeredEmail: Yup.string()
        .email('Enter a valid email address')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Enter a valid email address')
        .required('Please enter email address'),
    currentPassword: Yup.string().required('Please enter current password'),
    newPassword: Yup.string().required('Please enter new password'),
    confirmPassword: Yup.string()
        .required('Please confirm password')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

const ProfileValidation = Yup.object().shape({
    firstName: Yup.string()
        .required('Please enter first name')
        .matches(
            /^[a-zA-Z\s]*$/g,
            'Must not include space and special characters',
        ),
    lastName: Yup.string()
        .required('Please enter last name')
        .matches(
            /^[a-zA-Z\s]*$/g,
            'Must not include space and special characters',
        ),
    userName: Yup.string()
        .matches(
            /^[a-zA-Z0-9]*$/g,
            'Must not include space and special characters',
        )
        .required('Please enter username'),
    currency: Yup.string(),
    location: Yup.string().required('Please select location'),
    contact: Yup.number().typeError('Alphabets are not allowed'),
})

const QuoteValidationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter quote title'),
    serviceType: Yup.string().required('Please select category'),
    quoteLimit: Yup.string().required('Please select limit'),
    shootTime: Yup.string().required('Please select shoot time'),
    estimatedShootTime: Yup.string().required('Please select duration'),
    duration: Yup.string().required('Please select shoot date'),
    budget: Yup.string().required('Please enter budget'),
    locationString: Yup.string().required('Please select location'),
})

export {
    LoginValidation,
    AccountValidation,
    ProfileValidation,
    QuoteValidationSchema,
}
