import React, { Fragment, useState } from 'react'
import { ArrowRight2, Home2 } from 'iconsax-react'
import classNames from 'classnames'
import { ShootCategories } from 'components/dashboard/business/ShootCategories'
import { Questionnaires } from 'components/dashboard/business/Questionnaires'
import { Contract } from 'components/dashboard/business/Contract'
import { ClientGuide } from 'components/dashboard/business/ClientGuide'
import { EmailTemplate } from 'components/dashboard/business/EmailTemplate'
import { InvoiceTerm } from 'components/dashboard/business/InvoiceTerm'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const Service = () => {
    const [tab, setTab] = useState(1)
    const navigate = useNavigate()
    const [params] = useSearchParams()

    return (
        <Fragment>
            <div className="hidden items-center gap-2 md:flex">
                <Home2 className="h-5 w-5" />
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                    Business
                </div>
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                    Services
                </div>
            </div>

            <div className="w-full p-1 overflow-auto bg-white rounded-lg border border-zinc-200 justify-start items-center gap-2 inline-flex mt-4">
                <TabButton
                    label="Shoot Categories"
                    isActive={params.get('tab') === 'categories'}
                    onClick={() =>
                        navigate('/dashboard/services?tab=categories')
                    }
                />
                <TabButton
                    label="Questionnaires"
                    isActive={params.get('tab') === 'questionnaires'}
                    onClick={() =>
                        navigate('/dashboard/services?tab=questionnaires')
                    }
                />
                <TabButton
                    label="Contract"
                    isActive={params.get('tab') === 'contract'}
                    onClick={() => navigate('/dashboard/services?tab=contract')}
                />
                <TabButton
                    label="Client Guides"
                    isActive={params.get('tab') === 'guides'}
                    onClick={() => navigate('/dashboard/services?tab=guides')}
                />
                <TabButton
                    label="Email Template"
                    isActive={params.get('tab') === 'email'}
                    onClick={() => navigate('/dashboard/services?tab=email')}
                />
                <TabButton
                    label="Invoice Terms"
                    isActive={params.get('tab') === 'invoice'}
                    onClick={() => navigate('/dashboard/services?tab=invoice')}
                />
            </div>

            <ShootCategories tab={params.get('tab') === 'categories'} />
            <Questionnaires tab={params.get('tab') === 'questionnaires'} />
            <Contract tab={params.get('tab') === 'contract'} />
            <ClientGuide tab={params.get('tab') === 'guides'} />
            <EmailTemplate tab={params.get('tab') === 'email'} />
            <InvoiceTerm tab={params.get('tab') === 'invoice'} />
        </Fragment>
    )
}

export const TabButton = ({ isActive, label, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(
                'px-5 py-2.5 flex-1  whitespace-nowrap transform ease-linear delay-200 text-sm font-TTHovesM rounded-md',
                {
                    'text-white bg-tale-10 shadow': isActive,
                    'text-zinc-500': !isActive,
                },
            )}>
            {label}
        </button>
    )
}
