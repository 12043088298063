import { CheckCircle } from '../../../css/icons/icons'
import React from 'react'

export const ProjectListing = ({ project }) => {
    console.log({ project })
    return (
        <div className="flex flex-col gap-6 mt-4 relative z-10">
            <div className="absolute h-full w-px bg-zinc-200 left-3 -z-10" />

            <div className="flex items-center gap-3">
                <CheckCircle className="text-[#E8C0A6] w-6 h-6" />

                <p className="text-zinc-600 text-sm">Proposal Created</p>
            </div>

            <div className="flex items-center gap-3">
                {!project?.projectListing?.contractCreated ? (
                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                    </div>
                ) : (
                    <div className="flex items-center gap-3">
                        <CheckCircle className="text-[#E8C0A6] w-6 h-6" />

                        <p className="text-zinc-600 text-sm">Contract Added</p>
                    </div>
                )}
                {!project?.projectListing?.contractCreated && (
                    <p className="text-zinc-600 text-sm">Contract Pending</p>
                )}
            </div>

            <div className="flex items-center gap-3">
                {!project?.clientSignature ? (
                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                    </div>
                ) : (
                    <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                )}
                <p className="text-zinc-600 text-sm">Client Signature</p>
            </div>

            <div className="flex items-center gap-3">
                {!project?.projectListing?.invoiceCreated &&
                project?.clientSignature ? (
                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                    </div>
                ) : project?.projectListing?.invoiceCreated ? (
                    <div className="flex gap-3 items-center">
                        <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                        <p className="text-zinc-600 text-sm">Invoice Created</p>
                    </div>
                ) : (
                    <CheckCircle className="text-zinc-200 w-6 h-6" />
                )}
                {!project?.projectListing?.invoiceCreated && (
                    <p className="text-zinc-600 text-sm">Create Invoice</p>
                )}
            </div>

            <div className="flex items-center gap-3">
                {project?.projectListing?.invoiceCreated &&
                project?.clientSignature &&
                project?.projectListing?.contractCreated ? (
                    <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                        <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                    </div>
                ) : (
                    <CheckCircle className="text-zinc-200 w-6 h-6" />
                )}
                <p className="text-zinc-700 text-sm ">Project Active</p>
            </div>
        </div>
    )
}
