import { Header } from 'components/landing/Header'
import { Footer } from 'components/landing/Footer'
import { Outlet, useLocation } from 'react-router-dom'

export const HomeLayout = () => {
    const { pathname } = useLocation()
    return (
        <div className="w-full">
            <Header />
            <main className="p-5 md:p-10">
                <Outlet />
            </main>
            {!pathname.includes('photographer') && <Footer />}
        </div>
    )
}
