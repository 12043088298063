import classNames from 'classnames'
import styles from 'components/form/Input/Input.module.css'
import React, { useState } from 'react'
import UserService from 'services/user.service'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { setProject } from '../../../../redux/reducers/projectReducer'
import { dispatch } from 'jest-circus/build/state'
import { useThunkDispatch } from 'hooks/useThunkDisptach'

const service = new UserService()

export const Notes = ({ tab }) => {
    const { project } = useSelector((state: any) => state.project)
    const dispatch = useThunkDispatch()
    const [state, setState] = useState(project?.notes)
    const params = useParams()

    const handleSubmit = e => {
        e.preventDefault()
        service
            .addNotes({ projectId: params?.id, notes: state })
            .then(resp => {
                dispatch(setProject(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <form className="" onSubmit={handleSubmit}>
                <textarea
                    disabled
                    value={state}
                    onChange={e => setState(e.target.value)}
                    rows={10}
                    className={classNames('!pt-3', [styles.input])}
                />
                {/*<button*/}
                {/*    type="submit"*/}
                {/*    className="w-full md:w-auto px-4 py-2.5 bg-tale-10 text-white text-sm font-TTHovesM rounded-lg shadow">*/}
                {/*    Save*/}
                {/*</button>*/}
            </form>
        </div>
    )
}
