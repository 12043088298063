import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { RefreshCircle, SearchZoomIn } from 'iconsax-react'
import { Button } from 'components/form/Button/Button'
import { ImageCard } from 'components/landing/photographer/ImageCard'
import UserService from 'services/user.service'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { LocationSelect } from 'components/form/LocationSelect'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useSession } from 'hooks/useSession'

const service = new UserService()

export const FindPhotographer = () => {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        session: '',
        name: '',
        location: '',
    })
    const [pagination, setPagination] = useState({
        limit: 10,
        page: 1,
    })

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const user = useSelector(userSelector)
    const onChange = dates => {
        const [start, end] = dates
        setFilters({
            ...filters,
        })
        setStartDate(start)
        setEndDate(end)
    }

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllPhotographers({ ...pagination }, filters)
            .then(resp => {
                setData(resp.data.data.filter(item => item?._id !== user?._id))
            })
            .catch(() => {
                console.log('Error Photographers profile')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pagination, filters])

    useEffect(() => {
        fetchData()
    }, [pagination, filters])

    // useEffect(() => {
    //     setLoading(true)
    //     service
    //         .searchPhotographer({ ...filters, startDate, endDate })
    //         .then(resp => {
    //             setData(resp.data.data)
    //             setFilterData(resp.data.data)
    //         })
    //         .catch(error => {
    //             setData([])
    //         })
    //         .finally(() => {
    //             setLoading(false)
    //         })
    // }, [filters])

    const filteredData = data.filter(item =>
        Object.keys(item).some(
            key =>
                item[key]
                    ?.toString()
                    ?.toLowerCase()
                    .includes(filters?.name?.toString().toLowerCase()),
        ),
    )

    const { sessions } = useSession()

    // const hasFilter = Object.values(filters).some(item => item !== '')

    return (
        <Fragment>
            <div className="flex items-center justify-center my-16">
                <p className="text-center items-center text-3xl md:text-heading-bold font-OTabolas">
                    Find a Photographer
                </p>
            </div>
            <div className="grid grid-cols-auto md:flex gap-4 mt-8 p-3 md:p-0">
                <div className="w-full">
                    <ReactSelect
                        placeholder="Select type"
                        options={sessions.map(session => ({
                            value: session._id,
                            label: session.name,
                        }))}
                        isMulti
                        onChange={(data: any) => {
                            setFilters({
                                ...filters,
                                session: data
                                    ?.map(item => item.value)
                                    .join(','),
                            })
                        }}
                        label="Select Type"
                        value={filters.session}
                    />
                </div>
                {/*<Input*/}
                {/*    type="text"*/}
                {/*    label="What are you looking for"*/}
                {/*    onChange={e =>*/}
                {/*        setFilters({*/}
                {/*            ...filters,*/}
                {/*            name: e.target.value,*/}
                {/*        })*/}
                {/*    }*/}
                {/*    value={filters.name}*/}
                {/*    placeholder="Search a photographer..."*/}
                {/*/>*/}
                <LocationSelect
                    value={filters.location}
                    onChange={value => {
                        setFilters({
                            ...filters,
                            location: value,
                        })
                    }}
                />

                <div className="relative">
                    <label className="absolute -top-3 left-4 bg-creame-1 text-gray z-10">
                        Date
                    </label>
                    <DatePicker
                        selected={startDate}
                        placeholderText="Pick a date"
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date()}
                        customInput={
                            <input
                                placeholder="Select date"
                                className="w-56 h-14 bg-transparent rounded-xl border border-gray-outline outline-none focus-visible:shadow-none shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
                            />
                        }
                        selectsRange
                        selectsDisabledDaysInRange
                    />
                </div>

                <div className="flex items-center gap-4">
                    {/*{hasFilter ? (*/}
                    {/*    <div*/}
                    {/*        onClick={() => {*/}
                    {/*            setFilters({*/}
                    {/*                session: '',*/}
                    {/*                name: '',*/}
                    {/*                location: '',*/}
                    {/*            })*/}
                    {/*            fetchData()*/}
                    {/*        }}*/}
                    {/*        className="flex text-blue-500 items-center gap-2 cursor-pointer">*/}
                    {/*        <p className="text-sm  whitespace-nowrap">*/}
                    {/*            Clear Filters*/}
                    {/*        </p>*/}
                    {/*        <CloseIcon className="h-4 w-4" />*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <div className="flex items-center gap-3 justify-between divide-zinc-400  rounded-xl border border-gray-outline p-3.5 bg-transparent">*/}
                    {/*        <Element4 className="h-6 w-6 text-zinc-700" />*/}
                    {/*        <div className="h-6 border-[0.1px]" />*/}
                    {/*        <Firstline className="h-6 w-6 text-zinc-500" />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <Button className="w-full flex gap-4 items-center justify-center bg-tale-10 border-tale-10 h-[51px] text-white ">
                        <SearchZoomIn className="h-6 w-6" color="#FFFFFF" />
                        <span className="">Search</span>
                    </Button>
                </div>
            </div>
            {loading && data?.length === 0 && (
                <RefreshCircle className="text-tale-10 animate-spin mx-auto size-10 mt-6" />
            )}
            <div className="border-0 md:border-t md:border-l border-zinc-200 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-0 lg:grid-cols-3 mt-10">
                {filteredData?.map((item: any) => (
                    <ImageCard
                        key={item._id}
                        id={item._id}
                        name={item.firstName + ' ' + item.lastName}
                        type={item.type}
                        status={item.workPreferences.availibility}
                        price={item.servicePreferences.hourlyRate}
                        tier={item?.settings?.currentTier?.tier}
                        skills={item.sessions
                            ?.map(item => item?.session?.name)
                            ?.slice(0, 2)}
                        avatar={
                            item.profileImage
                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item.profileImage}`
                                : null
                        }
                        userName={item?.userName}
                        currencySymbol={item?.currencySymbol}
                        location={item.location}
                        image={item.coverImage}
                        portfolio={item.portfolio}
                    />
                ))}
            </div>
            {!loading && filteredData.length === 0 ? (
                <p className="text-center pt-8 font-semibold text-lg">
                    No photographer found
                </p>
            ) : (
                <div className="text-center mt-6">
                    <Button
                        onClick={() =>
                            setPagination({
                                ...pagination,
                                limit: pagination.limit + 10,
                            })
                        }
                        className="bg-brown-10 w-56 text-white border-0"
                        type="button">
                        {loading ? 'Loading...' : 'Load More'}
                    </Button>
                </div>
            )}
        </Fragment>
    )
}
