import { DocumentText } from 'iconsax-react'
import { Modal } from 'components/common/Modal'
import React, { useCallback, useEffect, useState } from 'react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import UserService from 'services/user.service'

const service = new UserService()

export const DeliveryModal = ({ deliveryModal, setDeliveryModal, project }) => {
    const [selected, setSelected] = useState('')
    const [data, setData] = useState<any>([])

    const fetchData = useCallback(() => {
        service
            .getGalleries()
            .then(resp => {
                setData(resp.data.data)
            })
            .catch(error => {
                console.log('Gallery', error)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Modal
            width="w-full lg:w-1/3"
            showModal={deliveryModal}
            setShowModal={setDeliveryModal}
            title="Delivery"
            footerButton="Send"
            handleClickPrimary={() => console.log('')}
            headerIcon={<DocumentText />}>
            {/* Form */}
            <form className="p-5 flex flex-col gap-5">
                <ReactSelect
                    value={selected}
                    onChange={value => setSelected(value)}
                    options={data.map(item => ({
                        value: item._id,
                        label: item.title,
                    }))}
                    placeholder="Select Gallery"
                />
                <p>Gallery Images</p>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
