import React, { useEffect, useState } from 'react'
import { userSelector } from '../../../../redux/selectors/user'
import classNames from 'classnames'
import { Add, DocumentText } from 'iconsax-react'
import { DataNotFound } from 'components/common/DataNotFound'
import { Modal } from 'components/common/Modal'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { useNotifications } from 'hooks/useNotification'
import { IconButton } from 'components/form/Button/Button'
import { BiLinkExternal } from '../../../../css/icons/icons'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setProject } from '../../../../redux/reducers/projectReducer'
import { ConfirmModal } from 'components/common/ConfirmModal'

const service = new UserService()

export const Questionnaires = ({ tab }) => {
    const user = useSelector(userSelector)
    const dispatch = useThunkDispatch()
    const { project } = useSelector((state: any) => state.project)

    const [questions, setQuestions] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [questionId, setQuestionId] = useState('')
    const [addModal, setAddModal] = useState(false)
    const [state, setState] = useState<any>({
        user: user._id,
        question: '',
        category: project?.shootType?._id,
    })

    // useEffect(() => {
    //     setSelected(project?.questions)
    // }, [project])

    useEffect(() => {
        setLoading(true)
        service
            .getQuestions(project?.shootType?._id)
            .then(resp => {
                setQuestions(
                    resp.data.data.map(item => ({
                        question: { ...item },
                        selected: false,
                    })),
                )
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const { successMessage } = useNotifications()

    const handleAdd = () => {
        service
            .addQuestion(state)
            .then(resp => {
                setState({
                    ...state,
                    _id: resp.data.data._id,
                })
                // setQuestions(prev => [
                //     ...prev,
                //     {
                //         question: { ...resp.data.data },
                //         selected: true,
                //     },
                // ])
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setAddModal(false)
                setState({
                    ...state,
                    question: '',
                })
                setTimeout(() => {
                    add()
                }, 1000)
            })
    }

    const handleDelete = questionId => {
        setIsOpen(true)
        setDeleteId(questionId)
    }

    const handleAddQuestion = id => {
        const shallowCopy = [...questions]
        const updated = shallowCopy.map(item =>
            item.question._id === id
                ? { ...item, selected: !item.selected }
                : item,
        )
        setQuestions(updated)
    }

    const handleDeleteQuestion = () => {
        service
            .deleteProjectQuestion(project._id, deleteId)
            .then(resp => {
                successMessage('Questions deleted successfully')
                dispatch(setProject(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
                setIsOpen(false)

                setDeleteId('')
            })
    }

    const add = () => {
        let alreadySelected = []
        const shallowCopy = [...questions]
        const newQuestions = shallowCopy
            .filter(item => item.selected)
            .map(item => ({ question: item.question?._id }))

        alreadySelected = project?.questions?.map(item => ({
            question: item.question._id,
        }))
        const apiData = [...newQuestions, ...alreadySelected]

        service
            .addProjectQuestion(apiData, project._id)
            .then(resp => {
                successMessage('Questions added successfully')

                dispatch(
                    setProject({
                        ...project,
                        questions: resp.data.data.questions,
                    }),
                )
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
                setIsOpen(false)

                setDeleteId('')
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200  p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-2xl font-OTabolas">
                    Questionnaires
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}

                <div className="flex gap-2">
                    <IconButton
                        onClick={() => setAddModal(true)}
                        icon={
                            <BiLinkExternal className="h-5 w-5 text-tale-10" />
                        }
                        text="Create new"
                    />
                    <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Add className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Add from template
                        </span>
                    </button>
                </div>
            </div>

            <div className="mt-6">
                {project?.questions?.length > 0 ? (
                    project?.questions?.map((item, index) => (
                        <div key={index}>
                            <div
                                onClick={() => {
                                    setQuestionId(item?._id)
                                }}
                                className="flex items-center gap-2">
                                <div className="flex-1 p-3 border shadow-sm border-[#ACB6BE] rounded-xl my-2 flex items-center justify-between">
                                    <p>{item?.question?.question}</p>
                                    <div
                                        className={classNames(
                                            'text-xs rounded-md px-3 py-1 uppercase text-green-800',
                                            {
                                                'bg-green-700  bg-opacity-20':
                                                    !item?.answer,
                                                'bg-[#D0DBE0]': item?.answer,
                                            },
                                        )}>
                                        {item?.answer ? 'Answered' : 'Pending'}
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleDelete(item?._id)}
                                    className="px-2 py-1 rounded-md border border-green-200 text-green-600 bg-green-600 bg-opacity-10">
                                    X
                                </button>
                            </div>
                            <div className="flex">
                                {item?._id === questionId && (
                                    <div className="flex-1 border border-gray-outline rounded-lg p-3 h-16">
                                        {item?.answer}
                                    </div>
                                )}
                                <div className="w-9" />
                            </div>
                        </div>
                    ))
                ) : (
                    <DataNotFound
                        title="No Question added"
                        description="Please select category to view questions."
                    />
                )}
            </div>

            <Modal
                width="w-full lg:w-1/2"
                showModal={showModal}
                setShowModal={() => setShowModal(false)}
                title={'Add Question'}
                footerButton={'Add Question'}
                description="Select questions for client from pre-defined template"
                handleClickPrimary={() => add()}
                headerIcon={<DocumentText />}>
                {/* Form */}
                {questions.map(question => (
                    <div
                        key={question._id}
                        className="flex gap-2 items-center px-4 mt-2">
                        <input
                            type="checkbox"
                            defaultChecked={
                                question.selected ||
                                project?.questions
                                    .map(item => item?.question?._id)
                                    .includes(question?.question?._id)
                            }
                            onClick={() =>
                                handleAddQuestion(question?.question?._id)
                            }
                            className="h-5 w-5 flex-shrink-0 flex-grow-0"
                        />
                        <div className="p-3 border shadow-sm border-[#ACB6BE] rounded-xl my-2 ">
                            <p>{question?.question?.question}</p>
                        </div>
                    </div>
                ))}
                <hr className="text-zinc-200 w-full " />
                {/* Form End */}
            </Modal>
            <Modal
                width="w-full lg:w-1/3"
                showModal={addModal}
                setShowModal={() => {
                    setAddModal(false)
                    setState({
                        ...state,
                        user: user._id,
                        question: '',
                    })
                }}
                title="Add Question"
                footerButton="Add Question"
                handleClickPrimary={handleAdd}
                headerIcon={<DocumentText />}>
                {/* Form */}
                <div className="p-5">
                    <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                        <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                            Question*
                        </label>
                        <input
                            className="border border-zinc-300 shadow-sm rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                            type="text"
                            value={state.question}
                            onChange={e =>
                                setState({
                                    ...state,
                                    question: e.target.value,
                                })
                            }
                            placeholder="Type here..."
                        />
                    </div>
                </div>
                <hr className="text-zinc-200 w-full " />
                {/* Form End */}
            </Modal>

            <ConfirmModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Are you sure to delete the question?"
                description="The question will be delete permanently."
                onConfirm={handleDeleteQuestion}
            />
        </div>
    )
}
