import React, { memo } from 'react'

import BreadCrums from 'components/common/breadCrums'
import QuoteTile from 'components/dashboard/invitePhotographers/QuoteTile'
import InvitePhotographersModule from 'components/dashboard/invitePhotographers/InvitePhotographersModule'

const InvitePhotographer: React.FC = () => (
    <div className="grid gap-6">
        <BreadCrums>
            <div className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                {'Invite Photographers'}
            </div>
        </BreadCrums>

        <QuoteTile />

        <InvitePhotographersModule />
    </div>
)

export default memo(InvitePhotographer)
