import BaseService from './base.service'

class AuthService extends BaseService {
    async login(data: { email: string; password: string }) {
        return await this.post('/user/email-login', data)
    }

    async signup(data: { email: string; password: string; userType: string }) {
        return await this.post('/user/create-account', data)
    }
}

export default AuthService
