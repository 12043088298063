export function formatFileSize(sizeInMB: number) {
    // Convert MB to KB
    const sizeInKB: number = sizeInMB * 1024

    // Determine whether to display in MB or KB
    if (sizeInMB < 1) {
        // Display in KB with two decimal places
        return sizeInKB.toFixed(2) + ' KB'
    } else {
        // Display in MB with two decimal places
        return sizeInMB.toFixed(2) + ' MB'
    }
}

export function timeAgo(date) {
    const now = new Date()
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    const seconds = Math.floor(((now as any) - date) / 1000)

    // Show "just now" for events within the last 60 seconds
    if (seconds < 60) {
        return 'just now'
    }

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    }

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const interval = Math.floor(seconds / secondsInUnit)
        if (interval >= 1) {
            // If the interval is greater than or equal to 1 minute, show the exact time
            if (unit === 'minute') {
                const timeString = date.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                })
                return timeString
            }

            // Otherwise, return the relative time
            return rtf.format(-interval, unit as any)
        }
    }

    return 'just now'
}

export const convertTime = dateString => {
    const date = new Date(dateString)
    return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })
}

export const formatTime = isoString => {
    const date = new Date(isoString)
    return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })
}
