import { FC, ReactNode } from 'react'
import { Button } from 'components/form/Button/Button'
import { IconArrowCurve } from '../../../css/icons/icons'

interface CategoryProps {
    title: string
    heading: ReactNode
    list: ListItemType[]
    buttonText: string
    buttonClick(): void
    colspan?: number
}

const Category: FC<CategoryProps> = ({
    title,
    heading,
    list,
    buttonText,
    buttonClick,
}) => {
    return (
        <>
            <div className=" pb-6">
                <span className="font-TTHoves text-body-normal text-dark-5 my-2">
                    {title}
                </span>
                <div className="mt-2 font-OTabolas text-2xl md:text-sub-heading-bold  text-dark-8">
                    {heading}
                </div>
            </div>
            <div>
                {list?.map(({ header, message }, index) => (
                    <ListItem
                        key={index}
                        listHeader={header}
                        listMessage={message}
                    />
                ))}
                <div className="px-4 py-4">
                    <Button
                        className="flex items-center bg-brown-10 text-white border-none"
                        onClick={buttonClick}>
                        <span className="px-0">{buttonText}</span>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Category

type ListItemType = {
    header: string
    message: string
}

interface ListItemTypeProps {
    listHeader: string
    listMessage: string
}

const ListItem: FC<ListItemTypeProps> = ({ listHeader, listMessage }) => {
    return (
        <div className="flex items-center py-4">
            <div className="m-4">
                <IconArrowCurve />
            </div>
            <div className="flex flex-col">
                <div className="text-body-small font-TTHovesSB text-dark-8 pb-2">
                    {listHeader}
                </div>
                <div className="text-base text-dark-5 font-TTHoves">
                    {listMessage}
                </div>
            </div>
        </div>
    )
}
