import { Fragment, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
    ArrowRight,
    HambergerMenu,
    Notification,
    Setting2,
    Wallet,
} from 'iconsax-react'
import { useSelector } from 'react-redux'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { PopMenu } from 'components/common/PopMenu'
import { MessagePanel } from 'components/dashboard/MessagePanel'
import { userSelector } from '../../redux/selectors/user'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { logOut } from '../../redux/actions/auth'
import { MobileMenu } from 'components/dashboard/MobileMenu'

export const DashboardLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const user = useSelector(userSelector)
    const dispatch = useThunkDispatch()

    return (
        <div className="w-full font-TTHoves">
            <div className="flex print:hidden items-center h-16 sticky top-0 z-50 justify-between lg:px-10 w-full bg-white border-b border-zinc-100 p-4 md:p-0">
                <div className="flex gap-3">
                    <button
                        onClick={() => setSidebarOpen(true)}
                        className="block md:hidden">
                        <HambergerMenu />
                    </button>
                    <Link
                        to="/dashboard"
                        className="text-body-bold text-primary text-dark-8 font-OTabolas">
                        Suura
                    </Link>
                </div>
                <MobileMenu
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <div className="hidden  md:flex items-center gap-8">
                    <NavLink
                        to="/dashboard"
                        active={location.pathname === '/dashboard'}
                        label="Dashboard"
                    />
                    <PopMenu
                        active={
                            location.pathname === '/dashboard/board' ||
                            location.pathname === '/dashboard/quotes' ||
                            location.pathname === '/dashboard/photographers'
                        }
                        button={
                            <span className="text-gray-600 font-medium">
                                Directory
                            </span>
                        }>
                        <DirectoryMenu
                            userType={user?.userType === 'Photographer'}
                        />
                    </PopMenu>
                    <PopMenu
                        active={
                            location.pathname ===
                            '/dashboard/photoshoot/requests' ||
                            location.pathname === '/dashboard/clients' ||
                            location.pathname === '/dashboard/services' ||
                            location.pathname === '/dashboard/photoshoot/new' ||
                            location.pathname ===
                            '/dashboard/photoshoot/bookings'
                        }
                        button={
                            <span className="text-gray-600 font-medium">
                                Business
                            </span>
                        }>
                        {user?.userType === 'Photographer' ? (
                            <BusinessMenu />
                        ) : (
                            <ClientBusinessMenu />
                        )}
                    </PopMenu>
                    <NavLink
                        to="/dashboard/browse/jobs"
                        active={
                            location.pathname === '/dashboard/browse/jobs' ||
                            location.pathname.includes('/dashboard/quote/')
                        }
                        label="Browse Jobs"
                    />
                    {user?.userType === 'Photographer' && (
                        <PopMenu
                            active={
                                location.pathname ===
                                '/dashboard/galleries/client' ||
                                location.pathname ===
                                '/dashboard/galleries/portfolio' ||
                                location.pathname ===
                                '/dashboard/galleries/library' ||
                                location.pathname ===
                                '/dashboard/gallery/specific'
                            }
                            button={
                                <span className="text-gray-600 font-medium">
                                    Galleries
                                </span>
                            }>
                            <GalleryMenu />
                        </PopMenu>
                    )}
                    {/*{user?.userType === 'Photographer' && (*/}
                    {/*    <PopMenu*/}
                    {/*        active={false}*/}
                    {/*        button={*/}
                    {/*            <span className="text-gray-600 font-medium">*/}
                    {/*                Photo Albums*/}
                    {/*            </span>*/}
                    {/*        }>*/}
                    {/*        <PhotoAlbumMenu />*/}
                    {/*    </PopMenu>*/}
                    {/*)}*/}
                </div>
                <div className="flex items-center gap-4">
                    <div className="items-center gap-4 text-zinc-700 flex mr-4">
                        <MessagePanel />
                        <Notification className="h-6 w-6" />
                        <span
                            className="flex h-[40px] border border-[#ddd] cursor-pointer w-[103px] items-center justify-center font-TTHovesSB text-sm gap-2 text-[#344054] rounded-lg"
                            onClick={() => navigate('/dashboard/wallet')}>
                            <Wallet className=" cursor-pointer" />
                            {'Є0.00'}
                        </span>
                    </div>
                    {/*<div className="px-4 py-2.5 rounded-lg border border-zinc-300 justify-center items-center gap-2 flex">*/}
                    {/*    <Wallet2 className="h-5 w-5 text-zinc-700"/>*/}
                    {/*    <p className="text-slate-700 text-sm font-semibold leading-tight">$0.00</p>*/}
                    {/*</div>*/}

                    <Menu as="div" className=" relative z-10">
                        {({ open }) => (
                            <>
                                <Menu.Button className="max-w-xs gap-2 flex items-center focus:outline-none">
                                    <div className="w-10 h-10 border rounded-full border-zinc-400">
                                        <img
                                            alt="profile"
                                            src={
                                                user?.profileImage
                                                    ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                    : '/assets/images/blank.webp'
                                            }
                                            className="h-full w-full rounded-full object-cover object-top"
                                        />
                                    </div>
                                </Menu.Button>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-100"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items
                                        static
                                        className="origin-top-right flex flex-col absolute right-0 mt-2 mr-3 w-52 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            <Link
                                                to={`/dashboard/${user?.userName}`}
                                                className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1}
                                                    stroke="currentColor"
                                                    className="w-5 h-5">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                                Profile
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                to="/"
                                                className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1}
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                                    />
                                                </svg>
                                                Home
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                to="/dashboard/settings?tab=profile"
                                                className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                <Setting2
                                                    className="w-5 h-5 font-light"
                                                    stroke={'1'}
                                                />
                                                Settings
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    dispatch(logOut())
                                                }
                                                className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1}
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                                    />
                                                </svg>
                                                Logout
                                            </button>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                </div>
            </div>
            <div className="flex-1 p-5 md:px-10 md:py-5 ">
                <Outlet />
            </div>
        </div>
    )
}

const NavLink = ({ label, to, active }) => {
    return (
        <Link
            to={to}
            className={classNames('p-5 text-gray-600', {
                'border-b-[1.5px] border-tale-10': active,
            })}>
            {label}
        </Link>
    )
}

const DirectoryMenu = ({ userType }) => {
    return (
        <div className="overflow-hidden print:hidden rounded-xl absolute z-[1000] shadow-lg ring-1 ring-black/5 p-6 bg-white ">
            <p className="text-zinc-600 text-base font-TTHovesM leading-snug">
                Directory
            </p>
            <p className="text-slate-800 text-[25px] font-OTabolas mt-2">
                Jobs opportunities and collaborate with others
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Browse Photographers
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">Quotes</p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                {userType && (
                    <Menu.Item>
                        <Link
                            to="/dashboard/board"
                            className="flex items-center justify-between pt-4">
                            <p className="text-zinc-600 font-TTHovesM">
                                Collab Board
                            </p>
                            <ArrowRight className="h-6 w-6 text-zinc-600" />
                        </Link>
                    </Menu.Item>
                )}
            </div>
        </div>
    )
}

const ClientBusinessMenu = () => {
    return (
        <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black/5 p-6 bg-white ">
            <p className="text-zinc-600 text-base font-TTHovesM leading-snug">
                Business
            </p>
            <p className="text-slate-800 text-[25px] font-OTabolas mt-2">
                Organise your shoots, clients and business
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photoshoot/requests"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Photoshoots Project
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/photoshoot/bookings"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            My Bookings
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/my-photographers"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Photographers
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/business/invoices"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">Invoicing</p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
        </div>
    )
}

const BusinessMenu = () => {
    return (
        <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black/5 p-6 bg-white ">
            <p className="text-zinc-600 text-base font-TTHovesM leading-snug">
                Business
            </p>
            <p className="text-slate-800 text-[25px] font-OTabolas mt-2">
                Organise your shoots, clients and business
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photoshoot/requests"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Photoshoots Project
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/photoshoot/bookings"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Session Bookings
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/clients"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">Clients</p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/services?tab=categories"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Services & Invoicing
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
        </div>
    )
}

const GalleryMenu = () => {
    return (
        <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black/5 p-6 bg-white ">
            <p className="text-zinc-600 text-base font-TTHovesM leading-snug">
                Galleries
            </p>
            <p className="text-slate-800 text-[25px] font-OTabolas mt-2">
                Share and deliver your photos to clients
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/galleries/client"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Client Galleries
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/galleries/portfolio"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Public Portfolio
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/galleries/library"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Photo Library
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
        </div>
    )
}

const PhotoAlbumMenu = () => {
    return (
        <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black/5 p-6 bg-white ">
            <p className="text-zinc-600 text-base font-TTHovesM leading-snug">
                Photo Albums
            </p>
            <p className="text-slate-800 text-[25px] font-OTabolas mt-2">
                Photography tools to make your photoshoot next level
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Album Library
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Create New Album
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Education & Resources
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
        </div>
    )
}
