import ModalSecondary from 'components/common/ModalSecandory'
import BreadCrums from 'components/common/breadCrums'
import TableMain from 'components/dashboard/wallet/TableMain'
import TopStrip from 'components/dashboard/wallet/TopStrip'
import { useState } from 'react'
import DepositModal from 'components/dashboard/wallet/DepositModal'
import PaymentMethod from '../../../components/dashboard/wallet/Payment'

const Wallet = () => {
    const [open, setOpen] = useState(true)
    const [openStripe, setOpenStripe] = useState(false)
    const [amount, setAmount] = useState(50)
    const [secretClient, setSecretClient] = useState(null)

    return (
        <div className="flex flex-col gap-4">
            <BreadCrums>
                <button className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                    {'Wallet'}
                </button>
            </BreadCrums>

            <TopStrip setOpen={setOpen} />

            <TableMain />

            <ModalSecondary open={open} toggle={setOpen} width="min-w-[670px]">
                <DepositModal
                    depositAmount={setAmount}
                    amount={amount}
                    openPaymnetModal={() => setOpenStripe(true)}
                />
            </ModalSecondary>

            <ModalSecondary
                open={openStripe}
                toggle={setOpenStripe}
                width="min-w-[670px]">
                <PaymentMethod amount={amount} />
            </ModalSecondary>
        </div>
    )
}

export default Wallet
