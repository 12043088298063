import React, { memo } from 'react'
import { SectionHeadingInterface } from 'utils/interfaces/commonComponents'

const SectionHeading: React.FC<SectionHeadingInterface> = ({ label }) => (
    <div className="text-lg leading-[28px] font-TTHovesM text-primary">
        {label}
    </div>
)

export default memo(SectionHeading)
