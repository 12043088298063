import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../redux/selectors/user'

const service = new UserService()

interface ISession {
    _id: string
    name: string
    selected: boolean
}
export const useSession = () => {
    const [sessions, setSessions] = useState<ISession[]>([])
    const [notUserSessions, setNotUserSessions] = useState<ISession[]>([])
    const [loading, setLoading] = useState(false)

    const user = useSelector(userSelector)

    const fetchData = useCallback(() => {
        service
            .getAllSessions()
            .then(resp => {
                setNotUserSessions(
                    resp.data.data.map(item => ({ ...item, selected: false })),
                )
                setSessions(
                    resp.data.data.map(item => ({
                        ...item,
                        selected: user.sessions.some(
                            sessionItem =>
                                sessionItem.session?._id === item._id,
                        ),
                    })),
                )
            })
            .catch(error => {
                console.log('Session Error: ', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setSessions,
        notUserSessions,
        setNotUserSessions,
        sessions,
    }
}
