import { useRoutes } from 'react-router-dom'
import { routes } from 'utils/routes'
import { useSelector } from 'react-redux'
import { authSelector, userSelector } from './redux/selectors/user'

function App() {
    const selector = useSelector(authSelector)
    const user = useSelector(userSelector)
    const routing = useRoutes(routes(selector.isAuth, user))
    return <>{routing}</>
}

export default App
