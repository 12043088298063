import { userSelector } from '../../../../redux/selectors/user'

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Calendar1, Location } from 'iconsax-react'

export const Contract = ({ tab }) => {
    const [proj, setProj] = useState<any>(null)
    const user = useSelector(userSelector)

    const { project } = useSelector((state: any) => state.project)

    useEffect(() => {
        setProj(project)
    }, [project])

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-2xl font-OTabolas">Contract</p>
            </div>
            <div className="flex-col inline-flex w-full px-0 md:px-6">
                <div className="flex flex-col md:flex-row gap-2 mt-4">
                    <div className=" flex-1 bg-[#DEEDEE] rounded-xl p-4">
                        <p className="tracking-wider text-xs text-zinc-500 uppercase">
                            Photographer
                        </p>
                        <p className="text-zinc-600 font-TTHovesM mt-1">
                            {user?.firstName + ' ' + user?.lastName}
                        </p>
                        <p className="text-zinc-600 text-sm mt-1">
                            {user?.email}
                        </p>
                    </div>
                    <div className="flex-1 bg-[#DDD8FF] rounded-xl p-4">
                        <p className="tracking-wider  text-xs text-zinc-500 uppercase">
                            Client
                        </p>
                        {proj?.proposalCreatedAs === 'Client' ? (
                            <div>
                                <p className="text-zinc-600 font-TTHovesM mt-1">
                                    {proj?.proposalCreatedBy?.firstName +
                                        ' ' +
                                        proj?.proposalCreatedBy?.lastName}
                                </p>
                                <p className="text-zinc-600 text-sm mt-1">
                                    {proj?.proposalCreatedBy?.email}
                                </p>
                            </div>
                        ) : (
                            proj?.clients?.map(item => (
                                <div key={item._id}>
                                    <p className="text-zinc-600 font-TTHovesM mt-1">
                                        {item?.firstName + ' ' + item?.lastName}
                                    </p>
                                    <p className="text-zinc-600 text-sm mt-1">
                                        {item?.email}
                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                    <div className="flex-1 bg-[#F3FBAA] rounded-xl p-4">
                        <p className="tracking-wider  text-xs text-zinc-500 uppercase">
                            Details
                        </p>
                        <div className="flex items-start  gap-2">
                            <Calendar1 className="mt-1 size-5 text-zinc-700" />
                            <p className="text-zinc-600 text-sm mt-1">
                                {new Date(proj?.shootDate).toDateString() +
                                    ' ' +
                                    proj?.shootTime}
                            </p>
                        </div>
                        <div className="flex items-start  gap-2">
                            <Location className="mt-1 size-5 text-zinc-700" />
                            <p className="text-zinc-600 text-sm mt-1">
                                {proj?.locationString}
                            </p>
                        </div>
                    </div>
                </div>
                <p className="text-xs uppercase text-zinc-400 mt-4">
                    Client Agreement
                </p>
                <p className=" text-zinc-900 text-lg font-TTHovesM leading-7 py-2">
                    {proj?.contract?.title}
                </p>
                <p className="text-slate-600 text-sm leading-7  text-justify">
                    {proj?.contract?.contractTerms}
                </p>
                <div className="flex w-full justify-between mt-7">
                    <div className="flex flex-col items-start gap-1">
                        {project?.signature && (
                            <div className="h-10 w-10">
                                <img
                                    src={project?.signature}
                                    className="h-full w-full object-contain"
                                    alt="sign"
                                />
                            </div>
                        )}
                        <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                            Photographer
                        </p>
                        <p className="text-gray-600 text-[13px] leading-[18px]">
                            {user?.firstName + ' ' + user?.lastName}
                        </p>
                        <p className="text-gray-600 text-[13px] leading-[18px]">
                            {new Date().toDateString()}
                        </p>
                        <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200">
                            {proj?.signature ? 'Signed' : ' Sign Pending'}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 items-end">
                        {project?.clientSignature && (
                            <div className="h-10 w-10">
                                <img
                                    src={project?.clientSignature}
                                    className="h-full w-full object-contain"
                                    alt="sign"
                                />
                            </div>
                        )}
                        <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                            Client
                        </p>
                        {proj?.proposalCreatedAs === 'Client' ? (
                            <p className="text-gray-600 text-[13px] leading-[18px]">
                                {proj?.proposalCreatedBy?.firstName +
                                    ' ' +
                                    proj?.proposalCreatedBy?.lastName}
                            </p>
                        ) : (
                            project?.clients?.map(item => (
                                <p
                                    key={item._id}
                                    className="text-gray-600 text-[13px] leading-[18px]">
                                    {item?.firstName + ' ' + item?.lastName}
                                </p>
                            ))
                        )}

                        <p className="text-gray-600 text-[13px] leading-[18px]">
                            {new Date().toDateString()}
                        </p>
                        <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200 justify-start items-center inline-flex">
                            {project?.clientSignature
                                ? 'Signed'
                                : 'Sign Pending'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
