import React, { useEffect, useState } from 'react'
import { styles, TABS } from 'pages/dashboard/Settings'
import classNames from 'classnames'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { setUser } from '../../../redux/reducers/authReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'

const service = new UserService()

interface IPreferences {
    _id: string
    title: string
    description: string
    selected: boolean
}

export const EmailPreferences = ({ tab, user }) => {
    const [preferences, setPreferences] = useState<IPreferences[]>([])
    const [submitting, setSubmitting] = useState(false)

    const { successMessage } = useNotifications()
    const dispatch = useThunkDispatch()

    useEffect(() => {
        service
            .getAllPreferences()
            .then(resp => {
                setPreferences(
                    resp.data.data.map(item1 => {
                        const matchingItem = user.emailPreferences.find(
                            item2 => item1._id === item2.emailPreference,
                        )

                        if (matchingItem) {
                            return { ...item1, ...matchingItem, selected: true }
                        } else {
                            return { ...item1, selected: false }
                        }
                    }),
                )
            })
            .catch(error => {
                console.log({ error })
            })
    }, [])

    const handleClick = (id: string, selected: boolean) => {
        const shallowCopy = [...preferences]
        const findPreference = shallowCopy.find(item => item._id === id)
        if (findPreference) findPreference.selected = selected

        setPreferences(shallowCopy)
    }

    const handleSave = () => {
        setSubmitting(false)
        const preferenceIds = preferences
            .filter(item => item.selected)
            .map(item => item._id)
            .join(',')

        service
            .savePreferences(preferenceIds)
            .then(resp => {
                const updated = { ...user, emailPreferences: resp.data.data }
                dispatch(setUser(updated))
                successMessage('Preferences updated successfully')
            })
            .catch(error => {
                console.log('Error: ', error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div className="flex-1">
                {/* Buttons */}
                <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full pb-6">
                    <p className="text-zinc-800 text-[22px] font-TTHovesM">
                        Email Preferences
                    </p>

                    <div className="justify-start items-center gap-3 flex">
                        <button type="button" className={styles.default}>
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                Cancel
                            </span>
                        </button>
                        <button
                            type="submit"
                            onClick={handleSave}
                            className={styles.primary}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                {submitting ? 'Saving...' : 'Save changes'}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                {preferences.map(item => (
                    <div key={item._id} className="flex items-start gap-3">
                        <div className="h-5 w-5">
                            <input
                                id={item._id}
                                type="checkbox"
                                defaultChecked={item.selected}
                                onChange={event =>
                                    handleClick(item._id, event.target.checked)
                                }
                                className="accent-brown-10 rounded-lg border-zinc-200 h-5 w-5"
                            />
                        </div>
                        <label
                            htmlFor={item._id}
                            className="flex flex-col gap-1">
                            <p className="text-slate-700 leading-4 text-lg font-TTHovesM">
                                {item.title}
                            </p>
                            <p className="text-zinc-500 leading-6 mt-1">
                                {item.description}
                            </p>
                        </label>
                    </div>
                ))}
            </div>
            <hr className="text-zinc-200 my-8" />
            <div className="flex-col justify-start items-start gap-4 inline-flex">
                <p className="text-gray-600">
                    Or check here to turn off all emails:
                </p>
                <label htmlFor="unsubscribe" className="flex gap-4">
                    <div className="h-5 w-5">
                        <input
                            id="unsubscribe"
                            type="checkbox"
                            className="accent-brown-10 h-5 w-5"
                        />
                    </div>
                    <p className="text-slate-800 text-lg leading-5 font-TTHovesM">
                        Unsubscribe from all emails
                    </p>
                </label>
            </div>
            <hr className="text-zinc-200 my-8" />
        </div>
    )
}
