import { Photo2, heartWhite } from "utils/staticData"

const PhotoCardForQuoteDetails = ({ photo }) => {
    return (
        <div className="w-[140px] h-[140px] rounded-lg overflow-hidden relative group">
            <img src={photo} alt="" className="w-full h-full object-cover" />

            <div className="absolute inset-0 bg-[rgb(0_0_0_/_78%)] flex flex-col justify-between items-baseline text-white p-[14px] opacity-0 group-hover:opacity-100 transition-all duration-700">
                <div>
                    <button type="button" onClick={() => console.log('wow')}>
                        <img src={heartWhite} alt="Heart" />
                    </button>
                </div>
                <div className="flex gap-[6px]">
                    <img
                        src={Photo2}
                        alt="DP"
                        className="w-4 h-4 object-cover rounded-full"
                    />
                    <p className="text-[12px] font-TTHovesM">{'Hanna Woa 🔥'} </p>
                </div>
            </div>
        </div>
    )
}

export default PhotoCardForQuoteDetails
