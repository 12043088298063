import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const useContracts = () => {
    const [contracts, setContracts] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        service
            .getContracts()
            .then(resp => {
                setContracts(resp.data.data)
            })
            .catch(error => {
                console.log({ error })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setContracts,
        fetchData,
        contracts,
    }
}
