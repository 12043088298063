import { ArrowLeft } from 'iconsax-react'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

const WorkingHoursTile: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="flex gap-4 items-center">
            <div>
                <ArrowLeft
                    size="18px"
                    color="#000000"
                    className="cursor-pointer"
                    onClick={() => navigate('/dashboard/availability')}
                />
            </div>

            <div>
                <p className="font-TTHovesM text-xl leading-7 text-dark-8">
                    {'Working Hours'}
                </p>
                <p className="font-TTHovesL text-sm leading-5 text-dark-5">
                    {'Mon - Fri, 9:00 AM - 5:00 PM'}
                </p>
            </div>
        </div>
    )
}

export default memo(WorkingHoursTile)
