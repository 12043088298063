import { FC, ReactNode } from 'react'

interface MessageNotificationProps {
    icon: ReactNode
    message: ReactNode
    className?: string
}

export const MessageNotification: FC<MessageNotificationProps> = ({
    icon,
    message,
    className,
}) => {
    return (
        <div
            className={`hidden md:flex items-center w-[270px] p-4 m-4 rounded-xl bg-white ${className}`}>
            <div className="p-1">{icon}</div>
            <div className="p-1">{message}</div>
        </div>
    )
}
