import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import inputStyles from './Input/Input.module.css'
import { Location } from 'iconsax-react'
import classNames from 'classnames'

export const LocationSelect = ({
    value,
    label = 'Location',
    onChange,
    onSelect = (value: any) => onChange(value),
    bgLabel = false,
    error = null,
}) => {
    return (
        <PlacesAutocomplete
            value={value}
            onChange={(value: string) => onChange(value)}
            onSelect={(value: string) => onSelect(value) || onChange(value)}>
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <div className="w-full h-14 z-40 relative">
                    <input
                        {...getInputProps({
                            placeholder: 'Search Location ...',
                            className: `${error && inputStyles.inputError} ${
                                inputStyles.input + ' !pr-8'
                            }`,
                        })}
                    />
                    <label
                        className={classNames('absolute -top-3 left-4 ', {
                            'text-gray': !error,
                            'text-red': error,
                            'bg-creame-1': !bgLabel,
                            'bg-white': bgLabel,
                        })}>
                        {label}
                    </label>
                    <div className="absolute right-2.5 top-[30%] text-gray cursor-pointer">
                        <Location className="h-5 w-5" />
                    </div>
                    <div className="shadow text-zinc-800 overflow-hidden rounded-lg mt-2">
                        {loading && (
                            <div className="bg-white px-3 pt-3 pb-2">
                                Loading...
                            </div>
                        )}
                        {suggestions.map(suggestion => {
                            const style = suggestion.active
                                ? {
                                      backgroundColor: '#C7987A',
                                      cursor: 'pointer',
                                  }
                                : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                  }
                            return (
                                <div
                                    key={suggestion.description}
                                    {...getSuggestionItemProps(suggestion, {
                                        className: 'p-3 hover:text-white',
                                        style,
                                    })}>
                                    <span>{suggestion.description}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}
