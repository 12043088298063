import {
    Add,
    ArrowRight2,
    Home2,
    RefreshCircle,
    Setting2,
    Settings,
    Trash,
} from 'iconsax-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Fragment, useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import classNames from 'classnames'
import { CollabPosts } from 'pages/dashboard/CollabPosts'
import { DataNotFound } from 'components/common/DataNotFound'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'
import { CollaborateModal } from 'components/dashboard/CollaborateModal'
// import {useState} from "react";

const service = new UserService()

export const CollabBoard = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [show, setShow] = useState(false)
    const [id, setId] = useState('')

    // const [current, setCurrent] = useState("Board")
    //
    // const renderComponent = () => {
    //     switch (current) {
    //         case "Board":
    //             return
    //     }
    // }

    const [params] = useSearchParams()
    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()

    const navigate = useNavigate()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getCollabs()
            .then(resp => {
                setData(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.date).getTime()
                        const dateB = new Date(a.date).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(() => {
                console.log('Error fetching: ')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    const handleDelete = () => {
        service
            .deletePost(id ?? '')
            .then(() => {
                successMessage('Post deleted successfully')
                const index = data.findIndex((item: any) => item._id === id)
                const updated = [...data]
                updated.splice(index, 1)
                setData(updated)
                setShow(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Fragment>
            <div className="flex flex-col gap-4 md:flex-row md:items-center justify-between">
                <div className="">
                    <div className="hidden items-center gap-2 md:flex">
                        <Home2 className="h-5 w-5" />
                        <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                        <p className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                            Directory
                        </p>
                        <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                        <p className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                            Collab Board
                        </p>
                    </div>
                </div>
                {/* Buttons */}
                <div className="flex items-center gap-3">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="flex shadow items-center gap-2 px-4 py-2.5 rounded-lg bg-white  text-sm font-TTHovesM border border-zinc-200 text-zinc-800  focus:outline-none ">
                        <Setting2 className="h-4 w-4" />
                        Preferences
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/dashboard/add-post')}
                        className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Add className="text-white h-4 w-4" />
                        <span className="text-white text-sm font-TTHovesM">
                            Create New Post
                        </span>
                    </button>
                </div>
                {/* Buttons */}
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-4 p-5">
                {/* Header */}
                <div className="w-full p-1 bg-white rounded-lg border border-zinc-200 justify-start items-center gap-2 inline-flex">
                    <button
                        onClick={() => {
                            navigate('/dashboard/board')
                            setLoading(true)
                            setTimeout(() => {
                                setLoading(false)
                            }, 2000)
                        }}
                        className={classNames(
                            'px-5 py-2 text-sm font-TTHovesM rounded-md',
                            {
                                'bg-tale-10 text-white': !params.has('tab'),
                                'text-zinc-700': params.has('tab'),
                            },
                        )}>
                        Collaboration Board
                    </button>
                    <button
                        onClick={() => navigate('/dashboard/board?tab=posts')}
                        className={classNames(
                            'px-5 py-2 text-sm font-TTHovesM rounded-md',
                            {
                                'bg-tale-10 text-white': params.has('tab'),
                                'text-zinc-700': !params.has('tab'),
                            },
                        )}>
                        Collaboration Posts
                    </button>
                </div>
                {/* Header End */}

                {/* Description */}
                <div className="border-b border-zinc-200 py-5">
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        Collaboration Board
                    </p>
                    <p className="text-slate-600 text-sm font-TTHovesL">
                        Need help with a job? Looking for advice? Our
                        collaboration board is an open platform for
                        photographers to seek each other out and do great things
                        together
                    </p>
                </div>
                {/* Description End */}
                {/* Cards */}
                {loading ? (
                    <div className="h-72 w-full flex items-center justify-center">
                        <RefreshCircle className="h-12 w-12 text-tale-10 animate-spin" />
                    </div>
                ) : params.has('tab') ? (
                    data?.length > 0 ? (
                        data.map((item: any) => (
                            <div
                                key={item._id}
                                className="md:w-[862px] border border-zinc-200 rounded-lg mt-6">
                                <div className="flex flex-col gap-3 p-5">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <div className="h-8 w-8 rounded-full overflow-hidden">
                                                <img
                                                    alt="profile"
                                                    src={
                                                        item?.userId
                                                            ?.profileImage
                                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.userId?.profileImage}`
                                                            : '/assets/images/blank.webp'
                                                    }
                                                    className="h-full w-full rounded-full"
                                                />
                                            </div>
                                            <div>
                                                <span className="text-zinc-600 text-[15px]">
                                                    Posted by{' '}
                                                </span>
                                                <span className="text-zinc-900 text-[15px] font-TTHovesM">
                                                    {item?.userId?.firstName +
                                                        ' ' +
                                                        item?.userId?.lastName}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2">
                                            <p className="px-[18px] text-center py-[7px] bg-zinc-50 rounded-md border border-zinc-200 text-slate-700 text-sm font-TTHovesM">
                                                {item?.collaborationId?.name}
                                            </p>
                                        </div>
                                    </div>

                                    <p className="text-slate-800 -mb-2 text-lg font-TTHovesM">
                                        {item.title}
                                    </p>
                                    <div
                                        className="leading-none text-sm text-gray-700"
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                    <div className="flex items-center gap-20">
                                        <div className="">
                                            <div className="text-zinc-600 text-[13px] ">
                                                Location
                                            </div>
                                            <div className="text-slate-800 text-sm font-TTHovesM">
                                                {item?.location
                                                    ? item?.location
                                                    : 'Not Specified'}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="text-zinc-600 text-[13px] ">
                                                Date Posted
                                            </div>
                                            <div className="text-slate-800 text-sm font-TTHovesM">
                                                {new Date(
                                                    item?.date,
                                                ).toDateString()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full items-center p-3 border-t border-zinc-200  justify-between">
                                    {user?._id === item?.userId._id ? (
                                        <div />
                                    ) : (
                                        <div className=" flex gap-3">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.83333 7.08333H10M5.83333 10H12.5M8.06979 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V16.9463C2.5 17.3903 2.5 17.6123 2.59102 17.7263C2.67019 17.8255 2.79022 17.8832 2.91712 17.8831C3.06302 17.8829 3.23639 17.7442 3.58313 17.4668L5.57101 15.8765C5.9771 15.5517 6.18014 15.3892 6.40624 15.2737C6.60683 15.1712 6.82036 15.0963 7.04101 15.051C7.28972 15 7.54975 15 8.06979 15Z"
                                                    stroke="#69884F"
                                                    strokeWidth="1.6"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="text-zinc-600 text-[15px] font-TTHovesM">
                                                Send Message
                                            </span>
                                        </div>
                                    )}
                                    <div className="flex items-center gap-2">
                                        <button
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/add-post?id=${item._id}`,
                                                )
                                            }
                                            className="px-[18px] py-[7px] rounded-md inline-flex gap-1 border border-zinc-200 bg-white text-zinc-800  text-sm font-TTHovesM">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.99998 16.6662H17.5M2.5 16.6662H3.89545C4.3031 16.6662 4.50693 16.6662 4.69874 16.6202C4.8688 16.5793 5.03138 16.512 5.1805 16.4206C5.34869 16.3175 5.49282 16.1734 5.78107 15.8852L16.25 5.4162C16.9404 4.72585 16.9404 3.60656 16.25 2.9162C15.5597 2.22585 14.4404 2.22585 13.75 2.9162L3.28105 13.3852C2.9928 13.6734 2.84867 13.8175 2.7456 13.9857C2.65422 14.1348 2.58688 14.2974 2.54605 14.4675C2.5 14.6593 2.5 14.8631 2.5 15.2708V16.6662Z"
                                                    stroke="#344054"
                                                    strokeWidth="1.6"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShow(true)
                                                setId(item?._id)
                                            }}
                                            className="bg-[#EB57571A] text-red inline-flex gap-1 text-sm font-TTHovesM px-[18px] py-[7px] rounded-md">
                                            <Trash className="h-5 w-5" />
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <DataNotFound title="No posts found" />
                    )
                ) : (
                    <CollabPosts />
                )}
                {/* Cards End */}
            </div>
            <ConfirmModal
                title="Delete Post?"
                description="The post will be deleted permanently"
                isOpen={show}
                setIsOpen={setShow}
                onConfirm={handleDelete}
            />
            <CollaborateModal showModal={modal} setShowModal={setModal} />
        </Fragment>
    )
}
