import React, { FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

type Props = {
    children: React.ReactElement | React.ReactNode
    showModal: boolean
    setShowModal: (value: boolean) => void
    cancelButtonRef?: React.RefObject<HTMLButtonElement>
    width?: string
    title: string
    description?: string
    onClose?: any
    padding?: string
    footerButton: string
    handleClickPrimary: any
    headerIcon: any
}

const DESCRIPTION =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'

export const Modal: FC<Props> = ({
    children,
    showModal,
    setShowModal,
    cancelButtonRef,
    width,
    title,
    onClose,

    headerIcon,
    handleClickPrimary,
    footerButton,
    description = DESCRIPTION,
}) => {
    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-50 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={showModal}
                onClose={setShowModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-[#0D0C1C] bg-opacity-[0.7] transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className={`${
                                width ? width : 'w-full md:w-2/4'
                            } inline-block align-bottom bg-dark-black rounded-lg bg-white text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle`}>
                            <div className="max-w-none mx-auto">
                                <div
                                    className="absolute right-7 top-5 cursor-pointer"
                                    onClick={() => setShowModal(false)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-zinc-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={`bg-dark-black  sm:rounded-lg sm:shadow`}>
                                    <div className="p-5 justify-start items-start gap-4 inline-flex">
                                        {headerIcon && (
                                            <div className="p-3 bg-white rounded-[10px] border border-zinc-200">
                                                {headerIcon}
                                            </div>
                                        )}
                                        <div className="flex-col justify-start items-start inline-flex">
                                            <p className="text-zinc-900 text-lg font-TTHovesSB">
                                                {title}
                                            </p>
                                            <div className="text-zinc-600 text-sm w-full">
                                                {description}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="text-zinc-200 w-full " />
                                    {children}

                                    <div className="flex gap-4 p-5">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShowModal(false)
                                                onClose && onClose()
                                            }}
                                            className="flex-1 text-slate-700  px-[18px] py-[10px] font-TTHovesM bg-white rounded-lg  border border-zinc-300 ">
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={handleClickPrimary}
                                            className="flex-1 text-white px-[18px] py-[10px] font-TTHovesM bg-tale-10 rounded-lg">
                                            {footerButton}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
