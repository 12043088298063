import classNames from 'classnames'
import { Input } from 'components/form/Input/Input'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { AddSquare, Trash } from 'iconsax-react'
import React, { useState } from 'react'
import { styles } from 'pages/dashboard/Settings'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { CloseIcon } from '../../../css/icons/icons'

const service = new UserService()

const YEARS = [
    {
        value: '1 year',
        label: '1 year',
    },
    {
        value: '2 years',
        label: '2 years',
    },
    {
        value: '3 years',
        label: '3 years',
    },
    {
        value: '4 years',
        label: '4 years',
    },
    {
        value: '5 years',
        label: '5 years',
    },
    {
        value: '6 years',
        label: '6 years',
    },
    {
        value: '7 years',
        label: '7 years',
    },
    {
        value: '8 years',
        label: '8 years',
    },
    {
        value: '9 years',
        label: '9 years',
    },
    {
        value: '10 years',
        label: '10 years',
    },
    {
        value: '10+ years',
        label: '10+ years',
    },
]

export const Education = ({ tab, settings, setSettings }) => {
    const [state, setState] = useState({
        experiences: {
            yearsInBusiness: settings?.experiences?.yearsInBusiness ?? '',
            yearsOfExperience: settings?.experiences?.yearsOfExperience ?? '',
            haveEmployees: settings?.experiences?.haveEmployees ?? 'No',
            noOfEmployees: settings?.experiences?.noOfEmployees ?? '0',
        },
        certifications: Array.isArray(settings?.certifications)
            ? settings?.certifications
            : [{ title: '' }],
    })
    const [title, setTitle] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const { successMessage } = useNotifications()

    const addNewExperience = event => {
        if (event.key === 'Enter') {
            setState({
                ...state,
                certifications: [...state.certifications, { title }],
            })
            setTitle('')
        }
    }

    const handleDeleteCertificate = (index, id) => {
        const shallowCopy = { ...state }
        shallowCopy.certifications.splice(index, 1)
        setState(shallowCopy)
        service
            .deleteCertification(id)
            .then(() => {
                successMessage('Certificate deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitting(true)

        try {
            const resp = await service.addExperience({
                ...state,
                experiences: {
                    ...state.experiences,
                    noOfEmployees: +state.experiences.noOfEmployees,
                },
            })
            setSettings(resp.data.data)
            successMessage('Certification added successfully')
        } catch (error) {
            console.log(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <p className="text-zinc-800 text-xl font-TTHovesM">Experiences</p>

            <form className="max-w-3xl mt-6">
                <div className="flex items-center gap-4">
                    <div className="flex-1">
                        <ReactSelect
                            label="No. of years in business"
                            options={YEARS}
                            placeholder="Select years"
                            value={state.experiences.yearsInBusiness}
                            onChange={value =>
                                setState({
                                    ...state,
                                    experiences: {
                                        ...state.experiences,
                                        yearsInBusiness: value,
                                    },
                                })
                            }
                        />
                    </div>
                    <div className="flex-1">
                        <ReactSelect
                            label="Years of experience"
                            options={YEARS}
                            placeholder="Select years"
                            value={state.experiences.yearsOfExperience}
                            onChange={value =>
                                setState({
                                    ...state,
                                    experiences: {
                                        ...state.experiences,
                                        yearsOfExperience: value,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
                <div className="flex items-center gap-4 mt-8">
                    <div className="flex-1">
                        <ReactSelect
                            label="Do you have employees?"
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                },
                            ]}
                            placeholder="Select "
                            value={state.experiences.haveEmployees}
                            onChange={value =>
                                setState({
                                    ...state,
                                    experiences: {
                                        ...state.experiences,
                                        haveEmployees: value,
                                    },
                                })
                            }
                        />
                    </div>
                    <div className="flex-1">
                        <Input
                            type="number"
                            label="No. of employees"
                            name="noOfEmployees"
                            placeholder="No. of employees"
                            value={state.experiences.noOfEmployees}
                            disabled={state.experiences.haveEmployees !== 'Yes'}
                            onChange={event =>
                                setState({
                                    ...state,
                                    experiences: {
                                        ...state.experiences,
                                        noOfEmployees: event.target.value,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
            </form>
            {/* Service Preferences */}

            <hr className="text-zinc-200 my-5" />
            {/* Languages */}
            <div className="flex flex-col gap-5 max-w-3xl">
                <p className="text-zinc-800 text-[22px] font-TTHovesM">
                    Certification
                </p>
                <Input
                    type="text"
                    label="Add certificate"
                    value={title}
                    onKeyDown={e => addNewExperience(e)}
                    onChange={event => setTitle(event.target.value)}
                    placeholder="e.g Fashion/Celebrity Photographer"
                />
                <div className="flex items-center gap-2">
                    {state.certifications.map((item, index) => (
                        <div
                            key={item._id}
                            className="px-3 py-2.5 bg-zinc-50 rounded-lg border border-zinc-300 items-center gap-5 inline-flex">
                            <p className="text-zinc-900 text-sm font-TTHovesM whitespace-nowrap">
                                {item?.title}
                            </p>
                            <button
                                className=""
                                onClick={() =>
                                    handleDeleteCertificate(index, item._id)
                                }
                                type="button">
                                <CloseIcon className=" w-4 text-zinc-700" />
                            </button>
                        </div>
                    ))}
                </div>

                <div className="flex items-center gap-3 mb-4 md:mb-0">
                    <button type="button" className={styles.default}>
                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                            Cancel
                        </span>
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={submitting}
                        className={styles.primary}>
                        <span className="text-white  text-sm font-TTHovesM leading-tight">
                            {submitting ? 'Saving...' : 'Save Changes'}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
