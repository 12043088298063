import { CloseIcon } from 'css/icons/icons'
import { CloseCircle } from 'iconsax-react'
import React from 'react'

interface Props {
    toggle: (boolean) => void
    open: boolean
    times?: boolean
    children: any
    width?: string
}

const ModalSecondary: React.FC<Props> = ({
    toggle,
    open,
    times,
    children,
    width,
}) => {
    if (!open) return null

    return (
        <div className="bg-[rgba(0,0,0,0.8)] fixed inset-0 z-50 flex items-center justify-center">
            <div className={`relative ${width}`}>
                <button
                    className="absolute top-2 right-2 z-10"
                    onClick={() => toggle(false)}
                    type="button">
                    <CloseCircle size="24px" />
                </button>
                <div>{children}</div>
            </div>
        </div>
    )
}

export default ModalSecondary
