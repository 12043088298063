import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { Add, RefreshCircle, Trash } from 'iconsax-react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'
import SectionHeading from 'components/common/SectionHeading'
import InvitedPhotographerList from 'components/dashboard/quotes/InvitedPhotographerList'
import AppliedPhotographerList from 'components/dashboard/quotes/AppliedPhotographerList'
import { DataNotFound } from 'components/common/DataNotFound'
import PhotoCardForQuoteDetails from 'components/dashboard/quotes/photos-card-quote-details'
import { divide } from 'lodash'
import { Photo1, Photo2, Photo3 } from 'utils/staticData'

const service = new UserService()

export const QuoteDetail = () => {
    const [selected, setSelected] = useState<any>()
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const params = useParams()
    const { id } = params
    const navigate = useNavigate()
    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()

    useEffect(() => {
        setLoading(true)
        service
            .getSingleQuote(id ?? '')
            .then(resp => {
                setSelected(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })

        service.getApplied().then(resp => {
            console.log({ resp })
        })
    }, [id, params])

    const handleDelete = () => {
        service
            .deleteQuote(id ?? '')
            .then(() => {
                navigate('/dashboard/browse/jobs')
                successMessage('Post deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="mt-4 bg-white rounded-xl border border-zinc-200 p-5">
                {loading ? (
                    <RefreshCircle className="mx-auto  animate-spin h-10 w-10 my-10 text-tale-10" />
                ) : (
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col md:flex-row  md:items-center gap-4 md:gap-0 justify-between">
                            <div className="flex  items-center gap-3">
                                {selected?.user?.profileImage ? (
                                    <div className="flex-shrink-0 h-8 w-8 rounded-full overflow-hidden">
                                        <img
                                            alt="profile"
                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${selected?.user?.profileImage}`}
                                            className="h-full w-full rounded-full"
                                        />
                                    </div>
                                ) : (
                                    <span className="h-8 bg-[#F9FAFB] rounded-full w-8 font-TTHovesSB text-dark-8 text-[18px] uppercase flex items-center justify-center">
                                        {selected?.user?.firstName[0]}
                                    </span>
                                )}

                                <div>
                                    <span className="text-zinc-600 text-[15px]">
                                        Posted by{' '}
                                    </span>
                                    <span className="text-zinc-900 text-[15px] font-TTHovesM">
                                        {selected?.user?.firstName +
                                            ' ' +
                                            selected?.user?.lastName}
                                    </span>
                                </div>
                            </div>
                            {selected?.user?._id === user?._id && (
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/add-quote?id=${selected._id}`,
                                            )
                                        }
                                        className="px-[18px] py-[7px] rounded-md inline-flex gap-1 border border-zinc-200 bg-white text-zinc-800  text-sm font-TTHovesM">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.99998 16.6662H17.5M2.5 16.6662H3.89545C4.3031 16.6662 4.50693 16.6662 4.69874 16.6202C4.8688 16.5793 5.03138 16.512 5.1805 16.4206C5.34869 16.3175 5.49282 16.1734 5.78107 15.8852L16.25 5.4162C16.9404 4.72585 16.9404 3.60656 16.25 2.9162C15.5597 2.22585 14.4404 2.22585 13.75 2.9162L3.28105 13.3852C2.9928 13.6734 2.84867 13.8175 2.7456 13.9857C2.65422 14.1348 2.58688 14.2974 2.54605 14.4675C2.5 14.6593 2.5 14.8631 2.5 15.2708V16.6662Z"
                                                stroke="#344054"
                                                strokeWidth="1.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => setShow(true)}
                                        className="bg-[#EB57571A] text-red inline-flex gap-1 text-sm font-TTHovesM px-[18px] py-[7px] rounded-md">
                                        <Trash className="h-5 w-5" />
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col items-start gap-1">
                            <p className="text-slate-800 text-lg font-TTHovesM">
                                {selected?.title}
                            </p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: selected?.description,
                                }}
                            />
                            <button className="px-[18px] py-[7px]  bg-zinc-50 rounded-md border border-zinc-200 flex text-slate-700 text-sm font-TTHovesM">
                                {selected?.serviceType?.name}
                            </button>
                        </div>
                        <div className="flex flex-col gap-6 max-w-6xl">
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-0">
                                <div className="place-self-start">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Location
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {selected?.locationString}
                                    </div>
                                </div>
                                <div className="self-start">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Budget
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {selected?.user?.currencySymbol}{' '}
                                        {selected?.budget}{' '}
                                        {selected?.rate === 'Hourly' && '/hr'}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Dated Posted
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {new Date(
                                            selected?.date,
                                        ).toDateString()}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-0">
                                <div className="">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Shoot Date
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {new Date(
                                            selected?.duration,
                                        ).toDateString()}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Estimated Shoot Time
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {selected?.estimatedShootTime}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-zinc-600 text-[13px] ">
                                        Quotes Received
                                    </div>
                                    <div className="text-slate-800 text-sm font-TTHovesM">
                                        {selected?.quotesReceived} /{' '}
                                        {selected?.quoteLimit}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full overflow-x-auto relative">
                            <div className="flex gap-4 min-w-max p-4 ">
                                <PhotoCardForQuoteDetails photo={Photo1} />
                                <PhotoCardForQuoteDetails photo={Photo2} />
                                <PhotoCardForQuoteDetails photo={Photo3} />
                                <PhotoCardForQuoteDetails photo={Photo1} />
                                <PhotoCardForQuoteDetails photo={Photo2} />
                                <PhotoCardForQuoteDetails photo={Photo3} />
                                <PhotoCardForQuoteDetails photo={Photo1} />
                                <PhotoCardForQuoteDetails photo={Photo2} />
                                <PhotoCardForQuoteDetails photo={Photo3} />
                                <PhotoCardForQuoteDetails photo={Photo1} />
                                <PhotoCardForQuoteDetails photo={Photo2} />
                                <PhotoCardForQuoteDetails photo={Photo3} />
                                <PhotoCardForQuoteDetails photo={Photo1} />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {user._id === selected?.user._id ? (
                <div className="mt-4 bg-white rounded-xl border border-zinc-200 p-5">
                    <div className="flex justify-between items-center">
                        <SectionHeading label="Quote Applications" />
                        {selected?._id && (
                            <button
                                className="flex gap-2 items-center text-sm rounded-lg p-2 border border-zinc-200 shadow-sm text-zinc-800"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/quote/invite-photographers/${selected._id}`,
                                    )
                                }>
                                <Add size={18} /> Invite Photographers
                            </button>
                        )}
                    </div>

                    {!selected?.photographers.length &&
                        !selected?.quoteSentUsers.length ? (
                        <div className="mt-9">
                            <DataNotFound title="No Applications Yet!" />
                        </div>
                    ) : undefined}

                    {selected?.photographers.length ? (
                        <InvitedPhotographerList
                            list={selected?.photographers || []}
                        />
                    ) : undefined}

                    {selected?.quoteSentUsers.length ? (
                        <AppliedPhotographerList
                            list={selected?.quoteSentUsers || []}
                            description={selected.description}
                        />
                    ) : undefined}
                </div>
            ) : undefined}

            {/*<div className="rounded-xl border border-zinc-200 p-5" />*/}
            {/*<div className="flex flex-col gap-3">*/}
            {/*<div className="flex items-center justify-between">*/}
            {/*    <div className="flex items-center gap-3">*/}
            {/*        <div className="h-8 w-8 rounded-full overflow-hidden">*/}
            {/*            <img*/}
            {/*                alt="profile"*/}
            {/*                src="/assets/homepage-photographer-2.jpg"*/}
            {/*                className="h-full w-full rounded-full"*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <span className="text-zinc-600 text-[15px]">*/}
            {/*                Posted by{' '}*/}
            {/*            </span>*/}
            {/*            <span className="text-zinc-900 text-[15px] font-TTHovesM">*/}
            {/*                {selected?.user?.firstName +*/}
            {/*                    ' ' +*/}
            {/*                    selected?.user?.lastName}*/}
            {/*            </span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*        {selected?.user?._id === user?._id && (*/}
            {/*            <div className="flex items-center gap-2">*/}
            {/*                <button className="px-[18px] py-[7px] rounded-md inline-flex gap-1 border border-zinc-200 bg-white text-zinc-800  text-sm font-TTHovesM">*/}
            {/*                    <svg*/}
            {/*                        width="20"*/}
            {/*                        height="20"*/}
            {/*                        viewBox="0 0 20 20"*/}
            {/*                        fill="none"*/}
            {/*                        xmlns="http://www.w3.org/2000/svg">*/}
            {/*                        <path*/}
            {/*                            d="M9.99998 16.6662H17.5M2.5 16.6662H3.89545C4.3031 16.6662 4.50693 16.6662 4.69874 16.6202C4.8688 16.5793 5.03138 16.512 5.1805 16.4206C5.34869 16.3175 5.49282 16.1734 5.78107 15.8852L16.25 5.4162C16.9404 4.72585 16.9404 3.60656 16.25 2.9162C15.5597 2.22585 14.4404 2.22585 13.75 2.9162L3.28105 13.3852C2.9928 13.6734 2.84867 13.8175 2.7456 13.9857C2.65422 14.1348 2.58688 14.2974 2.54605 14.4675C2.5 14.6593 2.5 14.8631 2.5 15.2708V16.6662Z"*/}
            {/*                            stroke="#344054"*/}
            {/*                            strokeWidth="1.6"*/}
            {/*                            strokeLinecap="round"*/}
            {/*                            strokeLinejoin="round"*/}
            {/*                        />*/}
            {/*                    </svg>*/}
            {/*                    Edit*/}
            {/*                </button>*/}
            {/*                <button className="bg-[#EB57571A] text-red inline-flex gap-1 text-sm font-TTHovesM px-[18px] py-[7px] rounded-md">*/}
            {/*                    <Trash className="h-5 w-5" />*/}
            {/*                    Delete*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*    <div className="flex flex-col items-start gap-1">*/}
            {/*        <p className=" text-slate-800 text-lg font-TTHovesM">*/}
            {/*            {selected?.title}*/}
            {/*        </p>*/}
            {/*        <div*/}
            {/*            dangerouslySetInnerHTML={{*/}
            {/*                __html: selected?.description,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <button className="px-[18px] py-[7px]  bg-zinc-50 rounded-md border border-zinc-200 flex text-slate-700 text-sm font-TTHovesM">*/}
            {/*            {selected?.serviceType?.name}*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div className="flex flex-col gap-6 max-w-6xl">*/}
            {/*        <div className="grid grid-cols-3">*/}
            {/*            <div className="place-self-start">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Location*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    {selected?.address}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="self-start">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Budget*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    ${selected?.budget}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Dated Posted*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    {new Date(selected?.date).toDateString()}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="grid grid-cols-3">*/}
            {/*            <div className="">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Shoot Date*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    {new Date(*/}
            {/*                        selected?.duration,*/}
            {/*                    ).toDateString()}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Estimated Shoot Time*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    {selected?.shootTime}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="">*/}
            {/*                <div className="text-zinc-600 text-[13px] ">*/}
            {/*                    Quotes Received*/}
            {/*                </div>*/}
            {/*                <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                    0 / {selected?.quoteLimit}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <ConfirmModal
                title="Delete Post?"
                description="The post will be deleted permanently"
                isOpen={show}
                setIsOpen={setShow}
                onConfirm={handleDelete}
            />
        </div>
    )
}
