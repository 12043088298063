import { CloseIcon, IconSearch } from '../../../css/icons/icons'
import React, { useState } from 'react'
import classNames from 'classnames'
import { useSession } from 'hooks/useSession'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { setUser } from '../../../redux/reducers/authReducer'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { useThunkDispatch } from 'hooks/useThunkDisptach'

const service = new UserService()
export const ShootCategories = ({ tab }) => {
    const user = useSelector(userSelector)

    const [submitting, setSubmitting] = useState(false)
    const [selected, setSelected] = useState(user?.portfolio)

    const { sessions, setSessions } = useSession()
    const dispatch = useDispatch()
    const { successMessage } = useNotifications()

    const onChange = value => {
        const item = selected.find(item => item.session._id === value)

        if (item) {
            return
        } else {
            const findItem = sessions.find(item => item._id === value)
            setSelected([...selected, { session: { ...findItem, images: [] } }])
            // setSelected(
            //     sessions.map(item => {
            //         if (item._id === value) {
            //             return {
            //                 ...item,
            //                 selected: true,
            //             }
            //         }
            //         return item
            //     }),
            // )
        }
    }

    const handleSave = () => {
        setSubmitting(true)
        const ids = selected.map(item => item.session._id).join(',')

        service
            .sessionOffer({ sessionIds: ids })
            .then(resp => {
                dispatch(setUser(resp.data.data))
                successMessage('Categories updated successfully')
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div
            className={classNames(
                'md:h-96 bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Shoot Categories
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}
                <button
                    type="submit"
                    onClick={handleSave}
                    className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    <span className="text-white text-sm font-TTHovesM ">
                        {submitting ? 'Saving...' : 'Save'}
                    </span>
                </button>
            </div>
            <div className="mt-5">
                <ReactSelect
                    label={'Search'}
                    value={''}
                    onChange={value => onChange(value)}
                    options={sessions.map(item => ({
                        value: item._id,
                        label: item.name,
                    }))}
                    placeholder="Search"
                />
                <RenderItem
                    sessions={selected}
                    setSessions={setSelected}
                    setUser={setUser}
                />
            </div>
        </div>
    )
}

const RenderItem = ({ sessions, setSessions, setUser }) => {
    const { successMessage } = useNotifications()
    const dispatch = useThunkDispatch()
    const handleRemove = value => {
        service
            .deleteCategory(value)
            .then(resp => {
                const index = sessions.findIndex(
                    item => item.session._id === value,
                )
                const copy = [...sessions]
                copy.splice(index, 1)
                setSessions(copy)
                dispatch(setUser(resp.data.data))
                successMessage('Category deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })

        // setSessions(
        //     newCategories.map(item => {
        //         if (item.session._id === value) {
        //             return {
        //                 ...item,
        //                 selected: false,
        //             }
        //         }
        //         return item
        //     }),
        // )
    }

    return (
        <div className="flex flex-wrap gap-3 mt-4">
            {sessions.map(item => (
                <div
                    key={item.session._id}
                    className="px-3 py-2.5 bg-zinc-50 rounded-lg border border-zinc-300 items-center gap-5 inline-flex">
                    <p className="text-zinc-900 text-sm font-TTHovesM whitespace-nowrap">
                        {item?.session?.name}
                    </p>
                    <button
                        className=""
                        onClick={() => handleRemove(item.session._id)}
                        type="button">
                        <CloseIcon className=" w-4 text-zinc-700" />
                    </button>
                </div>
            ))}
        </div>
    )
}
