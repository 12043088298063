import { ChangeEvent, FocusEvent, FC, useState } from 'react'
import styles from './Input.module.css'
import { Eye, EyeSlash } from 'iconsax-react'
import classNames from 'classnames'

interface CustomInputProps {
    className?: string
    type: string
    label?: string
    placeholder: string
    name?: string
    onChange?(e: ChangeEvent<HTMLInputElement>): void
    onBlur?(e: FocusEvent<HTMLInputElement, Element>): void
    value?: string
    defaultValue?: string
    icon?: any
    required?: boolean
    id?: string
    maxLength?: number
    disabled?: boolean
    min?: any
    max?: any
    onKeyDown?: any
    error?: any
    bgLabel?: boolean
}

export const Input: FC<CustomInputProps> = ({
    className,
    type,
    label,
    icon,
    placeholder,
    onChange,
    name,
    error,
    disabled = false,
    onBlur,
    defaultValue,
    maxLength,
    value,
    min,
    max,
    onKeyDown,
    required,
    bgLabel,
}) => {
    const [show, setShow] = useState(true)
    return (
        <div
            className={classNames('relative w-full h-14', [className], {
                'mb-2': error,
            })}>
            <label
                className={classNames('absolute -top-3 left-4', {
                    'text-red': error,
                    'text-gray': !error,
                    'bg-creame-1': !bgLabel,
                    'bg-white': bgLabel,
                })}>
                {label}
            </label>
            <input
                max={max}
                type={!show ? 'text' : type}
                placeholder={placeholder}
                onChange={onChange}
                min={min}
                defaultValue={defaultValue}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                maxLength={maxLength}
                autoComplete="off"
                disabled={disabled}
                name={name}
                value={value}
                className={classNames(styles.input, {
                    [styles.inputError]: !!error,
                })}
                required={required}
            />
            {error ? (
                <p className="text-red block mt-0.5 ml-1 font-TTHoves text-sm">
                    {error}
                </p>
            ) : null}
            {icon ? (
                <div className="absolute right-2 top-[28%] text-gray cursor-pointer">
                    {icon}
                </div>
            ) : null}
            {type === 'password' ? (
                <div
                    className="absolute right-3 top-[30%] text-gray cursor-pointer"
                    onClick={() => {
                        setShow(!show)
                    }}>
                    {!show ? (
                        <Eye className="h-6 w-6" />
                    ) : (
                        <EyeSlash className="h-6 w-6" />
                    )}
                </div>
            ) : null}
        </div>
    )
}
