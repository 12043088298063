import { DataNotFound } from 'components/common/DataNotFound'
import { Add } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

export const ProfileGrid = ({ current, portfolio, id }) => {
    const navigate = useNavigate()
    const user = useSelector(userSelector)
    const data = portfolio?.find(item => item?.session?._id === current)

    const mergedImages = portfolio?.reduce(
        (acc, obj) => acc.concat(obj.images),
        [],
    )

    return (
        <div className="flex overflow-x-auto gap-2 mt-4">
            {data?.images.length === 0 && (
                <div className="col-span-5 w-full text-center">
                    <DataNotFound
                        title="No Portfolio images added to this session"
                        description={'No Portfolio yet'}
                    />
                </div>
            )}
            {current
                ? data?.images?.map(image => (
                      <div key={image} className="flex flex-col gap-3">
                          <div className=" h-96 w-72">
                              <img
                                  alt=""
                                  src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                                  className="w-full h-full rounded-lg object-cover"
                              />
                          </div>
                      </div>
                  ))
                : mergedImages?.map(image => (
                      <div key={image} className="flex  flex-col gap-3">
                          <div className="w-72 h-96">
                              <img
                                  alt=""
                                  src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                                  className="w-full h-full rounded-lg object-cover"
                              />
                          </div>
                      </div>
                  ))}
            {!mergedImages?.length && !data?.images?.length && (
                <div className="col-span-5 w-full text-center">
                    <DataNotFound
                        title="No Portfolio"
                        description={
                            user?.userType === 'Client'
                                ? 'Ops! Photographer haven’t updated their portfolio yet. Contact them directly if you looking for specific portfolio.'
                                : 'No Portfolio yet'
                        }
                    />
                    {user?._id === id && (
                        <div className="flex items-center justify-center w-full">
                            <button
                                type="button"
                                onClick={() =>
                                    navigate('/dashboard/galleries/portfolio')
                                }
                                className="px-4 py-2.5 bg-tale-10 rounded-lg -mt-4 z-10 shadow justify-center items-center gap-2 flex">
                                <Add className="text-white h-5 w-5" />
                                <span className="text-white text-sm font-TTHovesM ">
                                    Add Portfolio
                                </span>
                            </button>
                        </div>
                    )}
                </div>
            )}

            {/*<div className="flex flex-col gap-3">*/}
            {/*    <div className="h-96">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/profile2.jpeg"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="h-full">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/profile3.jpeg"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="flex flex-col gap-3">*/}
            {/*    <div className="h-56">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/profile4.jpeg"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="h-full">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/Rectangle%201.png"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="flex flex-col gap-3">*/}
            {/*    <div className="h-96">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/profile2.jpeg"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="h-full">*/}
            {/*        <img*/}
            {/*            alt=""*/}
            {/*            src="/assets/images/profile3.jpeg"*/}
            {/*            className="w-full h-full rounded-lg object-cover"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
