import { useEffect, useState } from 'react'
import styles from 'pages/auth/Auth.module.css'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import { ICollaboration, useCollaborations } from 'hooks/useCollaborations'
import classNames from 'classnames'
import { RefreshCircle, TickCircle } from 'iconsax-react'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setUser } from '../../redux/reducers/authReducer'

const service = new UserService()

export const Collaborate = ({ currentPage, stepIncrement, pageChange }) => {
    const { collaborations, loading } = useCollaborations()
    const [servicesAsking, setServicesAsking] = useState<any>([])
    const [servicesOffering, setServicesOffering] = useState<any>([])

    const dispatch = useThunkDispatch()
    const user = useSelector(userSelector)

    useEffect(() => {
        setServicesAsking(collaborations)
        setServicesOffering(collaborations)
    }, [collaborations])

    const handleSubmit = e => {
        e.preventDefault()

        const servicesAskingFor = servicesAsking
            .filter(item => item.selected)
            .map(item => item._id)
            .join(',')

        const servicesOfferTo = servicesOffering
            .filter(item => item.selected)
            .map(item => item._id)
            .join(',')

        service
            .addCollaborate({
                servicesAskingFor,
                servicesOfferTo,
                userId: user._id,
            })
            .then(resp => {
                pageChange(pages.NEXT)
                stepIncrement(4)
                dispatch(setUser(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            id="page-5"
            style={{
                display:
                    currentPage === 4 && user?.userType === 'Photographer'
                        ? 'block'
                        : 'none',
            }}>
            <div className="grid ">
                <p className="font-normal text-body-caption-bold text-dark-8 font-OTabolas">
                    Connect and collaborate with other creatives
                </p>
                <p className={`mt-1 ${styles.messageText}`}>
                    Suura Collaboration Board is where our community comes
                    together to help each other thrive. Tell us what you're
                    seeking and what you're offering.
                </p>
                <hr className="text-gray-outline mt-8 mr-16" />
                <div className="mt-6">
                    <div className="w-full">
                        <div className="text-base text-gray-700 font-TTHoves font-semibold">
                            I'd like help with
                        </div>
                        <div className="text-body-thin  text-gray-600 font-TTHovesEL">
                            Select the services you’d like to ask from other
                            professionals
                        </div>
                        {loading ? (
                            <RefreshCircle className="animate-spin h-10 w-10 mt-2 text-tale-10" />
                        ) : (
                            <div className="flex flex-row flex-wrap pt-4 pr-10">
                                {servicesAsking.map((item, index) => {
                                    return (
                                        <HelpItem
                                            key={index}
                                            collaborations={servicesAsking}
                                            setCollaborations={
                                                setServicesAsking
                                            }
                                            {...item}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <hr className="text-gray-outline mt-6 mr-16" />
                    <div className="w-full mt-6">
                        <div className="text-base text-gray-700 font-TTHoves font-semibold">
                            I can offer
                        </div>
                        <div className="text-body-thin  text-gray-600 font-TTHovesEL">
                            Select the services you can offer to other
                            professionals
                        </div>
                        {loading ? (
                            <RefreshCircle className="animate-spin h-10 w-10 mt-2 text-tale-10" />
                        ) : (
                            <div className="flex flex-row flex-wrap pt-4 pr-10">
                                {servicesOffering.map((item, index) => {
                                    return (
                                        <HelpItem
                                            key={index}
                                            collaborations={servicesOffering}
                                            setCollaborations={
                                                setServicesOffering
                                            }
                                            {...item}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <div className="py-6">
                        <Button
                            type="submit"
                            className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-1/2"
                            onClick={handleSubmit}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const HelpItem = ({
    _id,
    name,
    selected,
    collaborations,
    setCollaborations,
}) => {
    const handleClick = () => {
        const copy = [...collaborations]
        setCollaborations(
            copy.map(item => {
                if (item._id === _id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    }
                }
                return item
            }),
        )
    }
    return (
        <div
            onClick={handleClick}
            className={classNames(
                'pl-4 pr-6 py-3 m-2 ml-0 flex rounded-xl w-fit items-center cursor-pointer gap-2 h-fit',
                {
                    'bg-tale-10 text-white border border-white': selected,
                    ' border border-dark-1 text-dark-9': !selected,
                },
            )}>
            {selected ? (
                <TickCircle variant="Bold" className={'text-white h-6 w-6'} />
            ) : (
                <div
                    className={classNames(
                        'h-5 w-5 rounded-full border border-zinc-200',
                    )}
                />
            )}
            {name}
        </div>
    )
}
