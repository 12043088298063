import styles from 'pages/auth/Auth.module.css'
import { Button } from 'components/form/Button/Button'
import UserService from 'services/user.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { Heart, RefreshCircle } from 'iconsax-react'
import { Link } from 'react-router-dom'
import { IconBrowseJobs, IconCameraBlank } from '../../css/icons/icons'
import { redirectTo } from 'utils/links'
import { ExploreItem } from 'pages/auth/CreateProfile'

const service = new UserService()

export const PersonaliseChoice = ({
    currentPage,
    stepIncrement,
    pageChange,
}) => {
    const [images, setImages] = useState([])
    const [showWelcome, setShowWelcome] = useState(false)
    const [loading, setLoading] = useState(false)
    const user = useSelector(userSelector)

    useEffect(() => {
        const ids = user?.sessions.map(item => item?.session?._id).join(',')
        setLoading(true)
        service
            .sessionYouLike(ids)
            .then(resp => {
                setImages(resp.data.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }, [currentPage])

    if (showWelcome) {
        return (
            <div
                id="page-6"
                className="h-full flex flex-col overflow-y-auto mx-auto justify-center relative">
                <div className="absolute md:hidden top-3 left-10">
                    <Link to="/">
                        <p className="text-body-bold text-primary text-dark-8 font-OTabolas z-10">
                            Suura
                        </p>
                    </Link>
                </div>
                <div className="mt-32 md:mt-0">
                    <p className="text-body-caption-bold text-dark-8 font-OTabolas text-center">
                        Welcome, You’re in!
                    </p>
                    <p
                        className={`mt-1 text-base text-gray font-TTHoves text-center`}>
                        What Suura’s feature do you want to explore first?
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-wrap justify-around">
                            <Link to="/dashboard/photographers">
                                <ExploreItem
                                    icon={<IconCameraBlank />}
                                    text="Search for photographer"
                                />
                            </Link>
                            <Link to="/dashboard/add-quote">
                                <ExploreItem
                                    icon={<IconBrowseJobs />}
                                    text="Create a new job"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <Button
                    container="w-full flex justify-center pb-2"
                    className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-60"
                    onClick={() => redirectTo('/')}>
                    Go to Homepage
                </Button>
            </div>
        )
    }

    return (
        <div
            id="page-3"
            style={{
                display:
                    currentPage === 4 && user?.userType === 'Client'
                        ? 'block'
                        : 'none',
            }}>
            <div className="h-full ">
                <p className="leading-10 text-body-caption-bold text-dark-8 font-OTabolas">
                    Personalise portfolio choices
                </p>
                <p className={styles.messageText}>
                    Choose the best work you like which will help photographers
                    to know your choices.
                </p>
                <hr className="text-zinc-200 mt-2" />
                {loading ? (
                    <div className="mt-4 ">
                        <RefreshCircle className="animate-spin h-10 w-10 mx-auto text-tale-10" />
                    </div>
                ) : (
                    <div className="mt-3 overflow-x-visible">
                        {images.map((item: any) => (
                            <div
                                key={item.session._id}
                                className="overflow-visible w-full">
                                <p className="text-zinc-700 font-TTHovesM mb-3">
                                    {item?.session?.name}
                                </p>
                                <div className="flex overflow-x-scroll  !-pr-96 overflow-visible w-full">
                                    {item.images.map((image, index) => (
                                        <div
                                            key={index}
                                            className="h-[240px] w-56 flex-shrink-0 overflow-hidden group group-hover:bg-gray-600 group-hover:bg-opacity-25 rounded-xl mb-6 mr-3 relative">
                                            <img
                                                alt="session"
                                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image.image}`}
                                                className="h-full w-full object-cover rounded-xl"
                                            />
                                            <LikeButton
                                                user={user}
                                                item={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                                            />
                                            <div className="absolute group-hover:visible invisible  inset-0 text-sm text-white  bg-transparent bg-gradient-to-tr from-zinc-500 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300  ">
                                                <div className="absolute bottom-2 left-2  p-1 text-wrap text-white">
                                                    {image?.userInfo
                                                        ?.firstName +
                                                        ' ' +
                                                        image?.userInfo
                                                            ?.lastName}
                                                    <p className="w-52 break-words break-normal break-all text-wrap">
                                                        {image?.userInfo?.email}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <hr className="text-zinc-200 pt-3 pb-2" />
                            </div>
                        ))}
                        <div className="py-6">
                            <Button
                                type="button"
                                onClick={() => redirectTo('/dashboard')}
                                className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-full">
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const LikeButton = ({ user, item }) => {
    const [liked, setLiked] = useState(false)

    const handleLike = () => {
        setLiked(!liked)
        service
            .likeImage({
                userId: item.userId,
                likedImage: item.image,
                client: user?._id,
            })
            .then(resp => {
                console.log(resp)
            })
            .catch(error => {
                console.log(error)
            })
    }
    return (
        <button
            type="button"
            onClick={handleLike}
            className="absolute top-3 z-50 right-3">
            <Heart variant={liked ? 'Bold' : 'Outline'} className="text-red" />
        </button>
    )
}
