import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Add,
    AddSquare,
    ArrowLeft,
    ArrowRight2,
    GalleryAdd,
    Home2,
    RefreshCircle,
    Trash,
} from 'iconsax-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { setUser } from '../../../redux/reducers/authReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { Modal } from 'components/common/Modal'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useSession } from 'hooks/useSession'

const service = new UserService()

export const PublicPortfolio = () => {
    const [images, setImages] = useState<any>([])
    const [currentSession, setCurrentSession] = useState<any>(null)
    const [state, setState] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [newSession, setNewSession] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const { sessions } = useSession()
    const user = useSelector(userSelector)
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [open, setOpen] = useState(false)
    const { successMessage } = useNotifications()
    const dispatch = useThunkDispatch()

    const fetchUser = useCallback(() => {
        setLoading(true)
        service
            .getSingleUser(user?.userName ?? '')
            .then(resp => {
                setImages(resp.data.data.portfolio)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user])

    useEffect(() => {
        fetchUser()
    }, [])

    const addSession = () => {
        service
            .sessionOffer({ sessionIds: newSession })
            .then(resp => {
                dispatch(setUser(resp.data.data))
                fetchUser()
                successMessage('Session added successfully')
                setOpen(false)
            })
            .catch(error => {
                console.log('Error: ', error)
            })
    }

    const onSave = () => {
        setSubmitting(true)
        service
            .createPortfolio([state])
            .then(() => {
                navigate(-1)
                successMessage('Portfolio updated successfully')
            })
            .catch(err => {
                console.log({ err })
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const deletePortfolio = id => {
        service
            .deletePortfolio(id)
            .then(resp => {
                dispatch(setUser({ ...resp.data.data }))
                fetchUser()
                successMessage('Portfolio deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    {params.has('session') && (
                        <button
                            onClick={() =>
                                navigate('/dashboard/galleries/portfolio')
                            }
                            className="mr-3">
                            <ArrowLeft />
                        </button>
                    )}
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        className="cursor-pointer px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight"
                        onClick={() => navigate(-1)}>
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        Public Portfolio
                    </div>
                </div>

                {!params.has('session') && (
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Add className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Add New Public Portfolio
                        </span>
                    </button>
                )}
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-6 p-5 w-full">
                <div className="flex items-start justify-between border-b border-zinc-200">
                    {params.has('session') ? (
                        <div className="pb-5 text-lg font-TTHovesM">
                            {currentSession?.session?.name}
                        </div>
                    ) : (
                        <div className="pb-5">
                            <p className="text-zinc-900 text-lg font-TTHovesM">
                                Create portfolio to showcase your work
                            </p>
                            <p className="text-slate-600 text-sm leading-tight">
                                Showcase your photo in public portfolio for each
                                of your different session type.
                            </p>
                        </div>
                    )}

                    {params.has('session') && (
                        <button
                            type="button"
                            onClick={onSave}
                            className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <span className="text-white text-sm font-TTHovesM ">
                                {submitting ? 'Saving...' : 'Save'}
                            </span>
                        </button>
                    )}
                </div>

                {loading ? (
                    <RefreshCircle className="text-tale-10 mx-auto my-10 size-10 animate-spin " />
                ) : (
                    <div className="grid grid-cols-2 md:grid-cols-6 gap-4 mt-4">
                        {params.has('session') ? (
                            <SessionItem
                                session={currentSession}
                                setSession={setCurrentSession}
                                setImages={setImages}
                                setState={setState}
                                images={images}
                            />
                        ) : (
                            images.map(item => (
                                <>
                                    {item.images.length > 0 ? (
                                        <div
                                            key={item._id}
                                            className="w-full h-72 relative">
                                            <img
                                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.images[0]}`}
                                                alt="gallery1"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/galleries/portfolio?session=${item.session._id}`,
                                                    )
                                                    setCurrentSession(item)
                                                }}
                                                className="h-full w-full rounded-xl cursor-pointer  object-cover"
                                            />
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    deletePortfolio(item._id)
                                                }
                                                className="absolute right-4 top-4  text-red">
                                                <Trash />
                                            </button>
                                            <div
                                                className="absolute bottom-1.5 px-3 py-2 flex cursor-pointer items-center justify-between w-full"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/galleries/portfolio?session=${item.session._id}`,
                                                    )
                                                    setCurrentSession(item)
                                                }}>
                                                <div className="flex items-end justify-between  w-full">
                                                    <div className="flex flex-col text-white ">
                                                        <p className="text-sm">
                                                            {
                                                                item?.session
                                                                    ?.name
                                                            }
                                                        </p>
                                                        <div className=" text-xs">
                                                            {
                                                                item?.images
                                                                    .length
                                                            }{' '}
                                                            images
                                                        </div>
                                                    </div>

                                                    {/*<Menu*/}
                                                    {/*    as="div"*/}
                                                    {/*    className="relative z-90">*/}
                                                    {/*    {({ open }) => (*/}
                                                    {/*        <>*/}
                                                    {/*            <Menu.Button className=" ">*/}
                                                    {/*                <svg*/}
                                                    {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                                    {/*                    fill="none"*/}
                                                    {/*                    viewBox="0 0 24 24"*/}
                                                    {/*                    strokeWidth={*/}
                                                    {/*                        1.5*/}
                                                    {/*                    }*/}
                                                    {/*                    stroke="currentColor"*/}
                                                    {/*                    className="w-6 h-6 text-white">*/}
                                                    {/*                    <path*/}
                                                    {/*                        strokeLinecap="round"*/}
                                                    {/*                        strokeLinejoin="round"*/}
                                                    {/*                        d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"*/}
                                                    {/*                    />*/}
                                                    {/*                </svg>*/}
                                                    {/*            </Menu.Button>*/}
                                                    {/*            <Transition*/}
                                                    {/*                show={open}*/}
                                                    {/*                as={Fragment}*/}
                                                    {/*                enter="transition ease-out duration-200"*/}
                                                    {/*                enterFrom="transform opacity-0 scale-95"*/}
                                                    {/*                enterTo="transform opacity-100 scale-100"*/}
                                                    {/*                leave="transition ease-in duration-100"*/}
                                                    {/*                leaveFrom="transform opacity-100 scale-100"*/}
                                                    {/*                leaveTo="transform opacity-0 scale-95">*/}
                                                    {/*                <Menu.Items*/}
                                                    {/*                    static*/}
                                                    {/*                    className="flex flex-col absolute -left-[75px] mt-2 w-28 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
                                                    {/*                    <Menu.Item>*/}
                                                    {/*                        <button*/}
                                                    {/*                            type="button"*/}
                                                    {/*                            onClick={() => {*/}
                                                    {/*                                navigate(*/}
                                                    {/*                                    `/dashboard/galleries/portfolio?session=${item.session._id}`,*/}
                                                    {/*                                )*/}
                                                    {/*                                setCurrentSession(*/}
                                                    {/*                                    item,*/}
                                                    {/*                                )*/}
                                                    {/*                            }}*/}
                                                    {/*                            className="p-2.5 text-sm text-gray-800  flex items-center gap-x-2">*/}
                                                    {/*                            Add*/}
                                                    {/*                        </button>*/}
                                                    {/*                    </Menu.Item>*/}
                                                    {/*                </Menu.Items>*/}
                                                    {/*            </Transition>*/}
                                                    {/*        </>*/}
                                                    {/*    )}*/}
                                                    {/*</Menu>*/}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full h-72 overflow-hidden flex flex-col items-center justify-center rounded-xl border-2 border-dashed border-zinc-200 relative">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    deletePortfolio(item._id)
                                                }
                                                className="absolute right-4 top-4 z-10 text-red">
                                                <Trash />
                                            </button>
                                            <div
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/galleries/portfolio?session=${item.session._id}`,
                                                    )
                                                    setCurrentSession(item)
                                                }}
                                                className="cursor-pointer h-14 w-14 rounded-full border border-dashed border-zinc-200 flex items-center justify-center">
                                                <AddSquare className="text-zinc-200" />
                                            </div>
                                            <div className="text-slate-800 text-sm mt-2">
                                                {item.session.name}
                                            </div>
                                            <div className="w-[181px]  text-center text-slate-600 text-xs mt-1">
                                                Empty Gallery
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))
                        )}
                    </div>
                )}
            </div>
            <Modal
                showModal={open}
                width="w-3/2"
                setShowModal={setOpen}
                title={'Add Session'}
                footerButton={'Save'}
                handleClickPrimary={addSession}
                headerIcon={''}>
                <form className="p-8">
                    <ReactSelect
                        label={'Select'}
                        value={newSession}
                        onChange={value => setNewSession(value)}
                        options={sessions.map(item => ({
                            value: item._id,
                            label: item.name,
                        }))}
                        placeholder={'Select session'}
                    />
                </form>
            </Modal>
        </Fragment>
    )
}

const SessionItem = ({ session, images, setImages, setState, setSession }) => {
    const [uploading, setUploading] = useState(false)

    const handleUploadImages = e => {
        setUploading(true)
        const selectedFiles = e.target.files
        service
            .getImagesURL({ images: selectedFiles })
            .then(resp => {
                setState({
                    session: session.session._id,
                    images: [...resp.data.urls],
                })
                setImages(
                    images.map(i => {
                        if (i._id === session._id) {
                            return {
                                ...i,
                                images: [...i.images, ...resp.data.urls],
                            }
                        }
                        return i
                    }),
                )
                const copy = { ...session }
                copy.images.push(...resp.data.urls)
                setSession(copy)
            })
            .catch(error => {
                console.log('Error: ', error)
                setUploading(false)
            })
            .finally(() => {
                setUploading(false)
            })
    }

    const handleDelete = idx => {
        const sessionImage = { ...session }
        sessionImage.images.splice(idx, 1)
        setSession(sessionImage)
    }

    return (
        <Fragment>
            <label
                id="dropzone"
                className="cursor-pointer h-72 overflow-hidden flex flex-col mr-3 items-center justify-center bg-gradient-to-b from-white to-tale-10/20 rounded-xl border-2 border-dashed border-zinc-200">
                {uploading ? (
                    <RefreshCircle className="animate-spin h-10 w-10 my-4 text-tale-10" />
                ) : (
                    <>
                        <GalleryAdd className="h-8 w-8" />
                        <div className="text-slate-800 text-sm mt-2">
                            Add photos
                        </div>
                    </>
                )}

                <input
                    type="file"
                    className="hidden"
                    onChange={handleUploadImages}
                    name="images"
                    id="dropzone"
                    multiple
                    accept="image/*"
                />
            </label>

            {session?.images?.map((url, index) => (
                <div key={index} className="h-72 rounded-xl mb-6 mr-3 relative">
                    <img
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${url}`}
                        className="h-full w-full object-cover rounded-xl"
                        alt="session-image"
                    />
                    <button
                        type="button"
                        onClick={() => handleDelete(index)}
                        className="absolute top-3 right-4">
                        <Trash className="text-red h-6 w-6" />
                    </button>
                </div>
            ))}
        </Fragment>
    )
}
