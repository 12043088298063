import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import Accordion from 'components/common/Accordion'
import { Input } from 'components/form/Input/Input'
import { TextArea } from 'components/form/Input/TextArea'
import { styles as style } from 'pages/dashboard/Settings'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import styles from '../../form/Input/Input.module.css'

import { AddSquare, ArrowDown2, HambergerMenu } from 'iconsax-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const service = new UserService()

export const Faq = ({ tab, settings, setSettings }) => {
    const [questions, setQuestions] = useState(settings?.faqs)
    const [state, setState] = useState({
        question: '',
        answer: '',
    })
    const [submitting, setSubmitting] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [editedObject, setEditedObject] = useState(null)
    const [questionId, setQuestionId] = useState('')

    const [charCount, setCharCount] = useState(400)

    useEffect(() => {
        setCharCount(400 - state.answer?.length)
    }, [state.answer])

    const { successMessage } = useNotifications()

    const handleChange = (index, event) => {
        const newData = [...questions]
        if (typeof event === 'string') {
            newData[index].answer = event
        } else {
            newData[index].question = event.target.value
        }
        setQuestions(newData)
        setEditedObject(newData[index])
    }

    const handleUpdate = id => {
        service
            .updateFaq(id, editedObject)
            .then(resp => {
                setSettings(resp.data.data)
                successMessage('Question added successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSubmit = e => {
        e.preventDefault()

        setSubmitting(true)
        service
            .addFaq(state)
            .then(resp => {
                setQuestions(resp.data.data.faqs)
                successMessage('Question added successfully')
                setState({
                    question: '',
                    answer: '',
                })
                setShowForm(false)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const handleDelete = id => {
        service
            .deleteFaq(id)
            .then(resp => {
                setSettings(resp.data.data)
                setQuestions(resp.data.data.faqs)
                successMessage('Question deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleDragAndDrop = results => {
        const { source, destination, type } = results

        if (!destination) return

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        )
            return

        if (type === 'group') {
            const reorderedStores = [...questions]

            const storeSourceIndex = source.index
            const storeDestinatonIndex = destination.index

            const [removedStore] = reorderedStores.splice(storeSourceIndex, 1)
            reorderedStores.splice(storeDestinatonIndex, 0, removedStore)

            service
                .updateOrder(reorderedStores)
                .then(() => {
                    successMessage('Question updated successfully')
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setSubmitting(false)
                })

            return setQuestions(reorderedStores)
        }
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <p className="text-zinc-800 text-xl font-TTHovesM">
                    Frequently Asked Questions
                </p>

                <p className="text-zinc-500">
                    Add Questions & Answers for Your Buyers
                </p>
            </div>

            {questions?.length !== 0 && (
                <DragDropContext onDragEnd={handleDragAndDrop}>
                    <Droppable
                        direction="vertical"
                        droppableId="ROOT"
                        type="group">
                        {provided => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="flex flex-col gap-3 mt-3">
                                {questions?.map((item, index) => (
                                    <Draggable
                                        draggableId={item._id}
                                        index={index}
                                        key={item._id}>
                                        {provided => (
                                            <div
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                key={item?._id}
                                                className="max-w-3xl">
                                                <div className="w-full max-w-3xl rounded-lg border border-zinc-200 shadow-sm bg-white p-2.5">
                                                    <button
                                                        onClick={() =>
                                                            setQuestionId(
                                                                prev =>
                                                                    prev
                                                                        ? ''
                                                                        : item?._id,
                                                            )
                                                        }
                                                        className="flex w-full justify-between rounded-lg  text-left focus:outline-none">
                                                        <div className="flex items-center gap-2">
                                                            <HambergerMenu
                                                                size={18}
                                                            />
                                                            <span className="text-sm font-TTHovesM text-zinc-800">
                                                                {item?.question}
                                                            </span>
                                                        </div>
                                                        <ArrowDown2
                                                            className={`${
                                                                item?._id ===
                                                                questionId
                                                                    ? 'rotate-180 transform'
                                                                    : ''
                                                            } h-5 w-5 `}
                                                        />
                                                    </button>
                                                    {item?._id ===
                                                        questionId && (
                                                        <div className="px-6 py-2">
                                                            <form className=" mt-3">
                                                                <Input
                                                                    type="text"
                                                                    onChange={event =>
                                                                        handleChange(
                                                                            index,
                                                                            event,
                                                                        )
                                                                    }
                                                                    maxLength={
                                                                        100
                                                                    }
                                                                    name="question"
                                                                    value={
                                                                        item.question
                                                                    }
                                                                    className="bg-white"
                                                                    placeholder="Add a Question: i.e Do you translate to English as well?"
                                                                />
                                                                <TextArea
                                                                    value={
                                                                        item?.answer
                                                                    }
                                                                    onChange={event =>
                                                                        handleChange(
                                                                            index,
                                                                            event,
                                                                        )
                                                                    }
                                                                    className="!bg-white mt-4"
                                                                    placeholder="Add an Answer: i.e. Yes, I also translate from English to Hebrew."
                                                                />
                                                                <div className="mt-2 flex justify-between items-center">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                item._id,
                                                                            )
                                                                        }
                                                                        className={
                                                                            style.default
                                                                        }>
                                                                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                                                            Delete
                                                                        </span>
                                                                    </button>
                                                                    <div className="flex place-content-end items-center gap-3 mb-4 md:mb-0">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setQuestionId(
                                                                                    '',
                                                                                )
                                                                            }
                                                                            className={
                                                                                style.default
                                                                            }>
                                                                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                                                                Cancel
                                                                            </span>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleUpdate(
                                                                                    item?._id,
                                                                                )
                                                                            }
                                                                            className={
                                                                                style.primary
                                                                            }>
                                                                            <span className="text-white  text-sm font-TTHovesM leading-tight">
                                                                                Update
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
            <form
                onSubmit={handleSubmit}
                className={`transition-all duration-500 ease-in-out ${
                    showForm ? 'opacity-100 max-h-full' : 'opacity-0 max-h-0'
                } overflow-hidden max-w-3xl mt-3`}>
                <Input
                    type="text"
                    onChange={e =>
                        setState({
                            ...state,
                            question: e.target.value,
                        })
                    }
                    maxLength={100}
                    value={state.question}
                    className="bg-white"
                    placeholder="Add a Question: i.e Do you translate to English as well?"
                />

                <div className={`relative w-full h-full`}>
                    <textarea
                        value={state.answer}
                        placeholder="Add an Answer: i.e. Yes, I also translate from English to Hebrew."
                        className={classNames(styles.input, '!bg-white mt-4')}
                        onChange={e =>
                            setState({
                                ...state,
                                answer: e.target.value,
                            })
                        }
                        rows={3}
                        maxLength={400}
                    />

                    <div
                        className={`text-xs font-TTHoves {text-normal} text-dark-5`}>{`${charCount} ${
                        charCount > 1 ? 'characters' : 'character'
                    } left`}</div>
                </div>

                <div className="flex place-content-end items-center gap-3 mb-4 md:mb-0">
                    <button
                        onClick={() => setShowForm(false)}
                        type="button"
                        className={style.default}>
                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                            Cancel
                        </span>
                    </button>
                    <button
                        disabled={submitting}
                        type="submit"
                        className={style.primary}>
                        <span className="text-white  text-sm  font-TTHovesM leading-tight">
                            {submitting ? 'Please wait...' : 'Add'}
                        </span>
                    </button>
                </div>
            </form>
            <div className="px-4 py-2.5 mt-4 rounded-lg font-TTHovesM text-sm text-zinc-700 bg-white border place-items-start w-32 items-center justify-start place-content-start border-zinc-300 gap-2 inline-flex">
                <AddSquare className="h-4 w-4" />
                <button
                    type="button"
                    onClick={() => setShowForm(!showForm)}
                    className="inline-block">
                    Add New
                </button>
            </div>
        </div>
    )
}
