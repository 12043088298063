import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ReactDOM from 'react-dom/client'
import './css/index.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store'
import 'react-notifications-component/dist/theme.css'

import App from './App'
import { ReactNotifications } from 'react-notifications-component'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
                <BrowserRouter>
                    <ReactNotifications />
                    <App />
                </BrowserRouter>
            </GoogleOAuthProvider>
        </PersistGate>
    </Provider>,
)
