import { IconFacebook, IconInstagram, IconTwitter } from '../../css/icons/icons'

export const Footer = () => {
    return (
        <>
            <div className="grid grid-cols-2 md:grid-cols-10 py-10 md:py-20 px-10 md:px-10">
                <div className="col-span-4">
                    <p className="text-heading-big font-OTabolas text-dark-8">
                        Suura
                    </p>
                    <p className="text-base text-dark-5 font-TTHoves w-[290px]">
                        Everything you need to grow your photography business.
                    </p>
                    <div className="grid grid-cols-3 gap-2 w-[200px] my-4">
                        <IconFacebook />
                        <IconTwitter />
                        <IconInstagram />
                    </div>
                </div>
                <div className="col-span-6 grid grid-cols-2 md:grid-cols-3 md:gap-44 font-TTHoves">
                    <ul>
                        <p className="text-dark-9 pb-4 font-TTHovesM">
                            Features
                        </p>
                        <li className="text-dark-5 pb-4">Directory</li>
                        <li className="text-dark-5 pb-4">Business</li>
                        <li className="text-dark-5 pb-4">Galleries</li>
                        <li className="text-dark-5 pb-4">Posing</li>
                        <li className="text-dark-5 pb-4">Education</li>
                    </ul>
                    <div>
                        <ul>
                            <p className="text-dark-9 pb-4 font-TTHovesM">
                                Quick Links
                            </p>
                            <li className="text-dark-5 pb-4">
                                Hire a photographer
                            </li>
                            <li className="text-dark-5 pb-4">
                                Create Business Profile
                            </li>
                            <li className="block md:hidden text-dark-5 pb-4">
                                Pricing
                            </li>
                            <li className="block md:hidden text-dark-5 pb-4">
                                Help Center
                            </li>
                            <li className="block md:hidden text-dark-5 pb-4">
                                Contact us
                            </li>
                            <li className="block md:hidden text-dark-5 pb-4">
                                FAQs
                            </li>
                        </ul>
                    </div>
                    <div className="hidden md:block">
                        <ul>
                            <p className="text-dark-9 pb-4 font-TTHovesM">
                                Connect
                            </p>
                            <li className="text-dark-5 pb-4">Help Center</li>
                            <li className="text-dark-5 pb-4">Contact us</li>
                            <li className="text-dark-5 pb-4">FAQs</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center pt-5 px-10 border-t border-black-10">
                <div className="text-dark-5 pb-4">
                    Copyright ©2023 Suura. All rights reserved.
                </div>
                <div className="flex justify-end">
                    <div className="text-dark-5 pb-4">Terms of use</div>
                    <div className="text-dark-5 pb-4 ml-10">Privacy policy</div>
                </div>
            </div>
        </>
    )
}
