import React from 'react'
import BalanceTile from './BalanceTile'
import DepositTile from './DepositTile'

const TopStrip: React.FC<{ setOpen: (boolean) => void }> = ({ setOpen }) => {
    return (
        <div className="flex gap-4">
            <div className="flex gap-4 flex-1">
                <div className="flex-1">
                    <BalanceTile
                        title="Available Balance"
                        date="12 August - 11 September"
                        balance="Є723.00"
                    />
                </div>
                <div className="flex-1">
                    <BalanceTile
                        title="Total Spending"
                        date="12 August - 11 September"
                        balance="Є923.00"
                    />
                </div>
            </div>
            <div className="flex-1">
                <DepositTile setOpen={setOpen} />
            </div>
        </div>
    )
}

export default TopStrip
