import React from 'react'
import classNames from 'classnames'
import { InvoicesTable } from 'components/tables/InvoicesTable'

export const Invoices = ({ tab }) => {
    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                <p className="text-zinc-900 text-2xl font-OTabolas">Invoices</p>
            </div>
            <div className="mt-6">
                <InvoicesTable />
            </div>
        </div>
    )
}
