import ImagesGrid from 'components/landing/ImagesGrid'
import { Button } from 'components/form/Button/Button'
import {
    Arrow,
    I3Square,
    MoneyTick,
    ReceiptEdit,
    SearchNormal,
    Star1,
    SunFog,
} from 'iconsax-react'
import Photographer from 'components/landing/home/Photographer'
import Category from 'components/landing/home/Category'
import { MessageNotification } from 'components/landing/home/MessageNotification'
import { Fragment } from 'react'

export const Home = () => {
    return (
        <Fragment>
            <div className="my-16 flex mx-auto text-center text-3xl md:text-heading-bold font-OTabolas md:w-[646px]">
                Connecting clients and photographers worldwide.
            </div>
            <div className="mb-32 relative">
                {/*<ImagesGrid />*/}
                <div className="hidden md:block -mr-10">
                    <img alt="" src="/assets/images/header1.png" />
                </div>
                <div className="block md:hidden -mx-5">
                    <img alt="" src="/assets/images/header2.png" />
                </div>
                {/*<div*/}
                {/*    style={{*/}
                {/*        background:*/}
                {/*            'linear-gradient(180deg, rgba(255, 251, 249, 0.00) 0%, #FFFBF9 100%)',*/}
                {/*        width: '100%',*/}
                {/*    }}*/}
                {/*    className="w-full absolute h-[140px] bottom-0 "*/}
                {/*/>*/}
                {/*<div className="w-full absolute h-[120px] bottom-0 bg-gradient-to-b from-transparent via-[#FFFBF9] to-[#FFFBF9]" />*/}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                <div className="pb-4 md:pb-10">
                    <span className="font-TTHoves text-body-normal text-dark-5">
                        Hire talents
                    </span>
                    <div className="md:w-[437px] font-OTabolas text-sub-heading-bold text-dark-8 mt-2">
                        <p>
                            Seeking Professional and undiscovered photographers
                            <span className="ml-2 relative align-middle">
                                <span className="bottom-0 inline-block">
                                    <img
                                        src="/assets/author-image-2.jpg"
                                        alt="signup-display-picture"
                                        className="h-10 w-10 rounded-full"
                                    />
                                </span>
                                <span className="-ml-2 inline-block">
                                    <img
                                        src="/assets/author-image-3.jpg"
                                        alt="signup-display-picture"
                                        className="h-10 w-10 rounded-full"
                                    />
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="">
                    <div className="font-TTHoves text-dark-8-5 text-normal md:w-[424px] my-4 md:my-8">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                    </div>
                    <Button className="flex items-center bg-brown-10 mt-8 md:mt-0 text-white border-none">
                        <span className="px-2">Discover now</span>
                        <SearchNormal className="text-white w-5 h-5" />
                    </Button>
                </div>
            </div>
            {/* Photographer Start */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 box-content- mt-6">
                <Photographer
                    src="/assets/homepage-photographer-1.jpg"
                    name="Melanie Beatrice"
                    location="Spokane, WA, USA."
                    buttonChildren={
                        <ul className="list-disc px-2">
                            <li>Top Photographer</li>
                        </ul>
                    }
                    className="md:col-span-1 h-[432px]"
                />
                <Photographer
                    src="/assets/homepage-photographer-2.jpg"
                    name="Jannely Affandi"
                    location="Salt Lake City, Utah, USA"
                    buttonChildren={
                        <ul className="list-disc px-2">
                            <li>Performer</li>
                        </ul>
                    }
                    className="md:col-span-1 h-[432px]"
                />
                <Photographer
                    src="/assets/homepage-photographer-3.jpg"
                    name="Autumn Waldman Photography"
                    location="Wahoo, NE, USA."
                    buttonChildren={
                        <div className="flex">
                            <span className="pr-4">Pro Photographer</span>
                            <Star1 />
                        </div>
                    }
                    className="col-span-1 md:col-span-2 h-[432px]"
                />
            </div>
            {/* Photographer End */}

            <div className="flex flex-col md:flex-row justify-between  gap-4 box-content my-8 md:my-20">
                <div className="w-full pb-6 md:w-2/3">
                    <Category
                        title="Plan photoshoots"
                        heading={
                            <div>
                                <p>Say goodbye to</p>
                                <p>awkward photoshoots</p>
                            </div>
                        }
                        list={[
                            {
                                header: 'Learn how to pose with confidence',
                                message:
                                    "With the world's largest pose library always in your pocket",
                            },
                            {
                                header: 'Find dreamy light with our Sun Tracker',
                                message:
                                    'Plan your sessions around the magic hours down to the minute',
                            },
                            {
                                header: 'Unleash your creative potential',
                                message:
                                    'Curate your fave poses and push your creativity every shoot',
                            },
                        ]}
                        buttonText="Try Suura for free"
                        buttonClick={() => console.log('')}
                    />
                </div>
                <div className="flex gap-2">
                    <div className="flex flex-col gap-4 my-4">
                        <div className="p-4 rounded-lg bg-dark-0">
                            <div className="h-[40]">
                                <I3Square />
                            </div>
                            <div className="whitespace-break text-body-small text-dark-8 font-TTHovesSB">
                                Best poses library to help create stunning
                                photos
                            </div>
                        </div>
                        <div className="h-96 w-44 md:w-full">
                            <img
                                className="h-full w-full rounded-lg object-cover"
                                src="/assets/homepage-image-1.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 mx-2 my-4">
                        <div className="h-96 w-44 md:w-80">
                            <img
                                className="h-full w-full rounded-lg object-cover"
                                src="/assets/homepage-image-2.jpg"
                                alt=""
                            />
                        </div>
                        <div className="p-4 rounded-lg bg-tale-2">
                            <div className="h-[40]">
                                <SunFog />
                            </div>
                            <div className="whitespace-break text-body-small text-dark-8 font-TTHovesSB">
                                Get updated with the golden hours
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Category Start */}
            <div className="flex flex-col-reverse md:grid md:grid-cols-10 gap-4 box-content my-8 md:my-20">
                <div className="col-span-6 pb-6">
                    <img
                        src="/assets/homepage-image-3.jpg"
                        className="rounded-xl"
                        alt=""
                    />
                </div>
                <div className="col-span-4 pt-0 md:pt-6">
                    <Category
                        title="Personalised Portfolio"
                        heading={
                            <div>
                                <p>Stand out in a sea of</p>
                                <p>other photographers</p>
                            </div>
                        }
                        colspan={5}
                        list={[
                            {
                                header: 'Showcase your work',
                                message:
                                    'Easily create a beautifully designed mini-website',
                            },
                            {
                                header: 'Get booked easier then ever',
                                message:
                                    'Get requests straight into your app with your own booking link',
                            },
                            {
                                header: 'Browse job opportunities',
                                message:
                                    'Find your ideal gig based on location, budget, or shoot type',
                            },
                        ]}
                        buttonText="Explore Directory Features"
                        buttonClick={() => console.log('')}
                    />
                </div>
            </div>
            {/* Category End */}

            <div className="flex flex-col md:flex-row justify-between items-center gap-4 box-content">
                <div className="col-span-5">
                    <Category
                        title="Manage your business"
                        heading={
                            <div>
                                <p>Easily make money</p>
                                <p>doing what you love</p>
                            </div>
                        }
                        colspan={5}
                        list={[
                            {
                                header: 'Get paid faster with online payments',
                                message:
                                    'Easily accept credit, debit, wire transfer, and more',
                            },
                            {
                                header: 'Easy done-for-you booking system',
                                message:
                                    'Set yourself up in minutes and start booking clients on the go',
                            },
                            {
                                header: 'Protect your biz with contracts',
                                message:
                                    'Show you’re a pro with lawyer-approved templates and e-signatures',
                            },
                        ]}
                        buttonText="Explore Business Features"
                        buttonClick={() => console.log('')}
                    />
                </div>
                <div className="relative col-span-5 pt-6">
                    <div className="absolute top-6 -left-20 w-full h-full">
                        <MessageNotification
                            icon={<MoneyTick className="text-tale-10" />}
                            message={
                                <div className="flex text-base text-dark-3 font-medium font-TTHovesM">
                                    You’ve been paid
                                    <p className="text-dark-8 ml-1 inline-block">
                                        $200.00
                                    </p>
                                </div>
                            }
                        />
                        <MessageNotification
                            icon={<ReceiptEdit className="text-tale-10" />}
                            message={
                                <div className="flex text-base text-dark-3 font-medium font-TTHovesM">
                                    You’ve been paid
                                    <p className="text-dark-8 ml-1 inline-block">
                                        $200.00
                                    </p>
                                </div>
                            }
                        />
                    </div>

                    <div className="hidden absolute md:flex flex-col justify-end items-center w-[90%] h-[90%] p-8">
                        <div className="bg-white rounded-xl m-10 w-[320px] h-[164px] p-2">
                            <div className="grid grid-cols-2">
                                <div className="flex flex-col justify-around h-full p-6 bg-creame-1 rounded-xl">
                                    <div className="text-body-extrasmall font-TTHovesSB font-semibold text-netural-4">
                                        Customers
                                    </div>
                                    <div className="text-sub-heading-bold font-TTHovesM font-medium">
                                        1024
                                    </div>
                                    <div className="flex items-center w-fit p-2 rounded-md bg-primary-3-light">
                                        <Arrow color="#FF6A55" />
                                        <div className="text-sm text-primary-3 font-TTHovesM mx-1">
                                            37.8%
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-around h-full p-6 rounded-xl">
                                    <div className="text-body-extrasmall font-TTHovesSB font-semibold text-netural-4">
                                        Income
                                    </div>
                                    <div className="text-sub-heading-bold font-TTHovesM font-medium">
                                        256k
                                    </div>
                                    <div className="flex items-center w-fit p-2 rounded-md bg-primary-3-light">
                                        <div className="rotate-180">
                                            <Arrow color="#83BF6E" />
                                        </div>
                                        <div className="text-sm text-primary-2 font-TTHovesM mx-1">
                                            37.8%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        src="/assets/homepage-image-4.jpg"
                        className="rounded-xl"
                        alt=""
                    />
                </div>
            </div>
        </Fragment>
    )
}
