import ProtectedService from 'services/protected.service'
import { getItem } from 'services/localStorage.service'
import Photographer from 'components/landing/home/Photographer'

class UserService extends ProtectedService {
    getUser() {
        const data = getItem('persist:root')
        const parsed = JSON.parse(data as string)
        const user = JSON.parse(parsed.user)
        return {
            id: user.user._id,
        }
    }

    async createProfile(data: any) {
        const id = this.getUser().id
        return await this.put(`/user/create-profile/${id}`, data)
    }

    async uploadProfileImages(data: any) {
        const id = this.getUser().id
        return await this.put(`/user/upload-profile-images/${id}`, data, {
            'content-type': 'multipart/form-data',
        })
    }

    async addClientGuide(data: any) {
        const id = this.getUser().id
        return await this.post(
            `/service/add-guide`,
            { user: id, clientGuide: data },
            {
                'content-type': 'multipart/form-data',
            },
        )
    }

    async addNotes(data: any) {
        return await this.put(`/proposal/add-notes`, data)
    }

    async shareGallery(gallery, data: any) {
        return await this.put(`/gallery/share-gallery/${gallery}`, data)
    }

    async verifyOtp(data) {
        return await this.post('/settings/verify-otp', data)
    }

    async initChat(data) {
        return await this.post('/chat', { userId: data })
    }

    async sessionOffer(data: any) {
        const id = this.getUser().id
        return await this.put(`/user/add-session/${id}`, data)
    }

    async sessionSignup(data: any) {
        const id = this.getUser().id
        return await this.put(`/user/add-session-signup/${id}`, data)
    }

    async getUserInvoices() {
        const id = this.getUser().id
        return await this.get(`/proposal/get-invoices/${id}`)
    }

    async getSettings(userId?: string) {
        return await this.get(`/settings/get-user-settings/${userId}`)
    }

    async getAllChats() {
        return await this.get(`/chat`)
    }
    async getSpecificChat(id: string) {
        return await this.get(`/chat/${id}`)
    }

    async addAnswer(id, data: any) {
        return await this.put(`/proposal/answer-to-question/${id}`, data)
    }

    async getInvoiceNumber() {
        const id = this.getUser().id
        return await this.post(`/invoice/create-invoice`, { userId: id })
    }

    async addFaq(data) {
        const id = this.getUser().id
        return await this.put(`/settings/add-faqs/${id}`, data)
    }

    async updateOrder(data) {
        const id = this.getUser().id
        return await this.put(`/settings/reorder-faqs/${id}`, {
            reOrderFAQs: data,
        })
    }
    async deleteFaq(question) {
        const id = this.getUser().id
        return await this.delete(`/settings/delete-faqs/${id}/${question}`)
    }

    async updateFaq(question, data) {
        const id = this.getUser().id
        return await this.put(`/settings/update-faqs/${id}/${question}/`, data)
    }

    async deleteCertification(certificate) {
        const id = this.getUser().id
        return await this.delete(
            `/settings/delete-certificate/${id}/${certificate}/`,
        )
    }

    async addExperience(data) {
        const id = this.getUser().id
        return await this.put(
            `/settings/experience-and-certification/${id}`,
            data,
        )
    }

    async createInvoice(data: any, id: string) {
        return await this.put(`/proposal/create-invoice/${id}`, data)
    }

    async addProjectQuestion(data: any, id: string) {
        return await this.put(`/proposal/add-questions/${id}`, {
            questions: data,
        })
    }
    async deleteProjectQuestion(project: any, id: string) {
        return await this.put(`/proposal/delete-question/${project}/${id}`)
    }

    async addSignature(data: any, id: string) {
        return await this.put(`/proposal/add-signature/${id}`, {
            signature: data,
        })
    }
    async updateSignature(data: any, id: string) {
        return await this.put(`/proposal/client-signature/${id}`, {
            clientSignature: data,
        })
    }

    async connectInstagram() {
        return await this.get('/user/instagram/callback')
    }
    async sessionYouLike(ids: string) {
        return await this.get(`/user/like-session?sessionIds=${ids}`)
    }
    async getTemplate(id: string) {
        return await this.get(`/service/specific-email-template/${id}`)
    }

    async getClient(value: string) {
        return await this.get(`/client/get-specific-client/${value}`)
    }

    async getSpecificGallery(value: string) {
        return await this.get(`/gallery/get-specific-gallery/${value}`)
    }

    async getPost(id: string) {
        return await this.get(`/collab-board/get-specific-post/${id}`)
    }

    async getSingleQuote(value: string) {
        return await this.get(`/quote/get-specific-quote/${value}`)
    }
    async getApplied() {
        return await this.get(`/quote/get-all-quotes`)
    }

    async getAllPhotographers({ page, limit }, filters: object | null = null) {
        return await this.post(
            `/home/get-all-photographers?limit=${limit}&page=${page}`,
            filters,
        )
    }

    async getAllInvitePhotographers(
        { page, limit },
        body: object | null = null,
    ) {
        return await this.post(
            `/home/get-all-photographers?limit=${limit}&page=${page}`,
            body,
        )
    }

    async getAllSessions() {
        return await this.get('/session/get-all-sessions')
    }

    async getAllQuotes() {
        return await this.get('/browse-jobs/all-jobs')
    }

    async getAllCollaborations() {
        return await this.get('/collaboration/get-all-collaborations')
    }

    async getContracts() {
        const id = this.getUser().id
        return await this.get('/contract/get-contracts/' + id)
    }

    async getBookings() {
        const id = this.getUser().id
        return await this.get('/proposal/get-booked-proposals/' + id)
    }

    async getSpecificInvoice(project, invoice) {
        return await this.get(
            `/proposal/specific-invoice/${project}/${invoice}`,
        )
    }

    async getProposals() {
        const id = this.getUser().id
        return await this.get('/proposal/get-proposal/' + id)
    }

    async getEmailTemplate() {
        const id = this.getUser().id
        return await this.get('/service/get-email-template/' + id)
    }

    async sendQuote(data) {
        const id = this.getUser().id
        return await this.post('/browse-jobs/send-quotation', {
            ...data,
            quotationBy: id,
        })
    }

    async getAllPreferences() {
        return await this.get('/email-preference/all')
    }

    async addPost(data: any) {
        return await this.post('/collab-board/publish-post', data)
    }

    async addEmailTemplate(data: any) {
        return await this.post('/service/create-email-template', data)
    }

    async updateEmailTemplate(data: any, id: any) {
        return await this.put(`/service/update-email-template/${id}`, data)
    }
    async updateInvoiceStatus(proposalId, data) {
        return await this.put(
            `/proposal/update-invoice-payment-status-by-client/${proposalId}`,
            data,
        )
    }

    async createGallery(data: any) {
        return await this.post('/gallery/create-gallery', data)
    }
    async likeImage(data: any) {
        return await this.post('/notifications/client-liked-image', data)
    }

    async updatePost(id: string, data: any) {
        return await this.put(`/collab-board/update-post/${id}`, data)
    }

    async updateGallery(id: string, data: any) {
        return await this.put(`/gallery/update-gallery-images/${id}`, {
            imageUrls: data,
        })
    }

    async updateGallerySettings(id: string, data: any) {
        return await this.put(`/gallery/gallery-settings/${id}`, data)
    }

    async addContract(data: any) {
        delete data.id
        return await this.post('/contract/create-contract', data)
    }

    async addProjectContract(data: any, project) {
        return await this.put(`/proposal/add-contract/${project}`, {
            contractId: data,
        })
    }

    async deleteContract(id: any) {
        return await this.delete(`/contract/delete-contract/${id}`)
    }

    async deleteTemplate(id: any) {
        return await this.delete(`/service/delete-email-template/${id}`)
    }

    async deleteChat(id: any) {
        return await this.delete(`/chat/delete-chat/${id}`)
    }

    async deleteClientGallery(id: any) {
        return await this.delete(`/gallery/delete-gallery/${id}`)
    }

    async deleteGalleryImage(id: any, url: string) {
        return await this.put(`/gallery/delete-gallery-images/${id}`, {
            imageUrls: url,
        })
    }

    async filterJobs(data: any) {
        return await this.get(
            `/browse-jobs/search-jobs?location=${data?.location}&session=${data?.session}`,
        )
    }

    async updateContract(data: any) {
        const id = data.id
        delete data.id
        return await this.put(`/contract/update-contract/${id}`, data)
    }

    async savePreferences(data: string) {
        const id = this.getUser().id
        return await this.put(`/settings/email-preferences/${id}`, {
            preferenceIds: data,
        })
    }

    async saveWorkPref(data: any) {
        const id = this.getUser().id
        return await this.put(`/settings/work-preference/${id}`, data)
    }

    async saveBilling(data: any) {
        const id = this.getUser().id
        return await this.put(`/settings/billing-setting/${id}`, data)
    }

    async addCollaborate(data: any) {
        return await this.put('/user/add-user-collaborations', data)
    }

    async getImagesURL(images: any) {
        return await this.post('/user/create-image-urls', images, {
            'Content-Type': 'multipart/form-data',
        })
    }

    async createPortfolio(images: any) {
        const id = this.getUser().id
        return await this.put(`/user/create-portfolio/${id}`, images)
    }

    async createQuote(data: any) {
        return await this.post('/quote/create-quote', data)
    }

    async updateQuote(data: any, id: string) {
        return await this.put(`/quote/update-quote/${id}`, data)
    }

    async getQuotes() {
        const id = this.getUser().id
        return await this.get('/quote/get-user-quotes/' + id)
    }

    async getCollabs() {
        const id = this.getUser().id
        return await this.get('/collab-board/get-posts/' + id)
    }

    async getAllPosts() {
        return await this.get('/collab-board/get-all-posts')
    }

    async getGuide() {
        const id = this.getUser().id
        return await this.get('/service/get-client-guide/' + id)
    }

    async getSpecificProject(id: string) {
        return await this.get('/proposal/specific-project/' + id)
    }

    async getGalleries() {
        const id = this.getUser().id
        return await this.get('/gallery/get-user-galleries/' + id)
    }

    async deleteGuide(id: string) {
        return await this.delete('/service/delete-client-guide/' + id)
    }

    async addClient(data: any) {
        return await this.post('/client/add-client', data)
    }

    async searchPhotographer(data: any) {
        return await this.post('/home/search-photographer', data)
    }

    async invitePhotographer(quoteId: string | '', payload: any) {
        return await this.put(`/quote/invite-photographer/${quoteId}`, payload)
    }

    async revokePhotographer(quoteId: string | '', payload: any) {
        return await this.put(
            `/quote/revoke-invited-photographer/${quoteId}`,
            payload,
        )
    }

    async addAvailability(userId: string | '', payload: any) {
        return await this.put(`/settings/add-availability/${userId}`, payload)
    }

    async getAvailability(userId: string | '') {
        return await this.get(`/settings/get-user-settings/${userId}`)
    }

    async sendMessage(data: any) {
        return await this.post('/message', data)
    }

    async getClients() {
        const id = this.getUser().id
        return await this.get('/client/get-clients/' + id)
    }

    async getAllClients() {
        return await this.get(`/client/get-all-clients`)
    }

    async getRecentClients() {
        const id = this.getUser().id
        return await this.get(`/client/get-recent-clients/${id}`)
    }
    async getMyClients() {
        const id = this.getUser().id
        return await this.get(`/client/my-clients/${id}`)
    }
    async getSingleUser(id: any) {
        return await this.get('/user/get-single-user-profile/' + id)
    }

    async deleteQuote(id: string) {
        return await this.delete(`/quote/delete-quote/${id}`)
    }

    async updateAccount(data: any) {
        return this.put('/settings/account-setting', data)
    }

    async updateProfile(data: any) {
        const id = this.getUser().id
        return this.put('/settings/profile-setting/' + id, data, {
            'content-type': 'multipart/form-data',
        })
    }

    async addQuestion(question: any) {
        return await this.post('/question/add-question', question)
    }

    async updateQuestion(question: any) {
        return await this.put(
            `/question/edit-question/${question.id}`,
            question,
        )
    }

    async getQuestions(id: string) {
        const user = this.getUser().id
        return await this.get(`/question/get-category-questions/${id}/${user}`)
    }

    async getInvoiceTerms() {
        const user = this.getUser().id
        return await this.get(`/service/get-invoice-term/${user}`)
    }

    async getInvoiceTermsByUser(id) {
        return await this.get(`/service/get-invoice-term/${id}`)
    }

    async deleteQuestion(id: string) {
        return await this.delete(`question/delete-question/${id}`)
    }

    async deleteClient(id: string) {
        const user = this.getUser().id
        return await this.delete(`/client/delete-client/${id}/${user}`)
    }

    async revokeClient(email: string) {
        return await this.delete(`/client/revoke-client/${email}`)
    }
    async resendInvitation(email: string) {
        return await this.post(`/client/resend-invite`, {
            email,
        })
    }
    async deletePortfolio(id: string) {
        const user = this.getUser().id
        return await this.delete(`/user/delete-portfolio/${user}/${id}`)
    }

    async deleteCategory(id: any) {
        const user = this.getUser().id

        return await this.delete(`/user/delete-session/${user}/${id}`)
    }

    async deletePost(id: string) {
        return await this.delete(`/collab-board/delete-post/${id}`)
    }

    async addShootCategory(session: any) {
        const user = this.getUser().id
        return await this.post('/service/add-shoot-cat', { session, user })
    }

    async saveInvoiceTerms(invoiceTerms: any) {
        const user = this.getUser().id
        return await this.post('/service/invoice-term', { invoiceTerms, user })
    }

    async updateInvoiceTerms(id, invoiceTerms: any) {
        return await this.put(`/service/update-invoice-term/${id}`, {
            invoiceTerms,
        })
    }

    async addPhotoShoot(data: any) {
        return await this.post('/proposal/create-proposal', data)
    }
}

export default UserService
