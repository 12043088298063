import { Button } from 'components/form/Button/Button'

const Photographer = ({ src, buttonChildren, name, location, className }) => {
    return (
        <div
            className={`w-full object-contain rounded-xl ${className}`}
            style={{
                backgroundImage: `url('${src}`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>
            <div
                className=" top-0 flex  flex-col justify-between p-4 text-white h-full w-full rounded-xl"
                style={{
                    background:
                        'linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0))',
                }}>
                <div>
                    <Button className="text-white font-OTabolasM border border-white">
                        {buttonChildren}
                    </Button>
                </div>
                <div className="ml-2">
                    <div className="text-body-medium text-white font-TTHovesSB">
                        {name}
                    </div>
                    <div className="text-white font-TTHovesM">{location}</div>
                </div>
            </div>
        </div>
    )
}

export default Photographer
