import classNames from 'classnames'

export const PhotographerType = ({
    type,
    fontSize = 'text-[10px]',
    padding = 'p-1.5',
}) => {
    return (
        <div
            className={classNames(
                'bg-white rounded-[5px] border border-zinc-300',
                [padding],
            )}>
            <p
                className={classNames(
                    'text-tale-10  px-3 font-TTHovesSB uppercase leading-3 tracking-tight',
                    [fontSize],
                )}>
                {type}
            </p>
        </div>
    )
}
