const LOREM =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'

export const DataNotFound = ({ title, description = LOREM }) => {
    return (
        <div className="h-80 flex bgPattern flex-col items-center bg-no-repeat bg-center justify-center relative mx-auto mt-4">
            <div className="w-44">
                <img
                    alt="no"
                    src="/assets/Illustration.png"
                    className="h-full w-full object-contain"
                />
            </div>
            <p className="text-center text-slate-800 text-lg font-TTHovesSB mt-4">
                {title}
            </p>
            <p className="w-full md:w-1/3 text-center text-zinc-600 text-sm mt-2">
                {description}
            </p>
        </div>
    )
}
