import { Eye, RefreshCircle } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import UserService from 'services/user.service'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

const service = new UserService()

export const ClientInvoices = () => {
    const [invoices, setInvoices] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        service
            .getUserInvoices()
            .then(resp => {
                setInvoices(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 p-5 w-full',
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                <p className="text-zinc-900 text-2xl font-OTabolas">Invoices</p>
            </div>
            {loading ? (
                <div className="mt-6">
                    <RefreshCircle className="mx-auto h-10 w-10 text-tale-10 animate-spin" />
                </div>
            ) : (
                <div className="mt-6">
                    <div className="min-w-full">
                        <div className="hidden md:flex items-center text-zinc-500 font-TTHovesM p-4 mt-1">
                            <p className="w-1/3" />

                            <p className="flex-1 ">Issued Date</p>
                            <p className="flex-1">Status</p>
                            <p className="flex-1">Total</p>
                            <p className="flex-1" />
                        </div>

                        <div className="flex flex-col gap-4 mt-1 min-w-full">
                            {/* Row 1 */}
                            {invoices?.length > 0 ? (
                                invoices.map(item => (
                                    <div
                                        key={item._id}
                                        className="hidden md:flex items-center border border-zinc-200 rounded-lg p-4">
                                        <div className="w-1/3">
                                            <p className="text-slate-800 font-TTHovesM leading-[21px]">
                                                {item?.invoiceNumber}
                                            </p>
                                        </div>
                                        <div className="flex-1">
                                            <span className="text-slate-800 font-TTHovesM">
                                                {new Date(
                                                    item?.issuedOn,
                                                ).toDateString()}
                                            </span>
                                        </div>
                                        <div className="flex-1">
                                            {item?.paymentStatus ===
                                            'Mark as Paid'
                                                ? 'Awaiting Confirmation'
                                                : item?.paymentStatus ===
                                                    'Confirm Payment'
                                                  ? 'Paid'
                                                  : 'Unpaid'}
                                            {/*<Badge*/}
                                            {/*    text="PAID"*/}
                                            {/*    type={StatusColorMap['Available']}*/}
                                            {/*/>*/}
                                        </div>
                                        <div className="flex-1">
                                            <p className="text-center text-slate-700 text-sm bg-zinc-50 px-6 py-2 rounded-md border border-zinc-200 inline-flex">
                                                ${' '}
                                                {item.customPackage.reduce(
                                                    (a, b) => a + b.rate,
                                                    0,
                                                )}
                                            </p>
                                        </div>

                                        <div className="flex-1">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/view/invoice/${item?.project?._id}?invoice=${item._id}`,
                                                    )
                                                }
                                                className="inline-flex items-center gap-1 text-tale-10 font-TTHovesM text-sm  p-2 bg-white rounded-lg  border border-zinc-300">
                                                <Eye className="text-tale-10 h-5 w-5" />{' '}
                                                View
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center font-TTHovesM ">
                                    No Invoice Created
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
