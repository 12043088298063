import { Eye } from "iconsax-react"

const TableRow = () => {
    return (
        <div className="flex border border-primary rounded-xl items-center py-5">
            <div className="flex-1 pl-7">
                <p className="text-dark-8 leading-[24px] font-TTHovesM">{'#21341321'}</p>
                <span className="text-[14px] leading-[20px] font-TTHovesL text-dark-5">{'Transection ID '}</span>
            </div>
            <div className="flex-1 text-center text-dark-8 leading-[24px] ">{'Aug 2, 2023'}</div>
            <div className="flex-1 text-center text-dark-8 leading-[24px] ">{'Expense'}</div>
            <div className="flex-1 text-center text-dark-8 leading-[24px] ">{'Job application'}</div>
            <div className="flex-1 text-center leading-[24px] font-TTHovesM text-brown-10">{'Є25.00'}</div>
            <div className="flex-1 text-center pr-7">
                <button
                    className="flex gap-3 mx-auto pl-3 items-center font-TTHovesM text-[14px] leading-[20px] border border-primary rounded-md py-[10px] px-[22px]"
                    type="button">
                    <Eye size="20px" color="#69884F" />
                    {'View'}
                </button>
            </div>
        </div>
    )
}

export default TableRow
