import React, { useState } from 'react'
import classNames from 'classnames'
import { Add } from 'iconsax-react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { useNotifications } from 'hooks/useNotification'
import UserService from 'services/user.service'
import { Input } from 'components/form/Input/Input'
import { useContracts } from 'hooks/useContracts'
import { AddContractModal } from 'components/dashboard/business/AddContractModal'

const service = new UserService()

export const Contract = ({ tab }) => {
    const user = useSelector(userSelector)
    const [showModal, setShowModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [state, setState] = useState({
        id: '',
        userId: user._id,
        title: '',
        contractTerms: '',
    })

    const { contracts, fetchData, setContracts } = useContracts()
    const { successMessage } = useNotifications()

    const handleEditContract = () => {
        const id = state.id
        service
            .updateContract(state)
            .then(resp => {
                const findIndex = contracts.findIndex(item => item._id === id)

                contracts[findIndex] = resp.data.data
                setState({
                    id: '',
                    userId: user._id,
                    title: '',
                    contractTerms: '',
                })
                setEditing(false)
                successMessage('Contract Updated successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSelect = (id: string) => {
        const find = contracts.find(item => item?._id === id)

        if (find) {
            setState({
                id,
                userId: find?.userId?._id,
                title: find?.title,
                contractTerms: find?.contractTerms,
            })
            setEditing(true)
        }
    }

    const handleDeleteContract = () => {
        service
            .deleteContract(state.id)
            .then(() => {
                setContracts(contracts.filter(item => item._id !== state.id))
                successMessage('Contract deleted successfully')
                setEditing(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div
                className={classNames(
                    'bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                )}>
                <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                    {/*<div>*/}
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        Contract Templates
                    </p>
                    {/*<p className="text-slate-600 text-sm leading-tight">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                    {/*    elit, sed do eiusmod tempor incididunt ut labore et*/}
                    {/*    dolore magna aliqua.*/}
                    {/*</p>*/}
                    {/*</div>*/}
                    <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Add className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Add new contract
                        </span>
                    </button>
                </div>
                <div className="mt-6">
                    <ReactSelect
                        label="Select Contract"
                        options={contracts.map(item => ({
                            label: item?.title,
                            value: item?._id,
                        }))}
                        placeholder="Contract"
                        value={state.id}
                        onChange={value => handleSelect(value)}
                    />
                    {/*<DataNotFound title="No Question added" />*/}
                </div>

                <AddContractModal
                    showModal={showModal}
                    fetchData={fetchData}
                    setShowModal={setShowModal}
                />
            </div>
            {editing && (
                <div className="bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full">
                    <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider mb-3">
                        EDIT CONTRACT
                    </p>
                    <div className="flex flex-col gap-3">
                        <Input
                            type="text"
                            placeholder="Title"
                            onChange={e =>
                                setState({
                                    ...state,
                                    title: e.target.value,
                                })
                            }
                            label="Title"
                            value={state.title}
                        />
                        <textarea
                            rows={6}
                            onChange={e =>
                                setState({
                                    ...state,
                                    contractTerms: e.target.value,
                                })
                            }
                            className="w-full outline-none h-full rounded-xl border border-gray-outline shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves"
                            value={state.contractTerms}
                        />
                    </div>
                    <div className="flex gap-2 ">
                        <button
                            type="button"
                            onClick={handleEditContract}
                            className="w-full md:w-auto mt-3 px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <span className="text-white text-sm font-TTHovesM ">
                                Update Contract
                            </span>
                        </button>
                        <button
                            type="button"
                            onClick={handleDeleteContract}
                            className="w-full md:w-auto mt-3 px-4 py-2.5 bg-red rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <span className="text-white text-sm font-TTHovesM ">
                                Delete Contract
                            </span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
