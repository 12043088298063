import { Fragment, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { MessageModal } from 'components/MessageModal'
import { useNavigate } from 'react-router-dom'

const service = new UserService()

export const CollabPosts = () => {
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [messageModal, setMessageModal] = useState(false)
    const user = useSelector(userSelector)

    const navigate = useNavigate()

    useEffect(() => {
        service
            .getAllPosts()
            .then(resp => {
                setData(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.date).getTime()
                        const dateB = new Date(a.date).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(() => {
                console.log('Error fetching: ')
            })
    }, [])

    const initChat = userChat => {
        service
            .initChat(userChat)
            .then(resp => {
                navigate(`/dashboard/chat/${resp.data.data._id}`)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Fragment>
            {data.map((item: any) => (
                <div
                    key={item}
                    className="md:w-[862px] border border-zinc-200 rounded-lg mt-6">
                    <div className="flex flex-col gap-3 p-5">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <div className="h-8 w-8 rounded-full overflow-hidden">
                                    <img
                                        alt="profile"
                                        src={
                                            item?.userId?.profileImage
                                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.userId?.profileImage}`
                                                : '/assets/images/blank.webp'
                                        }
                                        className="h-full w-full rounded-full"
                                    />
                                </div>
                                <div>
                                    <span className="text-zinc-600 text-[15px]">
                                        Posted by{' '}
                                    </span>
                                    <span className="text-zinc-900 text-[15px] font-TTHovesM">
                                        {item?.userId?.firstName +
                                            ' ' +
                                            item?.userId?.lastName}
                                    </span>
                                </div>
                            </div>

                            <button
                                // onClick={() => {
                                //     navigate(`/dashboard/quote/${item?._id}`)
                                // }}
                                className="px-[18px] py-[7px] bg-zinc-50 rounded-md border border-zinc-200  inline-flex text-slate-700 text-sm font-TTHovesM">
                                {item?.collaborationId?.name}
                            </button>
                        </div>
                        <p className=" text-slate-800 -mb-2  leading-6 font-TTHoves">
                            {item.title}
                        </p>
                        <div
                            className="text-sm text-gray-600"
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                        <div className="flex items-center gap-20">
                            <div className="">
                                <div className="text-zinc-600 text-[13px] ">
                                    Location
                                </div>
                                <div className="text-slate-800 text-sm font-TTHovesM">
                                    {item?.location
                                        ? item?.location
                                        : 'Not Specified'}
                                </div>
                            </div>
                            <div className="">
                                <div className="text-zinc-600 text-[13px]">
                                    Date Posted
                                </div>
                                <div className="text-slate-800 text-sm font-TTHovesM">
                                    {new Date(item?.date).toDateString()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {item?.userId?._id !== user?._id && (
                        <button
                            onClick={() => initChat(item?.userId?._id)}
                            className="w-full border-t border-zinc-200 p-5 mt-3 flex gap-3">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.83333 7.08333H10M5.83333 10H12.5M8.06979 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V16.9463C2.5 17.3903 2.5 17.6123 2.59102 17.7263C2.67019 17.8255 2.79022 17.8832 2.91712 17.8831C3.06302 17.8829 3.23639 17.7442 3.58313 17.4668L5.57101 15.8765C5.9771 15.5517 6.18014 15.3892 6.40624 15.2737C6.60683 15.1712 6.82036 15.0963 7.04101 15.051C7.28972 15 7.54975 15 8.06979 15Z"
                                    stroke="#69884F"
                                    strokeWidth="1.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="text-zinc-600 text-[15px] font-TTHovesM">
                                Send Message
                            </span>
                        </button>
                    )}
                </div>
            ))}

            <MessageModal
                showModal={messageModal}
                selected={selected}
                setShowModal={setMessageModal}
            />
        </Fragment>
    )
}
