// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__a7vbV {height: 100%;width: 100%;appearance: none;border-radius: 0.75rem;border-width: 1px;--tw-border-opacity: 1;border-color: rgb(171 178 196 / var(--tw-border-opacity));--tw-bg-opacity: 1;background-color: rgb(252 253 254 / var(--tw-bg-opacity));padding-top: 0.5rem;padding-bottom: 0.5rem;padding-left: 1rem;padding-right: 1rem;font-family: TT-Hoves-Regular;--tw-text-opacity: 1;color: rgb(43 42 64 / var(--tw-text-opacity));--tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);--tw-ring-color: rgb(199 152 122 / var(--tw-ring-opacity));--tw-ring-opacity: 0.5
}.Input_input__a7vbV:focus {--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}.Input_input__a7vbV:disabled {cursor: not-allowed;--tw-bg-opacity: 1;background-color: rgb(250 250 250 / var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgb(113 113 122 / var(--tw-text-opacity))
}.Input_input__a7vbV {background-color: transparent
}.Input_input__a7vbV::placeholder {--tw-placeholder-opacity: 1;color: rgb(172 182 190 / var(--tw-placeholder-opacity))
}.Input_input__a7vbV {outline: 2px solid transparent;outline-offset: 2px
}.Input_input__a7vbV:focus-visible {--tw-shadow: 0 0 #0000;--tw-shadow-colored: 0 0 #0000;box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.Input_inputError__Lkfb1 {border-width: 1px;--tw-border-opacity: 1;border-color: rgb(255 90 90 / var(--tw-border-opacity));--tw-ring-color: rgb(255 90 90 / var(--tw-ring-opacity));--tw-ring-opacity: 0.5
}

.Input_inputError__Lkfb1::placeholder {--tw-text-opacity: 1;color: rgb(255 90 90 / var(--tw-text-opacity))
}

.Input_inputError__Lkfb1:focus {--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.Input_input__a7vbV::placeholder {font-family: TT-Hoves-Regular;--tw-text-opacity: 1;color: rgb(127 138 165 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Input/Input.module.css"],"names":[],"mappings":"AACI,qBAAA,YAA2P,CAA3P,WAA2P,CAA3P,gBAA2P,CAA3P,sBAA2P,CAA3P,iBAA2P,CAA3P,sBAA2P,CAA3P,yDAA2P,CAA3P,kBAA2P,CAA3P,yDAA2P,CAA3P,mBAA2P,CAA3P,sBAA2P,CAA3P,kBAA2P,CAA3P,mBAA2P,CAA3P,6BAA2P,CAA3P,oBAA2P,CAA3P,6CAA2P,CAA3P,0CAA2P,CAA3P,uDAA2P,CAA3P,uGAA2P,CAA3P,0DAA2P,CAA3P;AAA2P,CAA3P,2BAAA,2GAA2P,CAA3P,yGAA2P,CAA3P;AAA2P,CAA3P,8BAAA,mBAA2P,CAA3P,kBAA2P,CAA3P,yDAA2P,CAA3P,oBAA2P,CAA3P;AAA2P,CAC3P,qBAAA;AAAkF,CAAlF,kCAAA,2BAAkF,CAAlF;AAAkF,CAAlF,qBAAA,8BAAkF,CAAlF;AAAkF,CAAlF,mCAAA,sBAAkF,CAAlF,8BAAkF,CAAlF;AAAkF;;AAIlF,0BAAA,iBAAkF,CAAlF,sBAAkF,CAAlF,uDAAkF,CAAlF,wDAAkF,CAAlF;AAAkF;;AAAlF,uCAAA,oBAAkF,CAAlF;AAAkF;;AAAlF,gCAAA,2GAAkF,CAAlF,yGAAkF,CAAlF;AAAkF;;AAIlF,kCAAA,6BAA6B,CAA7B,oBAA6B,CAA7B;AAA6B","sourcesContent":[".input {\n    @apply w-full h-full appearance-none rounded-xl border border-gray-outline shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;\n    @apply placeholder-[#ACB6BE] outline-none focus-visible:shadow-none bg-transparent;\n}\n\n.inputError {\n    @apply border border-red focus:ring-2 ring-red ring-opacity-50 placeholder:text-red\n}\n\n.input::placeholder {\n    @apply text-gray font-TTHoves;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__a7vbV`,
	"inputError": `Input_inputError__Lkfb1`
};
export default ___CSS_LOADER_EXPORT___;
