import { BusinessLayout } from 'components/dashboard/business/BusinessLayout'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import { AddSquare } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import ViewAvailability from 'components/dashboard/business/add-availability/ViewAvailability'
import { useEffect, useState } from 'react'
import { initViewAvailability } from 'utils/availabilityData'
import { userSelector } from '../../../redux/selectors/user'
import { useSelector } from 'react-redux'
import UserService from 'services/user.service'
import { DataNotFound } from 'components/common/DataNotFound'

const service = new UserService()

export const Availability = () => {
    const navigate = useNavigate()
    const [data, setData] = useState(initViewAvailability)
    const user = useSelector(userSelector)

    useEffect(() => {
        const preFetch = async () => {
            try {
                const res = await service.getAvailability(user._id)
                setData(res.data.data.availabilityPreferences)
            } catch (error) {
                console.log(error)
            }
        }

        preFetch()
    }, [])

    return (
        <BusinessLayout title="Availability">
            <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full ">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <p className="text-zinc-800 text-[22px] font-TTHovesM">
                        Availability
                    </p>
                    <p className="text-zinc-500">
                        Configure time when you are available for bookings.
                    </p>
                </div>

                <button
                    type="submit"
                    form="form1"
                    onClick={() =>
                        navigate(
                            data?.title
                                ? '/dashboard/edit-availability'
                                : `/dashboard/add-availability`,
                        )
                    }
                    className={classNames('flex items-center', [
                        styles.primary,
                    ])}>
                    <AddSquare className="text-white h-5 w-5" />
                    <span className="text-white text-sm font-TTHovesM leading-tight">
                        {data?.title ? ' Update' : 'New'}
                    </span>
                </button>
            </div>

            {data?.title ? (
                <ViewAvailability data={data} />
            ) : (
                <DataNotFound title="Add Working Hours" />
            )}
        </BusinessLayout>
    )
}
