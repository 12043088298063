import React, { memo, useCallback } from 'react'
import DayDateTimeTile from './DayDateTimeTile'

const DayDateTime: React.FC<{
    weekDays
    setWeekDays
    validation?: boolean
}> = ({ weekDays, setWeekDays, validation }) => {
    const onChangeTimings = useCallback(
        item => {
            const newBooking = weekDays
            const indexOf = weekDays.findIndex(items => item.day === items.day)
            newBooking[indexOf] = item

            setWeekDays(newBooking)
        },
        [weekDays],
    )

    return (
        <div className="border border-gray-outline rounded-xl p-4 flex flex-col gap-4">
            {weekDays.map(item => (
                <div key={item.day}>
                    <DayDateTimeTile
                        getData={onChangeTimings}
                        data={item}
                        validation={validation}
                    />
                </div>
            ))}
        </div>
    )
}

export default memo(DayDateTime)
