import React, { Fragment, useEffect, useState } from 'react'
import {
    Add,
    ArrowRight,
    ArrowRight2,
    Home2,
    RefreshCircle,
} from 'iconsax-react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CheckCircle } from '../../../css/icons/icons'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { IconButton } from 'components/form/Button/Button'
import UserService from 'services/user.service'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setProject } from '../../../redux/reducers/projectReducer'
import { TabButton } from 'pages/dashboard/business/Service'
import { Questionnaires } from 'components/dashboard/business/deliverables/Questionnaires'
import { Contract } from 'components/dashboard/business/deliverables/Contract'
import { Invoices } from 'components/dashboard/business/deliverables/Invoices'
import { Notes } from 'components/dashboard/business/deliverables/Notes'
import { AddContract } from 'components/dashboard/business/deliverables/AddContract'
import { DeliveryModal } from 'components/dashboard/business/deliverables/DeliveryModal'
import { ProjectListing } from 'components/dashboard/business/ProjectListing'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'

const service = new UserService()

export const Deliverables = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [updateContract, setUpdateContract] = useState(false)
    const [tab, setTab] = useState('overview')
    const { project } = useSelector((state: any) => state.project)
    const [deliveryModal, setDeliveryModal] = useState(false)
    const user = useSelector(userSelector)
    const dispatch = useThunkDispatch()

    const { id } = useParams()

    const [params] = useSearchParams()

    useEffect(() => {
        setLoading(true)
        service
            .getSpecificProject(id ?? '')
            .then(resp => {
                dispatch(setProject(resp.data.data))
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }, [id])

    const getImage = proposal => {
        let image = ''

        if (
            proposal?.proposalCreatedBy._id === user?._id &&
            proposal?.proposalCreatedAs === 'Photographer'
        ) {
            image = proposal?.clients[0]?.profileImage
                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${proposal?.clients[0]?.profileImage}`
                : '/assets/images/blank.webp'
        } else {
            image = proposal?.proposalCreatedBy?.profileImage
                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${proposal?.proposalCreatedBy?.profileImage}`
                : '/assets/images/blank.webp'
        }

        return image
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between ">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Business
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <p>...</p>
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {project?.photoshootTitle}
                    </div>
                </div>
            </div>

            <div className="hidden p-1 overflow-auto bg-white rounded-lg border border-zinc-200 justify-start items-center gap-2 md:inline-flex mt-4">
                <TabButton
                    label="Overview"
                    isActive={!params.has('tab')}
                    onClick={() =>
                        navigate(`/dashboard/business/deliverables/${id}`)
                    }
                />
                <TabButton
                    label="Questionnaires"
                    isActive={params.get('tab') === 'questionnaires'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=questionnaires`,
                        )
                    }
                />
                <TabButton
                    label="Contract"
                    isActive={params.get('tab') === 'contract'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=contract`,
                        )
                    }
                />
                <TabButton
                    label="Invoices"
                    isActive={params.get('tab') === 'invoices'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=invoices`,
                        )
                    }
                />
                {/*<TabButton*/}
                {/*    label="Messages"*/}
                {/*    isActive={params.get('tab') === 'email'}*/}
                {/*    onClick={() =>*/}
                {/*        navigate(*/}
                {/*            '/dashboard/business/deliverables?tab=questionnaires',*/}
                {/*        )*/}
                {/*    }*/}
                {/*/>*/}
                <TabButton
                    label="Notes"
                    isActive={params.get('tab') === 'notes'}
                    onClick={() =>
                        navigate(
                            `/dashboard/business/deliverables/${id}?tab=notes`,
                        )
                    }
                />
            </div>

            <div className="block md:hidden ">
                <ReactSelect
                    value={tab}
                    onChange={value => {
                        if (value === 'overview') {
                            navigate(`/dashboard/business/deliverables/${id}`)
                            setTab(value)
                        } else {
                            setTab(value)
                            navigate(
                                `/dashboard/business/deliverables/${id}?tab=${value}`,
                            )
                        }
                    }}
                    options={[
                        { label: 'Overview', value: 'overview' },
                        { label: 'Questionnaires', value: 'questionnaires' },
                        { label: 'Contract', value: 'contract' },
                        {
                            label: 'Invoices',
                            value: 'invoices',
                        },
                        { label: 'Notes', value: 'notes' },
                    ]}
                    placeholder="Select"
                />
            </div>

            <div className="flex flex-col md:flex-row gap-2 mt-4 items-start">
                {loading ? (
                    <div className="w-full mt-4">
                        <RefreshCircle className="animate-spin text-tale-10 size-12 mx-auto" />
                    </div>
                ) : (
                    <>
                        {!params.has('tab') && (
                            <div className="w-full bg-white rounded-xl border border-zinc-200 p-5 relative">
                                <p className="font-OTabolas text-2xl">
                                    {project?.photoshootTitle}
                                </p>
                                <p className="text-zinc-600 mt-1">
                                    Project Activity
                                </p>
                                {project?.clientSignature &&
                                project?.signature ? (
                                    <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                            Next Step
                                            <ArrowRight className="h-3 w-3" />
                                        </button>
                                        <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                            Project yet to be delivered
                                        </p>
                                        <p className="text-zinc-600 text-sm">
                                            Send Jerome Bell the required
                                            deliverables, they will review the
                                            delivery and complete the project!
                                        </p>
                                        <IconButton
                                            container="mt-4"
                                            icon={''}
                                            text="Send Deliverables"
                                            variant="primary"
                                            onClick={() =>
                                                setDeliveryModal(true)
                                            }
                                        />
                                    </div>
                                ) : //   project?.projectListing?.contractCreated ? (
                                //     <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                //         <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                //             Next Step
                                //             <ArrowRight className="h-3 w-3" />
                                //         </button>
                                //         <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                //             Generate an Invoice
                                //         </p>
                                //         <p className="text-zinc-600 text-sm">
                                //             Create a contract invoice and sent
                                //             it to the client. Lorem ipsum dolor
                                //             sit amet, consectetur adipiscing
                                //             elit, sed do eiusmod tempor
                                //             incididunt ut labore et dolore magna
                                //             aliqua.
                                //         </p>
                                //         <IconButton
                                //             container="mt-4"
                                //             icon={
                                //                 <Add className="text-white h-5 w-5" />
                                //             }
                                //             text="Create Invoice"
                                //             variant="primary"
                                //             onClick={() =>
                                //                 navigate(
                                //                     `/dashboard/photoshoot/invoice`,
                                //                 )
                                //             }
                                //         />
                                //     </div>
                                // ) : !loading &&
                                project?.projectListing?.contractCreated ? (
                                    <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                            Next Step
                                            <ArrowRight className="h-3 w-3" />
                                        </button>
                                        {project?.clientSignature ? (
                                            <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                                {project?.clients?.length > 0
                                                    ? project?.clients[0]
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.clients[0]
                                                          ?.lastName
                                                    : project?.proposalCreatedBy
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.proposalCreatedBy
                                                          ?.lastName}{' '}
                                                has signed the contract
                                            </p>
                                        ) : (
                                            <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                                Awaiting{' '}
                                                {project?.clients?.length > 0
                                                    ? project?.clients[0]
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.clients[0]
                                                          ?.lastName
                                                    : project?.proposalCreatedBy
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.proposalCreatedBy
                                                          ?.lastName}{' '}
                                                Signature
                                            </p>
                                        )}
                                        {!project?.clientSignature ? (
                                            <p className="text-zinc-600 text-sm">
                                                If{' '}
                                                {project?.clients?.length > 0
                                                    ? project?.clients[0]
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.clients[0]
                                                          ?.lastName
                                                    : project?.proposalCreatedBy
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.proposalCreatedBy
                                                          ?.lastName}{' '}
                                                accepts your proposal, they will
                                                countersign the contract and
                                                start the project!
                                            </p>
                                        ) : (
                                            <p className="text-zinc-600 text-sm">
                                                {project?.clients?.length > 0
                                                    ? project?.clients[0]
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.clients[0]
                                                          ?.lastName
                                                    : project?.proposalCreatedBy
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.proposalCreatedBy
                                                          ?.lastName}{' '}
                                                has accepted your proposal,
                                                countersign the contract and
                                                start the project!
                                            </p>
                                        )}
                                        {!project?.signature && (
                                            <IconButton
                                                container="mt-4"
                                                icon={''}
                                                text="Sign Contract"
                                                variant="primary"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/business/signature/${id}`,
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : !project?.projectListing
                                      ?.contractCreated ? (
                                    <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                            Next Step
                                            <ArrowRight className="h-3 w-3" />
                                        </button>
                                        <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                            Create a Contract
                                        </p>
                                        <p className="text-zinc-600 text-sm">
                                            Create a contract and sent it to the
                                            client. If they agree with the
                                            project terms, countersign the
                                            contract to start the project! You
                                            can also request changes to ensure
                                            that both you and{' '}
                                            {project?.clients[0]?.firstName +
                                                project?.clients[0]
                                                    ?.lastName}{' '}
                                            align on the project terms.
                                        </p>
                                        <IconButton
                                            container="mt-4"
                                            icon={
                                                <Add className="text-white" />
                                            }
                                            text="Create Contract"
                                            variant="primary"
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </div>
                                ) : null}

                                <div className="relative flex flex-col gap-8 mt-6 ml-5 z-10">
                                    <div className="absolute h-full w-px bg-zinc-200 left-3 -top-6 -z-10" />

                                    {project?.clientSignature && (
                                        <div className="flex items-center gap-3 z-10">
                                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                                Proposal accepted & signed the
                                                contract
                                            </p>
                                        </div>
                                    )}
                                    {/*{project?.projectListing?.invoiceCreated &&*/}
                                    {/*    project?.projectListing*/}
                                    {/*        ?.contractCreated && (*/}
                                    {/*        <div className="flex items-center gap-3 z-10">*/}
                                    {/*            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />*/}
                                    {/*            <p className="text-zinc-600 font-TTHovesM text-sm">*/}
                                    {/*                Invoice Created*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {project?.projectListing
                                        ?.contractCreated && (
                                        <div className="flex items-center gap-3 z-10">
                                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                                Contract Added
                                            </p>
                                        </div>
                                    )}

                                    <div className="flex items-center gap-3">
                                        <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                        <p className="text-zinc-600 font-TTHovesM text-sm">
                                            Project Created
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Questionnaires
                            tab={params.get('tab') === 'questionnaires'}
                        />
                        <Contract tab={params.get('tab') === 'contract'} />
                        <Invoices tab={params.get('tab') === 'invoices'} />
                        <Notes tab={params.get('tab') === 'notes'} />
                    </>
                )}
                <div className="flex flex-col gap-3">
                    {/*<div className="hidden md:block w-[350px] bg-[#DEEDEE] rounded-xl border border-zinc-200 p-5">*/}
                    {/*    <p className="text-zinc-800 font-TTHovesM">*/}
                    {/*        Collaborate*/}
                    {/*    </p>*/}

                    {/*    <p className="mt-1 text-zinc-600 text-sm font-TTHovesM  leading-tight">*/}
                    {/*        Collaborate on job with other photographers*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className=" bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                            CLIENT
                        </p>
                        <div className="flex gap-3 items-center mt-4">
                            <div className="flex-shrink-0 h-12 w-12 rounded-full relative">
                                <img
                                    alt="profile"
                                    className="h-full w-full rounded-full object-cover object-top"
                                    src={getImage(project)}
                                />
                                <div className="absolute bottom-0.5 right-1 h-2 w-2 rounded-full bg-green-600" />
                            </div>
                            <div>
                                <div className="text-zinc-700 text-base font-TTHovesSB ">
                                    {project?.clients?.length > 0
                                        ? project?.clients[0]?.firstName +
                                          ' ' +
                                          project?.clients[0]?.lastName
                                        : project?.proposalCreatedBy
                                              ?.firstName +
                                          ' ' +
                                          project?.proposalCreatedBy?.lastName}
                                </div>
                                <div className="text-zinc-600 text-sm leading-[18px]">
                                    {project?.clients?.length > 0
                                        ? project?.clients[0]?.location
                                        : project?.proposalCreatedBy?.location}
                                </div>
                            </div>
                        </div>
                        <div className="h-px w-full my-4 bg-zinc-200" />
                        <div className="text-zinc-700 text-base font-TTHovesSB">
                            Pre-launch checklist
                        </div>
                        <div className="text-zinc-600 text-[13px] leading-[18px]">
                            Complete the checklist in oder to submit the job
                            quotation
                        </div>
                        <ProjectListing project={project} />
                    </div>
                    <div className=" bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-700 text-base font-TTHovesSB">
                            Project Details
                        </p>

                        <div className="h-px w-full my-4 bg-zinc-200" />
                        <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                            TIMELINE
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {new Date(project?.shootDate).toDateString()}
                        </p>
                        <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                            PAYMENT SCHEDULE
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {project?.feeStructure}
                        </p>
                        <div className="flex justify-between">
                            <div>
                                <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                                    TOTAL PROJECT FEE
                                </p>
                                <p className="font-TTHovesM text-zinc-800 mt-0.5">
                                    {project?.currencySymbol}{' '}
                                    {project?.totalFee}
                                </p>
                            </div>
                            <div>
                                <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                                    SURRA CHARGES
                                </p>
                                <p className="font-TTHovesM text-zinc-800 mt-0.5">
                                    {project?.currencySymbol} 000
                                </p>
                            </div>
                        </div>

                        <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                            YOU WILL GET
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {project?.currencySymbol} {project?.totalFee}
                        </p>
                    </div>
                </div>
            </div>

            <AddContract
                showModal={isOpen}
                updateContract={updateContract}
                setShowModal={setIsOpen}
                project={id}
            />
            <DeliveryModal
                deliveryModal={deliveryModal}
                setDeliveryModal={setDeliveryModal}
                project={id}
            />
        </Fragment>
    )
}
