import { Input } from 'components/form/Input/Input'
import { useSession } from 'hooks/useSession'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { RichEditor } from 'components/common/RichEditor'

export const BasicForm = ({
    values,
    handleChange,
    setFieldValue,
    touched,
    handleBlur,
    errors,
    sessions,
}) => {
    return (
        <div className="flex flex-col gap-8">
            <Input
                type="text"
                name="title"
                placeholder="Enter Title"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.title && errors.title}
                label="Quote Title"
                value={values.title}
            />
            <div className="hidden">
                <ReactSelect
                    options={sessions.map(item => ({
                        value: item._id,
                        label: item.name,
                    }))}
                    onChange={value =>
                        console.log({
                            ...values,
                            collaborationId: value,
                        })
                    }
                    label="Category"
                    placeholder="Select Category"
                    value={values.serviceType}
                />
            </div>
            <ReactSelect
                placeholder="Select type"
                options={sessions.map(session => ({
                    value: session._id,
                    label: session.name,
                }))}
                error={touched.serviceType && errors.serviceType}
                onChange={value => setFieldValue('serviceType', value)}
                label="Select Type"
                value={values.serviceType.trim()}
            />
            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-2/3">
                    <p className="text-slate-800 font-TTHovesSB leading-tight">
                        Description
                    </p>
                    <p className="text-slate-600 text-sm leading-tight mt-1">
                        Write a short description.
                    </p>
                </div>
                <RichEditor
                    content={values.description}
                    setContent={(value: string) =>
                        setFieldValue('description', value)
                    }
                />
            </div>
        </div>
    )
}
