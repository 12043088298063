import { TickCircle } from 'iconsax-react'
import classNames from 'classnames'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const RolePage = () => {
    const [role, setRole] = useState('Client')

    const navigate = useNavigate()

    return (
        <div className="bg-pearl-white h-full w-full flex flex-col items-center justify-center">
            <div className="absolute top-4 left-5 md:left-10 z-10">
                <p className="text-body-bold text-primary text-dark-8 font-OTabolasM z-10">
                    Suura
                </p>
            </div>
            <div className="bg-white shadow rounded-lg p-4 max-w-sm w-full">
                <p className="text-body-bold text-center text-primary text-dark-8 font-OTabolasM z-10">
                    Suura
                </p>
                <p className="font-TTHovesSB text-center text-zinc-700 mt-5">
                    Select your role
                </p>
                <p className="font-TTHovesM text-zinc-500 text-center mt-1">
                    Select the role applies best to you
                </p>
                <div
                    onClick={() => setRole('Client')}
                    className={classNames(
                        'cursor-pointer rounded-lg p-3.5  shadow-sm mt-4',
                        {
                            'border border-tale-10 bg-tale-10 bg-opacity-20':
                                role === 'Client',
                            'border border-zinc-200': role !== 'Client',
                        },
                    )}>
                    <div className="flex justify-between ">
                        <p className="font-TTHovesSB text-zinc-700">Client</p>

                        {role === 'Client' ? (
                            <TickCircle
                                variant="Bold"
                                className={'text-tale-10 h-6 w-6'}
                            />
                        ) : (
                            <div
                                className={classNames(
                                    'h-5 w-5 rounded-full border border-zinc-200',
                                )}
                            />
                        )}
                    </div>
                    <p className="text-zinc-500">
                        I’m a client seeking to hire professional photographers.
                    </p>
                </div>
                <div
                    onClick={() => setRole('Photographer')}
                    className={classNames(
                        'cursor-pointer rounded-lg p-3.5  shadow-sm mt-4',
                        {
                            'border border-tale-10 bg-tale-10 bg-opacity-20':
                                role === 'Photographer',
                            'border border-zinc-200': role !== 'Photographer',
                        },
                    )}>
                    <div className="flex justify-between ">
                        <p className="font-TTHovesSB text-zinc-700">
                            Photographer
                        </p>

                        {role === 'Photographer' ? (
                            <TickCircle
                                variant="Bold"
                                className={'text-tale-10 h-6 w-6'}
                            />
                        ) : (
                            <div
                                className={classNames(
                                    'h-5 w-5 rounded-full border border-zinc-200',
                                )}
                            />
                        )}
                    </div>
                    <p className="text-zinc-500">
                        I’m a professional photographer seeking to provide
                        services.
                    </p>
                </div>
                <button
                    type="button"
                    onClick={() => navigate(`/signup?role=${role}`)}
                    className="w-full text-white mt-4 bg-brown-10 rounded-lg p-2.5 text-center">
                    Next
                </button>
            </div>
        </div>
    )
}
