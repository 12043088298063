import { useFormik } from 'formik'
import { Link, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import styles from './Auth.module.css'
import { LoginValidation } from 'utils/validations'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { AppleLogo, GoogleLogo } from '../../css/icons/icons'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { signUp } from '../../redux/actions/auth'
import { useNotifications } from 'hooks/useNotification'
import { AuthRightSide } from 'components/landing/AuthRightSide'

export const SignupPage = () => {
    const [authError, setAuthError] = useState('')

    const dispatch = useThunkDispatch()
    const { successMessage } = useNotifications()

    const [params] = useSearchParams()

    const {
        errors,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        values,
        touched,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            userType: params.get('role') ?? 'Photographer',
        },
        validationSchema: LoginValidation,
        onSubmit: values => {
            dispatch(signUp(values, setSubmitting, setAuthError))
            if (!isSubmitting && !authError) {
                successMessage('Account created successfully')
            }
        },
    })

    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex-1">
                <Link to="/">
                    <div className="absolute top-4 left-5 md:left-10 z-10">
                        <p className="text-body-bold text-primary text-dark-8 font-OTabolasM z-10">
                            Suura
                        </p>
                    </div>
                </Link>
                <form onSubmit={handleSubmit}>
                    <div className="relative pt-14 bg-gradient-to-br to-gray-200">
                        <div className="xl:px-16">
                            <div className="grid m-5 md:m-10">
                                <p className="text-body-caption-bold text-dark-8 font-OTabolasM">
                                    Welcome to Suura!
                                </p>
                                <p className={styles.messageText}>
                                    Lets create an account & start enjoying
                                    Suura
                                </p>
                                {authError && (
                                    <div className="mt-3 p-4 bg-red/5 rounded-xl shadow-sm border border-red/20 ">
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <p className="text-error text-sm font-TTHovesSB">
                                                {authError}
                                            </p>
                                            <div className="text-error text-sm">
                                                Try login your account or click
                                                forgot password to recover your
                                                account
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="my-4">
                                    <Input
                                        type="text"
                                        placeholder="Email"
                                        error={touched.email && errors.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        value={values.email}
                                        id="email-input"
                                    />
                                </div>

                                <div className="my-4">
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                        id="password-input"
                                        error={
                                            touched.password && errors.password
                                        }
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        value={values.password}
                                    />
                                </div>

                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="disabled:cursor-not-allowed disabled:bg-opacity-70 mt-4 bg-brown-10 border-brown-10 text-white px-7 h-14 w-full">
                                    {isSubmitting
                                        ? 'Please wait...'
                                        : 'Create an account'}
                                </Button>
                                <div className="text-center mt-2 mb-2">
                                    <p className={styles.messageText}>
                                        By creating an account you agreed with
                                        the Suura's
                                    </p>
                                    <p className={styles.messageText}>
                                        terms of service & privacy guidelines
                                    </p>
                                    <div className="relative py-4">
                                        <div className="absolute inset-0 flex items-center">
                                            <div className="w-full border-b border-gray" />
                                        </div>
                                        <div className="relative flex justify-center">
                                            <span className="bg-pearl-white px-4 text-md text-gray">
                                                Or
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button className={styles.buttonSecondary}>
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <div className="absolute left-0 w-5">
                                            <GoogleLogo />
                                        </div>
                                        <span className="block w-max tracking-wide font-TTHoves text-dark-8 text-md transition duration-300 group-hover:text-blue-600 sm:text-base md:w-64 md:mx-auto">
                                            Continue with Google
                                        </span>
                                    </div>
                                </button>
                                <button className={styles.buttonSecondary}>
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <div className="absolute left-0 w-5">
                                            <AppleLogo />
                                        </div>
                                        <span className="block w-max tracking-wide font-TTHoves  text-dark-8 text-md transition duration-300 group-hover:text-blue-600 sm:text-base md:w-64 md:mx-auto">
                                            Continue with Apple
                                        </span>
                                    </div>
                                </button>
                                <div className="text-center font-TTHoves">
                                    <p className="text-dark-8 mt-4">
                                        Already have an account ?&nbsp;
                                        <Link
                                            to="/login"
                                            className="text-tale-10 hover:text-blue-700">
                                            Login
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <AuthRightSide />
        </div>
    )
}
