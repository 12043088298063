import { ArrowDown2, HambergerMenu } from 'iconsax-react'
import { useState } from 'react'

export default function Accordion({ title, showBurger = true, children }) {
    const [show, setShow] = useState(false)
    return (
        <div className="w-full max-w-3xl rounded-lg border border-zinc-200 shadow-sm bg-white p-2.5">
            <button
                onClick={() => setShow(!show)}
                className="flex w-full justify-between rounded-lg  text-left focus:outline-none">
                <div className="flex items-center gap-2">
                    {showBurger && <HambergerMenu size={18} />}
                    <span className="text-sm font-TTHovesM text-zinc-800">
                        {title}
                    </span>
                </div>
                <ArrowDown2
                    className={`${show ? 'rotate-180 transform' : ''} h-5 w-5 `}
                />
            </button>
            {show && <div>{children}</div>}
        </div>
    )
}
