import classNames from 'classnames'
import { styles, TABS } from 'pages/dashboard/Settings'
import React, { useState } from 'react'
import { Input } from 'components/form/Input/Input'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()
export const Billing = ({ tab, user }) => {
    const [paymentMethod, setPaymentMethod] = useState(
        user?.billing?.method ?? 'Cash',
    )
    const [bankDetails, setBankDetails] = useState({
        bankName: user?.billing?.bankTransfer?.bankName ?? '',
        iban: user?.billing?.bankTransfer?.iban ?? '',
    })
    const [submitting, setSubmitting] = useState(false)

    const { successMessage } = useNotifications()

    const handleSubmit = e => {
        e.preventDefault()
        setSubmitting(true)

        const data =
            paymentMethod === 'Cash'
                ? { method: paymentMethod }
                : { ...bankDetails, method: paymentMethod }
        service
            .saveBilling(data)
            .then(resp => {
                successMessage('Billing updated successfully')
            })
            .catch(error => {
                console.log('Error: ', error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div className="flex-1 md:px-8">
                {/* Buttons */}
                <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full border-b border-zinc-200 pb-6">
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <p className="text-zinc-800 text-[22px] font-TTHovesM">
                            Payment Method
                        </p>
                        <p className="text-zinc-500 ">
                            Choose a default payment method, this will also be
                            included on your invoices
                        </p>
                    </div>
                    <div className="justify-start items-center gap-3 flex">
                        <button type="button" className={styles.default}>
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                Cancel
                            </span>
                        </button>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={submitting}
                            className={styles.primary}>
                            <span className="text-white disabled:cursor-not-allowed disabled:bg-opacity-70 text-sm font-TTHovesM leading-tight">
                                {submitting ? 'Saving...' : 'Save Changes'}
                            </span>
                        </button>
                    </div>
                </div>
                {/* Buttons End */}

                {/* Radio Buttons */}
                <div className="flex flex-col gap-5 mt-5">
                    <div className="flex items-start gap-3">
                        <input
                            type="radio"
                            name="method"
                            value="Cash"
                            defaultChecked={paymentMethod === 'Cash'}
                            onChange={e => setPaymentMethod(e.target.value)}
                            className="w-6 h-5 active:bg-brown-10 mt-1.5"
                            id="cash"
                        />
                        <label htmlFor="cash">
                            <div className="flex gap-3 items-center">
                                <p className="text-slate-800 text-lg font-TTHovesM">
                                    Cash
                                </p>
                                <div className=" px-2 py-1.5 bg-orange-100 rounded-[5px] justify-start items-start inline-flex">
                                    <p className="text-[#C7987A] text-[10px] font-TTHovesM uppercase ">
                                        Default
                                    </p>
                                </div>
                            </div>
                            <p className="text-gray-600 ">
                                Accept payments hands on cash
                            </p>
                        </label>
                    </div>

                    <div className="flex items-start gap-3">
                        <input
                            type="radio"
                            name="method"
                            value="BankTransfer"
                            defaultChecked={paymentMethod === 'BankTransfer'}
                            onChange={e => setPaymentMethod(e.target.value)}
                            className="w-6 h-5 active:bg-brown-10 mt-1.5"
                            id="bank"
                        />
                        <label htmlFor="bank">
                            <p className="text-slate-800 text-lg font-TTHovesM">
                                Bank Transfer
                            </p>
                            <p className="text-gray-600">
                                Add Bank details where you'd like to get the
                                transactions
                            </p>
                        </label>
                    </div>
                    {paymentMethod === 'BankTransfer' && (
                        <form className="flex flex-col gap-7 max-w-2xl">
                            <Input
                                type="text"
                                placeholder="Type Bank Name"
                                name="bankName"
                                value={bankDetails.bankName}
                                onChange={e =>
                                    setBankDetails({
                                        ...bankDetails,
                                        bankName: e.target.value,
                                    })
                                }
                                label="Bank Name"
                            />
                            <Input
                                type="text"
                                placeholder="Enter Bank IBAN"
                                name="iban"
                                value={bankDetails.iban}
                                maxLength={24}
                                onChange={e =>
                                    setBankDetails({
                                        ...bankDetails,
                                        iban: e.target.value,
                                    })
                                }
                                label="IBAN"
                            />
                        </form>
                    )}
                    <div className="flex items-start gap-3">
                        <input
                            type="radio"
                            name="method"
                            defaultChecked={paymentMethod === 'paypal'}
                            value="paypal"
                            onChange={e => setPaymentMethod(e.target.value)}
                            className="w-6 h-5 active:bg-brown-10 mt-1.5"
                            id="paypal"
                        />
                        <label htmlFor="paypal">
                            <p className="text-slate-800 text-lg font-TTHovesM">
                                Paypal Account
                            </p>
                            <p className="text-gray-600">
                                Add PayPal details to receive your payments
                            </p>
                        </label>
                    </div>
                </div>
                {/* Radio Buttons End */}

                <hr className="text-zinc-200 my-5" />
                <p className="text-zinc-800 text-[22px] font-TTHovesM">
                    Connect Stripe
                </p>
                <button className="text-zinc-700 text-[17px] font-TTHovesM mt-2 px-8 py-2.5 bg-white rounded-lg border border-zinc-300">
                    Accept payments with Stripe
                </button>
            </div>
        </div>
    )
}
