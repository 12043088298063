import { DocumentText } from 'iconsax-react'
import { Modal } from 'components/common/Modal'
import React, { useState } from 'react'
import { useContracts } from 'hooks/useContracts'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setProject } from '../../../../redux/reducers/projectReducer'

const service = new UserService()

export const AddContract = ({
    showModal,
    setShowModal,
    project,
    updateContract,
}) => {
    const [state, setState] = useState({
        title: '',
        contractTerms: '',
    })

    const [selected, setSelected] = useState('')
    const { successMessage } = useNotifications()
    const dispatch = useThunkDispatch()

    const { contracts } = useContracts()

    const handleAddContract = () => {
        service
            .addProjectContract(selected, project)
            .then(resp => {
                dispatch(setProject(resp.data.data))

                successMessage('Contract added successfully')
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
            })
    }

    const onChange = id => {
        setSelected(id)
        const findContract = contracts.find(item => item._id === id)
        setState({
            ...state,
            ...findContract,
        })
    }

    return (
        <Modal
            width="w-full lg:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            title="Add Contract"
            footerButton="Add Contract"
            handleClickPrimary={handleAddContract}
            headerIcon={<DocumentText />}>
            {/* Form */}
            <form className="p-5 flex flex-col gap-5">
                <ReactSelect
                    value={selected}
                    onChange={value => onChange(value)}
                    options={contracts.map(item => ({
                        value: item._id,
                        label: item.title,
                    }))}
                    placeholder="Select Contract"
                />
                <div className="flex flex-col gap-1">
                    <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                        Title*
                    </label>
                    <input
                        className="border border-zinc-300 shadow-sm text-dark-8 rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                        type="text"
                        value={state.title}
                        disabled
                        placeholder="e.g example@mail.com"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                        Contract Terms*
                    </label>
                    <textarea
                        className="border border-zinc-300 shadow-sm text-dark-8 rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                        placeholder="Add contract terms"
                        value={state.contractTerms}
                        rows={4}
                        disabled
                    />
                </div>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
