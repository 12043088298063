import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import React, { memo } from 'react'

const AddAvailabilitySubmit: React.FC<{
    onClick: () => void
    loading?: boolean
}> = ({ onClick, loading }) => {
    return (
        <button
            type="submit"
            form="form1"
            onClick={onClick}
            className={classNames('flex items-center', [styles.primary])}>
            <span className="text-white text-sm font-TTHovesM leading-tight">
                {loading ? 'Saving...' : 'Save Changes'}
            </span>
        </button>
    )
}

export default memo(AddAvailabilitySubmit)
