import React, { useEffect } from 'react'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import CharacterCount from '@tiptap/extension-character-count'
import { EditorContent, useEditor } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { IconBold, IconItalic, IconLink } from '../../css/icons/icons'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'

import './style.scss'

// const extensions = [
//     Color.configure({ types: [TextStyle.name, ListItem.name] }),
//     TextStyle.configure({ types: [ListItem.name] }),
//     StarterKit.configure({
//         bulletList: {
//             keepMarks: true,
//             keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
//         },
//         orderedList: {
//             keepMarks: true,
//             keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
//         },
//     }),
// ]

export const RichEditor = ({ content, setContent, limit = 400 }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle,
            ListItem,
            Link,
            CharacterCount.configure({
                limit,
            }),
        ],
        content,
    })

    React.useMemo(() => {
        if (editor?.getHTML()) setContent(editor?.getHTML())
    }, [editor?.getHTML()])

    if (!editor) {
        return null
    }

    return (
        <div className="flex w-full md:w-2/3 flex-col gap-4">
            <div className="flex items-center gap-1">
                <div className="flex-1">
                    <ReactSelect
                        placeholder="Select"
                        options={[
                            {
                                value: 'paragraph',
                                label: 'Regular',
                            },
                            {
                                value: 'bold',
                                label: 'Bold',
                            },
                            {
                                value: 'italic',
                                label: 'Italic',
                            },
                        ]}
                        label="Select"
                        value="bold"
                        onChange={value => {
                            if (value === 'bold') {
                                editor.chain().focus().toggleBold().run()
                            } else if (value === 'italic') {
                                editor.chain().focus().toggleItalic().run()
                            } else {
                                editor.chain().focus().setParagraph().run()
                            }
                        }}
                    />
                </div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={
                        editor.isActive('bold')
                            ? 'bg-zinc-200 rounded-lg text-sm text-zinc-800 p-1'
                            : 'font-semibold text-sm text-zinc-800 p-1'
                    }>
                    <IconBold />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={
                        editor.isActive('italic')
                            ? 'bg-zinc-200 rounded-lg text-sm text-zinc-800 p-1'
                            : 'font-semibold text-sm text-zinc-800 p-1'
                    }>
                    <IconItalic />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const previousUrl = editor.getAttributes('link').href
                        const url = window.prompt('URL', previousUrl)

                        // cancelled
                        if (url === null) {
                            return
                        }

                        // empty
                        if (url === '') {
                            editor
                                .chain()
                                .focus()
                                .extendMarkRange('link')
                                .unsetLink()
                                .run()

                            return
                        }

                        // update link
                        editor
                            .chain()
                            .focus()
                            .extendMarkRange('link')
                            .setLink({ href: url })
                            .run()
                    }}
                    className={
                        editor.isActive('link')
                            ? 'bg-zinc-200 rounded-lg text-sm text-zinc-800 px-1'
                            : 'font-semibold text-sm text-zinc-800 px-1'
                    }>
                    <IconLink />
                </button>
                {/*<button*/}
                {/*    onClick={() => editor.chain().focus().setParagraph().run()}*/}
                {/*    className={editor.isActive('paragraph') ? 'is-active' : ''}>*/}
                {/*    paragraph*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    onClick={() =>*/}
                {/*        editor.chain().focus().toggleHeading({ level: 6 }).run()*/}
                {/*    }*/}
                {/*    className={*/}
                {/*        editor.isActive('heading', { level: 6 })*/}
                {/*            ? 'is-active'*/}
                {/*            : ''*/}
                {/*    }>*/}
                {/*    h6*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    onClick={() =>*/}
                {/*        editor.chain().focus().toggleBulletList().run()*/}
                {/*    }*/}
                {/*    className={*/}
                {/*        editor.isActive('bulletList') ? 'is-active' : ''*/}
                {/*    }>*/}
                {/*    bullet list*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    onClick={() =>*/}
                {/*        editor.chain().focus().toggleOrderedList().run()*/}
                {/*    }*/}
                {/*    className={*/}
                {/*        editor.isActive('orderedList') ? 'is-active' : ''*/}
                {/*    }>*/}
                {/*    ordered list*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    onClick={() =>*/}
                {/*        editor.can().chain().focus().setColor('#958DF1').run()*/}
                {/*    }*/}
                {/*    className={*/}
                {/*        editor.isActive('textStyle', { color: '#958DF1' })*/}
                {/*            ? 'is-active'*/}
                {/*            : ''*/}
                {/*    }>*/}
                {/*    purple*/}
                {/*</button>*/}
            </div>
            <EditorContent className="content" editor={editor} />
            <div className={`text-xs font-TTHoves text-dark-5`}>
                {editor.getCharacterCount()}/{limit} characters
            </div>
        </div>
    )
}
