import classNames from 'classnames'
import { TABS } from 'pages/dashboard/Settings'
import React from 'react'
import {
    ArrowUpRightIcon,
    BiLinkExternal,
    IconDiscord,
} from '../../../css/icons/icons'
import { I24Support } from 'iconsax-react'

export const Help = ({ tab }) => {
    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div className="flex-col justify-start items-start inline-flex">
                <p className="text-zinc-800 text-[22px] font-TTHovesM">Help</p>
                <p className="text-zinc-500">
                    Check out our profile for guides and how-to's
                </p>
            </div>
            <hr className="text-zinc-200 my-6" />
            {/* Guides */}
            <p className="text-slate-800 text-[22px] font-TTHovesM">
                Check out our profile for guides & how-to’s:
            </p>
            <div className="px-14 py-3 bg-white rounded-lg  border border-zinc-300 inline-flex flex-col mt-3">
                <span className="text-zinc-700 text-[17px] font-TTHovesSB">
                    Suura Blogs
                    <br />
                </span>
                <span className="text-zinc-500 text-sm font-TTHovesM">
                    👋 We’re here to help
                </span>
            </div>
            {/* End Guides*/}
            <hr className="text-zinc-200 my-6" />
            {/* Discord */}
            <p className="text-zinc-800 text-[22px] font-TTHovesM">
                Join our Discord community to connect with other Independents:
            </p>
            <div className="h-[50px] px-[46px] py-[15px] bg-white rounded-lg border border-zinc-300 justify-center items-center gap-2 inline-flex mt-3">
                <IconDiscord />
                <div className="text-slate-700 text-[17px] font-TTHovesM">
                    Join Our discord Community
                </div>
            </div>
            {/* End Discord */}
            <hr className="text-zinc-200 my-6" />
            {/* FAQs */}
            <p className="text-zinc-800 text-[22px] font-TTHovesM">
                Read our FAQs to learn more about Contra:
            </p>
            <div className="h-[50px] px-[46px] py-[15px] bg-white rounded-lg border border-zinc-300 justify-center items-center gap-2 inline-flex mt-3">
                <ArrowUpRightIcon className="h-4 w-4" />
                <div className="text-slate-700 text-[17px] font-TTHovesM">
                    Read FAQs
                </div>
            </div>
            {/* End FAQs */}
            <hr className="text-zinc-200 my-6" />
            {/* Contact Us */}
            <p className="text-zinc-800 text-[22px] font-TTHovesM">
                Still need help? Contact our support team:
            </p>
            <div className="h-[50px] px-[46px] py-[15px] bg-white rounded-lg border border-zinc-300 justify-center items-center gap-2 inline-flex mt-3">
                <I24Support className="h-5 w-5" />
                <div className="text-slate-700 text-[17px] font-TTHovesM">
                    Contact Us
                </div>
            </div>
            {/* End Contact Us*/}
        </div>
    )
}
