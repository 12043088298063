import { Link1 } from 'iconsax-react'
import { trim } from 'lodash'
import React from 'react'
import { AppliedPhotographer } from 'utils/interfaces/invitePhotographers'

const AppliedPhotographerTile: React.FC<{ data: AppliedPhotographer }> = ({ data }) => (
    <div className="border border-primary rounded-[10px]">
        <div className="flex p-4 relative items-center gap-[13px]">
            <div className="relative">
                {data?.userId?.profileImage ? (
                    <img
                        alt="profile"
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${data?.userId?.profileImage}`}
                        className="h-[75px] w-[75px] rounded-full object-cover"
                    />
                ) : (
                    <span className="h-[75px] bg-[#F9FAFB] rounded-full w-[75px] font-TTHovesSB text-dark-8 text-[34px] uppercase flex items-center justify-center">
                        {data?.userId?.firstName[0]}
                    </span>
                )}
            </div>

            {/* Right Content Side */}
            <div>
                <p className="font-TTHovesSB text-[22px] leading-[32px] text-dark-8 capitalize">{`${data?.userId?.firstName} ${data?.userId?.lastName}`}</p>
                <div className="flex gap-2 mt-[9px]">
                    <span className="capitalize text-[13px] leading-[16px] tracking-[0.2px] font-TTHovesM border border-primary rounded text-tale-10 py-[7px] px-[13px]">
                        {data?.userId.businessName || '--'}
                    </span>

                    <span className="py-[7px] px-[13px] text-[13px] leading-[16px] border-primary border rounded bg-[#F9FAFB]">
                        {trim(data?.userId.currencySymbol)}
                        {`${data?.userId?.servicePreferences?.hourlyRate}/hr`}
                    </span>
                </div>
            </div>
            <button
                className="flex gap-3 absolute right-[15px] top-[17px] uppercase text-[14px] leading-[18px] tracking-[0.2px] font-TTHovesSB border border-[#69884F] rounded-[5px] bg-[rgba(105,136,79,0.1)] text-[#69884F] py-[4px] px-[13px]"
                type="button">
                {'Applied'}
            </button>
        </div>
        <div className="px-4 ">
            <p
                className="text-[14px] leading-[20px] mb-7 text-[#475467] mx-w-[100%] w-[452px] min-h-[60px]"
                dangerouslySetInnerHTML={{
                    __html: data?.description || '',
                }}
            />
        </div>
        <div className="border-t border-primary p-3 flex gap-10">
            <button className="text-[15px] leading-[20px] flex gap-2 items-center tracking-[0.2px] font-TTHovesM text-dark-5">
                <Link1 size="15px" color="#69884F" />
                {'Create a proposal'}
            </button>
        </div>
    </div>
)

export default AppliedPhotographerTile
