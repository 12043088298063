import React, { Fragment, useEffect, useState } from 'react'
import { ArrowRight2, Home2, RefreshCircle } from 'iconsax-react'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import SignPad from 'components/common/SignPad'
import { useSelector } from 'react-redux'
import { useNotifications } from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'
import { userSelector } from '../../../redux/selectors/user'

export const ClientSignature = () => {
    const [sign, setSign] = useState(false)
    const [state, setState] = useState<any>({
        sign: '',
    })

    const user = useSelector(userSelector)
    const { project } = useSelector((state: any) => state.project)

    // const { contracts, loading } = useContracts()
    const { successMessage } = useNotifications()
    const navigate = useNavigate()

    const handleAddSignature = () => {
        // service
        //     .updateSignature(state.sign, project._id)
        //     .then(resp => {
        //         console.log(resp)
        navigate(-1)
        successMessage('Proposal signed successfully')
        // })
        // .catch(error => {
        //     console.log(error)
        // })
    }

    const isClient =
        user?.userType === 'Client' ||
        (project?.proposalCreatedAs === 'Client' &&
            project?.proposalCreatedBy?._id === user?._id)

    console.log({ project, state })

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Photoshoots
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {project?.photoshootTitle}
                    </div>
                </div>
                <div className="justify-start items-center gap-3 flex">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={classNames(styles.default)}>
                        <span className="text-sm font-TTHovesM leading-tight">
                            Back
                        </span>
                    </button>
                    <button
                        type="button"
                        disabled={!state.sign}
                        onClick={handleAddSignature}
                        className={classNames(styles.primary)}>
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            Confirm & Sign
                        </span>
                    </button>
                </div>
            </div>
            <div className="border-b border-zinc-200 pb-5 flex flex-col mt-5">
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Review, sign and send a proposal
                </p>
                <p className="text-slate-600 text-sm leading-tight">
                    Be sure to review the terms in the template contract below.
                    If suitable for your Project, click start to sign. Once
                    signed, the proposal will be sent to client for review.
                </p>
            </div>
            {/*{[]?.length === 0 ? (*/}
            {/*    <div className="mt-6">*/}
            {/*        <RefreshCircle className="text-tale-10 animate-spin size-12 mx-auto" />*/}
            {/*    </div>*/}
            {/*) : (*/}
            <div className="max-w-4xl mx-auto my-4">
                <div className="w-full items-center justify-between inline-flex bg-slate-200 rounded-xl border border-zinc-200 p-4">
                    <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                        <p className="text-zinc-500 text-[10px] font-TTHovesM leading-none tracking-wider">
                            CONTRACT
                        </p>
                        <p className="text-gray-600 text-[15px] font-TTHovesM">
                            {project?.contract?.title}
                        </p>
                    </div>
                    <button
                        disabled={sign && !state?.sign}
                        onClick={() => setSign(!sign)}
                        className="disabled:opacity-50 text-white text-sm font-TTHovesM px-4 py-2.5 bg-slate-800 rounded-lg">
                        {sign ? 'Done' : 'Sign Contract'}
                    </button>
                </div>
                {sign ? (
                    <SignPad
                        state={state}
                        setState={setState}
                        proposalId={project?._id}
                        clientSignature={isClient}
                    />
                ) : (
                    <div className="flex-col items-center inline-flex px-0 md:px-6">
                        <p className="text-center text-zinc-900 text-lg font-TTHovesM leading-7 mt-2 p-2">
                            {project?.contract?.title}
                        </p>
                        <p className="text-slate-600 text-sm leading-7  text-justify">
                            {project?.contract?.contractTerms}
                        </p>
                        <div className="flex w-full justify-between mt-7">
                            <div className="flex flex-col items-start gap-1">
                                {project?.signature && (
                                    <div className="h-24 w-24">
                                        <img
                                            alt="sign"
                                            src={project?.signature}
                                            className="h-full w-full"
                                        />
                                    </div>
                                )}
                                <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                                    Photographer
                                </p>
                                <p className="text-gray-600 text-[13px] leading-[18px]">
                                    {project?.photographers?.length > 0 ? (
                                        project?.photographers.map(item => (
                                            <span key={item?._id}>
                                                {item?.firstName +
                                                    ' ' +
                                                    item?.lastName}
                                            </span>
                                        ))
                                    ) : (
                                        <span>
                                            {project?.proposalCreatedBy
                                                ?.firstName +
                                                ' ' +
                                                project?.proposalCreatedBy
                                                    ?.lastName}
                                        </span>
                                    )}
                                </p>
                                <p className="text-gray-600 text-[13px] leading-[18px]">
                                    {new Date().toDateString()}
                                </p>
                                <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200">
                                    {project?.signature
                                        ? 'Signed'
                                        : ' Sign Pending'}
                                </p>
                            </div>
                            <div className="flex flex-col gap-1 items-end">
                                {project?.clientSignature ||
                                    (state?.sign && (
                                        <div className="h-24 w-24">
                                            <img
                                                alt="sign"
                                                src={
                                                    project?.clientSignature
                                                        ? project?.clientSignature
                                                        : state?.sign
                                                }
                                                className="h-full w-full"
                                            />
                                        </div>
                                    ))}
                                <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                                    Client
                                </p>
                                {project?.clients?.length > 0 ? (
                                    project?.clients?.map(item => (
                                        <p
                                            key={item._id}
                                            className="text-gray-600 text-[13px] leading-[18px]">
                                            {item?.firstName +
                                                ' ' +
                                                item?.lastName}
                                        </p>
                                    ))
                                ) : (
                                    <p className="text-gray-600 text-[13px] leading-[18px]">
                                        {project?.proposalCreatedBy?.firstName +
                                            ' ' +
                                            project?.proposalCreatedBy
                                                ?.lastName}
                                    </p>
                                )}

                                <p className="text-gray-600 text-[13px] leading-[18px]">
                                    {new Date().toDateString()}
                                </p>
                                <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200 justify-start items-center inline-flex">
                                    {state?.clientSignature || state?.sign
                                        ? 'Signed'
                                        : 'Sign Pending'}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*)}*/}
        </Fragment>
    )
}
