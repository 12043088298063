import React, { useState } from 'react'
import { IconSearch } from '../../../css/icons/icons'
import UserService from 'services/user.service'
import { socket } from 'utils/socket'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { RefreshCircle, Trash } from 'iconsax-react'
import { useMessages } from 'hooks/useMessages'
import { timeAgo } from 'utils/funcs'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const AllChats = ({ setSelectedChat, setChatId, setMessages }) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [deleteChatId, setDeleteChatId] = useState('')

    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(userSelector)
    const { chats, setChats, loading } = useMessages()
    const { id } = useParams()
    const { successMessage } = useNotifications()

    const updateIdInURL = newId => {
        const newPath = location.pathname.replace(/\/\d+$/, `/${newId}`)
        navigate(newPath, { replace: true })
    }

    const initChat = chatId => {
        setChatId(chatId)
        service
            .getSpecificChat(chatId)
            .then(resp => {
                socket.emit('joinChat', chatId)
                setMessages(resp.data.data.messages)
                setSelectedChat(resp.data.data)
                if (id) {
                    updateIdInURL(chatId)
                } else {
                    navigate(`/dashboard/chat/${chatId}`)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleDeleteChat = () => {
        service
            .deleteChat(deleteChatId)
            .then(() => {
                const shallowCopy = [...chats]
                const index = shallowCopy.findIndex(
                    item => item._id === deleteChatId,
                )
                shallowCopy.splice(index, 1)
                setChats(shallowCopy)
                setConfirmModal(false)
                setDeleteChatId('')
                successMessage('Chat deleted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="w-96 hidden md:block scrollbar-hidden overflow-auto border-r border-zinc-200">
            <div className="flex gap-2 p-5">
                <p className="text-zinc-800 font-TTHovesM">Messages</p>
                <div className="px-1.5 py-0.5 bg-white rounded-md shadow-sm border border-zinc-300 justify-start items-center gap-1.5 inline-flex">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    <div className="text-center text-slate-700 text-xs font-TTHovesM">
                        {chats?.length}
                    </div>
                </div>
            </div>

            <div className="relative mx-4">
                <input
                    type="search"
                    className="bg-white w-full shadow-sm border border-zinc-200 focus:ring-2 ring-brown-10 ring-opacity-50 focus:outline-none rounded-lg pl-10 pr-3.5 py-2"
                    placeholder="Search"
                />
                <IconSearch className="absolute left-3 top-[11px]" />
            </div>

            {loading ? (
                <div className="h-[calc(100%-200px)] w-full flex flex-col items-center justify-center">
                    <RefreshCircle className="animate-spin text-tale-10 mx-auto size-12" />
                </div>
            ) : (
                <div className="flex flex-col divide-y divide-zinc-200 mt-3">
                    {/* Chat 1 */}
                    {chats.map(item => (
                        <div
                            key={item._id}
                            onClick={() => initChat(item._id)}
                            className="group relative cursor-pointer hover:bg-zinc-50 p-4 flex-col gap-2.5 inline-flex">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">
                                    <div className="w-2 h-2 relative bg-stone-500 rounded-[5px]" />
                                    <div className="justify-start items-center gap-3 flex">
                                        <div className="w-10 h-10 relative rounded-full border border-stone-50">
                                            <img
                                                className="h-full object-top w-full rounded-full object-cover"
                                                src={
                                                    item?.participants
                                                        ?.profileImage
                                                        ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.participants?.profileImage}`
                                                        : '/assets/images/blank.webp'
                                                }
                                                alt="profile"
                                            />
                                            <div className="w-2.5 h-2.5 left-[30px] top-[26px] absolute bg-green-500 rounded-[5px] border border-white" />
                                        </div>
                                        <div className="flex-col justify-start items-start inline-flex">
                                            <p className="text-slate-800 text-sm font-TTHovesSB">
                                                {item?.participants?.firstName +
                                                    ' ' +
                                                    item?.participants
                                                        ?.lastName}
                                            </p>
                                            <div className="text-zinc-700 text-sm ">
                                                @{item?.participants?.userName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-zinc-500 text-[13px] font-TTHovesM">
                                    {timeAgo(new Date(item?.updatedAt))}
                                </p>
                            </div>
                            <button
                                onClick={() => {
                                    setDeleteChatId(item._id)
                                    setConfirmModal(true)
                                }}
                                className="hidden group-hover:block  w-10 absolute right-4 bottom-5 z-20">
                                <Trash className="text-red" />
                            </button>
                            {item?.messages?.[0]?.content && (
                                <p className="pl-5 text-slate-500 text-[15px]">
                                    {item?.messages?.[
                                        item?.messages?.length - 1
                                    ]?.sender === user?._id
                                        ? 'You:'
                                        : item?.participants?.firstName +
                                          ': ' +
                                          item?.messages?.[
                                              item?.messages?.length - 1
                                          ]?.content.slice(0, 40)}
                                    {item?.messages?.[
                                        item?.messages?.length - 1
                                    ]?.content.length > 40 && '...'}
                                </p>
                            )}
                        </div>
                    ))}
                    {/* Chat 2 */}
                    {/*<div className="cursor-pointer hover:bg-zinc-50 p-4 flex-col gap-2.5 inline-flex">*/}
                    {/*    <div className="self-stretch justify-start items-start gap-4 inline-flex">*/}
                    {/*        <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">*/}
                    {/*            <div className="justify-start items-center gap-3 flex pl-5">*/}
                    {/*                <div className="w-10 h-10 relative rounded-full border border-stone-50">*/}
                    {/*                    <img*/}
                    {/*                        className="h-full w-full rounded-full object-cover"*/}
                    {/*                        src="/assets/homepage-photographer-2.jpg"*/}
                    {/*                        alt="profile"*/}
                    {/*                    />*/}
                    {/*                    <div className="w-2.5 h-2.5 left-[30px] top-[26px] absolute bg-green-500 rounded-[5px] border border-white" />*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-col justify-start items-start inline-flex">*/}
                    {/*                    <p className="text-slate-800 text-sm font-TTHovesSB">*/}
                    {/*                        Analah Whitten*/}
                    {/*                    </p>*/}
                    {/*                    <div className="text-zinc-700 text-sm ">*/}
                    {/*                        @analah*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <p className="text-zinc-500 text-[13px] font-TTHovesM">*/}
                    {/*            2h ago*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <p className="pl-5 text-slate-500 text-[15px]">*/}
                    {/*        Hey Olivia, Katherine sent me over the latest doc. I*/}
                    {/*        just have a quick question about the...*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/* Chat 3 */}
                    {/*<div className="cursor-pointer hover:bg-zinc-50 p-4 flex-col gap-2.5 inline-flex">*/}
                    {/*    <div className="self-stretch justify-start items-start gap-4 inline-flex">*/}
                    {/*        <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">*/}
                    {/*            <div className="justify-start items-center gap-3 flex pl-5">*/}
                    {/*                <div className="w-10 h-10 relative rounded-full border border-stone-50">*/}
                    {/*                    <img*/}
                    {/*                        className="h-full w-full rounded-full object-cover"*/}
                    {/*                        src="/assets/homepage-photographer-1.jpg"*/}
                    {/*                        alt="profile"*/}
                    {/*                    />*/}
                    {/*                    <div className="w-2.5 h-2.5 left-[30px] top-[26px] absolute bg-green-500 rounded-[5px] border border-white" />*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-col justify-start items-start inline-flex">*/}
                    {/*                    <p className="text-slate-800 text-sm font-TTHovesSB">*/}
                    {/*                        Brabiana Liu*/}
                    {/*                    </p>*/}
                    {/*                    <div className="text-zinc-700 text-sm ">*/}
                    {/*                        @brabianaliu*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <p className="text-zinc-500 text-[13px] font-TTHovesM">*/}
                    {/*            Just now*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <p className="pl-5 text-slate-500 text-[15px]">*/}
                    {/*        Hey Olivia, Katherine sent me over the latest doc. I*/}
                    {/*        just have a quick question about the...*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/* Chat 4 */}
                    {/*<div className="cursor-pointer hover:bg-zinc-50 p-4 flex-col gap-2.5 inline-flex">*/}
                    {/*    <div className="self-stretch justify-start items-start gap-4 inline-flex">*/}
                    {/*        <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">*/}
                    {/*            <div className="justify-start items-center gap-3 flex">*/}
                    {/*                <div className="w-2 h-2 relative bg-purple-500 rounded-[5px]" />*/}

                    {/*                <div className="w-10 h-10 relative rounded-full border border-stone-50">*/}
                    {/*                    <img*/}
                    {/*                        className="h-full w-full rounded-full object-cover"*/}
                    {/*                        src="/assets/homepage-photographer-3.jpg"*/}
                    {/*                        alt="profile"*/}
                    {/*                    />*/}
                    {/*                    <div className="w-2.5 h-2.5 left-[30px] top-[26px] absolute bg-zinc-500 rounded-[5px] border border-white" />*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-col justify-start items-start inline-flex">*/}
                    {/*                    <p className="text-slate-800 text-sm font-TTHovesSB">*/}
                    {/*                        Phoenix Baker*/}
                    {/*                    </p>*/}
                    {/*                    <div className="text-zinc-700 text-sm ">*/}
                    {/*                        @phoenix*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <p className="text-zinc-500 text-[13px] font-TTHovesM">*/}
                    {/*            20min ago*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <p className="pl-5 text-slate-500 text-[15px]">*/}
                    {/*        Hey Olivia, Katherine sent me over the latest doc. I*/}
                    {/*        just have a quick question about the...*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>
            )}
            {/* Chats End */}
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                onConfirm={handleDeleteChat}
                title="Are you sure to delete the chat?"
                description="The chat and its messages will be deleted permanently"
            />
        </div>
    )
}
