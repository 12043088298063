import React from 'react'
import classNames from 'classnames'
import {
    Add,
    Calendar2,
    ClipboardText,
    DollarCircle,
    Message,
    ReceiptSearch,
    RefreshCircle,
} from 'iconsax-react'
import { BusinessLayout } from 'components/dashboard/business/BusinessLayout'
import { styles } from 'pages/dashboard/Settings'
import { DataNotFound } from 'components/common/DataNotFound'
import { useNavigate } from 'react-router-dom'
import { useProposals } from 'hooks/useProposals'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { convertTime } from 'utils/funcs'

export const Request = () => {
    const navigate = useNavigate()
    const user = useSelector(userSelector)
    const { loading, proposals } = useProposals()

    console.log(proposals)

    return (
        <BusinessLayout title="Request">
            <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full pb-6">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <p className="text-zinc-800 text-[22px] font-TTHovesM">
                        Requests
                    </p>
                    <p className="text-zinc-500">
                        Configure time when you are available for bookings.
                    </p>
                </div>
                <div className="justify-start items-center gap-3 flex">
                    <button
                        disabled={true}
                        type="button"
                        className={classNames(
                            'disabled:text-zinc-300 disabled:cursor-not-allowed text-slate-700 flex items-center',
                            [styles.default],
                        )}>
                        <Calendar2 className="h-5 w-5 -mr-1.5" />
                        <span className="text-sm font-TTHovesM leading-tight">
                            Calender View
                        </span>
                    </button>
                    <button
                        onClick={() => navigate('/dashboard/photoshoot/new')}
                        className={classNames('flex items-center', [
                            styles.primary,
                        ])}>
                        <Add className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            New Shoot
                        </span>
                    </button>
                </div>
            </div>

            {loading && proposals.length === 0 ? (
                <RefreshCircle className="animate-spin h-10 w-10 mx-auto mt-10 text-tale-10" />
            ) : (
                proposals.map(item => (
                    <div
                        key={item}
                        className="  rounded-[10px] border border-zinc-200 p-5 my-5">
                        <div
                            onClick={() => {
                                if (
                                    item?.clients
                                        .map(c => c._id)
                                        .includes(user?._id) ||
                                    (item?.proposalCreatedBy?._id ===
                                        user?._id &&
                                        item?.proposalCreatedAs === 'Client')
                                ) {
                                    navigate(
                                        `/dashboard/business/proposal/${item._id}`,
                                    )
                                } else {
                                    navigate(
                                        `/dashboard/business/deliverables/${item._id}`,
                                    )
                                }
                            }}
                            className="flex cursor-pointer  flex-col md:flex-row items-start gap-4 md:gap-28">
                            <div className="flex flex-col gap-1">
                                <p className="text-slate-800 font-TTHovesM">
                                    {new Date(item?.shootDate).toDateString()}
                                </p>
                                <p className="text-gray-600 text-[15px]">
                                    {convertTime(item?.shootTime)}
                                </p>
                                <div className="flex mt-1">
                                    <div className="h-8 w-8 rounded-full">
                                        <img
                                            alt="profile"
                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`}
                                            className="h-full w-full rounded-full object-cover"
                                        />
                                    </div>
                                    <div className="h-8 w-8 rounded-full -ml-3">
                                        <img
                                            alt="profile"
                                            src={
                                                item?.clients.length > 0
                                                    ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.clients[0]?.profileImage}`
                                                    : `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.photographers[0]?.profileImage}`
                                            }
                                            className="h-full w-full rounded-full object-cover"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-1">
                                <p className="text-slate-800 font-TTHovesM">
                                    {item?.photoshootTitle}
                                </p>
                                <p className="text-gray-600 text-[15px]">
                                    {item?.locationString}
                                </p>
                                <div className="flex gap-2 ">
                                    <div className="px-3 text-center text-zinc-900 text-xs py-1.5 bg-white rounded-[7px] border border-slate-300">
                                        {item?.shootType?.name}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="text-zinc-200 mt-8" />
                        <div className="flex flex-wrap items-center gap-6 md:gap-12 mt-5">
                            {item?.projectListing?.invoiceCreated ? (
                                <div className="flex items-center gap-2">
                                    <DollarCircle className="h-5 w-5 text-zinc-600" />
                                    <p className="text-zinc-600 font-TTHovesM">
                                        Invoice created
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="flex z-10 cursor-pointer items-center gap-2"
                                    onClick={() => {
                                        if (
                                            item?.clients
                                                .map(c => c._id)
                                                .includes(user?._id) ||
                                            (item?.proposalCreatedBy?._id ===
                                                user?._id &&
                                                item?.proposalCreatedAs ===
                                                    'Client')
                                        ) {
                                            navigate(
                                                `/dashboard/business/proposal/${item._id}`,
                                            )
                                        } else {
                                            navigate(
                                                `/dashboard/business/deliverables/${item._id}`,
                                            )
                                        }
                                    }}>
                                    <DollarCircle className="h-5 w-5 text-zinc-600" />

                                    <p className="text-zinc-600 font-TTHovesM">
                                        Invoice Pending
                                    </p>
                                </div>
                            )}
                            {item?.projectListing?.contractCreated ? (
                                <div className="cursor-pointer flex items-center gap-2">
                                    <ReceiptSearch className="h-5 w-5 text-zinc-600" />
                                    <p className="text-zinc-600 font-TTHovesM">
                                        Contract Created
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="cursor-pointer flex z-10 items-center gap-2"
                                    onClick={() => {
                                        if (
                                            item?.clients
                                                .map(c => c._id)
                                                .includes(user?._id) ||
                                            (item?.proposalCreatedBy?._id ===
                                                user?._id &&
                                                item?.proposalCreatedAs ===
                                                    'Client')
                                        ) {
                                            navigate(
                                                `/dashboard/business/proposal/${item._id}`,
                                            )
                                        } else {
                                            navigate(
                                                `/dashboard/business/deliverables/${item._id}`,
                                            )
                                        }
                                    }}>
                                    <ReceiptSearch className="h-5 w-5 text-zinc-600" />
                                    <p className="text-zinc-600 font-TTHovesM">
                                        Contract Pending
                                    </p>
                                </div>
                            )}
                            {item?.questions.length > 0 ? (
                                <div className="flex z-10  items-center gap-2">
                                    <Message className="h-5 w-5 text-zinc-600" />
                                    <p className="text-zinc-600 font-TTHovesM">
                                        Questionnaire Added
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="flex cursor-pointer items-center gap-2"
                                    onClick={() => {
                                        if (
                                            item?.clients
                                                .map(c => c._id)
                                                .includes(user?._id) ||
                                            (item?.proposalCreatedBy?._id ===
                                                user?._id &&
                                                item?.proposalCreatedAs ===
                                                    'Client')
                                        ) {
                                            navigate(
                                                `/dashboard/business/proposal/${item._id}`,
                                            )
                                        } else {
                                            navigate(
                                                `/dashboard/business/deliverables/${item._id}?tab=questionnaire`,
                                            )
                                        }
                                    }}>
                                    <Message className="h-5 w-5 text-zinc-600" />
                                    <p className="text-zinc-600 font-TTHovesM">
                                        Questionnaire Pending
                                    </p>
                                </div>
                            )}
                            <div
                                onClick={() => {
                                    if (
                                        item?.clients
                                            .map(c => c._id)
                                            .includes(user?._id) ||
                                        (item?.proposalCreatedBy?._id ===
                                            user?._id &&
                                            item?.proposalCreatedAs ===
                                                'Client')
                                    ) {
                                        navigate(
                                            `/dashboard/business/proposal/${item._id}`,
                                        )
                                    } else {
                                        navigate(
                                            `/dashboard/business/deliverables/${item._id}?tab=notes`,
                                        )
                                    }
                                }}
                                className="flex cursor-pointer items-center gap-2">
                                <ClipboardText className="h-5 w-5 text-zinc-600" />
                                <p className="text-zinc-600 font-TTHovesM">
                                    Add Notes
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            )}

            {!loading && proposals.length === 0 && (
                <DataNotFound title="No Request(s) yet" />
            )}
        </BusinessLayout>
    )
}
