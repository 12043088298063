import { AppThunk } from 'redux/store'
import {
    initLogin,
    loginFailure,
    loginSuccess,
    logout,
    logoutSuccess,
} from '../reducers/authReducer'
// import { redirectTo } from "utils/links"
import { removeItem, setItem } from 'services/localStorage.service'
import AuthService from 'services/auth.service'
import { redirectTo } from 'utils/links'

const authService = new AuthService()

export const signIn =
    (
        { email, password }: { email: string; password: string },
        setSubmitting: any,
        setAuthError: any,
    ): AppThunk =>
    async dispatch => {
        dispatch(initLogin())
        try {
            const response = await authService.login({ email, password })
            if (!response.data.success) {
                dispatch(loginFailure(response.data.message))
            }
            dispatch(loginSuccess(response.data.data))
            setItem('token', response.data.data.token)
            redirectTo('/dashboard')
        } catch (error: any) {
            console.log({ error })
            setAuthError(error.response?.data?.message)
        } finally {
            setSubmitting(false)
        }
    }
export const signUp =
    (
        {
            email,
            password,
            userType,
        }: { email: string; password: string; userType: string },
        setSubmitting: any,
        setAuthError: any,
    ): AppThunk =>
    async dispatch => {
        try {
            const resp = await authService.signup({ email, password, userType })
            dispatch(
                loginSuccess({ ...resp.data.data, token: resp.data.token }),
            )
            setItem('token', resp.data.token)
            redirectTo('/create/profile')
        } catch (error: any) {
            setAuthError(error?.response?.data?.message)
        } finally {
            setSubmitting(false)
        }
    }

export const logOut = (): AppThunk => async dispatch => {
    try {
        dispatch(logout())
        dispatch(logoutSuccess())
        removeItem('token')
        removeItem('persist:root')
        removeItem('current')
        window.location.reload()
    } catch (error) {
        console.log(error)
    }
}
