import React, { useEffect, useState } from 'react'
import {
    Global,
    Location,
    ReceiptItem,
    RefreshCircle,
    Send2,
} from 'iconsax-react'
import { StatusBadge } from 'components/landing/photographer/StatusBadge'
import { StatusColorMap } from 'utils/maps'
import { ProfileGrid } from 'components/landing/photographer/ProfileGrid'
import classNames from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { Button } from 'components/form/Button/Button'
import Accordion from 'components/common/Accordion'

const service = new UserService()

export const PhotographerProfile = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [currentSession, setCurrentSession] = useState('')
    const [user, setUser] = useState<any>(null)
    const [education, setEducation] = useState<any>({})
    const [more, setMore] = useState(3)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        service
            .getSingleUser(id)
            .then(resp => {
                setUser(resp.data.data)
                service
                    .getSettings(resp.data.data?._id)
                    .then(resp => {
                        setEducation(resp.data.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(() => {
                console.log('Profile: ')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id])

    const firstThreeItems = user?.portfolio?.slice(0, more)
    const remainingCount = user?.portfolio?.length - more

    // const initChat = () => {
    //     service
    //         .initChat(user?._id)
    //         .then(resp => {
    //             navigate(`/dashboard/chat/${resp.data.data._id}`)
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }

    return (
        <div className="w-full relative">
            {loading ? (
                <div className="flex flex-col items-center justify-center h-96 w-full ">
                    <RefreshCircle className="text-tale-10 animate-spin h-14 w-14" />
                </div>
            ) : (
                <div className="flex-1">
                    <div className="relative">
                        {user?.coverImage ? (
                            <div className="block h-96 w-full">
                                <img
                                    alt="couple"
                                    src={
                                        user?.coverImage
                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`
                                            : '/assets/placeholder.png'
                                    }
                                    className="w-full h-full rounded-lg object-cover"
                                />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center w-full">
                                <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-zinc-50 border-zinc-200 hover:bg-gray-100">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16">
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">
                                                Go to profile settings
                                            </span>{' '}
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            Upload cover image (SVG, PNG, JPG or
                                            GIF (MAX. 800x400px)
                                        </p>
                                    </div>
                                </label>
                            </div>
                        )}
                        <div className="w-full ">
                            <div className="flex flex-col md:flex-row items-center w-full gap-3 ">
                                <div className="-mt-20 md:ml-6 w-52 h-52 flex-shrink-0 border-4 rounded-full border-white">
                                    <img
                                        className="rounded-full border-4 border-stone-50 overflow-hidden object-cover object-top h-full w-full"
                                        alt="profile"
                                        src={
                                            user?.profileImage
                                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                : '/assets/images/blank.webp'
                                        }
                                    />
                                </div>
                                <div className=" w-full flex flex-col md:flex-row  justify-between">
                                    <div className="flex mt-0 md:mt-6 flex-col gap-0.5 items-start">
                                        <p className="text-slate-800 text-lg font-TTHovesSB">
                                            {user?.firstName +
                                                ' ' +
                                                user?.lastName}
                                        </p>
                                        <p className="text-zinc-600 text-sm leading-none">
                                            {user?.businessName}
                                        </p>
                                        <div className="flex items-start gap-3">
                                            <p className=" text-center px-3 whitespace-nowrap py-1.5 bg-indigo-50 rounded-[7px] mt-1.5 text-gray-800 text-sm">
                                                {user?.servicePreferences
                                                    ?.hourlyRate
                                                    ? `${user?.currencySymbol} ${user?.servicePreferences?.hourlyRate} /hr`
                                                    : 'Not disclosed yet'}
                                            </p>
                                            <div className="flex flex-wrap  items-center gap-3 mt-2">
                                                {firstThreeItems?.map(
                                                    session => (
                                                        <div
                                                            key={session._id}
                                                            className={classNames(
                                                                'cursor-pointer px-3 py-1.5 rounded-[7px] bg-white border border-slate-300',
                                                            )}>
                                                            <p className="text-center whitespace-nowrap text-zinc-900 text-sm">
                                                                {
                                                                    session
                                                                        ?.session
                                                                        ?.name
                                                                }
                                                            </p>
                                                        </div>
                                                    ),
                                                )}
                                                {remainingCount > 0 &&
                                                    more <
                                                        user?.sessions
                                                            ?.length && (
                                                        <p
                                                            onClick={() =>
                                                                setMore(
                                                                    prev =>
                                                                        prev +
                                                                        10,
                                                                )
                                                            }
                                                            className="pl-2 rounded-full text-sm text-zinc-600 cursor-pointer">
                                                            +{remainingCount}{' '}
                                                            more
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white flex-shrink-0 w-full md:w-1/3 mt-5 flex flex-col  shadow-sm border border-zinc-200 rounded-lg p-5">
                                        <div className="flex">
                                            {user?.workPreferences
                                                ?.availibility && (
                                                <StatusBadge
                                                    type={
                                                        StatusColorMap[
                                                            'Available'
                                                        ]
                                                    }
                                                    text="Available"
                                                />
                                            )}
                                        </div>
                                        <p className="text-2xl py-3 font-TTHovesM">
                                            Get in touch with{' '}
                                            {user?.firstName +
                                                ' ' +
                                                user?.lastName}{' '}
                                            to ask their availability
                                        </p>
                                        <Button
                                            onClick={() =>
                                                navigate('/dashboard/add-quote')
                                            }
                                            className="w-full flex gap-4 items-center justify-center bg-tale-10 border-tale-10 p-3 text-white">
                                            <Send2
                                                className="h-6 w-6"
                                                color="#FFFFFF"
                                            />
                                            <span>Ask for a quote</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex max-w-4xl mt-5 md:mt-0 justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                                <Location size={18} />
                                <p className="text-slate-700 text-sm font-medium leading-tight">
                                    {user?.location}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <ReceiptItem size={18} />
                                <p className="text-slate-700 text-sm font-medium leading-tight">
                                    {education?.experiences?.yearsInBusiness ??
                                        0}{' '}
                                    in business
                                </p>
                            </div>
                            {/*<div className="flex items-center gap-2">*/}
                            {/*    <Global size={18} />*/}
                            {/*    <p className="text-slate-700 text-sm font-medium leading-tight">*/}
                            {/*        {user?.website}*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="border-t max-w-4xl border-zinc-200 mt-3">
                        <p className="mt-3 font-TTHovesM text-zinc-800">
                            About {user?.firstName}
                        </p>
                        <p
                            className="text-slate-800 font-medium mt-1.5"
                            dangerouslySetInnerHTML={{
                                __html: user?.bio,
                            }}
                        />
                    </div>
                    <div className="border-t max-w-4xl border-zinc-200 mt-3">
                        <p className="mt-3 font-TTHovesM text-zinc-800">
                            Experiences & Certifications
                        </p>
                        <p className="text-zinc-800 text-sm mt-2">Experience</p>
                        <div className="flex mt-1 gap-2">
                            <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                {education?.experiences?.yearsInBusiness ??
                                    `0 years`}{' '}
                                in business
                            </div>
                            <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                56 reviews
                            </div>
                            <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                {education?.experiences?.noOfEmployees ?? 0}{' '}
                                employees
                            </div>
                            <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                {education?.experiences?.yearsOfExperience ??
                                    `0 years`}{' '}
                                of experience
                            </div>
                        </div>

                        <p className="text-zinc-800 text-sm mt-3">
                            Certifications
                        </p>
                        <div className="flex mt-1 gap-2">
                            {education?.certifications?.map(item => (
                                <div
                                    key={item?._id}
                                    className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                    {item?.title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className="text-slate-800 text-[22px] font-TTHovesM mt-3">
                        Portfolio
                    </p>
                    <div className="flex flex-wrap gap-3 mt-2">
                        <div
                            onClick={() => setCurrentSession('')}
                            className={classNames(
                                'cursor-pointer px-4 py-2.5 rounded-[7px]',
                                {
                                    'bg-white border text-zinc-900 border-slate-300':
                                        currentSession,
                                    'bg-tale-10 text-white': !currentSession,
                                },
                            )}>
                            <p className="text-center text-sm">All</p>
                        </div>
                        {user?.sessions?.map(tag => (
                            <div
                                key={tag?._id}
                                onClick={() =>
                                    setCurrentSession(tag.session._id)
                                }
                                className={classNames(
                                    ' cursor-pointer px-4 py-2.5 rounded-[7px]',
                                    {
                                        'bg-white border text-zinc-900 border-slate-300':
                                            tag.session._id !== currentSession,
                                        'bg-tale-10 text-white':
                                            tag.session._id === currentSession,
                                    },
                                )}>
                                <p className="text-center  text-sm">
                                    {tag?.session?.name}
                                </p>
                            </div>
                        ))}
                    </div>

                    <ProfileGrid
                        portfolio={user?.portfolio}
                        id={user?._id}
                        current={currentSession}
                    />
                    <p className="text-slate-800 mt-6 text-[22px] font-TTHovesM ">
                        FAQs
                    </p>
                    <div className="flex flex-col gap-2 mt-2">
                        {education?.faqs?.map(item => (
                            <Accordion
                                showBurger={false}
                                key={item?._id}
                                title={item?.question}>
                                <div className="text-sm py-2">
                                    {item?.answer}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                    {/*<div className="flex mt-4 px-4 md:px-20 py-8 bg-[#E9F1DB] rounded-[10px] items-center gap-7">*/}
                    {/*    <div className="h-36 md:w-36">*/}
                    {/*        <img*/}
                    {/*            alt="profile"*/}
                    {/*            src={user?.profileImage}*/}
                    {/*            className="h-full w-full rounded-full object-cover ring-4 ring-white"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="flex flex-col gap-2">*/}
                    {/*        <p className="text-slate-500 font-medium ">*/}
                    {/*            Feeling interested?*/}
                    {/*        </p>*/}
                    {/*        <p className="text-slate-800 text-3xl font-medium font-OTabolas mb-1">*/}
                    {/*            Want to work with{' '}*/}
                    {/*            {user?.firstName + ' ' + user?.lastName}?{' '}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    )
}
