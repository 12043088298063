import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const useMessages = () => {
    const [chats, setChats] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllChats()
            .then(resp => {
                setChats(
                    resp.data.data.sort(
                        (a, b) =>
                            (new Date(b.updatedAt) as any) -
                            (new Date(a.updatedAt) as any),
                    ),
                )
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        chats,
        setChats,
        fetchData,
    }
}
