import React, { useState } from 'react'
import classNames from 'classnames'
import { Add, DocumentText, Edit, RefreshCircle, Trash } from 'iconsax-react'
import { DataNotFound } from 'components/common/DataNotFound'
import { Modal } from 'components/common/Modal'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { useSession } from 'hooks/useSession'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()
export const Questionnaires = ({ tab }) => {
    const user = useSelector(userSelector)
    const [questions, setQuestions] = useState<any[]>([])
    const [showModal, setShowModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        id: '',
        user: user._id,
        question: '',
        category: '',
    })

    const { sessions } = useSession()
    const { successMessage } = useNotifications()
    const handleAdd = e => {
        e.preventDefault()
        if (editing) {
            service
                .updateQuestion(state)
                .then(() => {
                    setQuestions(
                        questions.map(question => {
                            if (question._id === state.id) {
                                return {
                                    ...question,
                                    question: state.question,
                                }
                            }
                            return question
                        }),
                    )
                    successMessage('Question updated successfully')
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            service
                .addQuestion(state)
                .then(resp => {
                    setQuestions(prev => [...prev, resp.data.data])
                    successMessage('Question added successfully')
                })
                .catch(error => {
                    console.log(error)
                })
        }
        setShowModal(false)
        setState({
            ...state,
            question: '',
        })
    }

    const handleDelete = (index, id) => {
        service.deleteQuestion(id).then(() => {
            const values = [...questions]
            values.splice(index, 1)
            setQuestions(values)
            successMessage('Question deleted successfully')
        })
    }

    const getQuestions = id => {
        setLoading(true)
        setState({
            ...state,
            category: id,
        })
        service
            .getQuestions(id)
            .then(resp => {
                setQuestions(resp.data.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setQuestions([])
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Questionnaire Templates
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}
                {state.category && (
                    <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Add className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM ">
                            Add new question
                        </span>
                    </button>
                )}
            </div>
            <div className="mt-6">
                <ReactSelect
                    onChange={value => getQuestions(value)}
                    label="Select shoot category"
                    options={sessions.map(item => ({
                        label: item.name,
                        value: item._id,
                    }))}
                    placeholder="Shoot Category"
                    value=""
                />

                {loading && (
                    <RefreshCircle className="size-10 text-tale-10 mx-auto mt-6 animate-spin" />
                )}
                {!loading && questions.length > 0
                    ? questions.map((question, index) => (
                          <div
                              key={question._id}
                              className="p-3.5 border shadow-sm border-[#ACB6BE] rounded-xl my-4 flex items-center justify-between">
                              <p>{question.question}</p>
                              <div className="flex items-center gap-2">
                                  <button
                                      type="button"
                                      onClick={() => {
                                          setEditing(true)
                                          setState({
                                              ...state,
                                              id: question._id,
                                              question: question.question,
                                              category: question.category._id,
                                          })
                                          setShowModal(true)
                                      }}>
                                      <Edit className="h-5 w-5" />
                                  </button>
                                  <button
                                      type="button"
                                      onClick={() =>
                                          handleDelete(index, question._id)
                                      }>
                                      <Trash className="h-5 w-5" />
                                  </button>
                              </div>
                          </div>
                      ))
                    : !loading && (
                          <DataNotFound
                              title="No Question added"
                              description="Please select category to view questions."
                          />
                      )}
            </div>

            <Modal
                width="w-full lg:w-1/3"
                showModal={showModal}
                setShowModal={() => {
                    setShowModal(false)
                    setEditing(false)
                    setState({
                        ...state,
                        id: '',
                        user: user._id,
                        question: '',
                    })
                }}
                title={editing ? 'Update Question' : 'Add Question'}
                footerButton={editing ? 'Update Question' : 'Add Question'}
                handleClickPrimary={handleAdd}
                headerIcon={<DocumentText />}>
                {/* Form */}
                <form onSubmit={handleAdd} className="p-5">
                    <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                        <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                            Question*
                        </label>
                        <input
                            className="border border-zinc-300 shadow-sm rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                            type="text"
                            value={state.question}
                            onChange={e =>
                                setState({
                                    ...state,
                                    question: e.target.value,
                                })
                            }
                            placeholder="Type here..."
                        />
                    </div>
                </form>
                <hr className="text-zinc-200 w-full " />
                {/* Form End */}
            </Modal>
        </div>
    )
}
