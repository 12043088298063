import React, { Fragment } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export const MobileMenu = ({ sidebarOpen, setSidebarOpen }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const goTo = path => {
        navigate(path)
        setSidebarOpen(false)
    }

    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed w-96 inset-0 flex z-[200]"
                open={sidebarOpen}
                onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div
                        className="fixed inset-0 blur bg-black bg-opacity-25"
                        onClick={() => setSidebarOpen(false)}
                    />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <div className="flex-1 flex flex-col max-w-xs w-full h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="absolute top-0 right-0 -mr-6 pt-2">
                                <button
                                    className="ml-1 bg-gray-100 flex border border-white items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => setSidebarOpen(false)}>
                                    <span className="sr-only">
                                        Close sidebar
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5 text-white">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex md:flex-shrink-0 h-full ">
                            {/*{!user && <SmallLoader />}*/}
                            <div className="flex flex-col w-72 overflow-x-hidden">
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex flex-col flex-grow  pb-4 bg-white overflow-y-auto">
                                    <div className="px-5 pt-5 pb-3">
                                        <button
                                            onClick={() => goTo('/dashboard')}
                                            className="text-body-bold text-primary text-dark-8 font-OTabolas">
                                            Suura
                                        </button>
                                    </div>
                                    <div className=" flex-grow flex flex-col">
                                        <nav className="flex-1 px-1 bg-white divide-y divide-zinc-200 space-y-1">
                                            <Link
                                                to="/"
                                                className={`${
                                                    pathname === '/'
                                                        ? 'text-tale-10'
                                                        : 'text-gray-600 hover:text-gray-900 hover:bg-zinc-50'
                                                } flex px-5 py-3 items-center group w-full`}>
                                                <span className="font-TTHovesM">
                                                    Dashboard
                                                </span>
                                            </Link>

                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="w-full">
                                                            <div className="w-full flex items-center justify-between hover:bg-zinc-50 px-5 py-3">
                                                                <p className="text-left justify-self-start text-gray-600  w-full font-TTHovesM rounded-lg ">
                                                                    Directory
                                                                </p>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    stroke="currentColor"
                                                                    className={`${
                                                                        open
                                                                            ? 'rotate-180 transform'
                                                                            : ''
                                                                    } transition delay-100  h-4`}>
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <div className="overflow-hidden">
                                                            <Transition
                                                                show={open}
                                                                as="div"
                                                                enter="transition ease-in-out duration-300 transform"
                                                                enterFrom="-translate-y-full"
                                                                enterTo="translate-y-0"
                                                                leave="transition ease-in-out duration-300 transform"
                                                                leaveFrom="translate-y-0"
                                                                leaveTo="-translate-y-full">
                                                                <Disclosure.Panel className="font-TTHovesM text-gray-600 divide-y divide-zinc-200 shadow-inner flex flex-col transition-all duration-700  ">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/photographers',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Browse
                                                                        Photographers
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/quotes',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Quotes
                                                                    </button>
                                                                    <button
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/board',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Collab
                                                                        Board
                                                                    </button>
                                                                </Disclosure.Panel>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Disclosure>
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="w-full">
                                                            <div className="w-full flex items-center justify-between hover:bg-zinc-50 px-5 py-3">
                                                                <p className="text-left justify-self-start text-gray-600  w-full font-TTHovesM rounded-lg ">
                                                                    Business
                                                                </p>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    stroke="currentColor"
                                                                    className={`${
                                                                        open
                                                                            ? 'rotate-180 transform'
                                                                            : ''
                                                                    } transition delay-100  h-4`}>
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <div className="overflow-hidden">
                                                            <Transition
                                                                show={open}
                                                                as="div"
                                                                enter="transition ease-in-out duration-300 transform"
                                                                enterFrom="-translate-y-full"
                                                                enterTo="translate-y-0"
                                                                leave="transition ease-in-out duration-300 transform"
                                                                leaveFrom="translate-y-0"
                                                                leaveTo="-translate-y-full">
                                                                <Disclosure.Panel className="font-TTHovesM text-gray-600 divide-y divide-zinc-200 shadow-inner flex flex-col transition-all duration-700  ">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/photoshoot/requests',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Photoshoot
                                                                        Projects
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/photoshoot/bookings',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Session
                                                                        Bookings
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/clients',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Clients
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/services?tab=categories',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Services
                                                                        &
                                                                        Invoicing
                                                                    </button>
                                                                </Disclosure.Panel>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Disclosure>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    goTo(
                                                        '/dashboard/browse/jobs',
                                                    )
                                                }
                                                className={`${
                                                    pathname ===
                                                    '/dashboard/browse/jobs'
                                                        ? 'text-tale-10'
                                                        : 'text-gray-600 hover:text-gray-900 hover:bg-zinc-50'
                                                } flex px-5 py-3 items-center group w-full`}>
                                                <span className=" font-TTHovesM ">
                                                    Browse Jobs
                                                </span>
                                            </button>
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="w-full">
                                                            <div className="w-full flex items-center justify-between hover:bg-zinc-50 px-5 py-3">
                                                                <p className="text-left justify-self-start text-gray-600  w-full font-TTHovesM rounded-lg ">
                                                                    Galleries
                                                                </p>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    stroke="currentColor"
                                                                    className={`${
                                                                        open
                                                                            ? 'rotate-180 transform'
                                                                            : ''
                                                                    } transition delay-100  h-4`}>
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <div className="overflow-hidden">
                                                            <Transition
                                                                show={open}
                                                                as="div"
                                                                enter="transition ease-in-out duration-300 transform"
                                                                enterFrom="-translate-y-full"
                                                                enterTo="translate-y-0"
                                                                leave="transition ease-in-out duration-300 transform"
                                                                leaveFrom="translate-y-0"
                                                                leaveTo="-translate-y-full">
                                                                <Disclosure.Panel className="font-TTHovesM text-gray-600 divide-y divide-zinc-200 shadow-inner flex flex-col transition-all duration-700  ">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/galleries/client',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Client
                                                                        Galleries
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/galleries/portfolio',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Public
                                                                        Portfolio
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/galleries/library',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Photo
                                                                        Library
                                                                    </button>
                                                                </Disclosure.Panel>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Disclosure>
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="w-full">
                                                            <div className="w-full flex items-center justify-between hover:bg-zinc-50 px-5 py-3">
                                                                <p className="text-left justify-self-start text-gray-600  w-full font-TTHovesM rounded-lg ">
                                                                    Photo Albums
                                                                </p>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    stroke="currentColor"
                                                                    className={`${
                                                                        open
                                                                            ? 'rotate-180 transform'
                                                                            : ''
                                                                    } transition delay-100  h-4`}>
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <div className="overflow-hidden">
                                                            <Transition
                                                                show={open}
                                                                as="div"
                                                                enter="transition ease-in-out duration-300 transform"
                                                                enterFrom="-translate-y-full"
                                                                enterTo="translate-y-0"
                                                                leave="transition ease-in-out duration-300 transform"
                                                                leaveFrom="translate-y-0"
                                                                leaveTo="-translate-y-full">
                                                                <Disclosure.Panel className="font-TTHovesM text-gray-600 divide-y divide-zinc-200 shadow-inner flex flex-col transition-all duration-700  ">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/photoshoot/requests',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Album
                                                                        Library
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/photoshoot/bookings',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Create
                                                                        New
                                                                        Album
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/clients',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Clients
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            goTo(
                                                                                '/dashboard/services?tab=categories',
                                                                            )
                                                                        }
                                                                        className="pl-8 text-left py-4 text-sm tracking-wide">
                                                                        Education
                                                                        &
                                                                        Resources
                                                                    </button>
                                                                </Disclosure.Panel>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Disclosure>
                                            {/*<Link*/}
                                            {/*    to="/"*/}
                                            {/*    className={`${*/}
                                            {/*        pathname === '/'*/}
                                            {/*            ? 'text-tale-10'*/}
                                            {/*            : 'text-gray-600 hover:text-gray-900 hover:bg-zinc-50'*/}
                                            {/*    } flex px-5 py-3 items-center group w-full`}>*/}
                                            {/*    <span className=" font-TTHovesM ">*/}
                                            {/*        Collab Board*/}
                                            {/*    </span>*/}
                                            {/*</Link>*/}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
