// useSocket.js
import { useEffect, useState } from 'react'
import { socket } from 'utils/socket'

export const useSocket = userId => {
    const [onlineUsers, setOnlineUsers] = useState([])

    useEffect(() => {
        // Emit an event to notify the server that this user is online
        socket.emit('online', userId)

        // Listen for updates to the online users list
        socket.on('onlineUsers', users => {
            setOnlineUsers(users)
        })

        // Cleanup on unmount
        return () => {
            socket.disconnect()
        }
    }, [userId])

    return onlineUsers
}
