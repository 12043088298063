import React, { useEffect, useState } from 'react'
import { Input } from 'components/form/Input/Input'
import { DropdownIndicator } from 'components/create-profile/SetupProfile'
import TimezoneSelect from 'react-timezone-select'
import { styles } from 'pages/dashboard/Settings'
import { IconGallery } from '../../../css/icons/icons'
import classNames from 'classnames'
import { Camera, CloseCircle } from 'iconsax-react'
import { useFormik } from 'formik'
import { ProfileValidation } from 'utils/validations'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UserService from 'services/user.service'
import { LocationSelect } from 'components/form/LocationSelect'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setUser } from '../../../redux/reducers/authReducer'
import { useNotifications } from 'hooks/useNotification'
import { RichEditor } from 'components/common/RichEditor'
import { Education } from 'components/dashboard/settings/Education'
import { Faq } from 'components/dashboard/settings/Faq'

const service = new UserService()

export const ProfileSetting = ({ tab, user }) => {
    const [coverPreview, setCoverPreview] = useState(
        user.coverImage
            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`
            : '',
    )
    const [settingTab, setSettingTab] = useState('profile')
    const [profilePreview, setProfilePreview] = useState(
        user.profileImage
            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
            : '',
    )
    const [loading, setLoading] = useState(false)
    const [settings, setSettings] = useState()

    const dispatch = useThunkDispatch()
    const { successMessage, errorMessage } = useNotifications()

    useEffect(() => {
        setLoading(true)
        service
            .getSettings(user?._id)
            .then(resp => {
                setSettings(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        setFieldValue,
        touched,
        isSubmitting,
        setSubmitting,
        values,
    } = useFormik({
        initialValues: {
            firstName: user.firstName ?? '',
            coverImage: user?.coverImage,
            profileImage: user?.profileImage,
            lastName: user.lastName ?? '',
            userName: user.userName ?? '',
            website: user.website ?? '',
            timezone: user.timezone ?? '',
            location: user?.location ?? '',
            contactNumber: user?.contactNumber ?? '',
            businessName: user.businessName ?? '',
            currency: user.currency ?? '',
        },
        validationSchema: ProfileValidation,
        onSubmit: values => {
            service
                .updateProfile(values)
                .then(resp => {
                    dispatch(setUser(resp.data.data))
                    successMessage('Profile Updated successfully')
                })
                .catch(error => {
                    console.log({ error })
                })
                .finally(() => {
                    setSubmitting(false)
                })
        },
    })

    const handleUploadImage = e => {
        const file = e.target.files[0]
        const reader = new FileReader()
        const id = e.target.id

        if (!file.type.includes('image')) {
            errorMessage('Please upload a valid image')
            return
        }

        if (id === 'cover-dropzone') {
            setFieldValue('coverImage', file)
        } else {
            setFieldValue('profileImage', file)
        }

        reader.onload = () => {
            if (reader.readyState === 2) {
                if (id === 'cover-dropzone') {
                    setCoverPreview(reader.result as string)
                } else {
                    setProfilePreview(reader.result as string)
                }
            }
        }
        file && reader.readAsDataURL(file)
    }

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            {user?.userType === 'Photographer' && (
                <div className="flex font-TTHovesM text-zinc-500 gap-4 border-b border-zinc-200 mb-5">
                    <p
                        onClick={() => setSettingTab('profile')}
                        className={classNames(
                            'cursor-pointer hover:border-b-tale-10 py-2',
                            {
                                'border-b border-tale-10':
                                    settingTab === 'profile',
                            },
                        )}>
                        My Profile
                    </p>
                    <p
                        onClick={() => setSettingTab('education')}
                        className={classNames(
                            'cursor-pointer hover:border-b-tale-10 py-2',
                            {
                                'border-b border-tale-10':
                                    settingTab === 'education',
                            },
                        )}>
                        Experiences & Certifications
                    </p>
                    <p
                        onClick={() => setSettingTab('faq')}
                        className={classNames(
                            'cursor-pointer hover:border-b-tale-10 py-2',
                            {
                                'border-b border-tale-10': settingTab === 'faq',
                            },
                        )}>
                        FAQs
                    </p>
                </div>
            )}
            {!loading && (
                <Education
                    tab={settingTab === 'education'}
                    settings={settings}
                    setSettings={setSettings}
                />
            )}
            {!loading && (
                <Faq
                    tab={settingTab === 'faq'}
                    settings={settings}
                    setSettings={setSettings}
                />
            )}
            {settingTab === 'profile' && (
                <div className="mb-8 ">
                    <div className="relative">
                        {coverPreview ? (
                            <div className="h-80 rounded-xl relative">
                                <img
                                    alt="cover"
                                    src={coverPreview}
                                    className="h-full w-full rounded-xl object-cover"
                                />
                                <button
                                    onClick={() => setCoverPreview('')}
                                    className="absolute right-0 top-0 p-3 text-white">
                                    <CloseCircle />
                                </button>
                            </div>
                        ) : (
                            <label id="cover-dropzone">
                                <div className="cursor-pointer flex flex-row justify-center items-center border border-dashed border-gray-outline rounded-xl h-80">
                                    <div className="flex flex-col justify-center items-center">
                                        <div className="mb-1">
                                            <IconGallery />
                                        </div>
                                        <div className="text-base font-TTHovesM text-brown-10 text-center">
                                            Click to upload cover image{' '}
                                            <span className="text-base font-TTHoves text-normal text-dark-5 ">
                                                &nbsp; or drag and drop
                                            </span>
                                        </div>
                                        <div className="text-xs font-TTHoves text-normal text-dark-5">
                                            PNG, JPG or GIF (Optimal size 1440 x
                                            500px)
                                        </div>
                                        <input
                                            id="cover-dropzone"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={handleUploadImage}
                                        />
                                    </div>
                                </div>
                            </label>
                        )}

                        <div className="flex flex-col md:flex-row  justify-between border-b border-zinc-200 ">
                            <label
                                id="profile-dropzone"
                                className="inline-flex flex-col md:flex-row justify-between md:items-center z-10 -mt-20 ml-3 py-6">
                                {profilePreview ? (
                                    <div className="w-44 h-44 mb-2 relative">
                                        <img
                                            alt="profile"
                                            src={profilePreview}
                                            className="h-full w-full rounded-full object-cover"
                                        />
                                        <div
                                            id="profile-dropzone"
                                            className="group absolute cursor-pointer w-full transition-colors ease-in-out flex items-center justify-center top-0 h-full hover:bg-zinc-300 hover:bg-opacity-30 rounded-full text-white">
                                            <span className="hidden group-hover:block">
                                                <Camera className="h-12 w-12" />
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex cursor-pointer ">
                                        <div className="border w-fit border-dashed border-gray-outline rounded-full p-16 bg-creame-1">
                                            <IconGallery />
                                        </div>
                                        <div className="hidden md:flex flex-col justify-center items-center mr-10 pl-6 mt-4">
                                            <p className="text-base font-TTHovesM text-brown-10">
                                                Click to upload profile image
                                            </p>
                                            <p className="text-xs sfont-TTHoves text-normal text-dark-5">
                                                PNG or JPG (Optimal size 800 x
                                                800px)
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <input
                                    id="profile-dropzone"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleUploadImage}
                                />
                            </label>

                            <div className="flex items-center gap-3 mb-4 md:mb-0">
                                <button
                                    type="button"
                                    className={styles.default}>
                                    <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                        Cancel
                                    </span>
                                </button>
                                <button
                                    form="form1"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className={styles.primary}>
                                    <span className="text-white  text-sm font-TTHovesM leading-tight">
                                        {isSubmitting
                                            ? 'Saving...'
                                            : 'Save changes'}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {settingTab === 'profile' && (
                <form onSubmit={handleSubmit} id="form1">
                    <div className="grid md:grid-cols-2 gap-4 py-2">
                        <Input
                            type="text"
                            placeholder="e.g John"
                            label="First name"
                            value={values.firstName}
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                            error={touched.firstName && errors.firstName}
                            name="firstName"
                        />
                        <Input
                            name="lastName"
                            type="text"
                            value={values.lastName}
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                            error={touched.lastName && errors.lastName}
                            placeholder="e.g Doe"
                            label="Last name"
                        />
                    </div>
                    <div className="grid md:grid-cols-2 gap-4 py-4">
                        <Input
                            type="text"
                            placeholder="johnDoe"
                            value={values.userName}
                            onChange={handleChange('userName')}
                            onBlur={handleBlur('userName')}
                            error={touched.userName && errors.userName}
                            label="Username"
                            name="userName"
                        />
                        {/*<Input*/}
                        {/*    name="contactNumber"*/}
                        {/*    type="text"*/}
                        {/*    placeholder=""*/}
                        {/*    label="Contact number"*/}
                        {/*    value={values.contactNumber}*/}
                        {/*    error={touched.contactNumber && errors.contactNumber}*/}
                        {/*    onChange={handleChange('contactNumber')}*/}
                        {/*    onBlur={handleBlur('contactNumber')}*/}
                        {/*/>*/}

                        <PhoneInput
                            country={'us'}
                            value={values.contactNumber}
                            onChange={phone =>
                                setFieldValue('contactNumber', phone)
                            }
                        />
                    </div>

                    <div className="grid md:grid-cols-2 gap-4 py-4">
                        {user?.userType === 'Photographer' && (
                            <Input
                                type="text"
                                placeholder=""
                                disabled
                                value={values.userName}
                                onChange={handleChange('businessName')}
                                name="userName"
                                label="https://suura.com/"
                            />
                        )}
                        <Input
                            name="contactNumber"
                            type="text"
                            placeholder=""
                            label="Business Name"
                            value={values.businessName}
                            error={touched.businessName && errors.businessName}
                            onChange={handleChange('website')}
                            onBlur={handleBlur('website')}
                        />
                    </div>

                    {/*<p className="h-px bg-zinc-200 my-3 px-6" />*/}
                    <div className="py-3">
                        <LocationSelect
                            onChange={value => setFieldValue('location', value)}
                            value={values.location}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-4">
                        <div className="relative">
                            <label className="absolute -top-3 left-4 bg-creame-1 z-10 text-gray ">
                                Time Zone
                            </label>
                            <TimezoneSelect
                                value={values.timezone}
                                className="react-select"
                                placeholder="Select time zone"
                                onChange={(data: any) =>
                                    setFieldValue('timezone', data.value)
                                }
                                components={{ DropdownIndicator }}
                                classNamePrefix="react-select"
                            />
                        </div>
                        <ReactSelect
                            placeholder="Select currency"
                            value={values.currency}
                            options={[
                                // { label: '$ - Dollar', value: '$ - Dollar' },
                                { label: '€ - Euro', value: '€ - Euro' },
                                // {
                                //     label: 'AED - Dirham',
                                //     value: 'AED - Dirham',
                                // },
                                // { label: '£ - Pound', value: '£ - Pound' },
                                // { label: 'Riyal', value: 'Riyal' },
                            ]}
                            onChange={value => setFieldValue('currency', value)}
                            label="Currency"
                        />
                    </div>
                    <div className="flex  flex-col md:flex-row gap-4 mt-2">
                        <div className="w-full md:w-2/3">
                            <p className="text-slate-800 font-TTHovesSB leading-tight">
                                Description
                            </p>
                            <p className="text-slate-600 text-sm leading-tight mt-1">
                                Write a short description.
                            </p>
                        </div>
                        <RichEditor
                            content={user?.bio}
                            setContent={(value: string) =>
                                setFieldValue('bio', value)
                            }
                        />
                    </div>
                </form>
            )}
        </div>
    )
}
