import { Fragment } from 'react'
import { Add, ArrowRight2, Home2 } from 'iconsax-react'
import { Link, useNavigate } from 'react-router-dom'
import { BiLinkExternal } from '../../css/icons/icons'
import { QuotesTable } from 'components/tables/QuotesTable'
import { IconButton } from 'components/form/Button/Button'

export const Quotes = () => {
    const navigate = useNavigate()
    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="items-center gap-2 hidden md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Directory
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        Quotes
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <IconButton
                        onClick={() => navigate('/dashboard/browse/jobs')}
                        icon={
                            <BiLinkExternal className="h-5 w-5 text-tale-10" />
                        }
                        text="Browse Jobs"
                    />
                    <IconButton
                        icon={<Add className="text-white h-5 w-5" />}
                        text="Create New Quote"
                        variant="primary"
                        onClick={() => navigate('/dashboard/add-quote')}
                    />
                </div>
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-6 p-5 min-w-full">
                <div className="border-b border-zinc-200 pb-5">
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        Quotes
                    </p>
                    <p className="text-slate-600 text-sm leading-tight">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                    </p>
                </div>
                <QuotesTable />
            </div>
        </Fragment>
    )
}
