import React, { SyntheticEvent, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from '../../form/Input/Input.module.css'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

const service = new UserService()

export const InvoiceTerm = ({ tab }) => {
    const user = useSelector(userSelector)
    const [state, setState] = useState('')
    const [invoiceTerms, setInvoiceTerms] = useState<any>(null)
    const [submitting, setSubmitting] = useState(false)

    const { successMessage } = useNotifications()

    useEffect(() => {
        service
            .getInvoiceTerms()
            .then(resp => {
                setState(resp?.data?.data?.invoiceTerms)
                setInvoiceTerms(resp?.data?.data)
                console.log(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    console.log(user)

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault()

        setSubmitting(true)

        if (invoiceTerms) {
            service
                .updateInvoiceTerms(invoiceTerms._id, state)
                .then(() => {
                    successMessage('Terms updated successfully')
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        } else {
            service
                .saveInvoiceTerms(state)
                .then(() => {
                    successMessage('Terms added successfully')
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Invoice Terms
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-full md:w-auto px-4 py-2.5 bg-tale-10 text-white text-sm font-TTHovesM rounded-lg shadow">
                    {submitting ? 'Saving...' : 'Save Terms'}
                </button>
            </div>
            <div className={classNames('relative mt-6 w-full')}>
                <label className="absolute -top-3 left-4 bg-white text-gray px-1">
                    Invoice Terms
                </label>
                <textarea
                    value={state}
                    onChange={e => setState(e.target.value)}
                    rows={10}
                    className={classNames('!pt-3', [styles.input])}
                />
            </div>
        </div>
    )
}
