// ** For Single Quote Tile
export interface SingleQuoteTile {
    user: {
        profileImage: string
        firstName: string
        lastName: string
    }
    title: string
    description: string
}

//  ** Invite Photographer Filters
export interface FiltersInterFace {
    session: string
    byName: string
    location: string
    startDate: string
    endDate: string
    // byDate: {
    //     start: string
    //     end: string
    // }
}

//  ** Invite Photographer Filters Init Values
export const initFilter = {
    session: '',
    byName: '',
    location: '',
    startDate: '',
    endDate: '',
    // byDate: { start: '', end: '' },
}

//  ** Invite Photographer Listing Interface
export interface InvitePhotoGrapherListingTileData {
    id: string
    firstName: string
    lastName: string
    profileImage: string
    businessName: string
    // eslint-disable-next-line no-undef
    map(arg0: (item: object) => JSX.Element): React.ReactNode
}

interface Session {
    session: {
        name: string
    }
}

interface ServicePreferences {
    hourlyRate: number
}

export interface PhotographerData {
    _id: string
    profileImage: string
    firstName: string
    lastName: string
    location: string
    businessName: string
    currencySymbol: string
    servicePreferences: ServicePreferences
    sessions: Session[]
}

// Define the type for each item in the listing
export interface PhotographerDataForListing {
    _id: string
    firstName: string
    lastName: string
    profileImage: string
    location: string
    businessName: string
    currencySymbol: string
    servicePreferences: {
        hourlyRate: number
    }
    sessions: {
        session: {
            name: string
        }
    }[]
}
export interface InvitedPhotographerListInterface {
    list: PhotographerData[]
}

export interface AppliedPhotographerListInterface {
    list: AppliedPhotographer[]
    description?: string
}
export interface AppliedPhotographer {
    userId: PhotographerData
    description?: string
}
