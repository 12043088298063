import React, { Component, Fragment } from 'react'
import SignaturePad from 'react-signature-canvas'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import UserService from 'services/user.service'
import { connect } from 'react-redux'
import { setProject } from '../../redux/reducers/projectReducer'

const service = new UserService()

interface Props {
    state: any
    setState: any
    proposalId: string
    clientSignature?: boolean
    setSign?: any
    setProject?: any
}

class SignPad extends Component<Props> {
    state = { trimmedDataURL: null }
    sigPad: any = {}
    clear = () => {
        this.sigPad.clear()
    }

    apiCall = data => {
        if (this.props.clientSignature) {
            service
                .updateSignature(data, this.props.proposalId)
                .then(resp => {
                    this.props.setProject(resp.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            service
                .addSignature(data, this.props.proposalId)
                .then(resp => {
                    this.props.setProject(resp.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    saveImage = file => {
        if (file) {
            service
                .getImagesURL({ images: file })
                .then(resp => {
                    this.apiCall(resp.data.urls[0])
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            setTimeout(() => {
                this.apiCall(this.state.trimmedDataURL)
            }, 2000)
        }
    }

    trim = () => {
        if (!this.sigPad.isEmpty()) {
            this.setState({
                trimmedDataURL: this.sigPad
                    .getTrimmedCanvas()
                    .toDataURL('image/png'),
            })
            //
            // let file
            //
            // setTimeout(() => {
            //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //     // @ts-ignore
            //     file = new File([this.state.trimmedDataURL], 'sign.png', {
            //         type: 'image/png',
            //     })
            this.saveImage(null)
            //     console.log({ file })
            //     console.log(this.state.trimmedDataURL)
            // }, 2000)
            this.props.setState({
                ...this.props.state,
                // file,
                sign: this.sigPad.getTrimmedCanvas().toDataURL('image/PNG'),
            })
        }
    }

    handleUpload = e => {
        const file = e.target.files[0]
        this.saveImage(file)

        const reader = new FileReader()

        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({
                    trimmedDataURL: URL.createObjectURL(file),
                })
                this.props.setState({
                    ...this.props.state,
                    sign: URL.createObjectURL(file),
                })
            }
        }
        reader.readAsText(file)
    }

    render() {
        const { trimmedDataURL } = this.state
        return (
            <Fragment>
                <p className="text-center text-zinc-900 text-lg font-TTHovesM leading-7 mt-2 p-2">
                    Add Signature
                </p>
                <div className="h-11 p-1 bg-zinc-50 rounded-lg border border-zinc-200 justify-center items-center gap-2 inline-flex w-full mb-4">
                    <button className="px-3 py-2 text-white text-sm bg-tale-10 rounded-md shadow justify-center items-center gap-2 flex">
                        Draw Signature
                    </button>
                    <label
                        htmlFor="sign"
                        className="px-3 py-2 hover:bg-tale-10 hover:text-white rounded-md text-zinc-500 cursor-pointer text-sm">
                        Upload Signature
                        <input
                            type="file"
                            className="hidden "
                            id="sign"
                            onChange={this.handleUpload}
                        />
                    </label>
                </div>
                <div className="h-80 w-full overflow-hidden shadow-sm border border-zinc-200 rounded-xl">
                    <div className="sigContainer">
                        <SignaturePad
                            canvasProps={{ className: 'sigPad' }}
                            ref={ref => {
                                this.sigPad = ref
                            }}
                        />
                    </div>
                </div>

                <div className="flex items-start gap-6 mt-7">
                    {trimmedDataURL ? (
                        <img
                            alt="sign"
                            className="h-44 w-44 object-contain"
                            src={trimmedDataURL}
                        />
                    ) : null}
                    <div className="justify-start items-center gap-3 flex">
                        <button
                            type="submit"
                            onClick={this.trim}
                            className={classNames(styles.primary)}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                Sign
                            </span>
                        </button>
                        <button
                            type="button"
                            onClick={this.clear}
                            className={classNames(styles.default)}>
                            <span className="text-sm font-TTHovesM leading-tight">
                                Clear
                            </span>
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    project: state.project.project,
})

const mapDispatchToProps = {
    setProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignPad)
