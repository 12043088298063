import React, { memo, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import InvitePhotographerTile from './InvitePhotographerTile'
import { PhotographerDataForListing } from 'utils/interfaces/invitePhotographers'
import { useParams, useSearchParams } from 'react-router-dom'
import { DataNotFound } from 'components/common/DataNotFound'
import { RefreshCircle } from 'iconsax-react'
import moment from 'moment'

const service = new UserService()

const Listing: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [listing, setListing] = useState<PhotographerDataForListing[]>([])
    const params = useParams()
    const [searchParams] = useSearchParams()
    const paramsObject = Object.fromEntries(searchParams.entries())

    useEffect(() => {
        // Body for ENDPOINT
        const body = {
            quoteId: params?.quoteId,
            session: paramsObject?.session,
            location: paramsObject?.location,
            startDate: paramsObject?.startDate
                ? moment(paramsObject?.startDate).format('yyyy-MM-DD')
                : '',
            endDate: paramsObject?.endDate
                ? moment(paramsObject?.endDate).format('yyyy-MM-DD')
                : '',
        }

        // Main API Function
        const preFetch = async () => {
            setLoading(true)
            try {
                const res = await service.getAllInvitePhotographers(
                    {
                        page: 1,
                        limit: 20,
                    },
                    body,
                )

                setListing(res?.data?.data)
                setLoading(false)
            } catch (error) {
                setListing([])
                setLoading(false)
            }
        }

        preFetch()
    }, [params])

    return (
        <div>
            {!loading && listing.length ? (
                listing.map((item, index) => (
                    <div key={index}>
                        <InvitePhotographerTile data={item} />
                    </div>
                ))
            ) : loading ? (
                <RefreshCircle className="mx-auto  animate-spin h-10 w-10 mt-10 text-tale-10" />
            ) : (
                <div className="mt-9">
                    <DataNotFound title="No Records Found" />
                </div>
            )}
        </div>
    )
}

export default memo(Listing)
