import { DocumentText } from 'iconsax-react'
import { Modal } from 'components/common/Modal'
import React, { useState } from 'react'
import { useContracts } from 'hooks/useContracts'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const AddContractModal = ({ showModal, setShowModal, fetchData }) => {
    const user = useSelector(userSelector)

    const [state, setState] = useState({
        id: '',
        userId: user._id,
        title: '',
        contractTerms: '',
    })
    const { successMessage } = useNotifications()

    const handleAddContract = () => {
        service
            .addContract(state)
            .then(resp => {
                console.log(resp.data)
                fetchData()
                setState({
                    id: '',
                    userId: user._id,
                    title: '',
                    contractTerms: '',
                })
                successMessage('Contract added successfully')
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
            })
    }

    return (
        <Modal
            width="w-full lg:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            title="Add Contract"
            footerButton="Add Contract"
            handleClickPrimary={handleAddContract}
            headerIcon={<DocumentText />}>
            {/* Form */}
            <form className="p-5 flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                    <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                        Title*
                    </label>
                    <input
                        className="border border-zinc-300 shadow-sm text-dark-8 rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                        type="text"
                        value={state.title}
                        onChange={e =>
                            setState({
                                ...state,
                                title: e.target.value,
                            })
                        }
                        placeholder="e.g example@mail.com"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <label className="w-40 text-slate-700 text-sm font-TTHovesM">
                        Contract Terms*
                    </label>
                    <textarea
                        className="border border-zinc-300 shadow-sm text-dark-8 rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                        placeholder="Add contract terms"
                        value={state.contractTerms}
                        rows={4}
                        onChange={e =>
                            setState({
                                ...state,
                                contractTerms: e.target.value,
                            })
                        }
                    />
                </div>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
