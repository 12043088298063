import React, { useEffect, useState } from 'react'
import { userSelector } from '../../../../redux/selectors/user'
import classNames from 'classnames'
import { Add, DocumentText } from 'iconsax-react'
import { DataNotFound } from 'components/common/DataNotFound'
import { Modal } from 'components/common/Modal'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { useNotifications } from 'hooks/useNotification'
import { IconButton } from 'components/form/Button/Button'
import { BiLinkExternal } from '../../../../css/icons/icons'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setProject } from '../../../../redux/reducers/projectReducer'
import { ConfirmModal } from 'components/common/ConfirmModal'
import styles from 'components/form/Input/Input.module.css'
import { set } from 'immutable'

const service = new UserService()

export const Questionnaires = ({ tab }) => {
    const dispatch = useThunkDispatch()
    const { project } = useSelector((state: any) => state.project)
    const [answer, setAnswer] = useState('')
    const [questionId, setQuestionId] = useState('')

    const { successMessage } = useNotifications()

    const handleSaveAnswer = () => {
        service
            .addAnswer(project._id, { answer, questionId })
            .then(resp => {
                dispatch(
                    setProject({
                        ...project,
                        questions: resp.data.data,
                    }),
                )
                successMessage('Answer saved successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200  p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className=" pb-3 flex flex-col  gap-3 md:gap-0 ">
                {/*<div>*/}
                <p className="text-zinc-900 text-2xl font-OTabolas">
                    Questionnaires
                </p>
                <p className="text-dark-5 font-TTHovesL w-full md:w-1/2">
                    Pre-shoot questions will be displayed here once the
                    photographer adds them to the project. Please check back for
                    updates.
                </p>
            </div>

            <div className="">
                {project?.questions?.length > 0 ? (
                    project?.questions?.map(item => (
                        <>
                            <div
                                key={item?._id}
                                className="flex items-center gap-2">
                                <div
                                    onClick={() => {
                                        setAnswer(item.answer)
                                        setQuestionId(item?._id)
                                    }}
                                    className="cursor-pointer flex-1 p-3 border shadow-sm border-[#ACB6BE] rounded-xl my-2 flex items-center justify-between">
                                    <p>{item?.question?.question}</p>
                                    <div
                                        className={classNames(
                                            'text-xs rounded-md px-3 py-1 uppercase text-green-800',
                                            {
                                                'bg-green-700  bg-opacity-20':
                                                    !item?.answer,
                                                'bg-[#D0DBE0]': item.answer,
                                            },
                                        )}>
                                        {item?.answer ? 'Answered' : 'Pending'}
                                    </div>
                                </div>
                            </div>
                            {item?._id === questionId && (
                                <div>
                                    <textarea
                                        className={classNames('!pt-3', [
                                            styles.input,
                                        ])}
                                        value={answer}
                                        onChange={e =>
                                            setAnswer(e.target.value)
                                        }
                                    />
                                    <div className="flex my-2 gap-2">
                                        <IconButton
                                            icon={''}
                                            text="Cancel"
                                            onClick={() => setQuestionId('')}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleSaveAnswer}
                                            className="w-full  md:w-auto px-4 py-2.5 bg-tale-10 text-white text-sm font-TTHovesM rounded-lg shadow justify-center items-center gap-2 inline-flex">
                                            {item?.answer
                                                ? 'Update Response'
                                                : 'Add Response'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ))
                ) : (
                    <DataNotFound
                        title="No Question added"
                        description="Please select category to view questions."
                    />
                )}
            </div>
        </div>
    )
}
