import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
]

// #69884F42, #69884F00

export const CustomChart = ({ height, width = '100%' }) => {
    return (
        <ResponsiveContainer height={height} width={width}>
            <AreaChart data={data}>
                <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                            offset="20%"
                            stopColor="#69884F42"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="80%"
                            stopColor="#69884F00"
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                {/*<XAxis dataKey="name"/>*/}
                {/*<YAxis/>*/}
                <Tooltip />
                <Area
                    type="monotone"
                    width={1000}
                    dataKey="pv"
                    stroke="#69884F"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}
