import React, { Fragment, useState } from 'react'
import { ArrowRight2, Home2, RefreshCircle } from 'iconsax-react'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import SignPad from 'components/common/SignPad'
import { useContracts } from 'hooks/useContracts'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { useClients } from 'hooks/useClients'
import { useNotifications } from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'
import { usePhotographers } from 'hooks/usePhotographers'

export const SignContract = ({ state, setState, setProposal, proposalId }) => {
    const [sign, setSign] = useState(false)

    const user = useSelector(userSelector)

    const { contracts, loading } = useContracts()
    const { clients } = useClients()
    const { successMessage } = useNotifications()
    const { photographers } = usePhotographers()
    const navigate = useNavigate()

    const findContract = contracts.find(item => item._id === state.contract)

    const findClient = clients.filter(
        item => state?.clients?.includes(item._id),
    )
    const findPhtographer = photographers.filter(
        item => state?.photographers?.includes(item._id),
    )

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Photoshoots
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        New Photoshoot
                    </div>
                </div>
                <div className="justify-start items-center gap-3 flex">
                    <button
                        type="button"
                        onClick={() => setProposal(false)}
                        className={classNames(styles.default)}>
                        <span className="text-sm font-TTHovesM leading-tight">
                            Back
                        </span>
                    </button>
                    <button
                        type="button"
                        disabled={!state.sign}
                        onClick={() => {
                            navigate('/dashboard/photoshoot/requests')
                            successMessage('Proposal sent successfully')
                        }}
                        className={classNames(styles.primary)}>
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            Send Proposal
                        </span>
                    </button>
                </div>
            </div>
            <div className="border-b border-zinc-200 pb-5 flex flex-col mt-5">
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Review, sign and send a proposal
                </p>
                <p className="text-slate-600 text-sm leading-tight">
                    Be sure to review the terms in the template contract below.
                    If suitable for your Project, click start to sign. Once
                    signed, the proposal will be sent to client for review.
                </p>
            </div>
            {contracts?.length === 0 ? (
                <div className="mt-6">
                    <RefreshCircle className="text-tale-10 animate-spin size-12 mx-auto" />
                </div>
            ) : (
                <div className="max-w-4xl mx-auto my-4">
                    <div className="w-full items-center justify-between inline-flex bg-slate-200 rounded-xl border border-zinc-200 p-4">
                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                            <p className="text-zinc-500 text-[10px] font-TTHovesM leading-none tracking-wider">
                                CONTRACT
                            </p>
                            <p className="text-gray-600 text-[15px] font-TTHovesM">
                                {findContract?.title}
                            </p>
                        </div>
                        <button
                            onClick={() => setSign(!sign)}
                            disabled={sign && !state?.sign}
                            className="text-white disabled:opacity-50  text-sm font-TTHovesM px-4 py-2.5 bg-slate-800 rounded-lg">
                            {sign ? 'Done' : 'Sign Contract'}
                        </button>
                    </div>
                    {sign ? (
                        <SignPad
                            state={state}
                            setState={setState}
                            proposalId={proposalId}
                        />
                    ) : (
                        <div className="flex-col items-center inline-flex px-0 md:px-6">
                            <p className="text-center text-zinc-900 text-lg font-TTHovesM leading-7 mt-2 p-2">
                                {findContract?.title}
                            </p>
                            <p className="text-slate-600 text-sm leading-7  text-justify">
                                {findContract?.contractTerms}
                                {/*1. CLIENT may share the web or high res photos*/}
                                {/*delivered by the PHOTOGRAPHER on CLIENT’s personal*/}
                                {/*and business social media accounts, provided that in*/}
                                {/*each such post CLIENT must credit/tag PHOTOGRAPHER.*/}
                                {/*<br />*/}
                                {/*<br />*/}
                                {/*2. This license does not permit CLIENT to screenshot*/}
                                {/*poor quality images, or edit or alter photos in any*/}
                                {/*way, or to do any other act which would infringe*/}
                                {/*PHOTOGRAPHER’S moral rights.*/}
                                {/*<br />*/}
                                {/*<br />*/}
                                {/*3. CLIENT may print web or high res photos delivered*/}
                                {/*by the PHOTOGRAPHER or downloaded from the photo*/}
                                {/*gallery for their personal use, and: <br />*/}
                                {/*a: must not sell, license, sub-license, transfer, or*/}
                                {/*otherwise distribute the printed photos; <br />*/}
                                {/*b: must not use the printed photos for advertising*/}
                                {/*or commercial purposes; and <br />*/}
                                {/*c: must not submit or enter the photos into any*/}
                                {/*competition.*/}
                                {/*<br />*/}
                                {/*<br />*/}
                                {/*In consideration of the payment of monies due under*/}
                                {/*this agreement, PHOTOGRAPHER grants CLIENT a*/}
                                {/*perpetual non-exclusive license to print or to*/}
                                {/*reproduce images downloaded from the photo gallery*/}
                                {/*or delivered by the PHOTOGRAPHER as follows:*/}
                                {/*<br />*/}
                                {/*1. CLIENT may share the web or high res photos*/}
                                {/*delivered by the PHOTOGRAPHER on CLIENT’s personal*/}
                                {/*and business social media accounts, provided that in*/}
                                {/*each such post CLIENT must credit/tag PHOTOGRAPHER.*/}
                                {/*<br />*/}
                                {/*<br />*/}
                                {/*2. This license does not permit CLIENT to screenshot*/}
                                {/*poor quality images, or edit or alter photos in any*/}
                                {/*way, or to do any other act which would infringe*/}
                                {/*PHOTOGRAPHER’S moral rights.*/}
                                {/*<br />*/}
                                {/*<br />I confirm that I have read and understood this*/}
                                {/*contract, and I agree to enter into this contract.*/}
                            </p>
                            <div className="flex w-full justify-between mt-7">
                                <div className="flex flex-col items-start gap-1">
                                    {state.sign && (
                                        <div className="h-24 w-24">
                                            <img
                                                alt="sign"
                                                src={state.sign}
                                                className="h-full w-full object-contain"
                                            />
                                        </div>
                                    )}
                                    <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                                        {state?.proposalCreatedAs}
                                    </p>
                                    <p className="text-gray-600 text-[13px] leading-[18px]">
                                        {user?.firstName + ' ' + user?.lastName}
                                    </p>
                                    <p className="text-gray-600 text-[13px] leading-[18px]">
                                        {new Date().toDateString()}
                                    </p>
                                    <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200">
                                        {state?.sign
                                            ? 'Signed'
                                            : ' Sign Pending'}
                                    </p>
                                </div>
                                <div className="flex flex-col gap-1 items-end">
                                    <p className="text-zinc-500 text-[14px] font-TTHovesM tracking-wider">
                                        {state.proposalCreatedAs ===
                                        'Photographer'
                                            ? 'Client'
                                            : 'Photographer'}
                                    </p>
                                    {state.proposalCreatedAs === 'Photographer'
                                        ? findClient.map(item => (
                                              <p
                                                  key={item._id}
                                                  className="text-gray-600 text-[13px] leading-[18px]">
                                                  {item?.firstName +
                                                      ' ' +
                                                      item?.lastName}
                                              </p>
                                          ))
                                        : findPhtographer.map(item => (
                                              <p
                                                  key={item._id}
                                                  className="text-gray-600 text-[13px] leading-[18px]">
                                                  {item?.firstName +
                                                      ' ' +
                                                      item?.lastName}
                                              </p>
                                          ))}

                                    <p className="text-gray-600 text-[13px] leading-[18px]">
                                        {new Date().toDateString()}
                                    </p>
                                    <p className="text-slate-700 text-xs px-2 py-0.5 bg-gray-50 rounded-md border border-zinc-200 justify-start items-center inline-flex">
                                        Sign Pending
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    )
}
