import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Add,
    ArrowRight2,
    DocumentText,
    Home2,
    RefreshCircle,
    SearchNormal,
} from 'iconsax-react'
import { Link, useNavigate } from 'react-router-dom'
import { BiLinkExternal, FilterIcon } from '../../css/icons/icons'
import UserService from 'services/user.service'
import { LocationSelect } from 'components/form/LocationSelect'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useSession } from 'hooks/useSession'
import { Button } from 'components/form/Button/Button'
import { Modal } from 'components/common/Modal'
import { Input } from 'components/form/Input/Input'
import { TextArea } from 'components/form/Input/TextArea'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()

export const BrowseJobs = () => {
    const [data, setData] = useState([])
    const [sendQuoteModal, setQuoteModal] = useState(false)
    const [selected, setSelected] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [visibleJobs, setVisibleJobs] = useState(10)
    const [state, setState] = useState({
        price: '',
        message: '',
    })
    const [filters, setFilters] = useState({
        location: '',
        session: '',
    })

    const loadMore = () => {
        setVisibleJobs(prev => prev + 10)
    }

    const { sessions } = useSession()
    const { successMessage } = useNotifications()
    const user = useSelector(userSelector)

    const navigate = useNavigate()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllQuotes()
            .then(resp => {
                setData(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.date).getTime()
                        const dateB = new Date(a.date).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    const handleFilter = () => {
        service
            .filterJobs(filters)
            .then(resp => {
                setData(resp?.data?.data)
            })
            .catch(error => {
                console.log(error)
                setData([])
            })
    }

    const handleSendQuote = e => {
        e.preventDefault()

        service
            .sendQuote({ ...state, quoteId: selected._id })
            .then(resp => {
                successMessage('Quotation sent successfully')
                setQuoteModal(false)
                setState({
                    price: '',
                    message: '',
                })
                setSelected(null)
            })
            .catch(error => {
                console.log(error)
            })
    }

    console.log(data)

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="items-center gap-2 flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Browse Jobs
                    </div>
                </div>
                <Link
                    to="/dashboard/add-quote"
                    className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    <Add className="text-white h-5 w-5" />
                    <span className="text-white text-sm font-TTHovesM">
                        Create Job Quote
                    </span>
                </Link>
            </div>
            {/*{selected && (*/}
            {/*    <div className="mt-4 bg-white rounded-xl border border-zinc-200 p-5">*/}
            {/*        <div className="flex flex-col gap-3">*/}
            {/*            <div className="flex items-center justify-between">*/}
            {/*                <div className="flex items-center gap-3">*/}
            {/*                    <div className="h-8 w-8 rounded-full overflow-hidden">*/}
            {/*                        <img*/}
            {/*                            alt="profile"*/}
            {/*                            src="/assets/homepage-photographer-2.jpg"*/}
            {/*                            className="h-full w-full rounded-full"*/}
            {/*                        />*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <span className="text-zinc-600 text-[15px]">*/}
            {/*                            Posted by{' '}*/}
            {/*                        </span>*/}
            {/*                        <span className="text-zinc-900 text-[15px] font-TTHovesM">*/}
            {/*                            {selected?.user?.firstName +*/}
            {/*                                ' ' +*/}
            {/*                                selected?.user?.lastName}*/}
            {/*                        </span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <button*/}
            {/*                    type="button"*/}
            {/*                    onClick={() => setQuoteModal(true)}*/}
            {/*                    className="px-[18px] py-[7px] rounded-md inline-flex bg-tale-10 text-white text-sm font-TTHovesM">*/}
            {/*                    Send Quote*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*            <div className="flex flex-col items-start gap-1">*/}
            {/*                <p className=" text-slate-800 text-lg font-TTHovesM">*/}
            {/*                    {selected?.title}*/}
            {/*                </p>*/}
            {/*                <div*/}
            {/*                    dangerouslySetInnerHTML={{*/}
            {/*                        __html: selected?.description,*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                <button className="px-[18px] py-[7px]  bg-zinc-50 rounded-md border border-zinc-200 flex text-slate-700 text-sm font-TTHovesM">*/}
            {/*                    {selected?.serviceType?.name}*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*            <div className="flex flex-col gap-6 max-w-6xl">*/}
            {/*                <div className="grid grid-cols-3">*/}
            {/*                    <div className="place-self-start">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Location*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            {selected?.locationString}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="self-start">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Budget*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            ${selected?.budget}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Dated Posted*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            Sat 12 Aug 2023*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="grid grid-cols-3">*/}
            {/*                    <div className="">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Shoot Date*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            {new Date(*/}
            {/*                                selected?.duration,*/}
            {/*                            ).toDateString()}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Estimated Shoot Time*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            {selected?.shootTime}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="">*/}
            {/*                        <div className="text-zinc-600 text-[13px] ">*/}
            {/*                            Quotes Received*/}
            {/*                        </div>*/}
            {/*                        <div className="text-slate-800 text-sm font-TTHovesM">*/}
            {/*                            0 / {selected?.quoteLimit}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            <div className="mt-4 bg-white rounded-xl border border-zinc-200 p-5">
                <div className="flex items-center justify-between border-b border-zinc-200 pb-5">
                    <div className="">
                        <p className="text-zinc-900 text-lg font-TTHovesM">
                            Search for Jobs
                        </p>
                        <p className="text-slate-600 text-sm leading-tight">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                    </div>
                    {(filters.location || filters.session) && (
                        <button
                            type="button"
                            onClick={() => {
                                setFilters({
                                    location: '',
                                    session: '',
                                })

                                fetchData()
                            }}
                            className="pl-3 pr-[22px] py-2.5 bg-white rounded-lg shadow border border-zinc-300 justify-center items-end gap-3.5 inline-flex">
                            <FilterIcon />
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                Clear Filter
                            </span>
                        </button>
                    )}
                </div>

                <div className="flex-col flex md:flex-row mt-4 gap-2 md:gap-4 md:items-center">
                    <div className="flex-1">
                        <LocationSelect
                            value={filters.location}
                            onChange={value =>
                                setFilters({
                                    ...filters,
                                    location: value,
                                })
                            }
                        />
                    </div>
                    <div className="flex-1 mt-4 md:mt-0">
                        <ReactSelect
                            label="Session Type"
                            value={filters.session}
                            onChange={value =>
                                setFilters({
                                    ...filters,
                                    session: value,
                                })
                            }
                            options={sessions.map(item => ({
                                value: item._id,
                                label: item.name,
                            }))}
                            placeholder={'All Sessions'}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={handleFilter}
                        className="p-4 bg-tale-10 mt-4 md:mt-0 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <SearchNormal className="text-white h-5 w-5" />
                        <span className="text-white text-sm font-TTHovesM">
                            Search
                        </span>
                    </button>
                </div>

                <div className="flex items-start gap-3">
                    <div className="w-full ">
                        {loading && data?.length === 0 && (
                            <RefreshCircle className="mx-auto mt-6 size-10 animate-spin text-tale-10" />
                        )}
                        {data.slice(0, visibleJobs).map((item: any) => (
                            <div
                                key={item._id}
                                className="border border-zinc-200 rounded-lg mt-6">
                                <div className="flex flex-col gap-3 p-5">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <div className="h-8 w-8 rounded-full overflow-hidden">
                                                <img
                                                    alt="profile"
                                                    src={
                                                        item?.user?.profileImage
                                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item?.user?.profileImage}`
                                                            : '/assets/images/blank.webp'
                                                    }
                                                    className="h-full w-full rounded-full"
                                                />
                                            </div>
                                            <div>
                                                <span className="text-zinc-600 text-[15px]">
                                                    Posted by{' '}
                                                </span>
                                                <span className="text-zinc-900 text-[15px] font-TTHovesM">
                                                    {item?.user?.firstName +
                                                        ' ' +
                                                        item?.user?.lastName}
                                                </span>
                                            </div>
                                        </div>
                                        <button className="px-[18px] py-[7px] bg-zinc-50 rounded-md border border-zinc-200  inline-flex text-slate-700 text-sm font-TTHovesM">
                                            {item?.serviceType?.name}
                                        </button>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p
                                            onClick={() => setSelected(item)}
                                            className="cursor-pointer hover:text-tale-10 text-slate-800 text-lg font-TTHovesM">
                                            {item?.title}
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item?.description,
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-6">
                                        <div className="grid grid-cols-3">
                                            <div className="place-self-start">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Location
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {item?.locationString}
                                                </div>
                                            </div>
                                            <div className="self-start">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Budget
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {item?.user?.currencySymbol}{' '}
                                                    {item?.budget}{' '}
                                                    {item?.rate === 'Hourly' &&
                                                        '/hr'}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Dated Posted
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {new Date(
                                                        item?.date,
                                                    ).toDateString()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Shoot Date
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {new Date(
                                                        item?.duration,
                                                    ).toDateString()}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Estimated Shoot Time
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {item?.shootTime}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="text-zinc-600 text-[13px] ">
                                                    Quotes Received
                                                </div>
                                                <div className="text-slate-800 text-sm font-TTHovesM">
                                                    {item?.quotesReceived} /{' '}
                                                    {item?.quoteLimit}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-t border-zinc-200 p-5 mt-3 flex gap-8 md:gap-16">
                                    <button
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/quote/${item?._id}`,
                                            )
                                        }}
                                        className="flex items-center  gap-3">
                                        <DocumentText className="text-tale-10" />
                                        <span className="text-zinc-600 text-[15px] font-TTHovesM">
                                            View Quote
                                        </span>
                                    </button>
                                    {user?.userType === 'Photographer' && (
                                        <>
                                            {item?.quoteSentUsers
                                                .map(item => item.userId)
                                                .includes(user?._id) ? (
                                                <span className="text-zinc-600 text-[15px] font-TTHovesM">
                                                    Applied
                                                </span>
                                            ) : item?.user?._id !==
                                              user?._id ? (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setQuoteModal(true)
                                                        setSelected(item)
                                                    }}
                                                    className="flex items-center gap-3">
                                                    <BiLinkExternal className="w-6 text-tale-10" />
                                                    <span className="text-zinc-600 text-[15px] font-TTHovesM">
                                                        Apply For Job
                                                    </span>
                                                </button>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                        {!loading && data?.length === 0 && (
                            <p className="text-lg text-center p-4 font-semibold">
                                No Jobs Found
                            </p>
                        )}
                        {visibleJobs < data.length && (
                            <Button
                                onClick={() => loadMore()}
                                container="mx-auto"
                                className="bg-brown-10 mt-4  w-56 text-white border-0"
                                type="button">
                                Load More
                            </Button>
                        )}
                    </div>

                    <div className="hidden md:block w-[350px] mt-6  bg-violet-200 rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-500 text-xs font-TTHovesM">
                            WE'RE HERE TO HELP
                        </p>
                        <div className="mt-2 text-zinc-800 font-TTHovesM underline leading-tight">
                            Game Plan for Getting Clients
                        </div>
                        <p className="mt-2 text-zinc-800 font-TTHovesM underline leading-tight">
                            How to Write a Client Proposal
                        </p>
                        <div className="mt-4 text-zinc-500 text-xs font-TTHovesM">
                            Check out
                            <span className="text-slate-800"> our blog </span>
                            for more tips
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                showModal={sendQuoteModal}
                setShowModal={setQuoteModal}
                title="Apply for job"
                width="w-full md:w-1/3"
                footerButton="Apply"
                handleClickPrimary={handleSendQuote}
                headerIcon="">
                <div className="p-5 flex flex-col gap-5">
                    <div className="grid grid-cols-3 gap-16">
                        <div className="">
                            <div className="text-zinc-600 text-[13px] ">
                                Location
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                {selected?.locationString}
                            </div>
                        </div>
                        <div className="justify-start ">
                            <div className="text-zinc-600 text-[13px] ">
                                Budget
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                {selected?.user?.currencySymbol}{' '}
                                {selected?.budget}{' '}
                                {selected?.rate === 'Hourly' && '/hr'}
                            </div>
                        </div>
                        <div className="">
                            <div className="text-zinc-600 text-[13px] ">
                                Dated Posted
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                {new Date(selected?.date).toDateString()}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-16">
                        <div className="">
                            <div className="text-zinc-600 text-[13px] ">
                                Shoot Date
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                {new Date(selected?.duration).toDateString()}
                            </div>
                        </div>
                        <div className="">
                            <div className="text-zinc-600 text-[13px] ">
                                Estimated Shoot Time
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                {selected?.shootTime}
                            </div>
                        </div>
                        <div className="">
                            <div className="text-zinc-600 text-[13px] ">
                                Quotes Received
                            </div>
                            <div className="text-slate-800 text-sm font-TTHovesM">
                                0 / {selected?.quoteLimit}
                            </div>
                        </div>
                    </div>
                    <hr className="text-zinc-200" />
                    <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                        <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                            Quote Price*
                        </label>
                        <Input
                            type="number"
                            onChange={e => {
                                setState({
                                    ...state,
                                    price: e.target.value,
                                })
                            }}
                            value={state.price}
                            placeholder="$ | 0.0"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                        <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                            Your Message
                        </label>
                        <TextArea
                            onChange={e => {
                                setState({
                                    ...state,
                                    message: e,
                                })
                            }}
                            value={state.message}
                        />
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}
