import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export interface ICollaboration {
    _id: string
    name: string
    selected: boolean
}
export const useCollaborations = () => {
    const [collaborations, setCollaborations] = useState<ICollaboration[]>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllCollaborations()
            .then(resp => {
                setCollaborations(
                    resp.data.data.map(s => ({ ...s, selected: false })),
                )
            })
            .catch(error => {
                console.log('Collab Error: ', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setCollaborations,
        collaborations,
    }
}
