/* eslint-disable prettier/prettier */
import { LocationSelect } from 'components/form/LocationSelect'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useSession } from 'hooks/useSession'
import React, { memo, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    FiltersInterFace,
    initFilter,
} from 'utils/interfaces/invitePhotographers'

const Filters: React.FC = () => {
    const [searchParams] = useSearchParams()
    const paramsObject = Object.fromEntries(searchParams.entries())
    const navigate = useNavigate()
    const params = useParams()
    const { sessions } = useSession()
    const [filter, setFilter] = useState<FiltersInterFace>({
        ...initFilter,
        ...paramsObject,
    })

    const navigateTo = (filterData: any) => {
        const { session, location, startDate, endDate } = filterData

        const isSession = session ? `&session=${session}` : ''
        const isLocation = location ? `&location=${location}` : ''
        const isDate = endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''

        const endPoint = `/dashboard/quote/invite-photographers/${params?.quoteId}?${isSession}${isLocation}${isDate}`

        navigate(endPoint)
    }

    const onChange = (name: string, value: string) => {
        const newFilter: any = { ...filter }
        newFilter[name] = value

        navigateTo(newFilter)
        setFilter(newFilter)
    }

    const onChangeLocation = (name: string, value: string) => {
        const newFilter: any = { ...filter }
        newFilter[name] = value

        setFilter(newFilter)
    }

    const onChangeDate = (dates) => {
        const [start, end] = dates
        const newFilter = { ...filter }

        newFilter.startDate = start
        newFilter.endDate = end

        navigateTo(newFilter)
        setFilter(newFilter)
    }

    return (
        <div className="grid grid-cols-4 gap-4 mt-6 mb-5">
            <ReactSelect
                label="Session Type"
                value={filter.session}
                bgLabel
                onChange={value => onChange('session', value)}
                options={sessions.map(item => ({
                    value: item._id,
                    label: item.name,
                }))}
                isMulti
                placeholder={'All Sessions'}
            />

            <LocationSelect
                label="Location"
                value={filter.location}
                onSelect={value => onChange('location', value)}
                bgLabel
                onChange={e => onChangeLocation('location', e)}
            />

            <div className="relative grid">
                <label className="absolute -top-3 left-4 bg-white text-gray z-10">
                    Date
                </label>

                <DatePicker
                    selected={searchParams.get('startDate') || filter.startDate}
                    placeholderText="Pick a date"
                    onChange={(dates, e) => onChangeDate(dates)}
                    startDate={searchParams.get('startDate') || filter.startDate}
                    endDate={searchParams.get('endDate') || filter.endDate}
                    minDate={new Date()}
                    customInput={
                        <input
                            placeholder="Select date"
                            className="w-full h-14 bg-transparent rounded-xl border border-gray-outline outline-none focus-visible:shadow-none shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
                        />
                    }
                    selectsRange
                    selectsDisabledDaysInRange
                />
            </div>
        </div>
    )
}

export default memo(Filters)
