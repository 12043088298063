import { useNavigate } from 'react-router-dom'

export const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <p className="text-6xl font-TTHovesM text-zinc-400 pt-10">
                404 Not Found
            </p>
            <button
                type="button"
                className="shadow-sm bg-white border border-zinc-200 rounded-lg font-TTHovesM text-zinc-700 px-6 py-2 mt-5 w-40"
                onClick={() => navigate(-1)}>
                Go Back
            </button>
        </div>
    )
}
