import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { ArrowRight2, Home2 } from 'iconsax-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { styles } from 'pages/dashboard/Settings'
import { BiLinkExternal, CheckIcon } from '../../../css/icons/icons'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { Input } from 'components/form/Input/Input'
import { LocationSelect } from 'components/form/LocationSelect'
import { SignContract } from 'components/dashboard/business/SignContract'
import { useContracts } from 'hooks/useContracts'
import { useSession } from 'hooks/useSession'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { AddClientModal } from 'components/dashboard/business/AddClientModal'
import { AddContractModal } from 'components/dashboard/business/AddContractModal'
import { usePhotographers } from 'hooks/usePhotographers'
import { useNotifications } from 'hooks/useNotification'
import UserService from 'services/user.service'
import { UserSelect } from 'components/form/UserSelect'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Times } from 'utils/constants'
const service = new UserService()

export const NewPhotoshoot = () => {
    const [current, setCurrent] = useState('photographer')
    const [proposal, setProposl] = useState(false)
    const [shootError, setShootError] = useState('')
    const [contractModal, setContractModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [proposalId, setProposalId] = useState('')
    const [error, setError] = useState('')
    const [clients, setClients] = useState<any>([])

    const [params] = useSearchParams()
    const [allClients, setAllClients] = useState<any>([])

    // const [selectedTime, setSelectedTime] = useState(null)

    const user = useSelector(userSelector)

    useEffect(() => {
        service
            .getRecentClients()
            .then(resp => {
                setClients(resp.data.recentClients)
            })
            .catch(error => {
                console.log(error)
            })

        service
            .getAllClients()
            .then(resp => {
                setAllClients(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const [state, setState] = useState<any>({
        proposalCreatedBy: user?._id, //user id
        proposalCreatedAs:
            user?.userType === 'Photographer' ? 'Photographer' : 'Client', //Photographer, Client
        clients: [''],
        photographers: params?.get('id') ? [params.get('id')] : [''],
        photoshootTitle: '',
        shootDuration: '',
        shootType: '', //session id
        contract: '',
        locationString: '',
        feeStructure: 'Flat Fee', //Flat Fee, Hourly Fee
        totalFee: '', //currency symbol - amount
        shootDate: '', //yyyy-mm-dd
        shootTime: '',
    })

    // const { clients, setClients } = useClients()
    const { contracts, fetchData } = useContracts()
    const { photographers } = usePhotographers()
    const { sessions } = useSession()
    const { successMessage } = useNotifications()
    const navigate = useNavigate()

    const removeClient = idx => {
        const newState = [...state.clients]
        newState.splice(idx, 1)
        setState({
            ...state,
            clients: newState,
        })
    }

    const removePhotographer = idx => {
        const newState = [...state.photographers]
        newState.splice(idx, 1)
        setState({
            ...state,
            photographers: newState,
        })
    }

    const handleClientChange = (id: string, index: number) => {
        const values = { ...state }
        state.clients[index] = id
        setState(values)
    }

    const handlePhotographerChange = (id: string, index: number) => {
        const values = { ...state }
        state.photographers[index] = id
        setState(values)
    }

    const handleAdd = () => {
        setLoading(true)
        if (!state.photoshootTitle) {
            setError('Please enter shoot title')
            return
        }

        if (!state.shootDuration) {
            setShootError('Please select shoot duration')
            return
        }

        if (state.proposalCreatedAs === 'Photographer') {
            delete state.photographers
        } else {
            delete state.clients
        }

        service
            .addPhotoShoot({
                ...state,
                totalFee: `${user?.currencySymbol} - ${state.totalFee}`,
            })
            .then(resp => {
                successMessage('Proposal added successfully')
                setProposalId(resp.data.data._id)
                setLoading(false)
                if (state?.proposalCreatedAs === 'Client') {
                    navigate('/dashboard/photoshoot/requests')
                } else {
                    setProposl(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    // const convertTo12HourFormat = inputValue => {
    //     const [hours, minutes] = inputValue.split(':')
    //
    //     let twelveHourFormat: string
    //     if (hours >= 12) {
    //         if (hours > 12) {
    //             twelveHourFormat = `${hours - 12}:${minutes} PM`
    //         } else {
    //             twelveHourFormat = `${hours}:${minutes} PM`
    //         }
    //     } else {
    //         if (hours === '00') {
    //             twelveHourFormat = `12:${minutes} AM`
    //         } else {
    //             twelveHourFormat = `${hours}:${minutes} AM`
    //         }
    //     }
    //
    //     return twelveHourFormat
    // }

    const disableButton =
        !state.photoshootTitle ||
        !state.contract ||
        !state.shootType ||
        !state.shootTime ||
        !state.shootDate ||
        !state.totalFee

    return proposal && !loading ? (
        <SignContract
            state={state}
            setState={setState}
            setProposal={setProposl}
            proposalId={proposalId}
        />
    ) : (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Photoshoots
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        New Photoshoot
                    </div>
                </div>
                <div className="justify-start items-center gap-3 flex">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={classNames(styles.default)}>
                        <span className="text-sm font-TTHovesM leading-tight">
                            Back
                        </span>
                    </button>
                    {state?.proposalCreatedAs === 'Photographer' ? (
                        <button
                            type="submit"
                            onClick={() => handleAdd()}
                            disabled={disableButton}
                            className={classNames(styles.primary)}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                Continue to Sign
                            </span>
                        </button>
                    ) : (
                        <button
                            type="submit"
                            onClick={() => handleAdd()}
                            disabled={
                                !state.photoshootTitle || !state.shootType
                            }
                            className={classNames(styles.primary)}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                Send Proposal
                            </span>
                        </button>
                    )}
                </div>
            </div>
            <div className="border-b border-zinc-200 pb-5 flex flex-col mt-5">
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    New Shoot Proposal
                </p>
                <p className="text-slate-600 text-sm leading-tight">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                </p>
            </div>

            <div className="w-full">
                <div className="flex flex-col md:flex-row items-start mt-4 gap-6">
                    <div className="w-full flex-1">
                        {/* Checkbox */}
                        {user?.userType === 'Photographer' && (
                            <div className="flex flex-col md:flex-row gap-2 flex-1">
                                <div
                                    onClick={() => {
                                        setCurrent('photographer')
                                        setState({
                                            ...state,
                                            proposalCreatedAs: 'Photographer',
                                        })
                                    }}
                                    className={classNames(
                                        'cursor-pointer flex-1 rounded-xl p-5',
                                        {
                                            'bg-slate-200':
                                                current === 'photographer',
                                            'bg-white shadow-sm border border-zinc-300':
                                                current !== 'photographer',
                                        },
                                    )}>
                                    <div className="items-center flex justify-between gap-8">
                                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                                            <p className="text-zinc-500 uppercase text-[10px] font-TTHovesM leading-none tracking-wider">
                                                Photographer
                                            </p>
                                            <p className="text-gray-600 text-[15px] font-TTHovesM">
                                                Creating proposal as
                                                photographer for a client
                                            </p>
                                        </div>
                                        {current === 'photographer' && (
                                            <CheckIcon />
                                        )}
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setCurrent('client')
                                        setState({
                                            ...state,
                                            proposalCreatedAs: 'Client',
                                        })
                                    }}
                                    className={classNames(
                                        'cursor-pointer flex-1 rounded-xl p-5',
                                        {
                                            'bg-slate-200':
                                                current === 'client',
                                            'bg-white shadow-sm border border-zinc-300':
                                                current !== 'client',
                                        },
                                    )}>
                                    <div className="items-center flex justify-between gap-8">
                                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                                            <div className="text-zinc-500 text-[10px] font-TTHovesM leading-none tracking-wider">
                                                CLIENT
                                            </div>
                                            <p className="text-gray-600 text-[15px] font-TTHovesM leading-[18px]">
                                                I’m a client and seeking a
                                                professional photographer
                                            </p>
                                        </div>
                                        {current === 'client' && <CheckIcon />}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Checkbox End */}
                        <p className="text-zinc-900 text-lg font-TTHovesM py-5">
                            {state.proposalCreatedAs === 'Photographer'
                                ? 'Client'
                                : 'Photographer'}{' '}
                            Details
                        </p>
                        {/* Client Details */}
                        {state.proposalCreatedAs === 'Photographer' ? (
                            <div className="flex flex-col md:flex-row items-start gap-4">
                                <div className="flex-1 w-full flex flex-col gap-5">
                                    {state?.clients?.map((client, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2">
                                            <div className="w-full">
                                                <UserSelect
                                                    key={client}
                                                    label="Choose Client"
                                                    isClient
                                                    value={client}
                                                    onChange={value =>
                                                        handleClientChange(
                                                            value,
                                                            index,
                                                        )
                                                    }
                                                    hideSelectedOption
                                                    options={allClients?.map(
                                                        item => ({
                                                            ...item,
                                                            value: item?._id,
                                                            label: `${item?.firstName} ${item?.lastName}`,
                                                        }),
                                                    )}
                                                    placeholder="Select"
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        removeClient(index)
                                                    }
                                                    className="text-red font-bold">
                                                    X
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <button
                                    onClick={() => setModal(true)}
                                    className="p-4 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                                    <BiLinkExternal className="h-5 w-5 text-tale-10" />
                                    <span className="text-slate-700 text-sm font-TTHovesM">
                                        Create New Client
                                    </span>
                                </button>
                            </div>
                        ) : (
                            <div className="flex flex-col md:flex-row items-start gap-4">
                                <div className="flex-1 flex flex-col gap-5">
                                    {state?.photographers?.map(
                                        (photo, index) => (
                                            <div
                                                key={index}
                                                className="flex w-full items-center gap-2">
                                                <div className="w-full">
                                                    <UserSelect
                                                        key={photo}
                                                        label="Select Photographer"
                                                        value={photo}
                                                        onChange={value =>
                                                            handlePhotographerChange(
                                                                value,
                                                                index,
                                                            )
                                                        }
                                                        hideSelectedOption
                                                        options={photographers?.map(
                                                            item => ({
                                                                ...item,
                                                                value: item?._id,
                                                                label: `${item?.firstName} ${item?.lastName}`,
                                                            }),
                                                        )}
                                                        placeholder="Select"
                                                    />
                                                </div>
                                                {index !== 0 && (
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            removePhotographer(
                                                                index,
                                                            )
                                                        }
                                                        className="text-red font-bold">
                                                        X
                                                    </button>
                                                )}
                                            </div>
                                        ),
                                    )}
                                </div>
                                <button className="p-4 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                                    <BiLinkExternal className="h-5 w-5 text-tale-10" />
                                    <span className="text-slate-700 text-sm font-TTHovesM">
                                        Invite Photographer
                                    </span>
                                </button>
                            </div>
                        )}
                        {/*<div className="mt-4 px-4 py-2.5 rounded-lg text-zinc-700 border border-zinc-300 justify-center items-center gap-2 inline-flex">*/}
                        {/*    <AddSquare className="h-5 w-5" />*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        onClick={() => {*/}
                        {/*            if (*/}
                        {/*                state.proposalCreatedAs ===*/}
                        {/*                'Photographer'*/}
                        {/*            ) {*/}
                        {/*                setState({*/}
                        {/*                    ...state,*/}
                        {/*                    clients: [...state.clients, ''],*/}
                        {/*                })*/}
                        {/*            } else {*/}
                        {/*                setState({*/}
                        {/*                    ...state,*/}
                        {/*                    photographers: [*/}
                        {/*                        ...state.photographers,*/}
                        {/*                        '',*/}
                        {/*                    ],*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        className="text-sm font-TTHovesSB">*/}
                        {/*        {state.proposalCreatedAs === 'Photographer'*/}
                        {/*            ? 'Add another Client'*/}
                        {/*            : 'Add another Photographer'}*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/* Client Details End */}
                    </div>
                    {user?.userType === 'Photographer' ? (
                        <div className="w-96 flex flex-col gap-2">
                            <div className="flex-1 hidden md:block bg-violet-200 shadow-sm rounded-xl border border-zinc-200 p-5">
                                <div className="text-zinc-700 text-lg font-TTHovesM">
                                    Invite Clients
                                </div>
                                <p className="text-gray-600 text-[15px]  leading-tight">
                                    Invite a client via email or select from an
                                    existing client list. We’ll notify them when
                                    the proposal is create
                                </p>
                            </div>
                            <div className="flex-1 hidden md:block bg-white rounded-xl shadow-sm border border-zinc-300 p-5">
                                <p className="text-gray-600 text-[15px]  leading-tight">
                                    If this new person becomes your client by
                                    accepting your proposal, you will be charged{' '}
                                    <span className="font-TTHovesM" /> from the
                                    total pricing.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="w-96 flex flex-col gap-2" />
                    )}
                </div>
                <hr className="text-zinc-200 my-4" />

                <div className="flex gap-6">
                    {/* Shoot Details */}
                    <div className="flex-1">
                        <p className="text-zinc-900 text-lg font-TTHovesM pb-5">
                            Shoot Details
                        </p>
                        <div className="flex gap-5">
                            <Input
                                type="text"
                                placeholder="Enter Shoot Title..."
                                className="flex-1"
                                value={state.photoshootTitle}
                                error={error}
                                onChange={e => {
                                    setState({
                                        ...state,
                                        photoshootTitle: e.target.value,
                                    })
                                    setError('')
                                }}
                                onBlur={e => {
                                    if (!e.target.value) {
                                        setError('Please enter shoot title')
                                    }
                                }}
                                label="Shoot Title"
                            />
                            <div className="flex-1">
                                <ReactSelect
                                    placeholder="Shoot duration..."
                                    value={state.shootDuration}
                                    error={shootError}
                                    options={[
                                        {
                                            value: '1 to 2 hours',
                                            label: '1 to 2 Hours',
                                        },
                                        {
                                            value: '3 to 4 hours',
                                            label: '3 to 4 Hours',
                                        },
                                        {
                                            value: '5 to 6 hours',
                                            label: '5 to 6 Hours',
                                        },
                                    ]}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            shootDuration: e,
                                        })
                                        setShootError('')
                                    }}
                                    label="Duration"
                                />
                            </div>
                        </div>
                        <div
                            className={`flex flex-col md:flex-row items-start md:items-center gap-4 my-6 ${
                                error && 'mt-10'
                            }`}>
                            <div className="w-full md:w-none flex-1">
                                <ReactSelect
                                    label="Shoot type"
                                    value={state.shootType}
                                    onChange={value =>
                                        setState({
                                            ...state,
                                            shootType: value,
                                        })
                                    }
                                    options={sessions?.map(item => ({
                                        value: item._id,
                                        label: item.name,
                                    }))}
                                    placeholder="Select"
                                />
                            </div>
                            <button className="p-4 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                                <BiLinkExternal className="h-5 w-5 text-tale-10" />
                                <span className="text-slate-700 text-sm font-TTHovesM">
                                    New Shoot Template
                                </span>
                            </button>
                        </div>
                        <LocationSelect
                            value={state.locationString}
                            label="Shoot location"
                            onChange={value =>
                                setState({
                                    ...state,
                                    locationString: value,
                                })
                            }
                        />
                        {state?.proposalCreatedAs === 'Photographer' && (
                            <div className="flex items-center gap-4 my-6">
                                <div className="flex-1">
                                    <ReactSelect
                                        label="Contract"
                                        value={state.contract}
                                        onChange={value =>
                                            setState({
                                                ...state,
                                                contract: value,
                                            })
                                        }
                                        options={contracts.map(item => ({
                                            value: item._id,
                                            label: item.title,
                                        }))}
                                        placeholder="Select"
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setContractModal(true)}
                                    className="p-4 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                                    <BiLinkExternal className="h-5 w-5 text-tale-10" />
                                    <span className="text-slate-700 text-sm font-TTHovesM">
                                        New Contract
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* End Shoot Details */}
                    <div className="hidden md:block w-96" />
                </div>
                <hr className="text-zinc-200 mb-4" />
                <div className="flex gap-6">
                    {/* Payments Details */}
                    <div className="flex-1">
                        <p className="text-zinc-900 text-lg font-TTHovesM">
                            Payment & timeline
                        </p>
                        <div className="text-slate-600 text-sm">
                            Choose the amount and frequency of payments for this
                            shoot
                        </div>
                        <div className="flex items-center gap-4 my-6">
                            <div className="flex-1">
                                <ReactSelect
                                    label="Fee Structure*"
                                    value={state.feeStructure}
                                    onChange={value =>
                                        setState({
                                            ...state,
                                            feeStructure: value,
                                        })
                                    }
                                    options={[
                                        {
                                            label: 'Flat Fee',
                                            value: 'Flat Fee',
                                        },
                                        {
                                            label: 'Hourly Rate',
                                            value: 'Hourly Fee',
                                        },
                                    ]}
                                    placeholder="Select"
                                />
                            </div>
                            <div className="flex-1">
                                <Input
                                    type="number"
                                    value={state.totalFee}
                                    label={`${state.feeStructure}*`}
                                    onChange={e =>
                                        setState({
                                            ...state,
                                            totalFee: e.target.value,
                                        })
                                    }
                                    placeholder="$ 0.0"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-4 my-6">
                            <div className="flex-1">
                                <Input
                                    type="date"
                                    value={state.shootDate}
                                    min={new Date().toISOString().split('T')[0]}
                                    label="Shoot Date*"
                                    onChange={e =>
                                        setState({
                                            ...state,
                                            shootDate: e.target.value,
                                        })
                                    }
                                    placeholder=""
                                />
                            </div>
                            <div className="flex-1">
                                {/*<ReactSelect*/}
                                {/*    options={[*/}
                                {/*        {*/}
                                {/*            value: '1 to 2 hours',*/}
                                {/*            label: '1 to 2 Hours',*/}
                                {/*        },*/}
                                {/*        {*/}
                                {/*            value: '3 to 4 hours',*/}
                                {/*            label: '3 to 4 Hours',*/}
                                {/*        },*/}
                                {/*        {*/}
                                {/*            value: '5 to 6 hours',*/}
                                {/*            label: '5 to 6 Hours',*/}
                                {/*        },*/}
                                {/*    ]}*/}
                                {/*    onChange={value =>*/}
                                {/*        setState({*/}
                                {/*            ...state,*/}
                                {/*            shootTime: value,*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*    value={state.shootTime}*/}
                                {/*    label="Shoot Time"*/}
                                {/*    placeholder="Select shoot time"*/}
                                {/*/>*/}
                                {/*<Input*/}
                                {/*    type="time"*/}
                                {/*    // value={state.shootTime}*/}
                                {/*    label="Shoot Time"*/}
                                {/*    onChange={e =>*/}
                                {/*        setState({*/}
                                {/*            ...state,*/}
                                {/*            shootTime: convertTo12HourFormat(*/}
                                {/*                e.target.value,*/}
                                {/*            ),*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*    placeholder="00:00"*/}
                                {/*/>*/}

                                <div className="relative">
                                    <ReactSelect
                                        label="Shoot time*"
                                        options={Times}
                                        placeholder="Select shoot time"
                                        onChange={value =>
                                            setState({
                                                ...state,
                                                shootTime: value,
                                            })
                                        }
                                        value={state.shootTime}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Shoot Details */}
                    <div className="hidden md:block w-96" />
                    <AddClientModal
                        showModal={modal}
                        setData={setClients}
                        setShowModal={setModal}
                        data={clients}
                    />
                    <AddContractModal
                        fetchData={fetchData}
                        showModal={contractModal}
                        setShowModal={setContractModal}
                    />
                </div>
            </div>
        </Fragment>
    )
}
