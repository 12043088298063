import React from 'react'

export const ToggleButton = ({ id, value, onChange }) => {
    return (
        <label
            htmlFor={id}
            className="inline-flex relative items-center cursor-pointer">
            <input
                type="checkbox"
                value={value}
                onChange={onChange}
                id={id}
                checked={value}
                className="sr-only peer"
            />
            <div className="w-11 h-6 bg-zinc-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brown-10" />
        </label>
    )
}
