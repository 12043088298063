import { ArrowRight2, Home2 } from 'iconsax-react'
import { FavouriteButton } from 'components/landing/photographer/FavouriteButton'

export const PhotoLibrary = () => {
    return (
        <div className="flex flex-col">
            <div className="flex items-center gap-2">
                <Home2 className="h-5 w-5" />
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <p className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                    Galleries
                </p>
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <p className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                    Photo Library
                </p>
            </div>
            <div className="border-b border-zinc-200 py-5">
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Photo Library
                </p>
                <p className="text-slate-600 text-sm font-TTHovesL">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6">
                <div className="h-96 md:h-[590px] relative">
                    <img
                        src="/assets/images/library1.jpeg"
                        alt="library"
                        className="h-full w-full object-cover rounded-lg"
                    />
                    <FavouriteButton className="absolute left-4 top-4" />
                </div>
                <div className="h-96 md:h-[590px] relative">
                    <img
                        src="/assets/images/library2.jpeg"
                        alt="library"
                        className="h-full w-full object-cover rounded-lg"
                    />
                    <FavouriteButton className="absolute left-4 top-4" />
                </div>
                <div className="h-96 md:h-[590px] relative">
                    <img
                        src="/assets/images/library3.jpeg"
                        alt="library"
                        className="h-full w-full object-cover rounded-lg"
                    />
                    <FavouriteButton className="absolute left-4 top-4" />
                </div>
                <div className="h-96 md:h-[590px] relative">
                    <img
                        src="/assets/images/library1.jpeg"
                        alt="library"
                        className="h-full w-full object-cover rounded-lg"
                    />
                    <FavouriteButton className="absolute left-4 top-4" />
                </div>
                <div className="h-[590px] relative">
                    <img
                        src="/assets/images/library3.jpeg"
                        alt="library"
                        className="h-full w-full object-cover rounded-lg"
                    />
                    <FavouriteButton className="absolute left-4 top-4" />
                </div>
            </div>
        </div>
    )
}
