import { useState } from 'react'
import { Heart } from 'iconsax-react'
import classNames from 'classnames'

export const FavouriteButton = ({ className = '' }) => {
    const [favourite, setFavourite] = useState(false)

    return (
        <button onClick={() => setFavourite(!favourite)}>
            <Heart
                className={classNames('h-6 w-6', [className], {
                    'text-red': favourite,
                    'text-zinc-500': !favourite,
                })}
                variant={favourite ? 'Bold' : 'Linear'}
            />
        </button>
    )
}
