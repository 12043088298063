import { Eye } from "iconsax-react"
import TableRow from "./Row"

const TableMain = () => {
    return (
        <div className="border border-[#EAECF0] rounded-xl py-[19px] px-4 bg-white">
            <h4 className="text-lg leading-7 text-[#101828 font-TTHovesSB] text-[18px] border-b border-[#e5e5e5] pb-6 mb-6">{'Transaction history'}</h4>

            <div className="flex flex-col gap-4">
                {/* Table Head */}
                <div className="flex py-[15px]">
                    <div className="flex-1"></div> {/* Empty first column */}
                    <div className="flex-1 font-bold text-center text-[#6C6B83]">Date</div>
                    <div className="flex-1 font-bold text-center text-[#6C6B83]">Activity</div>
                    <div className="flex-1 font-bold text-center text-[#6C6B83]">Type</div>
                    <div className="flex-1 font-bold text-center text-[#6C6B83]">Amount</div>
                    <div className="flex-1"></div> {/* Empty last column */}
                </div>

                {/* Table Body (Example Row) */}
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
            </div>
        </div>
    )
}

export default TableMain
