import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useFormik } from 'formik'
import styles from './Auth.module.css'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { LoginValidation } from 'utils/validations'
import { AuthRightSide } from 'components/landing/AuthRightSide'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()
export const VerifyEmail = () => {
    const [authError, setAuthError] = useState('')
    const user = useSelector(userSelector)

    const {
        errors,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        values,
        touched,
    } = useFormik({
        initialValues: {
            email: user.email,
            otp: '',
        },
        validationSchema: LoginValidation,
        onSubmit: values => {
            service.verifyOtp(values).then(resp => {
                console.log(resp)
            })
        },
    })

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerLeft}>
                <Link to="/">
                    <div className="absolute top-4 left-5 md:left-10 z-10">
                        <p className="text-body-bold text-primary text-dark-8 font-OTabolasM z-10">
                            Suura
                        </p>
                    </div>
                </Link>
                <form onSubmit={handleSubmit}>
                    <div className="relative pt-32 bg-gradient-to-br to-gray-200">
                        <div className="xl:px-16">
                            <div className="grid m-5 md:mx-10">
                                <p className="font-normal text-body-caption-bold text-dark-8 font-OTabolasM">
                                    Verify your Email
                                </p>
                                <p className={styles.messageText}>
                                    A verification email has been sent to your
                                    account
                                </p>
                                {authError && (
                                    <div className="mt-3 mb-1 p-4 bg-red/5 rounded-xl shadow-sm border border-red/20 ">
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <p className="text-error text-sm font-TTHovesSB">
                                                {authError}
                                            </p>
                                            <div className="text-error text-sm">
                                                Try again!
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/*<div className="mt-3">*/}
                                {/*    <Input*/}
                                {/*        type="text"*/}
                                {/*        placeholder="Email"*/}
                                {/*        error={touched.email && errors.email}*/}
                                {/*        onChange={handleChange('email')}*/}
                                {/*        onBlur={handleBlur('email')}*/}
                                {/*        value={values.email}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="mt-6">
                                    <Input
                                        type="text"
                                        placeholder="OTP"
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        value=""
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    container="mt-6"
                                    className="disabled:cursor-not-allowed disabled:bg-opacity-70 bg-brown-10 border-brown-10 text-white px-7 h-14 w-full">
                                    {isSubmitting ? 'Please wait...' : 'Verify'}
                                </Button>
                                {/*<div className="text-center mt-2 mb-2">*/}
                                {/*    <p className={styles.messageText}>*/}
                                {/*        By clicking on login you agreed with the*/}
                                {/*        Suura's*/}
                                {/*    </p>*/}
                                {/*    <p className={styles.messageText}>*/}
                                {/*        terms of service & privacy guidelines*/}
                                {/*    </p>*/}
                                {/*    <div className="relative py-4">*/}
                                {/*        <div className="absolute inset-0 flex items-center">*/}
                                {/*            <div className="w-full border-b border-gray" />*/}
                                {/*        </div>*/}
                                {/*        <div className="relative flex justify-center">*/}
                                {/*            <span className="bg-pearl-white px-4 text-md text-gray">*/}
                                {/*                Or log in using*/}
                                {/*            </span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="text-center font-TTHoves">
                                    <p className="text-dark-8 mt-4">
                                        Didn't get OTP ?&nbsp;
                                        <Link
                                            to="/signup"
                                            className="text-tale-10 hover:text-blue-700">
                                            Resend
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <AuthRightSide />
        </div>
    )
}
