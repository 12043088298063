import React, { DetailedHTMLProps } from 'react'

export function useChatScroll<T>(
    dep: T,
): React.MutableRefObject<DetailedHTMLProps<any, any>> {
    const ref = React.useRef<DetailedHTMLProps<any, any>>()
    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
    }, [dep])

    return ref
}
