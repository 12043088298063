import Select, { type DropdownIndicatorProps, components } from 'react-select'
import React, { CSSProperties, FC } from 'react'
import { SelectArrowIcon } from '../../../css/icons/icons'
import classNames from 'classnames'

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SelectArrowIcon className="h-5 w-5" />
        </components.DropdownIndicator>
    )
}

interface IProps {
    label?: string
    value: string
    onChange: (value: string) => void
    options: {
        value: string
        label: string
    }[]
    hideSelectedOption?: boolean
    error?: string
    loading?: boolean
    isMulti?: boolean
    placeholder: string
    bgLabel?: boolean
}

export const ReactSelect: FC<IProps> = ({
    label,
    value,
    options,
    loading,
    hideSelectedOption = false,
    onChange,
    isMulti,
    error,
    placeholder,
    bgLabel,
}) => {
    const selected = options.find(item => item.value === value)

    return (
        <div className="relative">
            {label && (
                <label
                    className={classNames(
                        'absolute -top-3 left-4 z-10 bg-creame-1',
                        {
                            'text-red': Boolean(error),
                            'text-gray': !error,
                            'bg-creame-1': !bgLabel,
                            'bg-white': bgLabel,
                        },
                    )}>
                    {label}
                </label>
            )}
            <Select
                onChange={(data: any) => onChange(isMulti ? data : data.value)}
                defaultValue={selected}
                options={options}
                isMulti={isMulti}
                value={selected}
                hideSelectedOptions={hideSelectedOption}
                components={{ DropdownIndicator }}
                isLoading={loading}
                className={classNames('react-select', {
                    'react-select--has-error': error,
                })}
                placeholder={placeholder}
                classNamePrefix="react-select"
            />
            {error ? (
                <p className="text-red mt-0.5 ml-1 font-TTHoves text-sm">
                    {error}
                </p>
            ) : null}
        </div>
    )
}
