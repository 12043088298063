import axios from 'axios'

import { setAuthorizationHeader } from 'utils/auth'
import { getItem, removeItem } from 'services/localStorage.service'
import { redirectTo } from './links'

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || ''

const axiosInstance = axios.create({
    baseURL: API_DOMAIN,
})

axiosInstance.interceptors.response.use(
    response => {
        const token = response.headers['Token']
        if (token) {
            setAuthorizationHeader(token)
        }
        return response
    },
    error => {
        const status = error?.response?.status
        const message = error?.response?.data
        if (message === 'Invalid token.' || status === 401) {
            removeItem('persist:root')
            redirectTo('/login')
            removeItem('token')
        }

        return Promise.reject(error)
    },
)

export default axiosInstance

// set token from localStorage when application starts
setAuthorizationHeader(getItem('token'))
