import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import * as process from 'node:process'

export const MessageModal = ({ selected, showModal, setShowModal }) => {
    console.log(selected)
    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-50 inset-0 overflow-y-auto"
                open={showModal}
                onClose={setShowModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-[#0D0C1C] bg-opacity-[0.7] transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className={`w-full md:w-2/4 inline-block align-bottom bg-dark-black rounded-lg bg-white text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle`}>
                            <div className="max-w-none mx-auto">
                                <div
                                    className="absolute right-7 top-5 cursor-pointer"
                                    onClick={() => setShowModal(false)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-zinc-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={`bg-dark-black p-5 sm:rounded-lg sm:shadow`}>
                                    <div className="flex items-center gap-3">
                                        <div className="h-14 w-14 rounded-full flex-shrink-0">
                                            <img
                                                alt="avatar"
                                                className="h-full w-full rounded-full object-cover"
                                                src={
                                                    '/assets/images/blank.webp'
                                                }
                                            />
                                        </div>
                                        <div className="flex items-start flex-col gap-1">
                                            <p className="font-TTHovesM">
                                                {selected?.userId?.firstName +
                                                    ' ' +
                                                    selected?.userId?.lastName}
                                            </p>
                                            <div className="px-2 py-[1px] bg-white rounded-md border shadow-sm border-zinc-300 items-center gap-1.5 inline-flex">
                                                <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                                                <p className="text-slate-700 text-[12px] font-TTHovesM">
                                                    Online
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="text-zinc-200 w-full my-4" />
                                    <textarea
                                        className="w-full focus:outline-none border-0 "
                                        rows={8}
                                        placeholder={`Send ${
                                            selected?.userId?.firstName +
                                            ' ' +
                                            selected?.userId?.lastName
                                        } a collaboration message`}
                                    />
                                    <div className="flex flex-col gap-2 items-start">
                                        <p className="cursor-pointer border border-zinc-200 shadow-sm px-3 py-2 rounded-md text-sm text-zinc-700 font-TTHoves">
                                            Hey{' '}
                                            {selected?.userId?.firstName +
                                                ' ' +
                                                selected?.userId?.lastName}
                                            , I can help you with...{' '}
                                        </p>
                                        <p className="cursor-pointer border border-zinc-200 shadow-sm px-3 py-2 rounded-md text-sm text-zinc-700 font-TTHoves">
                                            Would it be possible to get a custom
                                            proposal offer for...
                                        </p>
                                        <p className="cursor-pointer border border-zinc-200 shadow-sm px-3 py-2 rounded-md text-sm text-zinc-700 font-TTHoves">
                                            Do you think you can collaborate
                                            on..
                                        </p>
                                    </div>
                                    <div className="flex place-content-end">
                                        <button
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                            className=" place-content-end mt-2 text-white px-[18px] py-[10px] font-TTHovesM bg-tale-10 rounded-lg">
                                            Send Message
                                        </button>
                                    </div>
                                    {/*<div className="flex gap-4 p-5">*/}
                                    {/*    <button*/}
                                    {/*        type="button"*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setShowModal(false)*/}
                                    {/*            onClose && onClose()*/}
                                    {/*        }}*/}
                                    {/*        className="flex-1 text-slate-700  px-[18px] py-[10px] font-TTHovesM bg-white rounded-lg  border border-zinc-300 ">*/}
                                    {/*        Cancel*/}
                                    {/*    </button>*/}

                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
