import { Modal } from 'components/common/Modal'
import { Profile2User } from 'iconsax-react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import UserService from 'services/user.service'
import PhoneInput from 'react-phone-input-2'
import React, { useCallback, useEffect } from 'react'
import { useNotifications } from 'hooks/useNotification'
import { Input } from 'components/form/Input/Input'
import * as Yup from 'yup'
import { useDebounce } from 'hooks/useDebounce'

const service = new UserService()
export const AddClientModal = ({ showModal, setShowModal, data, setData }) => {
    const [inputValue, setInputValue] = React.useState('')

    const debouncedValue = useDebounce(inputValue, 1000)

    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()
    const {
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        handleSubmit,
        resetForm,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            contactNumber: '',
            notes: '',
            clientAddedBy: user?._id,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required('Please enter last name')
                .matches(
                    /^[a-zA-Z\s]*$/g,
                    'Must not include space and special characters',
                ),
            lastName: Yup.string()
                .required('Please enter last name')
                .matches(
                    /^[a-zA-Z\s]*$/g,
                    'Must not include space and special characters',
                ),
            email: Yup.string()
                .email('Enter a valid email address')
                .matches(
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    'Enter a valid email address',
                )
                .required('Please enter email address'),
        }),
        onSubmit: values => {
            service
                .addClient(values)
                .then(resp => {
                    setData(prev => [
                        ...prev,
                        { ...resp.data.data, project: [] },
                    ])
                    successMessage('Client added successfully')
                    resetForm()
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setShowModal(false)
                    setFieldValue('firstName', '')
                    setFieldValue('lastName', '')
                    setFieldValue('contactNumber', '')
                    setInputValue('')
                })
        },
    })

    const search = useCallback(async () => {
        service
            .getClient(debouncedValue)
            .then(resp => {
                setFieldValue('firstName', resp.data.data.firstName)
                setFieldValue('lastName', resp.data.data.lastName)
                setFieldValue('contactNumber', resp.data.data.contactNumber)
                // setFieldValue('notes', resp.data.data.notes)
            })
            .catch(error => {
                console.log({ error })
            })
    }, [debouncedValue])

    // Resend Revoke

    useEffect(() => {
        search()
    }, [debouncedValue, search])

    return (
        <Modal
            width="w-full md:w-2/3 2xl:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            title="Add Client"
            footerButton="Add Client"
            handleClickPrimary={handleSubmit}
            headerIcon={<Profile2User />}>
            {/* Form */}
            <form className="p-5">
                <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                    <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                        Client Email*
                    </label>
                    <Input
                        type="email"
                        onChange={e => {
                            setInputValue(e.target.value)
                            setFieldValue('email', e.target.value)
                        }}
                        value={inputValue}
                        onBlur={handleBlur('email')}
                        error={touched?.email && errors?.email}
                        name="email"
                        placeholder="e.g example@mail.com"
                    />
                </div>
                <hr className="text-zinc-200 w-full my-5" />
                <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                    <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                        Client Name*
                    </label>
                    <div
                        className={`w-full flex gap-2 ${
                            errors?.lastName && 'mb-5'
                        }`}>
                        <Input
                            type="text"
                            placeholder="First Name"
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                            error={touched?.firstName && errors?.firstName}
                            value={values.firstName}
                        />
                        <Input
                            type="text"
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                            error={touched?.lastName && errors?.lastName}
                            value={values.lastName}
                            placeholder="Last Name"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-1 md:gap-[51px] h-[51px] mt-4">
                    <label className="w-32 md:w-52 text-slate-700 text-sm font-TTHovesM">
                        Contact Number
                    </label>
                    <PhoneInput
                        country="us"
                        value={values.contactNumber}
                        onChange={phone =>
                            setFieldValue('contactNumber', phone)
                        }
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-1 md:gap-20 mt-4">
                    <label className="mt-6 md:mt-0 w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                        Notes
                    </label>
                    <textarea
                        value={values.notes}
                        onChange={handleChange('notes')}
                        onBlur={handleBlur('notes')}
                        className="border border-gray-outline appearance-none shadow-sm rounded-lg p-3 w-full focus:outline-none focus:ring-2 ring-brown-10 ring-opacity-50"
                        placeholder="Please share anything that will help client understand the invitation."
                        rows={3}
                    />
                </div>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
