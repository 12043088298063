import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const useProposals = () => {
    const [proposals, setProposals] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getProposals()
            .then(resp => {
                setProposals(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.createdAt).getTime()
                        const dateB = new Date(a.createdAt).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(error => {
                console.log({ error })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setProposals,
        proposals,
    }
}
