import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

interface CustomButtonProps {
    className?: string
    onClick?: (e: any) => void
    container?: string
    disabled?: boolean
    type?: 'button' | 'submit' | 'reset'
}

export const Button: FC<PropsWithChildren<CustomButtonProps>> = ({
    children,
    className,
    disabled,
    onClick,
    container,
    type = 'button',
}) => {
    return (
        <div className={`${container}`}>
            <button
                className={classNames(
                    'py-2 rounded-lg transition duration-300 px-4',
                    [className],
                )}
                onClick={onClick}
                disabled={disabled}
                type={type}>
                {children}
            </button>
        </div>
    )
}

interface Props extends CustomButtonProps {
    variant?: 'primary' | 'white'
    text?: string
    icon: any
    onClick: any
}

export const IconButton: FC<Props> = ({
    container,
    className,
    type,
    text,
    icon,
    disabled,
    onClick,
    variant = 'white',
}) => {
    return (
        <div className={container}>
            <button
                type={type}
                onClick={onClick}
                disabled={disabled}
                className={classNames(
                    'px-4 py-2.5 disabled:text-zinc-300 disabled:bg-opacity-10 disabled:cursor-not-allowed rounded-lg shadow-sm justify-center items-center inline-flex',
                    [className],
                    {
                        'bg-white border border-zinc-300': variant === 'white',
                        'bg-tale-10': variant === 'primary',
                        'gap-3': text,
                    },
                )}>
                {icon}
                <span
                    className={classNames('text-sm font-TTHovesM', {
                        'text-slate-700': variant === 'white',
                        'text-white': variant === 'primary',
                    })}>
                    {text}
                </span>
            </button>
        </div>
    )
}
