import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {
    loader: false,
    isAuth: false,
    user: null,
    error: null,
}

const authReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        initLogin: state => {
            state.loader = true
        },
        loginSuccess: (state, action) => {
            state.user = action.payload
            state.isAuth = !!action.payload.token
            state.error = null
            state.loader = false
            state.error = null
        },
        loginFailure: (state, action) => {
            state.user = null
            state.isAuth = false
            state.loader = false
            state.error = action.payload
        },
        logout: state => {
            state.loader = true
        },
        logoutSuccess: state => {
            state.user = null
            state.loader = false
            state.isAuth = false
        },
        logoutFailure: state => {
            state.loader = false
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        updateLanguages(state, action) {
            if (state.user) {
                state.user = _.merge({}, state.user, action.payload)
            }
        },
        updatePortfolio: (state, action) => {
            console.log({ state, action })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // state.user.portfolio = action.payload
        },
    },
})

export const {
    loginSuccess,
    logoutFailure,
    loginFailure,
    logout,
    logoutSuccess,
    initLogin,
    updateLanguages,
    updatePortfolio,
    setUser,
} = authReducer.actions

export default authReducer.reducer
