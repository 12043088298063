import { SingleQuoteTile } from 'utils/interfaces/invitePhotographers'
import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserService from 'services/user.service'

const service = new UserService()

const QuoteTile: React.FC = () => {
    const { quoteId } = useParams<Record<string, string>>()
    const [signleQuote, setSignleQuote] = useState<SingleQuoteTile>()

    useEffect(() => {
        const preFetch = async () => {
            const res = await service.getSingleQuote(quoteId || '')

            setSignleQuote(res?.data?.data)
        }

        preFetch()
    }, [])

    return (
        <div className="border border-primary rounded-[6px] py-[23px] px-7 bg-white">
            <div className="flex items-center gap-2">
                {signleQuote?.user.profileImage ? (
                    <span className="h-8 w-8 rounded-full overflow-hidden">
                        <img
                            alt="profile"
                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${signleQuote?.user?.profileImage}`}
                            className="h-full w-full rounded-full"
                        />
                    </span>
                ) : (
                    <span className="h-[75px] bg-[#F9FAFB] rounded-full w-[75px] font-TTHovesSB text-dark-8 text-[34px] uppercase flex items-center justify-center">
                        {signleQuote?.user?.firstName[0]}
                    </span>
                )}

                <span>
                    <span className="text-dark-5 text-[15px]">
                        {'Posted by '}
                    </span>
                    <span className="text-dark-9 text-[15px] font-TTHovesM">
                        {`${signleQuote?.user?.firstName} ${signleQuote?.user?.lastName}`}
                    </span>
                </span>
            </div>

            <div className="flex flex-col items-start gap-1 mt-[13px]">
                <p className="text-primary leading-[24px] text-lg font-TTHovesM mb-[2px]">
                    {signleQuote?.title}
                </p>
                <p
                    className="max-w-[80%] leading-[20px] w-[680px] text-sm text-gray-600"
                    dangerouslySetInnerHTML={{
                        __html: signleQuote?.description || '',
                    }}
                />
            </div>
        </div>
    )
}

export default memo(QuoteTile)
