// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[translate=no] {
  height: 10rem;
  width: 100%;
  overflow-y: auto;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(171 178 196 / var(--tw-border-opacity));
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: TT-Hoves-Regular;
  --tw-text-opacity: 1;
  color: rgb(43 42 64 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-color: rgb(199 152 122 / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}

div[translate=no]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.div[translate=no]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.div[translate=no] strong {
  font-family: "Roboto", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/common/style.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,sBAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,6BAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,6BAAA;EAAA,oBAAA;EAAA,6CAAA;EAAA,0CAAA;EAAA,uDAAA;EAAA,uGAAA;EAAA,8BAAA;EAAA,mBAAA;EAAA,0DAAA;EAAA;AAAA;;AAAA;EAAA,2GAAA;EAAA,yGAAA;EAAA;AAAA;;AAIA;EAAA,8BAAA;EAAA;AAAA;;AAGF;EACE,iCAAA;AACF","sourcesContent":["div[translate=\"no\"] {\n  @apply w-full h-40 overflow-y-auto outline-none rounded-xl border border-gray-outline shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-transparent text-dark-8 font-TTHoves\n}\n\n.div[translate=\"no\"]:focus {\n  @apply outline-none\n}\n\n.div[translate=\"no\"] strong {\n  font-family: 'Roboto', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
