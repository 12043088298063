import React, { FC, useEffect, useState } from 'react'
import styles from './Input.module.css'
import classNames from 'classnames'

interface CustomTextAreaProps {
    className?: string
    maxLength?: number
    cols?: number
    id?: string
    value: string
    placeholder?: string
    rows?: number
    onChange?: (value: string) => void
}

export const TextArea: FC<CustomTextAreaProps> = ({
    className,
    maxLength = 400,
    cols = 100,
    id,
    rows = 3,
    value = '',
    placeholder,
    onChange,
}) => {
    const [text, setText] = useState(value)
    const [charCount, setCharCount] = useState(maxLength)

    useEffect(() => {
        setCharCount(maxLength - text?.length)
        setText(value)
    }, [text, maxLength])

    return (
        <div className={`relative w-full h-full`}>
            <textarea
                id={id}
                defaultValue={text}
                placeholder={placeholder}
                className={classNames(styles.input, [className])}
                value={text}
                onChange={e => {
                    setText(e.target.value)
                    onChange && onChange(e.target.value)
                }}
                maxLength={maxLength}
                rows={rows}
                cols={cols}>
                {text}
            </textarea>
            <div
                className={`text-xs font-TTHoves {text-normal} text-dark-5`}>{`${charCount} ${
                charCount > 1 ? 'characters' : 'character'
            } left`}</div>
        </div>
    )
}
