import axiosInstance from './axiosInstance'

function setAuthorizationHeader(token: string | null = null) {
    if (token) {
        axiosInstance.defaults.headers['x-auth-token'] = token
        axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
    } else {
        delete axiosInstance.defaults.headers['x-auth-token']
    }
}

// Adding token to the header
export { setAuthorizationHeader }
