import { ArrowRight2, Home2 } from 'iconsax-react'
import React, { ReactNode } from 'react'

interface BreadCrumsProps {
    children: ReactNode
}

const BreadCrums: React.FC<BreadCrumsProps> = ({ children }) => (
    <div className="items-center gap-2 flex">
        <Home2 className="h-5 w-5" />
        <ArrowRight2 className="h-4 w-4 text-zinc-400" />
        {children}
    </div>
)

export default BreadCrums
