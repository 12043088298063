import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import classNames from 'classnames'

export const PopMenu = ({ button, active, children }) => {
    return (
        <Menu as="div" className="relative">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={classNames(' focus:outline-none p-4', {
                            'bg-zinc-100 rounded-md': open,
                            'border-b-[1.5px] border-tale-10': active,
                        })}>
                        {button}
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1">
                        <Menu.Items className="absolute w-96 z-[100000000] mt-3">
                            {children}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}
