import React, { memo, useState } from 'react'
import BreadCrums from 'components/common/breadCrums'
import AddAvailabilitySubmit from 'components/dashboard/business/add-availability/AddAvailabilitySubmit'
import WorkingHoursTile from 'components/dashboard/business/add-availability/WorkingHoursTile'
import Form from 'components/dashboard/business/add-availability/Form'
import { initWeekDays } from 'utils/availabilityData'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { useNavigate } from 'react-router-dom'
const service = new UserService()

const AddAvailability: React.FC = () => {
    const navigate = useNavigate()
    const user = useSelector(userSelector)
    let holdData = initWeekDays
    const [validation, setValidation] = useState(false)
    const [loading, setLoading] = useState(false)

    const submitdata = async () => {
        const { title, timezone, weekDays } = holdData
        const anyWorkingHourMissing = weekDays.find(items => {
            return items.openToWork
                ? items.workingHours.find(item => !item.from || !item.to)
                : undefined
        })

        if (!title || !timezone || anyWorkingHourMissing) {
            setValidation(true)
        } else {
            setLoading(true)
            try {
                await service.addAvailability(user._id, {
                    availability: { ...holdData },
                })
                navigate('/dashboard/availability')
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
            setValidation(false)
        }
    }

    return (
        <div>
            <div className="flex justify-between mb-5">
                <BreadCrums>
                    <button className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                        {'Photoshoots'}
                    </button>
                </BreadCrums>

                <AddAvailabilitySubmit
                    onClick={() => submitdata()}
                    loading={loading}
                />
            </div>

            <WorkingHoursTile />

            <div className="mt-7">
                <Form
                    getFromData={data => (holdData = data)}
                    validation={validation}
                />
            </div>
        </div>
    )
}

export default memo(AddAvailability)
