export interface ILoginForm {
    email: string
    password: string
}

export enum StatusColorType {
    'green' = 'green',
    'blue' = 'blue',
    'purple' = 'purple',
    'red' = 'red',
    'orange' = 'orange',
    'progress' = 'progress',
    'grey' = 'grey',
    'blueLight' = 'blueLight',
    'pink' = 'pink',
    'indigo' = 'indigo',
    'violet' = 'violet',
}

export enum StatusType {
    'AVAILABLE' = 'Available',
    'RESPONSE' = 'Response',
    'COLD' = 'Cold',
    'PROGRESS' = 'Progress',
    'DEAD' = 'Dead',
    'REVIEW' = 'Active',
    'LIVE' = 'Live',
    'APPLIED' = 'Applied',
    'OPEN' = 'Open',
    'ACTIVE' = 'Active',
    'COMPLETED' = 'Completed',
}
