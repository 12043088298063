import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const useClients = () => {
    const [clients, setClients] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllClients()
            .then(resp => {
                setClients(resp.data.data)
            })
            .catch(error => {
                console.log({ error })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setClients,
        clients,
    }
}
