import React, {
    Fragment,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import {
    Add,
    ArrowRight2,
    GalleryAdd,
    Home2,
    Link1,
    Setting2,
    Trash,
} from 'iconsax-react'
import { BiLinkExternal } from '../../../css/icons/icons'
import { UploadImageModal } from 'components/dashboard/gallery/UploadImageModal'
import { ShareGalleryModal } from 'components/dashboard/gallery/ShareGalleryModal'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { CopyLinkModal } from 'components/dashboard/gallery/CopyLinkModal'
import { useNotifications } from 'hooks/useNotification'
import { formatFileSize } from 'utils/funcs'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

const service = new UserService()

export const SpecificGallery = () => {
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState<any>(null)
    const [galleryModal, setGalleryModal] = useState(false)
    const [linkModal, setLinkModal] = useState(false)

    const navigate = useNavigate()
    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()
    const { id } = useParams()

    const fetchData = useCallback(() => {
        service
            .getSpecificGallery(id ?? '')
            .then(resp => {
                setData(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [id])

    useEffect(() => {
        fetchData()
    }, [])

    const deletePhoto = url => {
        service
            .deleteGalleryImage(id, url)
            .then(() => {
                const index = [...data.images].findIndex(item => item === url)
                const updated = { ...data }
                updated.images.splice(index, 1)
                setData(updated)

                successMessage('Image deleted successfully')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onClick={() => navigate(-1)}
                        className="px-2 py-1 text-slate-600 cursor-pointer text-sm font-TTHovesM leading-tight">
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {data?.title ?? ''}
                    </div>
                </div>

                <div className="flex gap-2">
                    <button
                        type="button"
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Link1 className="text-tale-10 h-5 w-5" />
                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Copy Link
                        </span>
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-4 h-4 text-tale-10">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                        </svg>

                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Share
                        </span>
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <BiLinkExternal className="text-tale-10 h-5 w-5 font-TTHoves" />
                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Preview Gallery
                        </span>
                    </button>
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <div className="mt-5 bg-white rounded-xl border border-zinc-200 flex-1 p-4">
                    <p className="text-zinc-600 font-TTHoves">Gallery Views</p>
                    <div className="flex justify-between items-center mt-3">
                        <p className="font-TTHovesSB text-3xl text-zinc-800">
                            {data?.views}
                        </p>
                        <div className="flex gap-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-green-700">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                                />
                            </svg>
                            <span className="text-green-800">0</span>
                            <span className="text-zinc-600 ">
                                vs Last month
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mt-5 bg-white rounded-xl border border-zinc-200 flex-1 p-4">
                    <p className="text-zinc-600 font-TTHoves">
                        Gallery Downloads
                    </p>
                    <div className="flex justify-between items-center mt-3">
                        <p className="font-TTHovesSB text-3xl text-zinc-800">
                            {data?.downloads}
                        </p>
                        <div className="flex gap-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-red">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181"
                                />
                            </svg>

                            <span className="text-red">0 %</span>
                            <span className="text-zinc-600 ">
                                vs Last month
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mt-5 bg-white rounded-xl border border-zinc-200 flex-1 p-4">
                    <p className="text-zinc-600 font-TTHoves">Gallery Size</p>
                    <div className="flex justify-between items-center mt-3">
                        <p className="font-TTHovesSB text-3xl text-zinc-800">
                            {formatFileSize(data?.size ?? 0)}
                        </p>
                        <div className="flex gap-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-green-700">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 mt-6">
                <div className="bg-white rounded-xl border border-zinc-200 p-5 w-full">
                    <div className="flex flex-col md:flex-row justify-between ">
                        <p className="text-zinc-900 text-lg font-TTHovesM">
                            Manage Photos
                        </p>
                        <button
                            type="button"
                            onClick={() =>
                                navigate(`/dashboard/gallery/settings/${id}`)
                            }
                            className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <Setting2 className="text-tale-10 h-5 w-5" />
                            <span className="text-zinc-700 text-sm font-TTHovesM ">
                                Gallery Settings
                            </span>
                        </button>
                    </div>

                    <div className="flex flex-wrap gap-4 mt-4">
                        <div
                            onClick={() => setShowModal(true)}
                            className="cursor-pointer w-full md:w-[245px] h-[270px] overflow-hidden flex flex-col items-center justify-center bg-gradient-to-b from-white to-tale-10/20 rounded-xl border-2 border-dashed border-zinc-200">
                            <GalleryAdd className="h-8 w-8" />
                            <div className="text-slate-800 text-sm mt-2">
                                Add photos
                            </div>
                            {/*<div className="w-[181px]  text-center text-slate-600 text-xs mt-1">*/}
                            {/*    Lorem ipsum dolor set amet is a dummy text here*/}
                            {/*</div>*/}
                        </div>

                        {data?.images.map(image => (
                            <div
                                key={image}
                                className="w-full md:w-[245px] h-[270px] relative">
                                <img
                                    src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                                    alt="library"
                                    className="h-full w-full object-cover rounded-lg"
                                />
                                {/*<FavouriteButton className="absolute left-4 top-4" />*/}
                                <button
                                    type="button"
                                    onClick={() => deletePhoto(image)}
                                    className="absolute right-4 top-4 text-red">
                                    <Trash />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/3 gap-4">
                    <div className="bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="font-TTHovesM text-zinc-900 text-lg">
                            Used in projects
                        </p>
                        <div
                            className="px-6 shadow-sm py-4 bg-violet-200 rounded-xl mt-2 cursor-pointer"
                            onClick={() => setLinkModal(true)}>
                            <p className="text-zinc-700 text-lg font-TTHovesSB">
                                Link gallery
                            </p>
                            <p className=" text-gray-600 text-[15px] font-TTHovesM">
                                You can share this gallery with the client by
                                linking it with the active project
                            </p>
                        </div>
                        {/*<div className="border rounded-lg border-zinc-200 p-4 mt-3">*/}
                        {/*    <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*        Weyah Di Tasveeran*/}
                        {/*    </p>*/}
                        {/*    <div className="flex gap-2 items-center mt-2">*/}
                        {/*        <div className="text-zinc-800 text-sm rounded-lg px-3 py-2 border border-zinc-200 bg-white shadow-sm">*/}
                        {/*            Couples*/}
                        {/*        </div>*/}
                        {/*        <div className="text-zinc-800 text-sm rounded-lg px-3 py-2 border border-zinc-200 bg-white shadow-sm">*/}
                        {/*            Wedding*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-zinc-500 font-TTHovesL mt-2">*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur*/}
                        {/*        adipisicing elit. Aliquid commodi dolorem,*/}
                        {/*        doloremque ex facere.*/}
                        {/*    </p>*/}
                        {/*    <div className="mt-2">*/}
                        {/*        <p className="text-sm font-TTHovesM text-zinc-600">*/}
                        {/*            Thu 20 Jul - 27 Jul*/}
                        {/*        </p>*/}
                        {/*        <p className="text-sm text-zinc-400">*/}
                        {/*            2:00pm - 2:15pm*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="border rounded-lg border-zinc-200 p-4 mt-4">*/}
                        {/*    <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*        Weyah Di Tasveeran*/}
                        {/*    </p>*/}
                        {/*    <div className="flex gap-2 items-center mt-2">*/}
                        {/*        <div className="text-sm rounded-lg px-3 py-2 border border-zinc-200 bg-white shadow-sm">*/}
                        {/*            Couples*/}
                        {/*        </div>*/}
                        {/*        <div className="text-sm rounded-lg px-3 py-2 border border-zinc-200 bg-white shadow-sm">*/}
                        {/*            Wedding*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-zinc-500 font-TTHovesL mt-2">*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur*/}
                        {/*        adipisicing elit. Aliquid commodi dolorem,*/}
                        {/*        doloremque ex facere.*/}
                        {/*    </p>*/}
                        {/*    <div className="mt-2">*/}
                        {/*        <p className="text-sm font-TTHovesM text-zinc-600">*/}
                        {/*            Thu 20 Jul - 27 Jul*/}
                        {/*        </p>*/}
                        {/*        <p className="text-sm text-zinc-400">*/}
                        {/*            2:00pm - 2:15pm*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="bg-white rounded-xl border border-zinc-200 p-5 ">
                        <p className="font-TTHovesM text-zinc-900 text-lg">
                            Shared with clients
                        </p>
                        <div
                            className="border border-zinc-200 rounded-lg p-4 flex items-center gap-3 mt-3 cursor-pointer"
                            onClick={() => setGalleryModal(true)}>
                            <div className="rounded-full p-4 bg-tale-10/10">
                                <Add className="text-zinc-600 h-5 w-5" />
                            </div>
                            <div className="text-sm">
                                <p className="text-zinc-700 font-TTHovesM">
                                    Add Client
                                </p>
                                <p className=" text-zinc-500">
                                    Link a client to share a gallery
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UploadImageModal
                id={id}
                setShowModal={setShowModal}
                data={data}
                setData={setData}
                showModal={showModal}
            />
            <ShareGalleryModal
                setShowModal={setGalleryModal}
                id={id}
                showModal={galleryModal}
            />
            <CopyLinkModal setShowModal={setLinkModal} showModal={linkModal} />
        </Fragment>
    )
}
