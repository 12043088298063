import React from 'react'

export const AppleLogo = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_95_1156)">
            <path
                d="M13.7491 0C12.6829 0.0737496 11.4367 0.756246 10.7104 1.64499C10.0479 2.45124 9.50292 3.64873 9.71542 4.81247C10.8804 4.84872 12.0842 4.14998 12.7816 3.24623C13.4341 2.40499 13.9279 1.21499 13.7491 0Z"
                fill="black"
            />
            <path
                d="M17.9629 6.70989C16.9391 5.42615 15.5004 4.68115 14.1416 4.68115C12.3479 4.68115 11.5892 5.5399 10.3429 5.5399C9.05792 5.5399 8.08168 4.68365 6.53044 4.68365C5.0067 4.68365 3.38421 5.6149 2.35546 7.20739C0.90922 9.44987 1.15672 13.6661 3.50045 17.2573C4.3392 18.5423 5.45919 19.9873 6.92418 19.9998C8.22793 20.0123 8.59543 19.1636 10.3617 19.1548C12.1279 19.1448 12.4629 20.0111 13.7641 19.9973C15.2304 19.9861 16.4116 18.3848 17.2504 17.0998C17.8516 16.1786 18.0754 15.7148 18.5416 14.6748C15.1504 13.3836 14.6066 8.56113 17.9629 6.70989Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_95_1156">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const CheckIcon = () => {
    return (
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20.5" r="20" fill="#48D5DE" />
            <path
                d="M26.6668 15.5L17.5002 24.6667L13.3335 20.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const CloseIcon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
)
export const GoogleLogo = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_95_1127)">
            <path
                d="M19.2449 8.26113L11.087 8.26074C10.7268 8.26074 10.4348 8.5527 10.4348 8.91293V11.519C10.4348 11.8791 10.7268 12.1712 11.087 12.1712H15.681C15.1779 13.4767 14.239 14.57 13.0411 15.2647L15 18.6557C18.1423 16.8384 20 13.6497 20 10.0803C20 9.57203 19.9625 9.20871 19.8876 8.79961C19.8307 8.48879 19.5608 8.26113 19.2449 8.26113Z"
                fill="#167EE6"
            />
            <path
                d="M10 16.0871C7.75179 16.0871 5.78914 14.8587 4.73504 13.041L1.34418 14.9955C3.06976 17.9862 6.30234 20.0001 10 20.0001C11.8139 20.0001 13.5255 19.5117 15 18.6606V18.6559L13.0411 15.2649C12.1451 15.7846 11.1082 16.0871 10 16.0871Z"
                fill="#12B347"
            />
            <path
                d="M15 18.6603V18.6557L13.0411 15.2646C12.1451 15.7843 11.1083 16.0868 10 16.0868V19.9998C11.8139 19.9998 13.5256 19.5114 15 18.6603Z"
                fill="#0F993E"
            />
            <path
                d="M3.91305 10.0002C3.91305 8.89207 4.21547 7.85531 4.73504 6.95934L1.34418 5.00488C0.488359 6.47469 0 8.18164 0 10.0002C0 11.8188 0.488359 13.5258 1.34418 14.9956L4.73504 13.0411C4.21547 12.1452 3.91305 11.1084 3.91305 10.0002Z"
                fill="#FFD500"
            />
            <path
                d="M10 3.91305C11.4661 3.91305 12.8127 4.43398 13.8645 5.30051C14.124 5.51426 14.5012 5.49883 14.7389 5.26113L16.5853 3.41465C16.855 3.14496 16.8358 2.70352 16.5477 2.45359C14.7854 0.924727 12.4925 0 10 0C6.30234 0 3.06976 2.01395 1.34418 5.00465L4.73504 6.9591C5.78914 5.14141 7.75179 3.91305 10 3.91305Z"
                fill="#FF4B26"
            />
            <path
                d="M13.8645 5.30051C14.124 5.51426 14.5012 5.49883 14.7389 5.26113L16.5854 3.41465C16.855 3.14496 16.8358 2.70352 16.5477 2.45359C14.7854 0.924688 12.4925 0 10 0V3.91305C11.466 3.91305 12.8127 4.43398 13.8645 5.30051Z"
                fill="#D93F21"
            />
        </g>
        <defs>
            <clipPath id="clip0_95_1127">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const IconGlobe = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_938_16466)">
            <path
                d="M1.66675 9.99999H18.3334M1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333M1.66675 9.99999C1.66675 5.39762 5.39771 1.66666 10.0001 1.66666M18.3334 9.99999C18.3334 14.6024 14.6025 18.3333 10.0001 18.3333M18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666M10.0001 1.66666C12.0845 3.94862 13.269 6.91002 13.3334 9.99999C13.269 13.09 12.0845 16.0514 10.0001 18.3333M10.0001 1.66666C7.91568 3.94862 6.73112 6.91002 6.66675 9.99999C6.73112 13.09 7.91568 16.0514 10.0001 18.3333"
                stroke="#58576F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_938_16466">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const IconFacebook = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#E0EACE" />
            <path
                d="M22.3158 10.6436C21.0246 10.6436 19.7863 11.1565 18.8733 12.0695C17.9603 12.9825 17.4474 14.2208 17.4474 15.512V18.2225H14.8421C14.7113 18.2225 14.6053 18.3285 14.6053 18.4593V22.0383C14.6053 22.1691 14.7113 22.2751 14.8421 22.2751H17.4474V29.6172C17.4474 29.7481 17.5534 29.8541 17.6842 29.8541H21.2632C21.394 29.8541 21.5 29.7481 21.5 29.6172V22.2751H24.1284C24.2371 22.2751 24.3318 22.2012 24.3581 22.0957L25.2529 18.5168C25.2902 18.3673 25.1772 18.2225 25.0231 18.2225H21.5V15.512C21.5 15.2956 21.586 15.0881 21.739 14.9351C21.8919 14.7821 22.0995 14.6962 22.3158 14.6962H25.0527C25.1835 14.6962 25.2895 14.5901 25.2895 14.4593V10.8804C25.2895 10.7496 25.1835 10.6436 25.0527 10.6436H22.3158Z"
                fill="#69884F"
            />
        </svg>
    )
}

export const IconTwitter = () => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.660034" width="40" height="40" rx="20" fill="#E0EACE" />
            <g clipPath="url(#clip0_57_858)">
                <g clipPath="url(#clip1_57_858)">
                    <path
                        d="M30.4698 13.9636C30.5278 13.8828 30.4422 13.7785 30.3495 13.8144C29.6908 14.0698 29.0023 14.242 28.3001 14.3269C29.082 13.8598 29.6854 13.1487 30.02 12.3069C30.054 12.2211 29.9604 12.1445 29.8796 12.1891C29.1529 12.5905 28.3691 12.8795 27.555 13.0459C27.5209 13.0529 27.4858 13.0413 27.4619 13.016C26.8509 12.3713 26.0466 11.9421 25.1699 11.7938C24.2747 11.6423 23.3546 11.7923 22.5539 12.2201C21.7531 12.6479 21.117 13.3295 20.7453 14.1578C20.3919 14.9453 20.2965 15.8232 20.4708 16.6664C20.4844 16.7318 20.4332 16.7935 20.3665 16.7894C18.7824 16.691 17.2347 16.2697 15.8187 15.5508C14.4063 14.8337 13.1556 13.8362 12.1427 12.6196C12.0978 12.5656 12.0126 12.5726 11.9798 12.6348C11.6646 13.2328 11.4996 13.8994 11.5001 14.5769C11.4988 15.2513 11.6643 15.9155 11.9818 16.5105C12.2994 17.1054 12.7591 17.6126 13.3201 17.9869C12.7251 17.9707 12.1418 17.8237 11.6113 17.557C11.5429 17.5226 11.4609 17.5716 11.4645 17.648C11.5059 18.5372 11.8323 19.43 12.3974 20.1149C12.9987 20.8438 13.8333 21.3426 14.7601 21.5269C14.4033 21.6355 14.0329 21.6927 13.6601 21.6969C13.4571 21.6946 13.2546 21.6796 13.0536 21.6521C12.9791 21.6419 12.9182 21.7127 12.9443 21.7832C13.2207 22.5307 13.7091 23.183 14.3511 23.6591C15.0376 24.1681 15.8656 24.4505 16.7201 24.4669C15.2773 25.6022 13.4959 26.2218 11.6601 26.2269C11.4714 26.2275 11.2829 26.2214 11.0947 26.2086C10.9887 26.2014 10.94 26.3426 11.0314 26.3969C12.8201 27.4584 14.8647 28.0195 16.9501 28.0169C18.4898 28.0329 20.0172 27.7419 21.4432 27.161C22.8692 26.5801 24.1651 25.7208 25.2553 24.6334C26.3455 23.546 27.2081 22.2523 27.7927 20.8278C28.3772 19.4034 28.6721 17.8767 28.6601 16.3369V15.8571C28.6601 15.8255 28.675 15.7958 28.7002 15.7768C29.3785 15.2647 29.975 14.6532 30.4698 13.9636Z"
                        fill="#69884F"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_57_858">
                    <rect
                        width="20"
                        height="18"
                        fill="white"
                        transform="translate(10.66 11)"
                    />
                </clipPath>
                <clipPath id="clip1_57_858">
                    <rect
                        width="20"
                        height="18"
                        fill="white"
                        transform="translate(10.66 11)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export const IconArrowCurve = () => (
    <svg
        width="22"
        height="28"
        viewBox="0 0 22 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.2"
            d="M21.3536 23.8536C21.5488 23.6583 21.5488 23.3417 21.3536 23.1464L18.1716 19.9645C17.9763 19.7692 17.6597 19.7692 17.4645 19.9645C17.2692 20.1597 17.2692 20.4763 17.4645 20.6716L20.2929 23.5L17.4645 26.3284C17.2692 26.5237 17.2692 26.8403 17.4645 27.0355C17.6597 27.2308 17.9763 27.2308 18.1716 27.0355L21.3536 23.8536ZM0 0.5V10.5H1V0.5H0ZM13.5 24H21V23H13.5V24ZM0 10.5C0 17.9558 6.04416 24 13.5 24V23C6.59644 23 1 17.4036 1 10.5H0Z"
            fill="black"
        />
    </svg>
)

export const IconInstagram = () => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.320007" width="40" height="40" rx="20" fill="#E0EACE" />
            <g clipPath="url(#clip0_57_862)">
                <g clipPath="url(#clip1_57_862)">
                    <path
                        d="M20.32 16.9863C18.5251 16.9863 17.07 18.4414 17.07 20.2363C17.07 22.0313 18.5251 23.4864 20.32 23.4864C22.1149 23.4864 23.57 22.0313 23.57 20.2363C23.57 18.4414 22.1149 16.9863 20.32 16.9863Z"
                        fill="#69884F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.0895 11.3178C18.5377 10.9325 22.1024 10.9325 25.5505 11.3178C27.4489 11.53 28.9801 13.0258 29.2029 14.9309C29.6152 18.4559 29.6152 22.0169 29.2029 25.5419C28.9801 27.447 27.4489 28.9427 25.5505 29.1549C22.1024 29.5403 18.5377 29.5403 15.0895 29.1549C13.1911 28.9427 11.66 27.447 11.4371 25.5419C11.0249 22.0169 11.0249 18.4559 11.4371 14.9309C11.66 13.0258 13.1911 11.53 15.0895 11.3178ZM25.32 14.2364C24.7677 14.2364 24.32 14.6841 24.32 15.2364C24.32 15.7887 24.7677 16.2364 25.32 16.2364C25.8723 16.2364 26.32 15.7887 26.32 15.2364C26.32 14.6841 25.8723 14.2364 25.32 14.2364ZM15.57 20.2364C15.57 17.613 17.6967 15.4864 20.32 15.4864C22.9434 15.4864 25.07 17.613 25.07 20.2364C25.07 22.8597 22.9434 24.9864 20.32 24.9864C17.6967 24.9864 15.57 22.8597 15.57 20.2364Z"
                        fill="#69884F"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_57_862">
                    <rect
                        width="20"
                        height="19"
                        fill="white"
                        transform="translate(10.32 10.5)"
                    />
                </clipPath>
                <clipPath id="clip1_57_862">
                    <rect
                        width="20"
                        height="19"
                        fill="white"
                        transform="translate(10.32 10.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export const ArrowUpRightIcon = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={className}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
        </svg>
    )
}

export const IconBold = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.6 14.79C20.57 14.12 21.25 13.02 21.25 12C21.25 9.74 19.5 8 17.25 8H11V22H18.04C20.13 22 21.75 20.3 21.75 18.21C21.75 16.69 20.89 15.39 19.6 14.79ZM14 10.5H17C17.83 10.5 18.5 11.17 18.5 12C18.5 12.83 17.83 13.5 17 13.5H14V10.5ZM17.5 19.5H14V16.5H17.5C18.33 16.5 19 17.17 19 18C19 18.83 18.33 19.5 17.5 19.5Z"
                fill="#98A2B3"
            />
        </svg>
    )
}

export const IconItalic = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 8V11H16.21L12.79 19H10V22H18V19H15.79L19.21 11H22V8H14Z"
                fill="#98A2B3"
            />
        </svg>
    )
}
export const IconLink = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.9 16C7.9 14.29 9.29 12.9 11 12.9H15V11H11C8.24 11 6 13.24 6 16C6 18.76 8.24 21 11 21H15V19.1H11C9.29 19.1 7.9 17.71 7.9 16ZM12 17H20V15H12V17ZM21 11H17V12.9H21C22.71 12.9 24.1 14.29 24.1 16C24.1 17.71 22.71 19.1 21 19.1H17V21H21C23.76 21 26 18.76 26 16C26 13.24 23.76 11 21 11Z"
                fill="#98A2B3"
            />
        </svg>
    )
}

export const IconSessions = ({
    bgColor = 'white',
    color = 'black',
    outline = '#EAECF0',
}) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23388)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                />
                <rect
                    x="2.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke={outline}
                />
                <path
                    d="M17.6334 29.3332H26.3667C28.6667 29.3332 29.5834 27.9248 29.6917 26.2082L30.1251 19.3248C30.2417 17.5248 28.8084 15.9998 27.0001 15.9998C26.4917 15.9998 26.0251 15.7082 25.7917 15.2582L25.1917 14.0498C24.8084 13.2915 23.8084 12.6665 22.9584 12.6665H21.0501C20.1917 12.6665 19.1917 13.2915 18.8084 14.0498L18.2084 15.2582C17.9751 15.7082 17.5084 15.9998 17.0001 15.9998C15.1917 15.9998 13.7584 17.5248 13.8751 19.3248L14.3084 26.2082C14.4084 27.9248 15.3334 29.3332 17.6334 29.3332Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.75 17.6665H23.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0001 26.0002C23.4917 26.0002 24.7084 24.7835 24.7084 23.2918C24.7084 21.8002 23.4917 20.5835 22.0001 20.5835C20.5084 20.5835 19.2917 21.8002 19.2917 23.2918C19.2917 24.7835 20.5084 26.0002 22.0001 26.0002Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23388"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23388"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23388"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const IconCreatePortfolio = ({
    bgColor = 'white',
    color = 'black',
    outline = '#EAECF0',
}) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23399)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                />
                <rect
                    x="2.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke={outline}
                />
                <path
                    d="M19.4999 19.3333C20.4204 19.3333 21.1666 18.5871 21.1666 17.6667C21.1666 16.7462 20.4204 16 19.4999 16C18.5794 16 17.8333 16.7462 17.8333 17.6667C17.8333 18.5871 18.5794 19.3333 19.4999 19.3333Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.8334 12.6665H19.5001C15.3334 12.6665 13.6667 14.3332 13.6667 18.4998V23.4998C13.6667 27.6665 15.3334 29.3332 19.5001 29.3332H24.5001C28.6667 29.3332 30.3334 27.6665 30.3334 23.4998V19.3332"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.125 15.1665H29.7083"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M27.4167 17.4583V12.875"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M14.2251 26.7918L18.3334 24.0335C18.9918 23.5918 19.9418 23.6418 20.5334 24.1501L20.8084 24.3918C21.4584 24.9501 22.5084 24.9501 23.1584 24.3918L26.6251 21.4168C27.2751 20.8585 28.3251 20.8585 28.9751 21.4168L30.3334 22.5835"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23399"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23399"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23399"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const IconCreateGallery = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 8V2L20 4"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 2L16 4"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.66992 18.9501L7.59992 15.6401C8.38992 15.1101 9.52992 15.1701 10.2399 15.7801L10.5699 16.0701C11.3499 16.7401 12.6099 16.7401 13.3899 16.0701L17.5499 12.5001C18.3299 11.8301 19.5899 11.8301 20.3699 12.5001L21.9999 13.9001"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconCollaborateBlank = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4594 13.7299C13.0113 13.7299 14.2694 12.4718 14.2694 10.9199C14.2694 9.36794 13.0113 8.10986 11.4594 8.10986C9.90749 8.10986 8.64941 9.36794 8.64941 10.9199C8.64941 12.4718 9.90749 13.7299 11.4594 13.7299Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6495 20.1998C16.6495 17.8698 14.3295 15.9697 11.4595 15.9697C8.58953 15.9697 6.26953 17.8598 6.26953 20.1998"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 12.5C21 17.75 16.75 22 11.5 22C6.25 22 2 17.75 2 12.5C2 7.25 6.25 3 11.5 3C12.81 3 14.06 3.25999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C20.73 9.92998 21 11.19 21 12.5Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23 5C23 5.32 22.96 5.62999 22.88 5.92999C22.79 6.32999 22.63 6.72 22.42 7.06C21.94 7.87 21.17 8.49998 20.25 8.78998C19.86 8.92998 19.44 9 19 9C17.97 9 17.04 8.60997 16.34 7.96997C16.04 7.70997 15.78 7.4 15.58 7.06C15.21 6.46 15 5.75 15 5C15 4.56 15.07 4.13999 15.2 3.73999C15.39 3.15999 15.71 2.64002 16.13 2.21002C16.86 1.46002 17.88 1 19 1C20.18 1 21.25 1.51002 21.97 2.33002C22.61 3.04002 23 3.98 23 5Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.4898 4.97998H17.5098"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 3.52002V6.51001"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconBrowseJobs = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 13.7502C9.5 14.7202 10.25 15.5002 11.17 15.5002H13.05C13.85 15.5002 14.5 14.8202 14.5 13.9702C14.5 13.0602 14.1 12.7302 13.51 12.5202L10.5 11.4702C9.91 11.2602 9.51001 10.9402 9.51001 10.0202C9.51001 9.18023 10.16 8.49023 10.96 8.49023H12.84C13.76 8.49023 14.51 9.27023 14.51 10.2402"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 7.5V16.5"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22 6V2H18"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 7L22 2"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const FilterIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#69884F"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export const SelectArrowIcon = ({ className }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 15L12.8838 19.2518C12 20.2494 12 20.2494 11.1162 19.2518L7 15"
                stroke="#69884F"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 9L11.1162 4.74821C12 3.7506 12 3.7506 12.8838 4.74821L17 9"
                stroke="#69884F"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconLineVertical = ({ color = '#EAECF0' }) => {
    return (
        <svg
            width="2"
            height="42"
            viewBox="0 0 2 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="2" height="42" rx="1" fill={color} />
        </svg>
    )
}

export const IconCameraBlank = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.76017 22H17.2402C20.0002 22 21.1002 20.31 21.2302 18.25L21.7502 9.99C21.8902 7.83 20.1702 6 18.0002 6C17.3902 6 16.8302 5.65 16.5502 5.11L15.8302 3.66C15.3702 2.75 14.1702 2 13.1502 2H10.8602C9.83017 2 8.63017 2.75 8.17017 3.66L7.45017 5.11C7.17017 5.65 6.61017 6 6.00017 6C3.83017 6 2.11017 7.83 2.25017 9.99L2.77017 18.25C2.89017 20.31 4.00017 22 6.76017 22Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 8H13.5"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 18C13.79 18 15.25 16.54 15.25 14.75C15.25 12.96 13.79 11.5 12 11.5C10.21 11.5 8.75 12.96 8.75 14.75C8.75 16.54 10.21 18 12 18Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconEditPortfolioBlank = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconInstagramBlank = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6361 7H17.6477"
                stroke="#292D32"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconGallery = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill="white" />
            <path
                d="M28.0664 24.1335L25.4581 18.0419C24.5748 15.9752 22.9498 15.8919 21.8581 17.8585L20.2831 20.7002C19.4831 22.1419 17.9914 22.2669 16.9581 20.9752L16.7748 20.7419C15.6998 19.3919 14.1831 19.5585 13.4081 21.1002L11.9748 23.9752C10.9664 25.9752 12.4248 28.3335 14.6581 28.3335H25.2914C27.4581 28.3335 28.9164 26.1252 28.0664 24.1335Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.8081 16.6665C17.1888 16.6665 18.3081 15.5472 18.3081 14.1665C18.3081 12.7858 17.1888 11.6665 15.8081 11.6665C14.4274 11.6665 13.3081 12.7858 13.3081 14.1665C13.3081 15.5472 14.4274 16.6665 15.8081 16.6665Z"
                stroke="#2B2A40"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconCollaborate = ({
    bgColor = 'white',
    color = 'black',
    outline = '#EAECF0',
}) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23666)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                />
                <rect
                    x="2.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke={outline}
                />
                <path
                    d="M21.5502 22.4416C22.8434 22.4416 23.8918 21.3932 23.8918 20.1C23.8918 18.8067 22.8434 17.7583 21.5502 17.7583C20.2569 17.7583 19.2085 18.8067 19.2085 20.1C19.2085 21.3932 20.2569 22.4416 21.5502 22.4416Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.8751 27.8331C25.8751 25.8915 23.9418 24.3081 21.5501 24.3081C19.1584 24.3081 17.2251 25.8831 17.2251 27.8331"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.5001 21.4167C29.5001 25.7917 25.9584 29.3333 21.5834 29.3333C17.2084 29.3333 13.6667 25.7917 13.6667 21.4167C13.6667 17.0417 17.2084 13.5 21.5834 13.5C22.6751 13.5 23.7167 13.7167 24.6667 14.1167C24.5584 14.45 24.5001 14.8 24.5001 15.1667C24.5001 15.7917 24.6751 16.3833 24.9834 16.8833C25.1501 17.1667 25.3667 17.425 25.6167 17.6416C26.2001 18.175 26.9751 18.5 27.8334 18.5C28.2001 18.5 28.5501 18.4416 28.8751 18.325C29.2751 19.275 29.5001 20.325 29.5001 21.4167Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.1667 15.1668C31.1667 15.4335 31.1333 15.6918 31.0667 15.9418C30.9917 16.2752 30.8583 16.6002 30.6833 16.8835C30.2833 17.5585 29.6417 18.0835 28.875 18.3251C28.55 18.4418 28.2 18.5002 27.8333 18.5002C26.975 18.5002 26.2 18.1751 25.6167 17.6418C25.3667 17.4251 25.15 17.1668 24.9833 16.8835C24.675 16.3835 24.5 15.7918 24.5 15.1668C24.5 14.8002 24.5583 14.4502 24.6667 14.1168C24.825 13.6335 25.0917 13.2002 25.4417 12.8418C26.05 12.2168 26.9 11.8335 27.8333 11.8335C28.8167 11.8335 29.7083 12.2585 30.3083 12.9418C30.8417 13.5335 31.1667 14.3168 31.1667 15.1668Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.0749 15.1504H26.5916"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.8333 13.9336V16.4253"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23666"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23666"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23666"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const IconLinkPlatform = ({
    bgColor = 'white',
    color = 'black',
    outline = '#EAECF0',
}) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23379)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                />
                <rect
                    x="2.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke={outline}
                />
                <path
                    d="M19.5001 29.3332H24.5001C28.6667 29.3332 30.3334 27.6665 30.3334 23.4998V18.4998C30.3334 14.3332 28.6667 12.6665 24.5001 12.6665H19.5001C15.3334 12.6665 13.6667 14.3332 13.6667 18.4998V23.4998C13.6667 27.6665 15.3334 29.3332 19.5001 29.3332Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.9999 23.9168C23.6107 23.9168 24.9166 22.611 24.9166 21.0002C24.9166 19.3893 23.6107 18.0835 21.9999 18.0835C20.3891 18.0835 19.0833 19.3893 19.0833 21.0002C19.0833 22.611 20.3891 23.9168 21.9999 23.9168Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.6968 16.8332H26.7065"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23379"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23379"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23379"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const IconUnorderedList = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25 14.5C7.42 14.5 6.75 15.17 6.75 16C6.75 16.83 7.42 17.5 8.25 17.5C9.08 17.5 9.75 16.83 9.75 16C9.75 15.17 9.08 14.5 8.25 14.5ZM8.25 8.5C7.42 8.5 6.75 9.17 6.75 10C6.75 10.83 7.42 11.5 8.25 11.5C9.08 11.5 9.75 10.83 9.75 10C9.75 9.17 9.08 8.5 8.25 8.5ZM8.25 20.5C7.42 20.5 6.75 21.18 6.75 22C6.75 22.82 7.43 23.5 8.25 23.5C9.07 23.5 9.75 22.82 9.75 22C9.75 21.18 9.08 20.5 8.25 20.5ZM11.25 23H25.25V21H11.25V23ZM11.25 17H25.25V15H11.25V17ZM11.25 9V11H25.25V9H11.25Z"
                fill="#98A2B3"
            />
        </svg>
    )
}

export const IconProfileImage = ({ bgColor, color, outline = '#EAECF0' }) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23370)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                />
                <rect
                    x="2.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="7.5"
                    stroke={outline}
                />
                <path
                    d="M22.0999 21.6502C22.0416 21.6419 21.9666 21.6419 21.8999 21.6502C20.4333 21.6002 19.2666 20.4002 19.2666 18.9252C19.2666 17.4169 20.4833 16.1919 21.9999 16.1919C23.5083 16.1919 24.7333 17.4169 24.7333 18.9252C24.7249 20.4002 23.5666 21.6002 22.0999 21.6502Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.6166 27.1498C26.1333 28.5081 24.1666 29.3331 22 29.3331C19.8333 29.3331 17.8666 28.5081 16.3833 27.1498C16.4666 26.3665 16.9666 25.5998 17.8583 24.9998C20.1416 23.4831 23.875 23.4831 26.1416 24.9998C27.0333 25.5998 27.5333 26.3665 27.6166 27.1498Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0001 29.3332C26.6025 29.3332 30.3334 25.6022 30.3334 20.9998C30.3334 16.3975 26.6025 12.6665 22.0001 12.6665C17.3977 12.6665 13.6667 16.3975 13.6667 20.9998C13.6667 25.6022 17.3977 29.3332 22.0001 29.3332Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23370"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23370"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23370"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const IconProfile = ({
    bgColor = '#C7987A',
    color = 'white',
    outline = '#EAECF0',
}) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_205_23361)">
                <rect
                    x="2"
                    y="1"
                    width="40"
                    height="40"
                    rx="8"
                    fill={bgColor}
                    stroke={outline}
                />
                <path
                    d="M28.6666 28.5C28.6666 27.337 28.6666 26.7555 28.5231 26.2824C28.1999 25.217 27.3662 24.3834 26.3009 24.0602C25.8277 23.9167 25.2462 23.9167 24.0832 23.9167H19.9166C18.7536 23.9167 18.1721 23.9167 17.699 24.0602C16.6336 24.3834 15.8 25.217 15.4768 26.2824C15.3333 26.7555 15.3333 27.337 15.3333 28.5M25.7499 17.25C25.7499 19.3211 24.071 21 21.9999 21C19.9289 21 18.2499 19.3211 18.2499 17.25C18.2499 15.1789 19.9289 13.5 21.9999 13.5C24.071 13.5 25.7499 15.1789 25.7499 17.25Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_205_23361"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_205_23361"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_205_23361"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const TbDots = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
        </svg>
    )
}

export const IconSearch = ({ className }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
            stroke="#667085"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const IconDiscord = () => {
    return (
        <svg
            width="71"
            height="80"
            viewBox="0 0 71 80"
            fill="none"
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M60.1045 13.8978C55.5792 11.8214 50.7265 10.2916 45.6527 9.41542C45.5603 9.39851 45.468 9.44077 45.4204 9.52529C44.7963 10.6353 44.105 12.0834 43.6209 13.2216C38.1637 12.4046 32.7345 12.4046 27.3892 13.2216C26.905 12.0581 26.1886 10.6353 25.5617 9.52529C25.5141 9.44359 25.4218 9.40133 25.3294 9.41542C20.2584 10.2888 15.4057 11.8186 10.8776 13.8978C10.8384 13.9147 10.8048 13.9429 10.7825 13.9795C1.57795 27.7309 -0.943561 41.1443 0.293408 54.3914C0.299005 54.4562 0.335386 54.5182 0.385761 54.5576C6.45866 59.0174 12.3413 61.7249 18.1147 63.5195C18.2071 63.5477 18.305 63.5139 18.3638 63.4378C19.7295 61.5728 20.9469 59.6063 21.9907 57.5383C22.0523 57.4172 21.9935 57.2735 21.8676 57.2256C19.9366 56.4931 18.0979 55.6 16.3292 54.5858C16.1893 54.5041 16.1781 54.304 16.3068 54.2082C16.679 53.9293 17.0513 53.6391 17.4067 53.3461C17.471 53.2926 17.5606 53.2813 17.6362 53.3151C29.2558 58.6202 41.8354 58.6202 53.3179 53.3151C53.3935 53.2785 53.4831 53.2898 53.5502 53.3433C53.9057 53.6363 54.2779 53.9293 54.6529 54.2082C54.7816 54.304 54.7732 54.5041 54.6333 54.5858C52.8646 55.6197 51.0259 56.4931 49.0921 57.2228C48.9662 57.2707 48.9102 57.4172 48.9718 57.5383C50.038 59.6034 51.2554 61.5699 52.5959 63.435C52.6519 63.5139 52.7526 63.5477 52.845 63.5195C58.6464 61.7249 64.529 59.0174 70.6019 54.5576C70.6551 54.5182 70.6887 54.459 70.6943 54.3942C72.1747 39.0791 68.2147 25.7757 60.1968 13.9823C60.1772 13.9429 60.1437 13.9147 60.1045 13.8978ZM23.7259 46.3253C20.2276 46.3253 17.3451 43.1136 17.3451 39.1693C17.3451 35.225 20.1717 32.0133 23.7259 32.0133C27.308 32.0133 30.1626 35.2532 30.1066 39.1693C30.1066 43.1136 27.28 46.3253 23.7259 46.3253ZM47.3178 46.3253C43.8196 46.3253 40.9371 43.1136 40.9371 39.1693C40.9371 35.225 43.7636 32.0133 47.3178 32.0133C50.9 32.0133 53.7545 35.2532 53.6986 39.1693C53.6986 43.1136 50.9 46.3253 47.3178 46.3253Z"
                fill="#5865F2"
            />
        </svg>
    )
}
export const IconOrderedList = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 21H9V21.5H8V22.5H9V23H7V24H10V20H7V21ZM8 12H9V8H7V9H8V12ZM7 15H8.8L7 17.1V18H10V17H8.2L10 14.9V14H7V15ZM12 9V11H26V9H12ZM12 23H26V21H12V23ZM12 17H26V15H12V17Z"
                fill="#98A2B3"
            />
        </svg>
    )
}

export const MenuIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 12H21M3 6H21M9 18H21"
            stroke="#69884F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const CheckCircle = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={className}>
            <path
                fillRule="evenodd"
                fill="#f2f2f2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinejoin="inherit"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export const BiLinkExternal = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={className}>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
        />
    </svg>
)
